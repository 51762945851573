import { PaginatedMedia, QueryMediasInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_FOLDERS = 'GET_FOLDERS';
export const GET_FOLDERS_SUCCESS = 'GET_FOLDERS_SUCCESS';
export const GET_FOLDERS_ERROR = 'GET_FOLDERS_ERROR';

export interface GetFoldersAction {
  type: typeof GET_FOLDERS;
  payload: QueryMediasInput;
}

export interface GetFoldersActionSuccess {
  type: typeof GET_FOLDERS_SUCCESS;
  payload: PaginatedMedia;
}

export interface GetFoldersActionError {
  type: typeof GET_FOLDERS_ERROR;
  payload: AppError;
}

export type GetFoldersActionTypes = GetFoldersAction | GetFoldersActionSuccess | GetFoldersActionError;
