export interface ENV {
  apiEndPoint: string;
  assetsEndPoint: string;
  apiHost: string;
  root: string;
  pageTitle: string;
  tokenKey: string;
  appName: string;
  apiSubscriptionEndPoint: string;
  languageKey: string;
  formatDate: string;
  webrtcSignalingServer: string;
  webrtcStunServer: string;
  webrtcTurnServer: string;
  webrtcTurnUsername: string;
  webrtcTurnCredential: string;
}

const cwd = process.cwd();
const root = __dirname.replace(`${cwd}`, '.');
const env: ENV = {
  apiEndPoint: process.env.REACT_APP_API_END_POINT || '',
  assetsEndPoint: process.env.REACT_APP_ASSETS_END_POINT || '',
  apiHost: process.env.REACT_APP_API_HOST || '',
  apiSubscriptionEndPoint: process.env.REACT_APP_API_SUBSCRIPTION_END_POINT || '',
  root,
  pageTitle: process.env.REACT_APP_PAGE_TITLE || '',
  tokenKey: process.env.REACT_APP_TOKEN_KEY || '',
  appName: process.env.REACT_APP_NAME || '',
  languageKey: process.env.REACT_APP_LANGUAGE_KEY || '',
  formatDate: process.env.REACT_APP_FORMAT_DATE || '',
  webrtcSignalingServer: process.env.REACT_APP_WEB_RTC_SIGNALING_SERVER || '',
  webrtcStunServer: process.env.REACT_APP_WEB_RTC_STUN_SERVER || '',
  webrtcTurnServer: process.env.REACT_APP_WEB_RTC_TURN_SERVER || '',
  webrtcTurnUsername: process.env.REACT_APP_WEB_RTC_TURN_USER || '',
  webrtcTurnCredential: process.env.REACT_APP_WEB_RTC_TURN_CREDENTIAL || '',
};

export default env;
