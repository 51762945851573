import { Jwt, LoginInput, User } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { UPDATE_PROFILE_SUCCESS } from './update';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const SUPPER_ADMIN_AUTO_LOGIN = 'SUPPER_ADMIN_AUTO_LOGIN';
export const SUPPER_ADMIN_AUTO_LOGIN_SUCCESS = 'SUPPER_ADMIN_AUTO_LOGIN_SUCCESS';
export const VERIFY_TOKEN_AUTO_LOGIN = 'VERIFY_TOKEN_AUTO_LOGIN';

export interface AuthData {
  token: string;
  refreshToken: string;
  uid: string;
  expiresAt: number;
  refreshTokenExpiresAt: number;
}

export const Languages = [
  {
    key: 'no',
    name: 'Norwegian',
  },
  {
    key: 'en',
    name: 'English',
  },
  {
    key: 'vi',
    name: 'Vietnamese',
  },
];

export interface UserData extends User {
  companyId?: string | null;
  isSuperAdmin?: boolean;
}

export interface LoginData {
  login: Jwt;
}
export interface LoginState {
  loading: boolean;
  data?: UserData;
}
export interface VerifyTokenAutoLoginInput {
  token: string;
}

export interface LoginAction {
  type: typeof LOGIN;
  payload: LoginInput;
}

export interface LoginActionSuccess {
  type: typeof LOGIN_SUCCESS;
  payload: Jwt;
}

export interface LoginActionError {
  type: typeof LOGIN_ERROR;
  payload: AppError;
}

export interface SupperAdminLoginAction {
  type: typeof SUPPER_ADMIN_AUTO_LOGIN;
  payload: string;
}

export interface SupperAdminLoginActionSuccess {
  type: typeof SUPPER_ADMIN_AUTO_LOGIN_SUCCESS;
  payload: string;
}

export interface VerifyTokenAutoLoginAction {
  type: typeof VERIFY_TOKEN_AUTO_LOGIN;
  payload: VerifyTokenAutoLoginInput;
}
export interface UpdateProfileSuccessListener {
  type: typeof UPDATE_PROFILE_SUCCESS;
  payload: User;
}

export type LoginActionTypes =
  | LoginAction
  | LoginActionSuccess
  | LoginActionError
  | SupperAdminLoginAction
  | SupperAdminLoginActionSuccess
  | VerifyTokenAutoLoginAction
  | UpdateProfileSuccessListener;
