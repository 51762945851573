import { User } from '@/graphql/generated/graphql';
import ModalConfirm from '@commons/components/modals/ModalConfirm';
import TableHeader from '@commons/components/TableHeader';
import TableWithNumber from '@commons/components/TableWithNumber';
import UserCompanyRowAction from '@modules/Company/components/Action/UserCompanyRowAction';
import useGetCompanyUsers from '@modules/Company/hooks/useGetCompanyUsers';
import useKickOutCompany from '@modules/Company/hooks/useKickOutCompany';
import { PageSizeTable, UserCompanyFilter } from '@modules/Company/types';
import EditUserModal from '@modules/User/components/modal/EditUserModal';
import { Button, Form, Input } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';
import { useParams } from 'react-router';

interface IState {
  updateUserData?: User;
  deleteUserData?: User;
}

interface IProps {
  companyId: string;
}

export default function CompanyUsers(props: IProps) {
  const { t } = useTranslation(['company', 'user']);
  const [state, setState] = React.useState<IState>({
    updateUserData: undefined,
    deleteUserData: undefined,
  });
  const { isLoading: isKickOutLoading, mutate: onKickOutUserMutate, isSuccess: isKickOutSuccess } = useKickOutCompany();

  React.useEffect(() => {
    if (isKickOutSuccess) {
      onCancel();
    }
    // eslint-disable-next-line
  }, [isKickOutSuccess]);

  const onCancel = () => {
    setState({ ...state, updateUserData: undefined, deleteUserData: undefined });
  };

  const onKickOutUser = () => {
    if (state.deleteUserData) {
      onKickOutUserMutate({ input: { userId: state.deleteUserData?._id, companyId: props.companyId } });
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: t('stt'),
      key: '#',
      dataIndex: 'STT',
      width: 80,
      align: 'center',
    },
    {
      title: t('user:username'),
      key: 'username',
      dataIndex: 'username',
    },
    {
      title: t('user:email'),
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: t('user:role'),
      key: 'role',
      dataIndex: 'role',
    },
    {
      title: '',
      key: 'action',
      width: 60,
      render: (record: any) => (
        <UserCompanyRowAction
          record={record}
          onEdit={(record) => {
            setState({ ...state, updateUserData: record });
          }}
          onDelete={() => {
            setState({ ...state, deleteUserData: record });
          }}
        />
      ),
    },
  ];

  // eslint-disable-next-line
  const [paginate, setPaginate] = useState({
    page: 1,
    pageSize: PageSizeTable,
  });

  const { id } = useParams<{ id: string }>();

  // eslint-disable-next-line
  const [filter, setFilter] = useState<UserCompanyFilter>({});

  const { isLoading, data: listCompanyUsers } = useGetCompanyUsers({
    limit: PageSizeTable,
    companyId: id,
    page: paginate.page,
    query: filter.query,
  });

  // eslint-disable-next-line

  const [formSearch] = Form.useForm();

  const onSearch = (values: any) => {
    const input = {
      query: values.query ? values.query : undefined,
    };
    if (paginate.page !== 1) {
      setPaginate({
        ...paginate,
        page: 1,
      });
    }
    setFilter(input);
  };

  return (
    <TableHeader className="my-0" bodyStyle={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
      <div className="table-header-flex d-block d-xl-flex justify-content-end align-items-center">
        <Form layout="inline" form={formSearch} onFinish={onSearch} className="justify-content-end">
          <Form.Item className="mt-2 mt-xl-0">
            <Input.Group compact style={{ minWidth: '250px', width: 'auto' }}>
              <Form.Item name="query" className="w-full mr-0">
                <Input placeholder={`${t('search_staff')}...`} />
              </Form.Item>
              <Button icon={<FiSearch color="#F80000" />} className="btn-search" htmlType="submit" />
            </Input.Group>
          </Form.Item>
        </Form>
      </div>

      <TableWithNumber
        className="table-striped-rows"
        scroll={{
          x: 768,
        }}
        rowKey={(data) => data._id}
        columns={columns}
        dataSource={listCompanyUsers?.companyUsers?.items || []}
        loading={isLoading}
        pagination={false}
        paginate={{
          pageSize: paginate.pageSize,
          total: listCompanyUsers?.companyUsers?.total || 0,
          current: listCompanyUsers?.companyUsers?.currentPage || 1,
          onChange: (page) => {
            setPaginate({
              ...paginate,
              page,
            });
          },
        }}
      />

      <EditUserModal onCancel={onCancel} data={state.updateUserData} />
      <ModalConfirm
        isLoading={isKickOutLoading}
        data={state.deleteUserData}
        handleOk={onKickOutUser}
        handleCancel={onCancel}
        title={t('kick_user_title')}
        okTitle={t('confirm')}
        content={
          <Trans
            i18nKey="kick_user_content"
            t={t}
            values={{ username: state.deleteUserData?.username }}
            components={[<span className="primary_color fw-500"></span>]}
          />
        }
      />
    </TableHeader>
  );
}
