import { Roles } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import ic_location from '@assets/images/icons/location1.svg';
import ButtonCancel from '@commons/components/modals/Button/ButtonCancel';
import ButtonOk from '@commons/components/modals/Button/ButtonOk';
import { NotificationError } from '@commons/components/Notification';
import { FULL_DATE } from '@helpers/date';
import { getHistory } from '@helpers/history';
import { LoginState } from '@modules/Auth/redux/action-types';
import { LocationInput } from '@modules/Company/types';
import { getQueryVariable } from '@modules/Setting/helpers/utils';
import { RootState } from '@redux/reducers';
import { Card, Col, DatePicker, Form, Input, InputNumber, Row } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ModalMapView from '../Modal/ModalMapView';
import localeNO from 'antd/es/date-picker/locale/nb_NO';
import localeEN from 'antd/es/date-picker/locale/en_US';
import localeVI from 'antd/es/date-picker/locale/vi_VN';

interface PackageFormProps {
  isLoading: boolean;
  onFinish(values: any): void;
  isUpdate?: boolean;
  companyData?: any;
  classNameForm?: string;
}

export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

export default function CompanyForm(props: PackageFormProps) {
  const { t } = useTranslation(['company', 'login']);
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);
  const [form] = Form.useForm();
  const [location, setLocation] = useState<LocationInput>();
  const [visibleModal, setVisibleModal] = useState<boolean>(false);

  const { ref } = usePlacesWidget<any>({
    apiKey: GOOGLE_MAP_API_KEY,
    language: 'nor',
    options: {
      // @ts-ignore
      // string[] not working, work fine with string only
      types: 'country',
    },
    onPlaceSelected: async (place) => {
      const address: LocationInput = {
        name: place.formatted_address,
        lat: place.geometry?.location.lat(),
        lng: place.geometry?.location.lng(),
      };
      setLocation(address);
    },
  });

  React.useEffect(() => {
    if (props?.companyData) {
      form.setFieldsValue({
        ...props.companyData,
        startDate: props?.companyData?.startDate ? moment(props.companyData?.startDate) : undefined,
        endDate: props?.companyData?.endDate ? moment(props.companyData?.endDate) : undefined,
      });
      setLocation({
        name: props.companyData?.address,
        lat: props.companyData?.address_latitude,
        lng: props.companyData?.address_longitude,
      });
    }
    // eslint-disable-next-line
  }, [props.companyData]);

  const handleSubmit = (values: any) => {
    if (values.startDate && values.endDate && moment(values.startDate).isAfter(moment(values.endDate))) {
      NotificationError(t('warning'), t('smaller_than_date'));
      return;
    }
    const data: any = {
      email: values.email,
      phone: values.phone,
      name: values.name,
      address: location?.name,
      capacity: values.capacity,
      userLimit: values.userLimit,
      description: values.description,
      // domain: values.domain,
      address_latitude: location?.lat,
      address_longitude: location?.lng,
      limitUpload: Number(values.limitUpload),
      startDate: values.startDate ? moment(values.startDate).valueOf() : null,
      endDate: values.endDate ? moment(values.endDate).valueOf() : null,
    };
    props.onFinish(data);
  };

  const onCancel = () => {
    setVisibleModal(false);
  };

  return (
    <>
      <Row justify="space-between" align="middle" className="bg-white py-3 px-4">
        <Row align="middle"></Row>
        <Row>
          <Col span={24} className="d-flex justify-content-end">
            {!loginState.data?.companyId ? (
              <ButtonCancel
                classNameBtn="mr-3"
                title={t('cancel_add')}
                onClick={() => {
                  const query = getQueryVariable('page');
                  getHistory().replace(`/company/list?page=${query || 1}`, '/company/list');
                }}
              />
            ) : null}
            <ButtonOk
              key="confirm"
              onClick={() => form.submit()}
              title={props?.isUpdate ? t('save') : t('add_new')}
              isLoading={props.isLoading}
            />
          </Col>
        </Row>
      </Row>
      <Card
        title={t('general_information')}
        bordered={false}
        headStyle={{ borderBottom: 'none', marginTop: '1rem', paddingTop: '0.5rem' }}
        bodyStyle={{ marginLeft: '2rem', marginRight: '2rem', paddingTop: '1.5rem' }}
        className={`card-form form-company ${props.classNameForm}`}
      >
        <Form form={form} onFinish={handleSubmit} labelAlign="left" labelCol={{ xs: 24, md: 6, xl: 4 }} colon={false}>
          {/* <Divider className="mt-2 mt-lg-2" /> */}
          <Form.Item
            name="name"
            label={t('company_name')}
            rules={[
              { whitespace: true, message: t('validate.required_name') },
              { required: true, message: t('validate.required_name') },
              { max: 150, message: t('validate.field_long') },
            ]}
          >
            <Input maxLength={150} placeholder={t('company_name')} />
          </Form.Item>
          <Row className="form-row-2-fields">
            <Col xs={24} xl={12}>
              <Form.Item
                name="email"
                label={t('email')}
                rules={[
                  { whitespace: true, message: t('validate.required_username') },
                  { required: true, message: t('validate.required_username') },
                  {
                    pattern: /^([a-zA-Z0-9_\-\\.]+)@([a-zA-Z0-9_\-\\.]+)\.([a-zA-Z]{2,5})$/,
                    message: t('validate.invalid_email'),
                  },
                  { max: 150, message: t('validate.field_long') },
                ]}
                labelCol={{ xs: 24, md: 6, xl: 8 }}
              >
                <Input maxLength={150} placeholder={t('email')} autoCorrect="off" autoCapitalize="none" />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item
                name="phone"
                label={t('phone')}
                rules={[
                  { whitespace: true, message: t('validate.required_phone') },
                  { required: true, message: t('validate.required_phone') },
                  { max: 20, message: t('validate.field_long') },
                ]}
                labelCol={{ xs: 24, md: 6, xl: 8 }}
                className=""
              >
                <Input maxLength={20} placeholder={t('phone')} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label={t('company_address')}>
            <div className="input-address-map">
              <input
                className="ant-input"
                ref={ref}
                defaultValue={location?.name || ''}
                onChange={(e) => {
                  e.persist();
                  setLocation((state) => {
                    return { ...state, name: e.target?.value, lat: undefined, lng: undefined };
                  });
                }}
                placeholder={t('company_address')}
                autoComplete="new-password"
                spellCheck="false"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    return false;
                  }
                }}
              />
              <div
                className="view_map"
                onClick={() => {
                  if (location && location.lat && location.lng) {
                    setVisibleModal(true);
                  }
                }}
              >
                <img src={ic_location} alt="location" width={16} height={16} />
                <p>{t('view_on_map')}</p>
              </div>
            </div>
          </Form.Item>

          <Form.Item
            name="description"
            label={t('description')}
            rules={[{ max: 500, message: t('validate.field_long') }]}
          >
            <Input.TextArea rows={5} placeholder={t('description')} />
          </Form.Item>
          <Form.Item
            name="limitUpload"
            label={`${t('limit_upload')} (MB)`}
            rules={[
              { required: true, message: t('validate.required_limit') },
              {
                validator: async (_, limit) => {
                  if (limit && Number(limit) < 0) {
                    return Promise.reject(new Error(t('validate.limit_min')));
                  }
                },
              },
            ]}
          >
            <InputNumber
              min={0}
              style={{ width: '100%' }}
              disabled={loginState.data?.role !== Roles.SuperAdmin && !loginState.data?.isSuperAdmin}
              placeholder={t('limit_upload')}
            />
          </Form.Item>
          <Form.Item name="startDate" label={`${t('start_date')}`} rules={[]}>
            <DatePicker
              style={{ width: '100%' }}
              className="ant-input"
              // onChange={onChange}
              disabledDate={(current) => {
                return current && moment(current).subtract(-1, 'days').isBefore(moment());
              }}
              format={FULL_DATE}
              locale={i18n.language === 'en' ? localeEN : i18n.language === 'no' ? localeNO : localeVI}
              disabled={loginState.data?.role !== Roles.SuperAdmin && !loginState.data?.isSuperAdmin}
              placeholder={t('start_date')}
            />
          </Form.Item>
          <Form.Item name="endDate" label={`${t('end_date')}`} rules={[]}>
            <DatePicker
              style={{ width: '100%' }}
              className="ant-input"
              locale={i18n.language === 'en' ? localeEN : i18n.language === 'no' ? localeNO : localeVI}
              disabledDate={(current) => {
                const from = form.getFieldValue('fromDate');
                return from
                  ? current.isBefore(moment(from))
                  : current && moment(current).subtract(-1, 'days').isBefore(moment());
              }}
              // onChange={onChange}
              format={FULL_DATE}
              disabled={loginState.data?.role !== Roles.SuperAdmin && !loginState.data?.isSuperAdmin}
              placeholder={t('end_date')}
            />
          </Form.Item>
        </Form>
        <ModalMapView data={location} visible={visibleModal} onCancel={onCancel} />
      </Card>
    </>
  );
}
