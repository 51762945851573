import { all, fork, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../action-types';
import { changePasswordAsync, changePasswordSuccessAsync } from './change-password';
import { requestEmailAsync, requestEmailSuccessAsync } from './forgot-password';
import {
  autoLoginFlow,
  loginAsync,
  loginSuccessAsync,
  supperAdminAutoLoginAsync,
  verifyTokenAutoLoginAsync,
} from './login';
import { logoutAsync, logoutSuccessAsync } from './logout';
import { resetPasswordAsync, resetPasswordSuccessAsync } from './reset-password';

export default function* root(): any {
  return all([
    yield takeLatest(actionTypes.LOGIN, loginAsync),
    yield takeLatest(actionTypes.LOGIN_SUCCESS, loginSuccessAsync),
    yield takeLatest(actionTypes.LOGOUT, logoutAsync),
    yield takeLatest(actionTypes.LOGOUT_SUCCESS, logoutSuccessAsync),
    yield takeLatest(actionTypes.REQUEST_EMAIL, requestEmailAsync),
    yield takeLatest(actionTypes.REQUEST_EMAIL_SUCCESS, requestEmailSuccessAsync),
    yield takeLatest(actionTypes.RESET_PASSWORD, resetPasswordAsync),
    yield takeLatest(actionTypes.RESET_PASSWORD_SUCCESS, resetPasswordSuccessAsync),
    yield takeLatest(actionTypes.CHANGE_PASSWORD, changePasswordAsync),
    yield takeLatest(actionTypes.CHANGE_PASSWORD_SUCCESS, changePasswordSuccessAsync),
    yield takeLatest(actionTypes.SUPPER_ADMIN_AUTO_LOGIN, supperAdminAutoLoginAsync),
    yield takeLatest(actionTypes.VERIFY_TOKEN_AUTO_LOGIN, verifyTokenAutoLoginAsync),
    yield fork(autoLoginFlow),
  ]);
}
