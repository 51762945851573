import { MailOutlined } from '@ant-design/icons';
import CaretDownOutlined from '@ant-design/icons/lib/icons/CaretDownOutlined';
import flagN from '@assets/images/norway.svg';
import flagE from '@assets/images/united-kingdom.svg';
import flagV from '@assets/images/vietnam.svg';
import logo from '@assets/images/WinSmartSignLogoBlack.svg';
import { CommonPath } from '@commons/base-routes';
import { Languages, RequestEmailInput } from '@modules/Auth/redux/action-types';
import { Button, Form, Input, Select } from 'antd';
import { FormProps } from 'antd/es/form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../../Login/styled/LoginForm/index.scss';
import { setDefaultLanguage } from '@helpers/token';

interface Props extends FormProps {
  onRequest(email: RequestEmailInput): void;
  loading: boolean;
}

const ForgotPasswordForm = (props: Props) => {
  const onFinish = (values: any) => {
    props.onRequest(values);
  };

  const { i18n, t } = useTranslation(['login', 'forgot-password']);

  const changeLanguage = (lng: string) => {
    setDefaultLanguage(lng);
  };

  const { Option } = Select;
  return (
    <div className="login-container">
      <div className="login-content">
        <div className="change-language d-flex">
          <Select
            defaultValue={i18n?.language?.toString()}
            style={{ minWidth: 100 }}
            className="select_lang color_text_header ml-auto"
            onChange={(e) => changeLanguage(e)}
            placeholder={t('select_language')}
            value={i18n?.language?.toString()}
            suffixIcon={<CaretDownOutlined style={{ width: 12, color: '#999999' }} />}
          >
            {Languages.map((item) => {
              return (
                <Option key={item.key} value={item.key}>
                  <div className="d-flex align-items-center justify-content-start item_select_change_language">
                    <img
                      src={item.key === 'en' ? flagE : item.key === 'vi' ? flagV : flagN}
                      alt="flag"
                      className="mr-1 ic_lang"
                    />
                    {item.name}
                  </div>
                </Option>
              );
            })}
          </Select>
        </div>
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <Form name="login-form" className="login-form" onFinish={onFinish}>
          <Form.Item name="email" rules={[{ required: true, message: t('validate.required_username') }]}>
            <Input
              autoFocus={true}
              className="input_username"
              type="email"
              prefix={<MailOutlined className="site-form-item-icon" style={{ marginRight: 18 }} />}
              placeholder="Email"
              autoCorrect="off"
              autoCapitalize="none"
            />
          </Form.Item>

          <Form.Item className="login-form-submit">
            <Button loading={props.loading} htmlType="submit" type="primary" className="login-form-button">
              {t('forgot-password:send_email')}
            </Button>
          </Form.Item>
          <Link to={CommonPath.LOGIN_PATH}>
            <span className="text-link">{t('login')}</span>
          </Link>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
