import { GetPlaylistDetailQuery, Playlist } from '@/graphql/generated/graphql';
// import { NotificationSuccess } from '@commons/components/Notification';
import { getPlaylistApi } from '@modules/PlayList/services/apis';
import { put } from 'redux-saga/effects';
import { GetPlaylistAction } from '../action-types';
import { getPlaylistError, getPlaylistSuccess } from '../actions/get_playlist';
// import i18n from '@/i18n';

export function* getPlaylistAsync(action: GetPlaylistAction) {
  try {
    const data: GetPlaylistDetailQuery = yield getPlaylistApi(action.payload);

    if (data.playlist) {
      yield put(getPlaylistSuccess(data.playlist as Playlist));
    }
  } catch (error) {
    yield put(getPlaylistError(error));
  }
}

export function getPlaylistAsyncSuccess() {
  // NotificationSuccess(i18n.t('notification'), i18n.t('update_success'));
}

export function getPlaylistAsyncError() {}
