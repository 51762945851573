export const validURL = (str: string) => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
};

export function _formatNum(num: string | number) {
  if (num) return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  return '';
}

export const converBytesToMB = (bytes: number) => {
  return Number((bytes / 1048576).toFixed(2));
};

export const converBytesToMBNofix = (bytes: number) => {
  return Number(bytes / 1048576);
};
