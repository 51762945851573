import { PaginatedMedia, QueryMediasInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_MEDIAS = 'GET_MEDIAS';
export const GET_MEDIAS_SUCCESS = 'GET_MEDIAS_SUCCESS';
export const GET_MEDIAS_ERROR = 'GET_MEDIAS_ERROR';

export interface GetMediasAction {
  type: typeof GET_MEDIAS;
  payload: QueryMediasInput;
}

export interface GetMediasActionSuccess {
  type: typeof GET_MEDIAS_SUCCESS;
  payload: PaginatedMedia;
}

export interface GetMediasActionError {
  type: typeof GET_MEDIAS_ERROR;
  payload: AppError;
}

export type GetMediasActionTypes = GetMediasAction | GetMediasActionSuccess | GetMediasActionError;
