/* eslint-disable react-hooks/exhaustive-deps */
import env from '@/env';
import { MediaFilterType, QueryMediasInput, Setting, SettingType } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import {
  AppstoreOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  SortAscendingOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import icon_filter from '@assets/images/icons/icon_filter.svg';
import icon_refresh_media from '@assets/images/icons/icon_refresh_media.svg';
import icon_upload from '@assets/images/icons/icon_upload.svg';
import TableHeader from '@commons/components/TableHeader';
import { converBytesToMB } from '@helpers/regex';
import { getAuthLocalData } from '@helpers/token';
import EN from '@helpers/uppystringlocale/Uppy_en';
import NOR from '@helpers/uppystringlocale/Uppy_no';
import vi_VN from '@helpers/uppystringlocale/Uppy_vi';
import { useCustomSelector } from '@hooks/useCustomSelector';
import { LoginState } from '@modules/Auth/redux/action-types';
import useGetCompanyByID from '@modules/Company/hooks/useGetCompanyByID';
import FolderModal from '@modules/Media/component/modal/FolderModal';
import { allowedMimeTypes } from '@modules/Media/helper/file';
import useGetAllMedia from '@modules/Media/hooks/useGetAllMedia';
import { uploadFileActionSuccess } from '@modules/Media/redux/actions';
import { pageDefault } from '@modules/Media/types';
import { getObjectSettingBySettingType } from '@modules/Setting/helpers/utils';
import useGetSettingDetail from '@modules/Setting/hooks/useGetSettingDetail';
import { RootState } from '@redux/reducers';
import Uppy, { UppyFile } from '@uppy/core';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { DashboardModal } from '@uppy/react';
import '@uppy/status-bar/dist/style.css';
import XHRUpload from '@uppy/xhr-upload';
import { Button, Dropdown, Form, Input, Menu, message } from 'antd';
import { isNumber } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';

interface IProps {
  filterType: any;
  setFilterType: (filterType: any) => void;
  filterTitle: any;
  setFilterTitle: (filterTitle: any) => void;
  searchState: QueryMediasInput;
  setSearchState: (input: any) => void;
  mediaInfo: any;
  setMediaInfo: (input: any) => void;
  onSelectFolder: (input: any) => void;
  currentStorage: any;
  setCurrentStorage: (input: any) => void;
  setLimitStorage: (input: any) => void;
}

const defaultRefreshInput = {
  page: pageDefault.page,
  limit: pageDefault.pageSize,
  filterType: null,
  isLoadMore: false,
  parentId: null,
  order: { direction: 'Desc', field: 'CREATED_AT' },
  query: null,
};

export default function MediaHeaderComponent(props: IProps) {
  const { t } = useTranslation(['media']);
  const authData = getAuthLocalData();
  const dispatch = useDispatch();
  const [openUploadModal, setOpenUploadModal] = useState(false);

  const [form] = Form.useForm();
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);
  const { data: company, refetch } = useGetCompanyByID({
    _id: loginState.data?.companyId || '',
  });
  const { data: dataSetting } = useGetSettingDetail();
  const dataUploadSetting = getObjectSettingBySettingType(dataSetting?.settings as Setting[] | any, SettingType.Upload);
  const [currentMedia, setCurrentMedia] = useState(0);
  const mediasData = useCustomSelector((state) => {
    return state?.media?.list?.data;
  });
  const { onGetMedias } = useGetAllMedia();

  let uppy = useRef(new Uppy());

  const onSearch = (value: any) => {
    props.setSearchState({ ...props.searchState, filterType: null, query: value.query });
    props.setFilterTitle('');
  };

  // Uppy upload
  useEffect(() => {
    if (company) {
      if (currentMedia > 0 && currentMedia === mediasData?.items?.length) return;
      setCurrentMedia(mediasData?.items?.length || 0);
      uppy.current = new Uppy({
        restrictions: {
          maxFileSize: Number(dataUploadSetting.maxFileSize) * 1048576,
          allowedFileTypes: allowedMimeTypes,
        },
        meta: {
          parentId: mediasData?.currentFolder?._id.toString() || '',
        },

        onBeforeUpload: (allFiles: { [key: string]: UppyFile<any> }) => {
          // props.setMediaInfo(undefined);
          let size = 0;
          // eslint-disable-next-line
          for (const [key, file] of Object.entries(allFiles)) {
            size += file?.size;
          }

          const limitUpload = company?.company?.limitUpload || 0;
          const fileSize = converBytesToMB(size);
          const curentSize = fileSize + (company?.company?.currentStorageSize || 0);

          if (Number(curentSize.toFixed(2)) > limitUpload) {
            message.error(`${t('max_file_size')} ${limitUpload - (company?.company?.currentStorageSize || 0)}MB`, 5);
            return false;
          }
          if (fileSize > Number(dataUploadSetting.maxFileSize)) {
            message.error(`${t('max_file_size')} ${dataUploadSetting.maxFileSize}MB`, 5);
            return false;
          }

          if (
            limitUpload > 0 &&
            Number(converBytesToMB(company?.company?.currentStorageSize || 0)) + Number(converBytesToMB(size)) <=
              limitUpload
          ) {
            return true;
          } else {
            message.error(`${t('out_of_storage_space')}`, 9999);
            return false;
          }
        },
      });
      uppy.current
        .use(XHRUpload, {
          id: company.company?._id,
          endpoint: `${env.apiHost}/medias/uploadFile`,
          formData: true,
          headers: {
            authorization: `Bearer ${authData?.token}`,
          },
        })
        .on('file-added', (file: any) => {
          uppy.current.setFileMeta(file.id, {
            size: file.size,
            parentId: mediasData?.currentFolder?._id.toString() || '',
          });
        })
        .on('upload-error', (file: any) => {
          uppy.current.setFileMeta(file.id, {
            size: file.size,
            parentId: mediasData?.currentFolder?._id.toString() || '',
          });
        })
        .on('complete', (data: any) => {
          data?.successful?.map((media: any) => {
            if (media?.response?.body && media?.response?.body?._id) {
              dispatch(uploadFileActionSuccess(media?.response?.body));
            }
          });
        });
    }

    return () => {};
  }, [company, mediasData?.currentFolder, t, dataUploadSetting, mediasData?.items?.length, openUploadModal]);

  useEffect(() => {
    if (mediasData?.items?.length) {
      refetch();
    }
    return () => {};
  }, [mediasData?.items?.length]);

  useEffect(() => {
    if (company?.company && isNumber(company?.company?.currentStorageSize) && isNumber(company?.company?.limitUpload)) {
      props.setCurrentStorage(company.company.currentStorageSize);
      props.setLimitStorage(company.company.limitUpload);
    }
    // props.onSelectFolder(null);
    // eslint-disable-next-line
  }, [company?.company]);

  const renderFilter = () => {
    const handleMenuClick = (e: any) => {
      switch (Number(e.key)) {
        case 1:
          props.setFilterTitle(`(${t('everything')})`);
          props.searchState.filterType = null;
          props.setFilterType(null);
          break;
        case 2:
          props.setFilterTitle(`(${t('image')})`);
          props.searchState.filterType = MediaFilterType.Image;
          props.setFilterType(MediaFilterType.Image);
          break;
        case 3:
          props.setFilterTitle(`(${t('video')})`);
          props.searchState.filterType = MediaFilterType.Video;
          props.setFilterType(MediaFilterType.Video);
          break;
        case 4:
          props.setFilterTitle(`(${t('pdf')})`);
          props.searchState.filterType = MediaFilterType.Pdf;
          props.setFilterType(MediaFilterType.Pdf);
          break;
        case 5:
          props.setFilterTitle(`(${t('powerpoint')})`);
          props.searchState.filterType = MediaFilterType.Powerpoint;
          props.setFilterType(MediaFilterType.Powerpoint);
          break;
        default:
          props.setFilterTitle('');
          props.searchState.filterType = null;
          props.searchState.filterType = null;
          props.setFilterType(null);
          break;
      }

      onGetMedias({
        ...props.searchState,
        isLoadMore: false,
      });
    };

    // const handleSortClick = (e: any) => {
    //   switch (Number(e.key)) {
    //     case 1:
    //       props.setSearchState({
    //         ...props.searchState,
    //         order: { direction: Sort.Asc, field: MediaOrderField.CreatedAt },
    //       });

    //       break;
    //     case 2:
    //       props.setSearchState({
    //         ...props.searchState,
    //         order: { direction: Sort.Desc, field: MediaOrderField.CreatedAt },
    //       });
    //       break;
    //     case 3:
    //       props.setSearchState({ ...props.searchState, order: { direction: Sort.Asc, field: MediaOrderField.Name } });
    //       break;
    //     case 4:
    //       props.setSearchState({ ...props.searchState, order: { direction: Sort.Desc, field: MediaOrderField.Name } });
    //       break;

    //     default:
    //       break;
    //   }
    // };

    const menu = (
      <Menu onClick={handleMenuClick}>
        <Menu.Item key="1" icon={<SortAscendingOutlined />}>
          {t('everything')}
        </Menu.Item>
        <Menu.Item key="2" icon={<FileImageOutlined />}>
          {t('image')}
        </Menu.Item>
        <Menu.Item key="3" icon={<VideoCameraOutlined />}>
          {t('video')}
        </Menu.Item>
        <Menu.Item key="4" icon={<FilePdfOutlined />}>
          {t('pdf')}
        </Menu.Item>
        <Menu.Item key="5" icon={<AppstoreOutlined />}>
          {t('powerpoint')}
        </Menu.Item>
      </Menu>
    );

    // const menuSort = (
    //   <Menu onClick={handleSortClick}>
    //     <Menu.Item key="1" icon={<FaLongArrowAltUp size={16} />}>
    //       {t('created_at')}
    //     </Menu.Item>
    //     <Menu.Item key="2" icon={<FaLongArrowAltDown size={16} />}>
    //       {t('created_at')}
    //     </Menu.Item>
    //     <Menu.Item key="3" icon={<FaLongArrowAltUp size={16} />}>
    //       {t('text_name')}
    //     </Menu.Item>
    //     <Menu.Item key="4" icon={<FaLongArrowAltDown size={16} />}>
    //       {t('text_name')}
    //     </Menu.Item>
    //   </Menu>
    // );

    return (
      <div className="filter d-flex py-2  justify-content-between">
        <div className="d-flex flex-wrap align-items-center justify-content-start left-side">
          <Button
            className="buttonHeader"
            onClick={() => {
              setOpenUploadModal(true);
            }}
          >
            <img src={icon_upload} className="icon-btn" alt="icon_create" />
            {t('upload')}
          </Button>
          <FolderModal />
          <Dropdown overlay={menu} className="buttonHeader">
            <Button className="buttonHeader">
              <img src={icon_filter} className="icon-btn" alt="icon_create" />
              {`${t('filter')} ${props.filterTitle}`}
            </Button>
          </Dropdown>
          {/* <Dropdown overlay={menuSort} className="buttonHeader">
            <Button className="buttonHeader">
              {props.searchState.order?.direction === Sort.Asc &&
              props.searchState.order.field === MediaOrderField.CreatedAt ? (
                <div className="d-flex align-items-center">
                  <FaLongArrowAltUp size={16} className="mr-2" />
                  <span> {t('created_at')}</span>
                </div>
              ) 
              : props.searchState.order?.direction === Sort.Desc &&
                props.searchState.order.field === MediaOrderField.CreatedAt ? (
                <div className="d-flex align-items-center">
                  <FaLongArrowAltDown size={16} className="mr-2" />
                  <span> {t('created_at')}</span>
                </div>
              ) 
              : props.searchState.order?.direction === Sort.Desc &&
                props.searchState.order.field === MediaOrderField.Name ? (
                <div className="d-flex align-items-center">
                  <FaLongArrowAltDown size={16} className="mr-2" />
                  <span> {t('text_name')}</span>
                </div>
              ) : props.searchState.order?.direction === Sort.Asc &&
                props.searchState.order.field === MediaOrderField.Name ? (
                <div className="d-flex align-items-center">
                  <FaLongArrowAltUp size={16} className="mr-2" />
                  <span> {t('text_name')}</span>
                </div>
              ) : (
                <>
                  <div className="d-flex align-items-center">
                    <FaLongArrowAltDown size={16} className="mr-2" />
                    <span> {t('created_at')}</span>
                  </div>
                </>
              )}
            </Button>
          </Dropdown> */}
          <Button
            className="buttonHeader"
            onClick={() => {
              props.setSearchState(defaultRefreshInput);
              props.setFilterTitle('');
              form.setFieldsValue({
                query: '',
              });
            }}
          >
            <img src={icon_refresh_media} className="icon-btn" alt="icon_create" />
            {t('refresh')}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <TableHeader>
      <div className="header-page-media px-2 bg-white mb-2 w-100 d-flex flex-wrap align-items-center justify-content-between">
        {renderFilter()}
        <Form layout="inline" form={form} onFinish={onSearch} className="">
          <Form.Item>
            <Input.Group compact>
              <Form.Item name="query" className="w-full mr-0">
                <Input placeholder={`${t('search')}`} className="search_input" />
              </Form.Item>
              <Button icon={<FiSearch color="#F80000" />} className="btn-search" htmlType="submit" />
            </Input.Group>
          </Form.Item>
        </Form>
      </div>
      <DashboardModal
        uppy={uppy.current}
        open={openUploadModal}
        translate="yes"
        locale={i18n.language === 'no' ? NOR : i18n.language === 'en' ? EN : vi_VN}
        closeAfterFinish={true}
        animateOpenClose={true}
        closeModalOnClickOutside={true}
        proudlyDisplayPoweredByUppy={false}
        onRequestClose={() => {
          setOpenUploadModal(false);
          message.destroy();
        }}
      />
    </TableHeader>
  );
}
