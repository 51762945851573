import { ScheduleType, SpecialDayScheduleInputData } from '@/graphql/generated/graphql';
import { ArrowRightOutlined, PlusOutlined } from '@ant-design/icons';
import icon_delete_2 from '@assets/images/icons/icon_delete2.svg';
import { checkTimeInDay, formatMomentDate, FULL_DATE, HOUR_MINUTE } from '@helpers/date';
import useUpdateDevicePlaylistSchedules from '@modules/GroupDevice/hooks/useUpdateScheduleGroup';
import { CurrentPlaylistSchedulesState } from '@modules/GroupDevice/redux/reducers/current_playlist_schedule';
import { TimeInDayI } from '@modules/GroupDevice/types';
import { RootState } from '@redux/reducers';
import { Button, Col, DatePicker, Form, Input, Row, Space, TimePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ErrorTimeInDayState } from '../Shedule/Right';

const { RangePicker } = DatePicker;

interface SpecialDayProps {
  onCancelSchedule?: () => void;
  errorTimeInDay: ErrorTimeInDayState;
  onCancel: () => void;
}

interface SpecialDayScheduleDataInput {
  timeInDay?: TimeInDayI;
  date?: string | undefined;
}

const SpecialDay1Component = (props: SpecialDayProps, ref: any) => {
  const { t } = useTranslation(['group-device']);
  const [specialForm] = Form.useForm();
  const { onUpdatePlaylistScheduleGroupDevice } = useUpdateDevicePlaylistSchedules();

  const currentPlaylistScheduleState = useSelector<RootState, CurrentPlaylistSchedulesState>(
    (state) => state?.groupDevice?.currentPlaylistSchedule,
  );

  const [errorTimeInDay, setErrorTimeInDay] = useState<ErrorTimeInDayState[]>([
    {
      message: '',
      status: false,
    },
  ]);

  const disabledDate: RangePickerProps['disabledDate'] = (current: any) => {
    // Can not select days before today and today
    return current && current < moment().startOf('day');
  };

  useEffect(() => {
    if (
      currentPlaylistScheduleState.data?.type === ScheduleType.SpecialDay &&
      currentPlaylistScheduleState.data.specialDayData
    ) {
      const specialDays: any = [];
      currentPlaylistScheduleState?.data?.specialDayData.map((item) => {
        specialDays.push({
          dates: item.date
            ? [moment(item.date, FULL_DATE), item.toDate ? moment(item.toDate, FULL_DATE) : undefined]
            : undefined,
          startOfDay: item?.timeInDay ? moment(item.timeInDay.startOfDay, HOUR_MINUTE) : undefined,
          endOfDay: item.timeInDay?.endOfDay ? moment(item.timeInDay?.endOfDay, HOUR_MINUTE) : undefined,
          name: item.name || '',
        });
      });
      specialForm.setFieldsValue({
        specialDays,
      });
    } else {
      specialForm.setFieldsValue({
        specialDays: [
          {
            dates: undefined,
            startOfDay: undefined,
            endOfDay: undefined,
            name: '',
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlaylistScheduleState.data]);

  useImperativeHandle(
    ref,
    () => ({
      onUpdatePlaylistScheduleGroup: () => {
        specialForm.submit();
      },
    }),
    // eslint-disable-next-line
    [],
  );

  const onFinish = (values: any) => {
    const specialDayData: SpecialDayScheduleInputData[] = [];
    const nArray: any = [];
    if (values?.specialDays) {
      values?.specialDays?.map((item: any, index: number) => {
        if (item?.startOfDay && !item?.endOfDay) {
          nArray[index] = {
            status: true,
            message: t('group-device:schedule.validate.required_end_time'),
          };
          setErrorTimeInDay(nArray);
          return;
        }
        if (!item?.startOfDay && item?.endOfDay) {
          nArray[index] = {
            status: true,
            message: t('group-device:schedule.validate.required_start_time'),
          };
          setErrorTimeInDay(nArray);
          return;
        }
        let timeInDay;
        if (item?.endOfDay && item?.startOfDay) {
          if (!checkTimeInDay(item?.startOfDay, item?.endOfDay)) {
            nArray[index] = {
              status: true,
              message: t('group-device:schedule.validate.time_in_day'),
            };
            setErrorTimeInDay(nArray);
            return;
          }
          timeInDay = {
            startOfDay: formatMomentDate(item?.startOfDay, HOUR_MINUTE),
            endOfDay: formatMomentDate(item?.endOfDay, HOUR_MINUTE),
          };
        }
        specialDayData.push({
          name: item.name || '',
          date: formatMomentDate(item.dates[0], FULL_DATE),
          toDate: formatMomentDate(item.dates[1], FULL_DATE),
          timeInDay,
        });
      });
    }

    if (nArray && nArray?.length > 0) {
      return;
    }

    const scheduleData: any = {
      playlistId: (currentPlaylistScheduleState.data && currentPlaylistScheduleState.data.playlistId) || '',
      playlistName: currentPlaylistScheduleState.data?.playlistName,
      type: ScheduleType.SpecialDay,
      index: currentPlaylistScheduleState.data?.index,
      specialDayData,
    };
    onUpdatePlaylistScheduleGroupDevice(scheduleData);
    props.onCancel();
  };

  const handleSelectTime = (key: number, type: number, value: any) => {
    const specialDays: any = specialForm.getFieldValue('specialDays');
    const newData: any = [];
    if (specialDays) {
      specialDays?.map((item: any, index: number) => {
        if (index === key) {
          if (type === 1) {
            newData.push({
              ...item,
              startOfDay: value,
            });
          } else {
            newData.push({
              ...item,
              endOfDay: value,
            });
          }
        } else {
          newData.push(item);
        }
      });
    }
    specialForm.setFieldsValue({
      specialDays: newData,
    });
  };

  return (
    <div className="schedule-type-content schedule-special-day">
      <Form name="dynamic_form_nest_item" autoComplete="off" form={specialForm} onFinish={onFinish}>
        <div className="mb-2">
          <label className="key">{t('special_day_list')}:</label>
        </div>
        <Form.List name="specialDays">
          {(fields, { add, remove }) => (
            <>
              <Form.Item>
                <Button
                  type="dashed"
                  className="btn-add-new-dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  {t('button:add_new')}
                </Button>
              </Form.Item>

              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} className="d-flex align-items-center flex-column justify-content-between day-item">
                  <div className="d-flex align-items-center justify-content-between">
                    <Form.Item {...restField} name={[name, 'name']} className="mb-0">
                      <Input placeholder={t('enter_name_here')} className="day-name" />
                    </Form.Item>
                    <div>
                      {fields.length > 1 ? (
                        <Button
                          icon={<img src={icon_delete_2} alt="icon delete" />}
                          className="btn-delete-day-item"
                          onClick={() => {
                            if (fields.length > 1) {
                              remove(name);
                            }
                          }}
                        >
                          {t('button:delete')}
                        </Button>
                      ) : null}
                    </div>
                  </div>
                  <div className="w-100" style={{ display: 'flex', marginBottom: 8 }}>
                    <Row gutter={30}>
                      <Col span={10}>
                        <Form.Item
                          {...restField}
                          name={[name, 'dates']}
                          className="mb-2"
                          rules={[
                            {
                              required: true,
                              message: t('validate.required'),
                            },
                          ]}
                        >
                          <RangePicker
                            format={FULL_DATE}
                            allowEmpty={[false, true]}
                            placeholder={[t('schedule.start_date'), t('schedule.end_date')]}
                            disabledDate={disabledDate}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={14}>
                        <div className={`d-flex align-items-center justify-content-end`}>
                          <div>
                            <Form.Item name={[name, 'startOfDay']} className="mb-0">
                              <TimePicker
                                format={HOUR_MINUTE}
                                showNow={false}
                                allowClear
                                placeholder={t('group-device:schedule.open_time')}
                                onSelect={(e) => {
                                  handleSelectTime(key, 1, e);
                                }}
                              />
                            </Form.Item>
                          </div>
                          <ArrowRightOutlined style={{ fontSize: 14, margin: '0 15px', color: '#999999' }} />
                          <div>
                            <Form.Item name={[name, 'endOfDay']} className="mb-0">
                              <TimePicker
                                format={HOUR_MINUTE}
                                showNow={false}
                                allowClear
                                placeholder={t('group-device:schedule.close_time')}
                                onSelect={(e) => {
                                  handleSelectTime(key, 2, e);
                                }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                      <Col
                        span={24}
                        md={{ span: 14, offset: 10 }}
                        className="d-flex align-items-center justify-content-between"
                      >
                        {errorTimeInDay[key]?.status && (
                          <div className="ant-form-item-explain ant-form-item-explain-error w-100 text-right">
                            <div role="alert">{errorTimeInDay[key]?.message}</div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Space>
              ))}
            </>
          )}
        </Form.List>
      </Form>
    </div>
  );
};

export default forwardRef(SpecialDay1Component);
