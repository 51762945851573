import { Media } from '@/graphql/generated/graphql';
// import i18n from '@/i18n';
// import { NotificationSuccess } from '@commons/components/Notification';
import { uploadFileApi } from '@modules/Media/services/apis';
import { put } from 'redux-saga/effects';
import { UploadFileAction } from '../action-types';
import { updateUpdateAtFolderAction, uploadFileActionError, uploadFileActionSuccess } from '../actions';

export function* uploadFileAsync(action: UploadFileAction) {
  try {
    const media: Media = yield uploadFileApi(action.payload);
    yield put(uploadFileActionSuccess(media));
    if (media.parent && media.parent?.updatedAt) {
      yield put(
        updateUpdateAtFolderAction({
          id: media.parent?._id || '',
          updatedAt: media.parent?.updatedAt,
        }),
      );
    }
  } catch (error) {
    yield put(uploadFileActionError(error));
  }
}

export function uploadFileAsyncSuccess() {
  // NotificationSuccess(i18n.t('notification'), i18n.t('upload_success'));
}
