import { MediaType } from '@/graphql/generated/graphql';

export const allowedMimeTypes = [
  'application/pdf',
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/gif',
  'image/bmp',
  'image/webp',
  'video/x-msvideo',
  'video/mp4',
  'video/mpeg',
  // 'application/msword',
  // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/octet-stream',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  // 'application/vnd.ms-excel',
  // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const allowedMimeImage: any = {
  // 'application/pdf': 'application/pdf',
  'image/jpeg': 'image/jpeg',
  'image/png': 'image/png',
  'image/jpg': 'image/jpg',
  'image/gif': 'image/gif',
  'image/bmp': 'image/bmp',
  'image/webp': 'image/webp',
  // 'application/msword':// 'application/msword',
  // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':// 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  // 'application/vnd.ms-powerpoint': 'application/vnd.ms-powerpoint',
  // 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
  //   'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  // 'application/vnd.ms-excel':// 'application/vnd.ms-excel',
  // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':// 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};
export const allowedMimeImageName: any = {
  // 'application/pdf': 'application/pdf',
  'image/jpeg': 'image/jpeg',
  'image/png': 'image/png',
  'image/jpg': 'image/jpg',
  'image/gif': 'image/gif',
  'image/bmp': 'image/bmp',
  'image/webp': 'image/webp',
  'application/msword': 'document',
  'video/x-msvideo': 'video',
  'video/mp4': 'video/mp4',
  'video/mpeg': 'video/mpeg',
  // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'document',
  'application/vnd.ms-powerpoint': 'document/ptx',
  'application/octet-stream': 'document/ptx',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'document',
  'application/vnd.ms-excel': 'document/xlsx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'document/sheet',
};

export function getTypeOfFile(mimetype: string): MediaType {
  switch (mimetype) {
    case 'image/jpeg':
    case 'image/png':
    case 'image/jpg':
    case 'image/webp':
    case 'image/bmp':
    case 'image/gif':
      return MediaType.Image;
    case 'video/x-msvideo':
    case 'video/mp4':
    case 'video/mpeg':
      return MediaType.Video;
    case 'application/vnd.ms-powerpoint':
    case 'application/octet-stream':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return MediaType.Powerpoint;
    case 'application/pdf':
      return MediaType.Pdf;
    default:
      return MediaType.Document;
  }
}

export function getTypeOfFileNoPDf(mimetype: string): MediaType {
  switch (mimetype) {
    case 'image/jpeg':
    case 'image/png':
    case 'image/jpg':
    case 'image/webp':
    case 'image/bmp':
    case 'image/gif':
      return MediaType.Image;
    case 'video/x-msvideo':
    case 'video/mp4':
    case 'video/mpeg':
      return MediaType.Video;
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'application/pdf':
      return MediaType.Document;
    default:
      return MediaType.Document;
  }
}
