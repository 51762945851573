import { FrameInputType } from '@/graphql/generated/graphql';
import { FrameData } from '@modules/PlayList/types';

export const ADD_NEW_FRAME = 'ADD_NEW_FRAME';
export const ADD_NEW_FRAME_SUCCESS = 'ADD_NEW_FRAME_SUCCESS';
export const ADD_NEW_PLAY_LIST_DATA = 'ADD_NEW_PLAY_LIST_DATA';
export const CHANGE_NEW_FRAME_TYPE = 'CHANGE_NEW_FRAME_TYPE';

export interface AddNewFrameAction {
  type: typeof ADD_NEW_FRAME;
  payload: FrameData;
}
export interface ChangeNewFrameTypeAction {
  type: typeof CHANGE_NEW_FRAME_TYPE;
  payload: FrameData;
}

export interface AddNewPlaylistData {
  type: typeof ADD_NEW_PLAY_LIST_DATA;
  payload: FrameInputType[];
}

export type AddNewPlayListActionTypes = AddNewFrameAction | ChangeNewFrameTypeAction | AddNewPlaylistData;
