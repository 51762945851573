import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { setAppLayout as setAppLayoutAction } from '../redux/actions';
import { RootState } from '@redux/reducers';

export default function useSetAppLayout() {
  const layoutSize = useSelector<RootState, 'small' | 'middle' | 'large'>((state) => state.app.layoutSize);
  const dispatch = useDispatch();

  const setAppLayout = useCallback(
    (layoutSize: 'small' | 'middle' | 'large') => {
      dispatch(setAppLayoutAction(layoutSize));
    },
    [dispatch],
  );

  return {
    setAppLayout,
    layoutSize,
  };
}
