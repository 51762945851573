import { getSDK } from '@/graphql/client';
import i18n from '@/i18n';
import { useMutation, useQueryClient } from 'react-query';
import { GET_ALL_GROUPS } from './useGetAllGroups';

export default function useDeleteGroup() {
  const queryClient = useQueryClient();

  return useMutation(getSDK().DeleteGroup, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(GET_ALL_GROUPS);
      const messageData = data.deleteGroup
        ? {
            message: i18n.t('delete_success'),
          }
        : {
            message: i18n.t('delete_fail'),
          };
      // global on success
      const globalOnSuccess = queryClient.getDefaultOptions().mutations?.onSuccess;
      globalOnSuccess && globalOnSuccess(messageData, variables, context);
    },
  });
}
