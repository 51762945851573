import { PlaylistResolutionInterface } from '@modules/PlayList/types';

export const SELECT_RESOLUTION = 'SELECT_RESOLUTION';

export interface SelectResolutionAction {
  type: typeof SELECT_RESOLUTION;
  payload: PlaylistResolutionInterface;
}

export type SelectResolutionActionTypes = SelectResolutionAction;
