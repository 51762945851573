import { UpdateDeviceSchedulesInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { PlaylistSchedule } from '@modules/GroupDevice/types';
import {
  AddPlaylistScheduleInput,
  ADD_PLAYLIST_SCHEDULES_GROUP,
  ADD_PLAYLIST_SCHEDULES_SECOND_GROUP,
  CANCEL_SCHEDULES_GROUP_ACTION,
  RemovePlaylistScheduleInput,
  REMOVE_PLAYLIST_SCHEDULES_GROUP,
  REMOVE_PLAYLIST_SCHEDULES_SECOND_GROUP,
  UpdateSchedulesGroupActionTypes,
  UPDATE_PLAYLIST_SCHEDULES_GROUP,
  UPDATE_PLAYLIST_SCHEDULES_SECOND_GROUP,
  UPDATE_SCHEDULES_GROUP_ACTION,
  UPDATE_SCHEDULES_GROUP_ERROR,
  UPDATE_SCHEDULES_GROUP_SUCCESS,
  UPDATE_SCHEDULES_SECOND_GROUP_SUCCESS,
} from '../action-types';

export const addPlaylistSchedulesGroup = (payload: AddPlaylistScheduleInput): UpdateSchedulesGroupActionTypes => ({
  type: ADD_PLAYLIST_SCHEDULES_GROUP,
  payload,
});

export const addPlaylistSchedulesSecondGroup = (
  payload: AddPlaylistScheduleInput,
): UpdateSchedulesGroupActionTypes => ({
  type: ADD_PLAYLIST_SCHEDULES_SECOND_GROUP,
  payload,
});

export const cancelSchedulesGroupAction = (): UpdateSchedulesGroupActionTypes => ({
  type: CANCEL_SCHEDULES_GROUP_ACTION,
});

export const removePlaylistSchedulesGroup = (
  payload: RemovePlaylistScheduleInput,
): UpdateSchedulesGroupActionTypes => ({
  type: REMOVE_PLAYLIST_SCHEDULES_GROUP,
  payload,
});

export const removePlaylistSchedulesSecondGroup = (
  payload: RemovePlaylistScheduleInput,
): UpdateSchedulesGroupActionTypes => ({
  type: REMOVE_PLAYLIST_SCHEDULES_SECOND_GROUP,
  payload,
});

export const updatePlaylistSchedulesGroup = (payload: PlaylistSchedule): UpdateSchedulesGroupActionTypes => ({
  type: UPDATE_PLAYLIST_SCHEDULES_GROUP,
  payload,
});

export const updatePlaylistSchedulesSecondGroup = (payload: PlaylistSchedule): UpdateSchedulesGroupActionTypes => ({
  type: UPDATE_PLAYLIST_SCHEDULES_SECOND_GROUP,
  payload,
});

export const updateSchedulesGroupAction = (
  payload: UpdateDeviceSchedulesInput & { createdById?: string },
): UpdateSchedulesGroupActionTypes => ({
  type: UPDATE_SCHEDULES_GROUP_ACTION,
  payload,
});

export const updateSchedulesGroupSuccess = (payload: PlaylistSchedule[]): UpdateSchedulesGroupActionTypes => ({
  type: UPDATE_SCHEDULES_GROUP_SUCCESS,
  payload,
});

export const updateSchedulesSecondGroupSuccess = (payload: PlaylistSchedule[]): UpdateSchedulesGroupActionTypes => ({
  type: UPDATE_SCHEDULES_SECOND_GROUP_SUCCESS,
  payload,
});

export const updateSchedulesGroupError = (payload?: AppError): UpdateSchedulesGroupActionTypes => ({
  type: UPDATE_SCHEDULES_GROUP_ERROR,
  payload,
});
