import { NotificationSuccess } from '@commons/components/Notification';
import { AppError } from '@commons/types';
import { __ } from '@helpers/string';
import { getAuthLocalData, setAuthData } from '@helpers/token';
import { put } from 'redux-saga/effects';
import * as apis from '../../services/apis';
import { ChangePasswordAction } from '../action-types';
import { changePasswordDone, changePasswordError, changePasswordSuccess } from '../actions';

export function* changePasswordAsync(action: ChangePasswordAction) {
  try {
    const data: any = yield apis.changePassword(action.payload);
    const authData = getAuthLocalData();
    if (data?.changePassword.token && authData) {
      setAuthData({ ...authData!, token: data.changePassword.token });
      yield put(changePasswordSuccess());
      yield put(changePasswordDone());
    } else {
      const error = {
        message: 'change_password_error',
      };
      yield put(changePasswordError(error as AppError));
    }
  } catch (error) {
    yield put(changePasswordError(error));
  }
}

export function changePasswordSuccessAsync() {
  NotificationSuccess(__('notification'), __('change_password_success'));
}
