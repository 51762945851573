import { History } from 'history';
import { useLocation } from 'react-router-dom';

let _history: History;
let _redirectUrl: string;

_redirectUrl = window.location.href;

export const setHistory = (history: History) => {
  _history = history;
};

export const getHistory = () => {
  return _history;
};

export const setRedirectUrl = (url: string) => {
  _redirectUrl = url;
};

export const getRedirectUrl = () => {
  return _redirectUrl;
};

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}
