import { Button } from 'antd';
import { ButtonHTMLType } from 'antd/lib/button/button';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onClick?: () => void;
  className?: string;
  isLoading?: boolean;
  title?: string;
  htmlType?: ButtonHTMLType;
}

const BtnPublish = (props: Props) => {
  const { t } = useTranslation(['button']);

  return (
    <Button
      className={`btn-publish ${props.className}`}
      onClick={props?.onClick}
      loading={props?.isLoading}
      type="default"
      htmlType={props.htmlType}
    >
      {props.title ? props.title : t('publish_all').toUpperCase()}
    </Button>
  );
};

export default BtnPublish;
