import { UpdateUserInput } from '@/graphql/generated/graphql';
import FormHeader from '@commons/components/FormHeader';
import PageHeader from '@commons/components/PageHeader';
import FormSkeleton from '@commons/components/Skeletons/FormSkeleton';
import { getHistory } from '@helpers/history';
import UpdateUserAdminForm from '@modules/User/components/Form/update_admin_company';
import useGetUserByID from '@modules/User/hooks/useGetUserByID';
import useUpdateUserAdmin from '@modules/User/hooks/useUpdateUserAdmin';
import { Form } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

export default function UpdateUserAdminPage() {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation(['user']);
  const [form] = Form.useForm();
  const { data, isLoading } = useGetUserByID({
    _id: id,
  });
  const { isSuccess, mutate, isLoading: isSaving } = useUpdateUserAdmin();

  function onFinish(values: any) {
    const payload: UpdateUserInput = {
      _id: id,
      name: values.name,
      password: values.password || undefined,
      phone: values.phone,
    };
    mutate({ input: payload });
  }

  useEffect(() => {
    if (data?.user) {
      form.setFieldsValue({
        ...data.user,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      getHistory().push('/user/list');
    }
  }, [isSuccess]);

  return (
    <div>
      <PageHeader title={t('update_user')} subTitle={t('update_user')} />
      <FormHeader>
        <FormSkeleton loading={isLoading}>
          {data?.user ? (
            <UpdateUserAdminForm
              isLoading={isSaving}
              onFinish={onFinish}
              form={form}
              isUpdate={true}
              userData={data.user}
            />
          ) : null}
        </FormSkeleton>
      </FormHeader>
    </div>
  );
}
