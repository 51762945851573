import { CreateUploadSettingInput, SettingType } from '@/graphql/generated/graphql';
import ButtonOk from '@commons/components/modals/Button/ButtonOk';
import useUpdateSetting from '@modules/Setting/hooks/useUpdateSetting';
import { Card, Col, Divider, Form, Input, Row, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface SettingDeviceFormProps {
  data: any;
}

export default function SettingDeviceForm(props: SettingDeviceFormProps) {
  const { t } = useTranslation(['setting']);
  const { mutate, isLoading } = useUpdateSetting();

  const [form] = Form.useForm();
  const handleFinish = (values: any) => {
    const payload: CreateUploadSettingInput = {
      key: SettingType.Device,
      value: [
        {
          key: 'timeTakeSrc',
          value: `${values.timeTakeSrc}`,
        },
        {
          key: 'emailDeviceLongTimeConnect',
          value: `${values.emailDeviceLongTimeConnect}`,
        },
      ],
    };
    mutate({ input: payload });
  };

  const listTimes = [5, 10, 15, 20, 25, 30];

  const selectOptionTimes = listTimes.map((e: number, index) => {
    return (
      <Select.Option key={index} value={e}>
        {e}
      </Select.Option>
    );
  });

  return (
    <Card
      bordered={false}
      headStyle={{ borderBottom: 'none' }}
      bodyStyle={{ marginLeft: '2rem', marginRight: '2rem' }}
      className="card-form"
    >
      <Form form={form} onFinish={handleFinish} initialValues={props.data}>
        <Form.Item
          name="timeTakeSrc"
          label={`${t('time_take_scr')} (${t('minutes')})`}
          labelAlign="left"
          labelCol={{ xs: 24, md: 10, xl: 8 }}
          colon={false}
          preserve={false}
        >
          <Select style={{ width: '100%', maxWidth: 485 }} placeholder={t('time_take_scr')}>
            {selectOptionTimes}
          </Select>
        </Form.Item>

        <Form.Item
          name="emailDeviceLongTimeConnect"
          label={`${t('email_device_long_time_connect')}`}
          labelAlign="left"
          labelCol={{ xs: 24, md: 10, xl: 8 }}
          rules={[
            {
              type: 'email',
              message: t('validate.invalid_email'),
            },
          ]}
        >
          <Input placeholder={t('enter_email')} style={{ width: '100%', maxWidth: 485, background: '#fff' }} />
        </Form.Item>
        <Divider className="mt-4 mt-lg-5" />
        <Row>
          <Col span={24} className="d-flex justify-content-end">
            <ButtonOk key="confirm" onClick={() => form.submit()} title={t('save')} isLoading={isLoading} />
          </Col>
        </Row>
      </Form>
    </Card>
  );
}
