import ButtonCancel from '@commons/components/modals/Button/ButtonCancel';
import ButtonOk from '@commons/components/modals/Button/ButtonOk';
import { getDaysInMonth } from '@helpers/date';
import { SelectionEvent } from '@viselect/react';
import SelectionArea from '@viselect/react/lib/viselect.esm.es5';
import { Button, Drawer } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalDayOfMonthData } from '../ScheduleTypes/Monthly';

interface ScheduleTypesI {
  onCancel: () => void;
  onChoose: (selected: any) => void;
  dayOfMonthData: ModalDayOfMonthData;
  visible: boolean;
}

export default function DayOfMonth(props: ScheduleTypesI) {
  const { t } = useTranslation(['device', 'group-device', 'button']);
  const [selected, setSelected] = useState<Set<number>>(() => new Set());

  useEffect(() => {
    if (props.dayOfMonthData.month) {
      setSelected(new Set(props.dayOfMonthData.month.days));
    }
  }, [props.dayOfMonthData.month]);

  const renderDayOfMonth = useMemo(() => {
    const element = [];
    if (props.dayOfMonthData.month)
      for (let index = 1; index <= getDaysInMonth(props.dayOfMonthData.month.value); index++) {
        const el = (
          <div key={index} className={selected.has(index) ? 'day selected' : 'day'} data-key={index}>
            {index}
          </div>
        );
        element.push(el);
      }
    return element;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const extractIds = (els: Element[]): number[] =>
    els
      .map((v) => v.getAttribute('data-key'))
      .filter(Boolean)
      .map(Number);

  const onStart = ({ event, selection }: SelectionEvent) => {
    if (!event?.ctrlKey && !event?.metaKey) {
      selection.clearSelection();
      setSelected(() => new Set());
    }
  };

  const onMove = (e: SelectionEvent) => {
    const {
      store: {
        changed: { added, removed },
      },
    } = e;

    setSelected((prev) => {
      const next = new Set(prev);

      extractIds(added).forEach((id) => next.add(id));
      extractIds(removed).forEach((id) => next.delete(id));
      return next;
    });
  };

  return (
    <Drawer
      title={`${t(props?.dayOfMonthData?.month?.label || '')}-${new Date().getFullYear()} (${
        props?.dayOfMonthData?.month?.days.length
      }/${getDaysInMonth(props?.dayOfMonthData?.month?.value || 0)})`}
      placement="bottom"
      closable={true}
      visible={props.visible}
      getContainer={false}
      onClose={() => {
        props.onCancel();
      }}
      style={{ position: 'absolute' }}
      height={520}
      className="day-of-month"
      footer={[
        <ButtonCancel
          key={'btn_cancel'}
          title={t('button:cancel')}
          classNameBtn={`mr-3 `}
          onClick={() => props.onCancel()}
        />,
        <ButtonOk
          key={'btn_ok'}
          onClick={() => {
            props.onChoose(selected);
            props.onCancel();
          }}
          title={t('group-device:schedule.choose').toUpperCase()}
          classNameBtn={``}
        />,
      ]}
    >
      <SelectionArea
        className="days d-flex align-items-start flex-wrap justify-content-start py-4 px-5"
        onStart={(e: any) => {
          onStart(e);
        }}
        onMove={(e: any) => {
          onMove(e);
        }}
        selectables=".day"
      >
        <div className="d-flex align-items-start flex-wrap justify-content-start" style={{ gap: 10 }}>
          {renderDayOfMonth}
          <Button
            className="btn-select-all"
            onClick={() => {
              if (props.dayOfMonthData && props.dayOfMonthData.month && props.dayOfMonthData.month.days) {
                setSelected(new Set(props.dayOfMonthData.month.days));
              }
            }}
          >
            {t('button:select_all')}
          </Button>
          <Button
            className="btn-clear-all"
            onClick={() => {
              setSelected(new Set());
            }}
          >
            {t('button:clear_all')}
          </Button>
        </div>
      </SelectionArea>
      <div className="px-5 pb-3" style={{ color: '#F80000', opacity: 0.7 }}>
        {t('group-device:schedule.note')}
      </div>
    </Drawer>
  );
}
