import { all, takeLatest, takeLeading } from 'redux-saga/effects';
import * as actionTypes from '../action-types';
import { createFolderAsync, createFolderAsyncSuccess } from './create_folder';
import { deleteMediaAsync, deleteMediaAsyncSuccess } from './delete_media';
import { getFoldersAsync } from './get_folders';
import { getMediasAsync } from './get_medias';
import { moveFileAsync, moveFileAsyncSuccess } from './move_file';
import { updateFileAsync, updateFileAsyncSuccess } from './update_file';
import { uploadFileAsync, uploadFileAsyncSuccess } from './upload_file';

export default function* root(): any {
  return all([
    yield takeLatest(actionTypes.UPLOAD_FILE, uploadFileAsync),
    yield takeLatest(actionTypes.UPLOAD_FILE_SUCCESS, uploadFileAsyncSuccess),
    yield takeLeading(actionTypes.GET_MEDIAS, getMediasAsync),
    yield takeLatest(actionTypes.CREATE_FOLDER, createFolderAsync),
    yield takeLatest(actionTypes.CREATE_FOLDER_SUCCESS, createFolderAsyncSuccess),
    yield takeLatest(actionTypes.UPDATE_FILE, updateFileAsync),
    yield takeLatest(actionTypes.UPDATE_FILE_SUCCESS, updateFileAsyncSuccess),
    yield takeLatest(actionTypes.DELETE_MEDIA, deleteMediaAsync),
    yield takeLatest(actionTypes.DELETE_MEDIA_SUCCESS, deleteMediaAsyncSuccess),
    yield takeLatest(actionTypes.MOVE_FILE, moveFileAsync),
    yield takeLatest(actionTypes.MOVE_FILE_SUCCESS, moveFileAsyncSuccess),
    yield takeLatest(actionTypes.GET_FOLDERS, getFoldersAsync),
  ]);
}
