import { CloseOutlined } from '@ant-design/icons';
import useSetAppLayout from '@modules/App/hooks/useSetAppLayout';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onClick?: () => void;
  className?: string;
  isLoading?: boolean;
  title?: string;
}

const BtnRejectAll = (props: Props) => {
  const { layoutSize } = useSetAppLayout();
  const { t } = useTranslation(['button']);

  return (
    <Button
      className="btn-reject-all"
      onClick={props?.onClick}
      loading={props?.isLoading}
      type="default"
      size={layoutSize}
      icon={<CloseOutlined />}
    >
      {props.title ? props.title : t('reject-all').toUpperCase()}
    </Button>
  );
};

export default BtnRejectAll;
