import { getSDK } from '@/graphql/client';
import i18n from '@/i18n';
import { useMutation, useQueryClient } from 'react-query';
import { GET_PENDING_GROUPS } from './useGetPendingGroup';

export default function useRejectAllGroup() {
  const queryClient = useQueryClient();

  return useMutation(getSDK().rejectAllGroup, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(GET_PENDING_GROUPS);
      const messageData = {
        message: i18n.t('update_success'),
      };

      const globalOnSuccess = queryClient.getDefaultOptions().mutations?.onSuccess;
      // tslint:disable-next-line: no-unused-expression
      globalOnSuccess && globalOnSuccess(messageData, variables, context);
    },
  });
}
