import { combineReducers } from 'redux';
import addNewPlayList, { AddNewPlayListState } from './add_new_play_list';

export interface PlayListModuleState {
  addNewPlayListState: AddNewPlayListState;
}

export default combineReducers<PlayListModuleState>({
  addNewPlayListState: addNewPlayList,
});
