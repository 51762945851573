/* eslint-disable import/no-anonymous-default-export */
import { FileType, Media, PaginatedMedia } from '@/graphql/generated/graphql';
import {
  CreateFolderActionTypes,
  CREATE_FOLDER_SUCCESS,
  DeleteMediaActionTypes,
  DELETE_MEDIA_SUCCESS,
  GetFoldersActionTypes,
  GET_FOLDERS,
  GET_FOLDERS_SUCCESS,
} from '../action-types';

export interface ListFoldersState {
  loading?: boolean;
  foldersState?: Media[];
  data?: PaginatedMedia;
}

const initialState: ListFoldersState = {
  loading: false,
  data: {
    currentPage: 1,
    totalPage: 1,
    total: 0,
    items: [],
    hasMore: false,
    isLoadMore: false,
    currentFolder: undefined,
  },
};

export default (
  state = initialState,
  action: GetFoldersActionTypes | CreateFolderActionTypes | DeleteMediaActionTypes,
): ListFoldersState => {
  switch (action.type) {
    case GET_FOLDERS:
      return {
        ...state,
        loading: true,
      };

    case GET_FOLDERS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };

    case CREATE_FOLDER_SUCCESS:
      const files: Media[] = [...(state.data?.items || [])];
      if (action.payload && state.data && state.data.items) {
        files.unshift(action.payload);
      }
      return {
        ...state,
        data: {
          ...state.data,
          total: state.data ? state.data.total + 1 : undefined,
          items: files,
        } as PaginatedMedia,
      };

    case DELETE_MEDIA_SUCCESS:
      let filesDelete: Media[] = [...(state.data?.items || [])];
      let folderDelete = filesDelete.find((file) => file._id === action.payload && file.type === FileType.Folder);
      if (folderDelete) {
        filesDelete = filesDelete.filter((file) => file._id !== action.payload);
      }
      return {
        ...state,
        data: {
          ...state.data,
          items: filesDelete,
        } as PaginatedMedia,
      };

    default:
      return {
        ...state,
      };
  }
};
