import {
  FrameDirection,
  FrameType,
  MediaImage,
  PlaylistScreenType,
  PlaylistStatus,
  Resolutions,
  VideoProfile,
} from '@/graphql/generated/graphql';
import { FabricJSEditor } from 'fabricjs-react';
export const GET_ALL_PLAYLIST = 'GET_ALL_PLAYLIST';

declare global {
  interface Window {
    editor?: FabricJSEditor;
    activeFrame?: FrameData;
  }
}

export interface PlaylistsFilter {
  query?: string;
  resolution?: Resolutions;
  status?: PlaylistStatus;
  diffApprove?: boolean;
}

export const PlaylistResolutionArray = [
  // {
  //   key: Resolutions.Sd,
  //   text: 'SD',
  // },
  {
    key: Resolutions.Hd,
    text: 'HD',
  },
  {
    key: Resolutions.FullHd,
    text: 'Full HD',
  },
  // {
  //   key: Resolutions.UltraHd_4K,
  //   text: 'Full HD 4K',
  // },
  // {
  //   key: Resolutions.UltraHd_8K,
  //   text: 'Full HD 8K',
  // },
];

export const PlaylistScreenTypeArray = [
  {
    key: PlaylistScreenType.FulScreen,
    text: 'full_screen',
  },
  {
    key: PlaylistScreenType.SplitScreen,
    text: 'split_screen',
  },
];

export const FrameDirectionTypeArray = [
  {
    key: FrameDirection.Horizontal,
    text: 'horizontal',
  },
  {
    key: FrameDirection.Vertical,
    text: 'vertical',
  },
];

export const PlaylistStatusArray = [
  {
    key: PlaylistStatus.Draft,
    text: 'Draft',
  },
  {
    key: PlaylistStatus.WaitToApprove,
    text: 'WaitToApprove',
  },
  {
    key: PlaylistStatus.Approved,
    text: 'Approved',
  },
  {
    key: PlaylistStatus.Rejected,
    text: 'Rejected',
  },
];

export interface PlaylistResolutionInterface {
  name: string;
  width: number;
  height: number;
  key: Resolutions;
}

export const PlaylistResolutions: PlaylistResolutionInterface[] = [
  // {
  //   name: 'SD',
  //   width: 640,
  //   height: 480,
  //   key: Resolutions.Sd,
  // },
  {
    name: 'HD',
    width: 1280,
    height: 720,
    key: Resolutions.Hd,
  },
  {
    name: 'Full HD',
    width: 1920,
    height: 1080,
    key: Resolutions.FullHd,
  },
  // {
  //   name: 'Full HD 4K',
  //   width: 3840,
  //   height: 2160,
  //   key: Resolutions.UltraHd_4K,
  // },
  // {
  //   name: 'Full HD 8K',
  //   width: 7680,
  //   height: 4320,
  //   key: Resolutions.UltraHd_8K,
  // },
];

export enum FrameTypes {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  SLIDER = 'SLIDER',
  WEB_URL = 'WEB_URL',
  WEB_RSS = 'WEB_RSS',
}

export const DefaultFrameConfig = {
  name: '',
  isEditing: false,
  text: '',
  type: FrameType.Image,
  duration: 5,
  isEnabled: true,
  fabricData: {},
};

export interface FrameData {
  id: string;
  name?: string;
  text?: string | null;
  isEditing: boolean;
  type: FrameType;
  duration: number;
  fabricData?: any;
  isEnabled?: boolean | null;
  backgroundColor?: string | null;
  documentImages?: MediaImage[] | null;
  videoProfiles?: VideoProfile[] | null;
  order?: number | null;
  frameCover?: string;
}

export interface EditorConfig {
  resolution: PlaylistResolutionInterface;
  zoom: number;
  width: number;
  height: number;
  orientation: FrameDirection;
}

export interface PlayListData {
  companyId?: string;
  _id?: string;
  createdById?: string;
  createdAt?: number;
  createdBy?: { username?: string };
  group?: { id: string; name: string };
  name: string;
  editor: EditorConfig;
  currentFrames?: FrameData[];
  frames?: FrameData[];
  secondFrames?: FrameData[];
  activeFrame?: FrameData;
  status?: PlaylistStatus;
  screenType: PlaylistScreenType;
  currentPlaylist?: number;
}
