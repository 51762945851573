import React from 'react';

interface TableLayoutI {
  tableHeader?: any;
  children?: any;
}

export default function TableLayout(props: TableLayoutI) {
  return (
    <div>
      {props?.tableHeader ? (
        <div className="table-header-flex d-block d-xl-flex justify-content-end align-items-center bg-white py-3 px-4">
          {props?.tableHeader}
        </div>
      ) : null}
      {props?.children}
    </div>
  );
}
