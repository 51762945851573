import { QueryUsersInput, Roles, User } from '@/graphql/generated/graphql';
import BtnAddNew from '@commons/components/Button/BtnAddNew';
import ModalConfirm from '@commons/components/modals/ModalConfirm';
import TableLayout from '@commons/components/TableLayout';
import TableWithNumber from '@commons/components/TableWithNumber';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { LoginState } from '@modules/Auth/redux/action-types';
import UserCompanyRowAction from '@modules/Company/components/Action/UserCompanyRowAction';
import useKickOutCompany from '@modules/Company/hooks/useKickOutCompany';
import { PageSizeTable } from '@modules/Company/types';
import AddUserModal from '@modules/User/components/modal/AddUserModal';
import EditUserModal from '@modules/User/components/modal/EditUserModal';
import useGetUsers from '@modules/User/hooks/useGetUsers';
import { RootState } from '@redux/reducers';
import { Button, Form, Input, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import TextTruncate from 'react-text-truncate';

const { Option } = Select;
interface IState {
  updateUserData?: User;
  deleteUserData?: User;
  visibleModalAddUser: boolean;
}

export default function CompanyUsersListPage() {
  const [state, setState] = useState<IState>({
    updateUserData: undefined,
    deleteUserData: undefined,
    visibleModalAddUser: false,
  });
  const [paginate, setPaginate] = useState({
    pageSize: PageSizeTable,
    page: 1,
  });
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);
  // eslint-disable-next-line
  const companyId = loginState.data?.companyId ? loginState.data?.companyId : useParams<{ id: string }>().id;
  const { t } = useTranslation(['user']);
  const { isLoading, mutate: onKickOutUserMutate, isSuccess } = useKickOutCompany();
  const { setPageTitle } = useSetPageTitle();

  useEffect(() => {
    return t && setPageTitle(t('account'));
    // eslint-disable-next-line
  }, [t]);

  React.useEffect(() => {
    if (isSuccess) {
      onCancel();
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  const columns: ColumnsType<any> = [
    {
      title: t('stt'),
      key: '#',
      dataIndex: 'STT',
      align: 'center',
      width: 100,
    },
    {
      title: t('full_name'),
      key: 'name',
      dataIndex: 'name',
      width: 180,
      responsive: ['xl'],
      render: (record) => {
        return (
          <TextTruncate line={3} containerClassName="word-break" element="div" truncateText="…" text={record || ''} />
        );
      },
    },
    {
      title: t('username'),
      key: 'username',
      dataIndex: 'username',
      width: 120,
      render: (record) => {
        return <div>{record}</div>;
      },
    },
    {
      title: t('phone'),
      key: 'phone',
      dataIndex: 'phone',
      width: 100,
    },
    {
      title: t('company'),
      key: 'company',
      width: 250,
      render: (record: User) => (
        <TextTruncate
          line={3}
          element="span"
          containerClassName="word-break"
          truncateText="…"
          text={record?.company?.name || ''}
        />
      ),
    },
    {
      title: t('role'),
      key: 'role',
      width: 180,
      render: (record: User) => {
        return record.role === Roles.Admin ? (
          <span>{t('status.checker')}</span>
        ) : (
          <span className="text-capitalize">{t('status.editor').toLowerCase()}</span>
        );
      },
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      width: 160,
      render: (record: User) => {
        return (
          <UserCompanyRowAction
            record={record}
            onEdit={(record) => {
              setState({ ...state, updateUserData: record });
            }}
            onDelete={(record) => {
              setState({ ...state, deleteUserData: record });
            }}
          />
        );
      },
    },
  ];

  const [filter, setFilter] = useState<QueryUsersInput>({
    page: 1,
    limit: PageSizeTable,
  });

  useEffect(() => {
    if (loginState.data?.companyId) {
      setFilter({
        page: 1,
        limit: PageSizeTable,
        companyId: loginState.data.companyId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginState.data?.companyId]);

  const [form] = Form.useForm();

  const { data } = useGetUsers(filter);

  const onSearch = (values: any) => {
    const input = {
      query: values.query ? values.query.trim() : undefined,
      role: values.role || undefined,
    };
    setFilter({
      ...filter,
      ...input,
      page: 1,
    });
  };

  const onCancel = () => {
    setState({ ...state, updateUserData: undefined, deleteUserData: undefined, visibleModalAddUser: false });
  };

  const onKickOutUser = () => {
    if (state.deleteUserData) {
      onKickOutUserMutate({ input: { userId: state.deleteUserData?._id, companyId } });
    }
  };

  return (
    <>
      <TableLayout
        tableHeader={
          <Form
            layout="inline"
            form={form}
            onFinish={onSearch}
            className="justify-content-start justify-content-md-end gap-3"
          >
            <Form.Item name="role">
              <Select
                style={{ width: 140 }}
                allowClear
                placeholder={t('role')}
                onChange={(role: Roles) => setFilter({ ...filter, role })}
              >
                <Option value={Roles.Admin}>{t('status.checker')}</Option>
                <Option value={Roles.Editor}>{t('status.editor')}</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Input.Group compact>
                <Form.Item name="query" className="w-full mr-0">
                  <Input placeholder={`${t('search_user')}`} className="bd-form-search" />
                </Form.Item>
                <Button icon={<FiSearch color="#F80000" />} className="btn-search" htmlType="submit" />
              </Input.Group>
            </Form.Item>
            <BtnAddNew
              onClick={() =>
                setState({
                  ...state,
                  visibleModalAddUser: true,
                })
              }
            />
          </Form>
        }
      >
        <TableWithNumber
          className="table-striped-rows"
          rowKey={(data) => data._id}
          columns={columns}
          dataSource={data?.users?.items || []}
          loading={false}
          scroll={{
            x: true,
          }}
          pagination={false}
          totalTitle={t('account')}
          paginate={{
            pageSize: paginate.pageSize,
            total: data?.users?.total || 0,
            current: data?.users?.currentPage || 1,
            onChange: (page: number) => {
              setPaginate({
                ...paginate,
                page,
              });
            },
          }}
        />
      </TableLayout>
      <EditUserModal onCancel={onCancel} data={state.updateUserData} />
      <ModalConfirm
        isLoading={isLoading}
        data={state.deleteUserData}
        handleOk={onKickOutUser}
        handleCancel={onCancel}
        title={t('delete_title')}
        okTitle={t('delete')}
        content={
          <Trans
            i18nKey="delete_content"
            t={t}
            values={{ username: state.deleteUserData?.username }}
            components={[<span className="primary_color fw-500" key={''} />]}
          />
        }
      />
      <AddUserModal onCancel={onCancel} visible={state.visibleModalAddUser} />
    </>
  );
}
