import '../styles/index.scss';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MediaContentComponent from './components/media-content.component';
import MediaPreview from './components/media-preview';

export const enum TypeViewMedia {
  LIST = 'LIST',
  GRID = ' GRID',
}

function MediaPage(props: any) {
  const { t } = useTranslation(['media']);
  const { setPageTitle } = useSetPageTitle();

  useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t('media'));
    // eslint-disable-next-line
  }, [t]);

  return (
    <>
      <MediaContentComponent {...props} />
      <MediaPreview />
    </>
  );
}

export default MediaPage;
