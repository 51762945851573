import { LogoutActionTypes, LOGOUT_SUCCESS, LOGOUT_ERROR, LOGOUT, LogoutState } from '../action-types/logout';

const initialState = {
  loading: false,
};
export default (state = initialState, action: LogoutActionTypes): LogoutState => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case LOGOUT_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
