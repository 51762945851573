import app, { AppState } from '@modules/App/redux/reducers';
import auth, { AuthModuleState } from '@modules/Auth/redux/reducers';
import device, { DeviceModuleState } from '@modules/Device/redux/reducers';
import groupDevice, { GroupDeviceModuleState } from '@modules/GroupDevice/redux/reducers/index';
import media, { MediaModuleState } from '@modules/Media/redux/reducers';
import playList, { PlayListModuleState } from '@modules/PlayList/redux/reducers';
import { combineReducers } from 'redux';

export interface RootState {
  app: AppState;
  auth: AuthModuleState;
  playList: PlayListModuleState;
  media: MediaModuleState;
  device: DeviceModuleState;
  groupDevice: GroupDeviceModuleState;
}

export default combineReducers<RootState>({
  app,
  auth,
  playList,
  media,
  device,
  groupDevice,
});
