// src/i18n.js
import i18n from 'i18next';
import 'moment/locale/vi';
import 'moment/locale/en-gb';
import 'moment/locale/nb';
// import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    preload: ['no', 'en', 'vi'],
    fallbackLng: 'no',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    ns: ['notification'],
    defaultNS: ['notification'],
    detection: {
      order: ['localStorage', 'cookie', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      lookupCookie: 'win-solution-ads-language',
      lookupLocalStorage: 'win-solution-ads-language',
      lookupSessionStorage: 'win-solution-ads-language',
      caches: ['localStorage', 'cookie'],
    },
  });

export default i18n;
