import { FrameData } from '@modules/PlayList/types';

export const DELETE_FRAME = 'DELETE_FRAME';
export const DELETE_FRAME_SUCCESS = 'DELETE_FRAME_SUCCESS';
export const DELETE_FRAME_ERROR = 'DELETE_FRAME_ERROR';

export interface DeleteFrameAction {
  type: typeof DELETE_FRAME;
  payload: FrameData;
}

export interface DeleteFrameActionSuccess {
  type: typeof DELETE_FRAME_SUCCESS;
  payload: FrameData;
}

export type DeleteFrameActionTypes = DeleteFrameAction | DeleteFrameActionSuccess;
