import { combineReducers } from 'redux';
import deviceItem, { DeviceItemState } from './device_item';

export interface DeviceModuleState {
  deviceItem: DeviceItemState;
}

export default combineReducers<DeviceModuleState>({
  deviceItem,
});
