import { FrameDirection } from '@/graphql/generated/graphql';
import React from 'react';

interface LandscapeFullScreenI {
  background?: string;
  className?: string;
  orientation?: FrameDirection;
}

export default function FullScreen(props: LandscapeFullScreenI) {
  if (props.orientation === FrameDirection.Horizontal) {
    return (
      <div
        className={`landscape-full-screen layout-1 ${props.className}`}
        style={{ backgroundColor: props.background || '#77829A' }}
      />
    );
  }
  return <div className="portrait-full-screen" style={{ backgroundColor: props.background || '#77829A' }} />;
}
