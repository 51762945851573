import { FrameInputType } from '@/graphql/generated/graphql';
import { FrameData } from '@modules/PlayList/types';
import { AddNewPlayListActionTypes, ADD_NEW_FRAME, ADD_NEW_PLAY_LIST_DATA } from '../action-types';

export const addNewFrame = (payload: FrameData): AddNewPlayListActionTypes => ({
  type: ADD_NEW_FRAME,
  payload,
});

export const addNewDataPlayList = (payload: FrameInputType[]): AddNewPlayListActionTypes => ({
  type: ADD_NEW_PLAY_LIST_DATA,
  payload,
});
