import { MediaImage, MediaType } from '@/graphql/generated/graphql';
import Loading from '@commons/components/Loading';
import { getTypeOfFile } from '@modules/Media/helper/file';
import usePreviewMedia from '@modules/Media/hooks/usePreviewMedia';
import { Carousel, Modal } from 'antd';
import React, { useEffect, useState } from 'react';

export default function MediaPreview() {
  const { item, onPreview } = usePreviewMedia();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (item) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
    // eslint-disable-next-line
  }, [item]);

  const handleOk = () => {
    onPreview(undefined);
  };

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  };

  const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      const handleResize = () => setWindowDimensions(getWindowDimensions());

      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  };

  const { width, height } = useWindowDimensions();
  const isVertical = width < height;

  const renderPreview = () => {
    if (!item) {
      return <Loading type="modal" />;
    } else {
      switch (getTypeOfFile(item.mimetype || '')) {
        case MediaType.Image:
          return <img className="img-responsive" src={item?.optimizeUrl || ''} alt={item?.filename} />;
        case MediaType.Document:
        case MediaType.Powerpoint:
        case MediaType.Pdf:
        case MediaType.Video:
          if (item.images?.length) {
            return (
              <div style={{ minWidth: '300px' }}>
                <Carousel dotPosition="top" fade={true}>
                  {item.images.map((image: MediaImage, index: number) => (
                    <img className="img-responsive" key={index} src={image.url || ''} alt="" />
                  ))}
                </Carousel>
              </div>
            );
          }
          break;
        default:
          return <Loading type="modal" />;
      }
    }
  };

  return (
    <Modal
      destroyOnClose
      footer={null}
      onCancel={handleOk}
      centered={isVertical}
      className="preview-modal"
      visible={isVisible}
      style={{ width: (80 * width) / 100, minWidth: (80 * width) / 100, top: 20 }}
    >
      <div className="d-flex justify-content-center align-items-center">{renderPreview()}</div>
    </Modal>
  );
}
