import { Media } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const SELECT_FOLDER = 'SELECT_FOLDER';
export const SELECT_FOLDER_SUCCESS = 'SELECT_FOLDER_SUCCESS';
export const SELECT_FOLDER_ERROR = 'SELECT_FOLDER_ERROR';
export const GO_BACK_FOLDER = 'GO_BACK_FOLDER';

export interface SelectFolderAction {
  type: typeof SELECT_FOLDER;
  payload: Media;
}

export interface SelectFolderActionSuccess {
  type: typeof SELECT_FOLDER_SUCCESS;
}

export interface SelectFolderActionError {
  type: typeof SELECT_FOLDER_ERROR;
  payload: AppError;
}

export interface GoBackFolderAction {
  type: typeof GO_BACK_FOLDER;
  payload: Media;
}

export type SelectFolderActionTypes =
  | SelectFolderAction
  | SelectFolderActionSuccess
  | SelectFolderActionError
  | GoBackFolderAction;
