import { Device, DevicePlaylistSchedule, ScheduleType } from '@/graphql/generated/graphql';
import moment from 'moment';
import { isDayInRange } from '@helpers/date';

const defaultTimezone = 'Europe/Oslo';

/**
 * Function check: Is playlist working ?
 * @param schedule
 * @param timezone
 */
export function isInSchedule(schedule: DevicePlaylistSchedule, timezone: string = defaultTimezone) {
  const { type } = schedule;
  const now = moment().tz(timezone);
  const nowHour = now.clone().format('HH:mm');
  switch (type) {
    case ScheduleType.Daily: {
      const { dailyData } = schedule;
      const day = now.clone().format('DD.MM.YYYY');
      if (
        dailyData?.dateRange?.dateFrom &&
        isDayInRange(day, dailyData?.dateRange?.dateFrom, dailyData?.dateRange?.dateTo)
      ) {
        if (dailyData?.timeInDay) {
          return dailyData?.timeInDay?.startOfDay <= nowHour && nowHour <= dailyData?.timeInDay?.endOfDay;
        }
      } else if (!dailyData?.dateRange?.dateFrom) {
        if (dailyData?.timeInDay) {
          return dailyData?.timeInDay?.startOfDay <= nowHour && nowHour <= dailyData?.timeInDay?.endOfDay;
        }
      }

      return true;
    }
    case ScheduleType.Weekly: {
      const { weeklyData } = schedule;
      if (weeklyData?.timeInDay) {
        const day = now.clone().format('DD.MM.YYYY');
        const dayOfWeek = now.clone().day(); // value 0->6

        if (
          (weeklyData?.dateRange?.dateFrom &&
            weeklyData?.dateRange?.dateTo &&
            isDayInRange(day, weeklyData?.dateRange?.dateFrom, weeklyData?.dateRange?.dateTo)) ||
          !weeklyData?.dateRange?.dateFrom
        ) {
          if (weeklyData?.daysOfWeek?.includes(dayOfWeek + 1)) {
            return weeklyData?.timeInDay?.startOfDay <= nowHour && nowHour <= weeklyData?.timeInDay?.endOfDay;
          }
        }
      }
      return true;
    }
    case ScheduleType.Monthly: {
      const { monthlyData } = schedule;
      const monthOfYear = now.clone().month();
      const dayOfMonth = now.clone().date();
      if (monthlyData) {
        const { months } = monthlyData;
        const monthCheck = months?.find((month) => month.month === monthOfYear);
        if (monthCheck) {
          return monthCheck?.days?.includes(dayOfMonth + 1);
        }
        return false;
      }
      return true;
    }
    case ScheduleType.SpecialDay: {
      const { specialDayData } = schedule;
      if (specialDayData) {
        const day = now.clone().format('DD.MM.YYYY');
        const isSpecialToday = specialDayData?.find((special) => {
          return isDayInRange(day, special?.date, special?.toDate);
        });
        if (isSpecialToday) {
          if (isSpecialToday?.timeInDay?.endOfDay) {
            return (
              isSpecialToday?.timeInDay?.startOfDay &&
              isSpecialToday?.timeInDay?.startOfDay <= nowHour &&
              nowHour <= isSpecialToday?.timeInDay?.endOfDay
            );
          }
          return isSpecialToday?.timeInDay?.startOfDay && isSpecialToday?.timeInDay?.startOfDay <= nowHour;
        }
        return false;
      }
      return true;
    }
    default: {
      return true;
    }
  }
}

/**
 * Function checks : Is device on schedule working time or not
 * @param schedules
 * @param timezone
 */
export function isDeviceOnSchedule(schedules: DevicePlaylistSchedule[], timezone: string = defaultTimezone): boolean {
  let isWorking = false;
  schedules.forEach((playlistSchedule) => {
    if (isInSchedule(playlistSchedule, timezone)) {
      isWorking = true;
    }
  });
  return isWorking;
}

/**
 * Function checks : Are device playlists ready for displaying ?
 * @param device
 */
export function isDevicePlaylistsReady(device: Device): boolean {
  const { group } = device;
  if (group) {
    const { schedules, timezone = defaultTimezone } = group;
    if (schedules) {
      const isOnWorkingTime = isDeviceOnSchedule(schedules, timezone);
      if ('WS-2344Z9OB' === device?.deviceCode) {
        console.log('isOnWorkingTime>>>>', device, isOnWorkingTime);
      }
      return isOnWorkingTime;
    }
  }

  return true;
}
