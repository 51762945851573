import { ScheduleType } from '@/graphql/generated/graphql';
import { checkTimeInDay, formatMomentDate, FULL_DATE, HOUR_MINUTE } from '@helpers/date';
import useUpdateDevicePlaylistSchedules from '@modules/GroupDevice/hooks/useUpdateScheduleGroup';
import { CurrentPlaylistSchedulesState } from '@modules/GroupDevice/redux/reducers/current_playlist_schedule';
import { PlaylistSchedule, WeeklyI } from '@modules/GroupDevice/types';
import { RootState } from '@redux/reducers';
import { Button, Checkbox, Col, DatePicker, Form, Row } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import OpenCloseTime from '../Shedule/OpenCloseTime';
import { ErrorTimeInDayState } from '../Shedule/Right';

const { RangePicker } = DatePicker;

interface WeeklyProps {
  currentPlaylistSchedule?: PlaylistSchedule;
  onCancelSchedule?: () => void;
  errorTimeInDay: ErrorTimeInDayState;
  onCancel: () => void;
}

interface WeeklyScheduleDataInput {
  daysOfWeek: number[];
}

const WeeklyDefaultCheckedAll: number[] = [1, 2, 3, 4, 5, 6, 7];

const Weekly2Component = (props: WeeklyProps, ref: any) => {
  const { t } = useTranslation(['group-device']);
  const { onUpdatePlaylistScheduleGroupDevice } = useUpdateDevicePlaylistSchedules();
  const currentPlaylistScheduleState = useSelector<RootState, CurrentPlaylistSchedulesState>(
    (state) => state?.groupDevice?.currentPlaylistSchedule,
  );

  const WeeklyDefault: WeeklyI[] = [
    { value: 1, label: 'week.1' },
    { value: 2, label: 'week.2' },
    { value: 3, label: 'week.3' },
    { value: 4, label: 'week.4' },
    { value: 5, label: 'week.5' },
    { value: 6, label: 'week.6' },
    { value: 7, label: 'week.7' },
  ];

  const formatDaysOfWeek = (week: WeeklyI[]): number[] => {
    if (week) {
      return week.map((w) => w.value);
    }
    return WeeklyDefaultCheckedAll;
  };

  const [state, setState] = React.useState<WeeklyScheduleDataInput>({
    daysOfWeek: formatDaysOfWeek(WeeklyDefault),
  });

  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [unCheckedAll, setUnCheckedAll] = useState<boolean>(false);

  const [weeklyForm] = Form.useForm();

  const [errorTimeInDay, setErrorTimeInDay] = useState<ErrorTimeInDayState>({
    message: '',
    status: false,
  });

  useEffect(() => {
    let daysOfWeek = formatDaysOfWeek(WeeklyDefault);
    if (currentPlaylistScheduleState.data?.weeklyData) {
      daysOfWeek = currentPlaylistScheduleState.data?.weeklyData.daysOfWeek;
      weeklyForm.setFieldsValue({
        daysOfWeek,
        dateRange: currentPlaylistScheduleState.data?.weeklyData?.dateRange
          ? [
              moment(currentPlaylistScheduleState.data?.weeklyData?.dateRange?.dateFrom, FULL_DATE),
              moment(currentPlaylistScheduleState.data?.weeklyData?.dateRange?.dateTo, FULL_DATE),
            ]
          : undefined,
        startOfDay: currentPlaylistScheduleState.data.weeklyData?.timeInDay
          ? moment(currentPlaylistScheduleState.data.weeklyData?.timeInDay.startOfDay, HOUR_MINUTE)
          : undefined,
        endOfDay: currentPlaylistScheduleState.data.weeklyData?.timeInDay?.endOfDay
          ? moment(currentPlaylistScheduleState.data.weeklyData?.timeInDay?.endOfDay, HOUR_MINUTE)
          : undefined,
      });
    }

    setState({
      daysOfWeek,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlaylistScheduleState.data?.weeklyData]);

  useEffect(() => {
    weeklyForm.setFieldsValue({
      daysOfWeek: state.daysOfWeek,
    });
  }, [state.daysOfWeek, weeklyForm]);

  const handleCheckedAll = () => {
    setState({
      daysOfWeek: WeeklyDefaultCheckedAll,
    });
    weeklyForm.setFieldsValue({
      daysOfWeek: WeeklyDefaultCheckedAll,
    });
  };

  const handleUnCheckedAll = () => {
    setState({
      daysOfWeek: [],
    });
    weeklyForm.setFieldsValue({
      daysOfWeek: [],
    });
  };

  useImperativeHandle(
    ref,
    () => ({
      onUpdatePlaylistScheduleGroup: () => {
        weeklyForm.submit();
      },
    }),
    // eslint-disable-next-line
    [],
  );

  useEffect(() => {
    if (state.daysOfWeek.length >= 7) {
      setCheckedAll(true);
      setUnCheckedAll(false);
    } else {
      if (state.daysOfWeek.length === 0) {
        setUnCheckedAll(true);
        setCheckedAll(false);
      } else {
        setCheckedAll(false);
        setUnCheckedAll(false);
      }
    }
  }, [state.daysOfWeek]);

  const setAllDayTime = () => {
    weeklyForm.setFieldsValue({
      startOfDay: moment('00:00', HOUR_MINUTE),
      endOfDay: moment('00:00', HOUR_MINUTE),
    });
  };

  const onFinish = (values: any) => {
    let dateRange;
    let timeInDay;
    if (values.dateRange && values.dateRange.length > 1) {
      dateRange = {
        dateFrom: formatMomentDate(values.dateRange[0], FULL_DATE),
        dateTo: formatMomentDate(values.dateRange[1], FULL_DATE),
      };
    }
    if (values?.startOfDay && !values?.endOfDay) {
      setErrorTimeInDay({
        status: true,
        message: t('group-device:schedule.validate.required_end_time'),
      });
      return;
    }
    if (!values?.startOfDay && values?.endOfDay) {
      setErrorTimeInDay({
        status: true,
        message: t('group-device:schedule.validate.required_start_time'),
      });
      return;
    }
    if (values?.endOfDay && values?.startOfDay) {
      if (!checkTimeInDay(values?.startOfDay, values?.endOfDay)) {
        setErrorTimeInDay({
          status: true,
          message: t('group-device:schedule.validate.time_in_day'),
        });
        return;
      }
      timeInDay = {
        startOfDay: formatMomentDate(values?.startOfDay, HOUR_MINUTE),
        endOfDay: formatMomentDate(values?.endOfDay, HOUR_MINUTE),
      };
    }
    if (values.daysOfWeek && values.daysOfWeek.length > 0) {
      const scheduleData: any = {
        playlistId: (currentPlaylistScheduleState?.data && currentPlaylistScheduleState.data?.playlistId) || '',
        playlistName: currentPlaylistScheduleState.data?.playlistName,
        type: ScheduleType.Weekly,
        index: currentPlaylistScheduleState.data?.index,
        weeklyData: {
          dateRange,
          timeInDay,
          daysOfWeek: values?.daysOfWeek,
        },
      };
      setErrorTimeInDay({
        status: false,
        message: '',
      });
      onUpdatePlaylistScheduleGroupDevice(scheduleData);
      props.onCancel();
    }
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current: any) => {
    return current && current < moment().startOf('day');
  };

  return (
    <div className="schedule-type-content weekly">
      <Form
        name="weekly-form"
        initialValues={{ remember: true }}
        autoComplete="off"
        form={weeklyForm}
        onFinish={onFinish}
      >
        <Row className="mb-4">
          <Col span={24} md={5} className="d-flex align-items-center justify-content-start mb-2 mb-md-0">
            <label className="key">{t('schedule.select_date')}:</label>
          </Col>
          <Col span={24} md={19} className="d-flex align-items-center justify-content-between">
            <Form.Item className="mb-0" name={'dateRange'}>
              <RangePicker
                style={{ width: '100%', maxWidth: '400px' }}
                format={FULL_DATE}
                placeholder={[t('schedule.start_date'), t('schedule.end_date')]}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col span={24} md={5} className="d-flex align-items-center justify-content-start mb-2 mb-md-0">
            <label className="key">{t('schedule.select_time')}:</label>
          </Col>
          <Col span={24} md={19} className="d-flex align-items-center justify-content-between">
            <OpenCloseTime
              errorTimeInDay={props.errorTimeInDay}
              changeStartOfDay={(value?: string) => {
                weeklyForm.setFieldsValue({
                  startOfDay: value ? moment(value, HOUR_MINUTE) : undefined,
                });
              }}
              changeEndOfDay={(value?: string) => {
                weeklyForm.setFieldsValue({
                  endOfDay: value ? moment(value, HOUR_MINUTE) : undefined,
                });
              }}
            />
            <Button
              className="btn-all-day mr-2"
              onClick={() => {
                setAllDayTime();
              }}
            >
              {t('button:all_day')}
            </Button>
          </Col>
          <Col span={24} md={{ span: 19, offset: 5 }} className="d-flex align-items-center justify-content-between">
            {errorTimeInDay?.status && (
              <div className="ant-form-item-explain ant-form-item-explain-error">
                <div role="alert">{errorTimeInDay?.message}</div>
              </div>
            )}
          </Col>
        </Row>
        <Row className="mb-4">
          <Col span={7} className="d-flex align-items-center justify-content-start">
            <label className="key">{t('schedule.select_day')}:</label>
          </Col>
          <Col span={17} className="d-flex align-items-center justify-content-end">
            <div>
              <Checkbox onClick={handleUnCheckedAll} checked={unCheckedAll} className="mr-3">
                {t('schedule.un_check_all')}
              </Checkbox>
              <Checkbox onClick={handleCheckedAll} checked={checkedAll}>
                {t('schedule.check_all')}
              </Checkbox>
            </div>
          </Col>
        </Row>

        <Form.Item
          name="daysOfWeek"
          className="mb-0"
          rules={[{ required: true, message: t('schedule.validate.required_day') }]}
        >
          <Checkbox.Group
            style={{ width: '100%' }}
            onChange={(e) => {
              if (e.length >= 7) {
                setCheckedAll(true);
                setUnCheckedAll(false);
              } else if (e.length === 0) {
                setCheckedAll(false);
                setUnCheckedAll(true);
              } else {
                setCheckedAll(false);
                setUnCheckedAll(false);
              }
            }}
          >
            <Row>
              {WeeklyDefault.map((w, key) => {
                return (
                  <Col span={8} lg={{ span: 8 }} xl={{ span: 6 }} className="pb-4" key={key}>
                    <Checkbox value={w.value}>{t(`${w.label}`)}</Checkbox>
                  </Col>
                );
              })}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </div>
  );
};

export default forwardRef(Weekly2Component);
