/* eslint-disable react-hooks/exhaustive-deps */
import { Group } from '@/graphql/generated/graphql';
import {
  addCurrentGroupAction,
  removeCurrentGroupAction,
  updateLayoutCurrentGroupAction,
} from '@modules/GroupDevice/redux/actions';
import { RootState } from '@redux/reducers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_CURRENT_GROUP_ACTIVE_SCREEN_ACTION } from '../redux/action-types';

const useUpdateCurrentGroup = () => {
  const loading = useSelector<RootState>((state) => state.groupDevice.currentGroup.loading) as boolean;
  const dispatch = useDispatch();

  const onAddCurrentGroup = useCallback((data: Group) => {
    dispatch(addCurrentGroupAction(data));
  }, []);

  const onUpdateCurrentGroup = useCallback((data: any) => {
    dispatch(updateLayoutCurrentGroupAction(data));
  }, []);

  const onUpdateCurrentGroupActiveScreen = useCallback((active: number) => {
    dispatch({
      type: UPDATE_CURRENT_GROUP_ACTIVE_SCREEN_ACTION,
      payload: active,
    });
  }, []);

  const onRemoveCurrentGroup = useCallback(() => {
    dispatch(removeCurrentGroupAction());
  }, []);

  return {
    onAddCurrentGroup,
    onUpdateCurrentGroup,
    onRemoveCurrentGroup,
    onUpdateCurrentGroupActiveScreen,
    loading,
  };
};

export default useUpdateCurrentGroup;
