import AddNewDeviceyPage from './pages/AddNewDevice';
import EditDevicePage from './pages/EditDevice';
import DeviceListPage from './pages/ListDevice';
import DeviceAvailablePage from './pages/Available/index';

export const DevicePageRoute = [
  {
    path: '/device/list',
    exact: true,
    component: DeviceListPage,
  },
  {
    path: '/device/available',
    exact: true,
    component: DeviceAvailablePage,
  },
  {
    path: '/device/new',
    exact: true,
    component: AddNewDeviceyPage,
  },
  {
    path: '/device/:id',
    exact: true,
    component: EditDevicePage,
  },
];
