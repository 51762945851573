import { ResetPasswordInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  ResetPasswordActionTypes,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
} from '../action-types/reset-password';

export const resetPassword = (payload: ResetPasswordInput): ResetPasswordActionTypes => ({
  type: RESET_PASSWORD,
  payload,
});
export const resetPasswordSuccess = (payload: ResetPasswordInput): ResetPasswordActionTypes => ({
  type: RESET_PASSWORD_SUCCESS,
  payload,
});
export const resetPasswordError = (payload: AppError): ResetPasswordActionTypes => ({
  type: RESET_PASSWORD_ERROR,
  payload,
});
