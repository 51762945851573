import { Button } from 'antd';
import React from 'react';
import './styles.scss';

interface Props {
  title: string;
  classNameBtn?: string;
  onClick: () => void;
  isLoading?: boolean;
  icon?: any;
  disabled?: boolean;
}

const ButtonOk = (props: Props) => {
  return (
    <Button
      type="primary"
      className={`btn_ok ${props.classNameBtn}`}
      onClick={props.onClick}
      loading={props.isLoading || false}
      icon={props?.icon || null}
      disabled={props.disabled || false}
    >
      {props.title.toLocaleUpperCase()}
    </Button>
  );
};

export default ButtonOk;
