import { Jwt } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';

export interface RegisterInput {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  token?: string;
  organizationName?: string | null;
}

export interface RegisterState {
  loading: boolean;
  data?: RegisterInfo;
}

export interface RegisterAction {
  type: typeof REGISTER;
  payload: RegisterInput;
}

export interface RegisterInfo extends Jwt {}

export interface RegisterActionSuccess {
  type: typeof REGISTER_SUCCESS;
  payload: RegisterInfo;
}

export interface RegisterActionError {
  type: typeof REGISTER_ERROR;
  payload: AppError;
}

export type RegisterActionTypes = RegisterAction | RegisterActionSuccess | RegisterActionError;
