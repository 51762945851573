import { ResetPasswordInput } from '@/graphql/generated/graphql';
import { resetPassword } from '@modules/Auth/redux/actions';
import { RootState } from '@redux/reducers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useRequestPassword = () => {
  const loading = useSelector<RootState>((state) => state.auth.requestEmailState.loading) as boolean;
  const dispatch = useDispatch();
  const onSending = useCallback(
    (data: ResetPasswordInput) => {
      dispatch(resetPassword(data));
    },
    [dispatch],
  );
  return {
    onSending,
    loading,
  };
};
export default useRequestPassword;
