import { Roles, User } from '@/graphql/generated/graphql';
import ButtonDeleteRow from '@commons/components/ButtonRowActions/ButtonDeleteRed';
import ButtonEditRow from '@commons/components/ButtonRowActions/ButtonEditBlue';
import { LoginState } from '@modules/Auth/redux/action-types';
import UpdateStatus from '@modules/User/components/Action/UpdateStatus';
import { RootState } from '@redux/reducers';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

interface UserCompanyRowActionProp {
  record: User;
  onEdit?(user: User): void;
  onDelete?(user: User): void;
}

export default function UserCompanyRowAction(props: UserCompanyRowActionProp) {
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);
  // eslint-disable-next-line
  const id = loginState.data?.companyId ? loginState.data?.companyId : useParams<{ id: string }>().id;

  return (
    <div onClick={(e) => e.stopPropagation()} className="d-flex justify-content-end">
      {loginState.data?.role === Roles.SuperAdmin || loginState.data?.isSuperAdmin ? (
        <UpdateStatus record={props.record} />
      ) : loginState.data?.role === Roles.Admin && props.record.role === Roles.Editor ? (
        <UpdateStatus record={props.record} />
      ) : null}
      {loginState.data?.role === Roles.SuperAdmin ||
      loginState.data?.isSuperAdmin ||
      props.record.role !== Roles.Admin ||
      (props.record.role === Roles.Admin && props.record._id.toString() === loginState.data?._id) ? (
        <ButtonEditRow
          onClick={(e) => {
            props.onEdit && props.onEdit(props.record);
            e.stopPropagation();
          }}
          classNameContainer="mr-2"
        />
      ) : null}

      {id &&
      (loginState.data?.role === Roles.SuperAdmin ||
        loginState.data?.isSuperAdmin ||
        props.record.role !== Roles.Admin) ? (
        <ButtonDeleteRow
          onClick={(e) => {
            props.onDelete && props.onDelete(props.record);
            e.stopPropagation();
          }}
          classNameContainer="mr-2"
        />
      ) : null}
    </div>
  );
}
