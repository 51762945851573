import ButtonCancel from '@commons/components/modals/Button/ButtonCancel';
import ButtonOk from '@commons/components/modals/Button/ButtonOk';
import { getHistory } from '@helpers/history';
import { Card, Form, Input } from 'antd';
import { FormInstance } from 'antd/es/form';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface PackageFormProps {
  isLoading?: boolean;
  form?: FormInstance;
  onFinish?(values: any): void;
}

export default function ChangePasswordForm(props: PackageFormProps) {
  const { t } = useTranslation(['change-password']);

  return (
    <Card
      title={t('change_password')}
      bordered={false}
      headStyle={{ borderBottom: 'none' }}
      bodyStyle={{ marginLeft: '2rem', marginRight: '2rem' }}
      className="card-form form-profile"
    >
      <Form
        onFinish={props.onFinish}
        form={props.form}
        labelAlign="left"
        labelCol={{ xs: 24, md: 6, xl: 4 }}
        colon={false}
        preserve={false}
      >
        <Form.Item
          name="oldPassword"
          label={t('old_password')}
          rules={[
            {
              required: true,
              message: t('validate.required_password'),
            },
            {
              min: 6,
              message: t('validate.min_lenght_password'),
            },
          ]}
        >
          <Input.Password placeholder={t('old_password')} />
        </Form.Item>
        <Form.Item
          name="newPassword"
          label={t('new_password')}
          rules={[
            {
              required: true,
              message: t('validate.required_password'),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('oldPassword') !== value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('validate.old_and_new_password_not_match')));
              },
            }),
            {
              min: 6,
              message: t('validate.min_lenght_password'),
            },
          ]}
        >
          <Input.Password placeholder={t('new_password')} />
        </Form.Item>
        <Form.Item
          name="confirm"
          label={t('confirm_password')}
          dependencies={['newPassword']}
          rules={[
            {
              required: true,
              message: t('validate.required_password'),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('validate.password_confirm')));
              },
            }),
          ]}
        >
          <Input.Password placeholder={t('confirm_password')} />
        </Form.Item>
        <div className="d-flex align-items-center justify-content-end">
          <Form.Item>
            <ButtonCancel classNameBtn="mr-3" key="cancel" onClick={() => getHistory().push('/')} title={t('cancel')} />
            <ButtonOk
              key="confirm"
              onClick={() => props.form?.submit()}
              title={t('save')}
              isLoading={props.isLoading}
            />
          </Form.Item>
        </div>
      </Form>
    </Card>
  );
}
