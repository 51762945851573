import React from 'react';
import { Modal } from 'antd';
import ButtonCancel from './Button/ButtonCancel';
import ButtonOk from './Button/ButtonOk';
import { useTranslation } from 'react-i18next';

interface Props {
  handleOk: () => void;
  handleCancel: () => void;
  title?: string;
  content?: any;
  classContent?: string;
  classModalContainer?: string;
  classBtnOk?: string;
  classBtnCancel?: string;
  data?: any;
  isLoading?: boolean;
  okTitle?: string;
}

const ModalConfirm = (props: Props) => {
  const { t } = useTranslation(['user']);

  return (
    <Modal
      centered
      onCancel={() => props.handleCancel()}
      className={`modal_confirm_custom ${props.classModalContainer}`}
      title={props.title}
      visible={!!props.data}
      footer={[
        <ButtonCancel
          key="cancel"
          title={t('cancel')}
          classNameBtn={`mr-2 ${props.classBtnCancel}`}
          onClick={props.handleCancel}
        />,
        <ButtonOk
          key="confirm"
          onClick={props.handleOk}
          title={props?.okTitle || t('confirm')}
          classNameBtn={` ${props.classBtnOk}`}
          isLoading={props.isLoading}
        />,
      ]}
    >
      <div className={`content_modal ${props.classContent}`}>{props.content}</div>
    </Modal>
  );
};

export default ModalConfirm;
