import { ChangePasswordInput } from '@/graphql/generated/graphql';
import { changePassword } from '@modules/Auth/redux/actions';
import { RootState } from '@redux/reducers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useChangePassword = () => {
  const loading = useSelector<RootState>((state) => state.auth.changePasswordState.loading) as boolean;
  const status = useSelector<RootState>((state) => state.auth.changePasswordState.status) as boolean;
  const dispatch = useDispatch();
  const onChangePassword = useCallback(
    (data: ChangePasswordInput) => {
      dispatch(changePassword(data));
    },
    [dispatch],
  );
  return {
    onChangePassword,
    loading,
    status,
  };
};
export default useChangePassword;
