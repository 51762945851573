import { MoveMediaInput } from '@/graphql/generated/graphql';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { moveFileAction } from '../redux/actions';

const useMoveFile = () => {
  const dispatch = useDispatch();
  const onMoveFile = useCallback(
    (input: MoveMediaInput) => {
      dispatch(moveFileAction(input));
    },
    [dispatch],
  );
  return {
    onMoveFile,
  };
};
export default useMoveFile;
