import { ScheduleType } from '@/graphql/generated/graphql';
import { checkTimeInDay, formatMomentDate, FULL_DATE, HOUR_MINUTE } from '@helpers/date';
import useUpdateDevicePlaylistSchedules from '@modules/GroupDevice/hooks/useUpdateScheduleGroup';
import { CurrentPlaylistSchedulesState } from '@modules/GroupDevice/redux/reducers/current_playlist_schedule';
import { RootState } from '@redux/reducers';
import { Button, Col, DatePicker, Form, Row } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import OpenCloseTime from '../Shedule/OpenCloseTime';
import { ErrorTimeInDayState } from '../Shedule/Right';

const { RangePicker } = DatePicker;

interface DailyProps {
  errorTimeInDay: ErrorTimeInDayState;
  onCancel: () => void;
}

const Daily1Component = (props: DailyProps, ref: any) => {
  const { t } = useTranslation(['group-device']);
  const [form] = Form.useForm();
  const currentPlaylistScheduleState = useSelector<RootState, CurrentPlaylistSchedulesState>(
    (state) => state?.groupDevice?.currentPlaylistSchedule,
  );
  const [errorTimeInDay, setErrorTimeInDay] = useState<ErrorTimeInDayState>({
    message: '',
    status: false,
  });

  const { onUpdatePlaylistScheduleGroupDevice } = useUpdateDevicePlaylistSchedules();

  useEffect(() => {
    if (currentPlaylistScheduleState.data) {
      form.setFieldsValue({
        dateRange: currentPlaylistScheduleState.data?.dailyData?.dateRange
          ? [
              moment(currentPlaylistScheduleState.data?.dailyData?.dateRange?.dateFrom, FULL_DATE),
              moment(currentPlaylistScheduleState.data?.dailyData?.dateRange?.dateTo, FULL_DATE),
            ]
          : undefined,
        startOfDay: currentPlaylistScheduleState.data.dailyData?.timeInDay
          ? moment(currentPlaylistScheduleState.data.dailyData?.timeInDay.startOfDay, HOUR_MINUTE)
          : undefined,
        endOfDay: currentPlaylistScheduleState.data.dailyData?.timeInDay?.endOfDay
          ? moment(currentPlaylistScheduleState.data.dailyData?.timeInDay?.endOfDay, HOUR_MINUTE)
          : undefined,
      });
    }
  }, [currentPlaylistScheduleState.data, form]);

  useImperativeHandle(
    ref,
    () => ({
      onUpdatePlaylistScheduleGroup: () => {
        form.submit();
      },
    }),
    // eslint-disable-next-line
    [],
  );

  const onFinish = (values: any) => {
    let dateRange;
    let timeInDay;
    if (values.dateRange && values.dateRange.length > 1) {
      dateRange = {
        dateFrom: formatMomentDate(values.dateRange[0], FULL_DATE),
        dateTo: formatMomentDate(values.dateRange[1], FULL_DATE),
      };
    }
    if (values?.startOfDay && !values?.endOfDay) {
      setErrorTimeInDay({
        status: true,
        message: t('group-device:schedule.validate.required_end_time'),
      });
      return;
    }
    if (!values?.startOfDay && values?.endOfDay) {
      setErrorTimeInDay({
        status: true,
        message: t('group-device:schedule.validate.required_start_time'),
      });
      return;
    }

    if (values?.endOfDay && values?.startOfDay) {
      if (!checkTimeInDay(values?.startOfDay, values?.endOfDay)) {
        setErrorTimeInDay({
          status: true,
          message: t('group-device:schedule.validate.time_in_day'),
        });
        return;
      }
      timeInDay = {
        startOfDay: formatMomentDate(values?.startOfDay, HOUR_MINUTE),
        endOfDay: formatMomentDate(values?.endOfDay, HOUR_MINUTE),
      };
    }

    const scheduleData: any = {
      playlistId: (currentPlaylistScheduleState.data && currentPlaylistScheduleState.data.playlistId) || '',
      playlistName: currentPlaylistScheduleState?.data?.playlistName,
      type: ScheduleType.Daily,
      index: currentPlaylistScheduleState?.data?.index,
      dailyData: {
        dateRange,
        timeInDay,
      },
    };
    setErrorTimeInDay({
      message: '',
      status: false,
    });
    onUpdatePlaylistScheduleGroupDevice(scheduleData);
    props.onCancel();
  };

  const setAllDayTime = () => {
    form.setFieldsValue({
      startOfDay: moment('00:00', HOUR_MINUTE),
      endOfDay: moment('00:00', HOUR_MINUTE),
    });
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current: any) => {
    return current && current < moment().startOf('day');
  };

  return (
    <div className="schedule-type-content">
      <Form form={form} onFinish={onFinish}>
        <Row className="mb-4">
          <Col span={24} md={5} className="d-flex align-items-center justify-content-start mb-2 mb-md-0">
            <label className="key">{t('schedule.select_date')}:</label>
          </Col>
          <Col span={24} md={19} className="d-flex align-items-center justify-content-between">
            <Form.Item className="mb-0" name={'dateRange'}>
              <RangePicker
                style={{ width: '100%', maxWidth: '400px' }}
                format={FULL_DATE}
                placeholder={[t('schedule.start_date'), t('schedule.end_date')]}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col span={24} md={5} className="d-flex align-items-center justify-content-start mb-2 mb-md-0">
            <label className="key">{t('schedule.select_time')}:</label>
          </Col>
          <Col span={24} md={19} className="d-flex align-items-center justify-content-between">
            <OpenCloseTime
              changeStartOfDay={(value?: string) => {
                form.setFieldsValue({
                  startOfDay: value ? moment(value, HOUR_MINUTE) : undefined,
                });
              }}
              changeEndOfDay={(value?: string) => {
                form.setFieldsValue({
                  endOfDay: value ? moment(value, HOUR_MINUTE) : undefined,
                });
              }}
            />

            <Button className="btn-all-day mr-md-2" onClick={setAllDayTime}>
              {t('button:all_day')}
            </Button>
          </Col>
          <Col span={24} md={{ span: 19, offset: 5 }} className="d-flex align-items-center justify-content-between">
            {errorTimeInDay?.status && (
              <div className="ant-form-item-explain ant-form-item-explain-error">
                <div role="alert">{errorTimeInDay?.message}</div>
              </div>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default forwardRef(Daily1Component);
