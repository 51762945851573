import { UpdateMediaInput } from '@/graphql/generated/graphql';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateFileAction } from '../redux/actions';

const useUpdateFile = () => {
  const dispatch = useDispatch();
  const onUpdateFile = useCallback(
    (input: UpdateMediaInput) => {
      dispatch(updateFileAction(input));
    },
    [dispatch],
  );
  return {
    onUpdateFile,
  };
};
export default useUpdateFile;

// import { getSDK } from '@/graphql/client';
// import i18n from '@/i18n';
// import { useMutation, useQueryClient } from 'react-query';
// import { GET_ALL_MEDIA } from '../types';

// export default function useUpdateFile() {
//   const queryClient = useQueryClient();

//   return useMutation(getSDK().updateFile, {
//     onSuccess: (data, variables, context) => {
//       queryClient.invalidateQueries(GET_ALL_MEDIA);
//       const messageData = {
//         message: i18n.t('update_success'),
//       };

//       // global on success
//       const globalOnSuccess = queryClient.getDefaultOptions().mutations?.onSuccess;
//       // tslint:disable-next-line: no-unused-expression
//       globalOnSuccess && globalOnSuccess(messageData, variables, context);
//     },
//   });
// }
