import React from 'react';
import logoText from '@assets/images/win-cloud.png';
import env from '@/env';
import { Link } from 'react-router-dom';
import { CommonPath } from '@commons/base-routes';

interface PropsInterface {
  collapsed: boolean;
  canDirect: boolean;
}
interface LogoInterface {
  canDirect: boolean;
}
export const LogoIcon = (props: LogoInterface) => {
  return (
    <>
      {props.canDirect ? (
        <Link to={CommonPath.DEFAULT_PATH}>
          <img src={logoText} alt={env.appName} className="mw-100 logo" />
        </Link>
      ) : (
        <img src={logoText} alt={env.appName} className="mw-100 logo" />
      )}
    </>
  );
};

export const LogoFull = (props: LogoInterface) => {
  return (
    <div className="w-100 text-center">
      {props.canDirect ? (
        <Link to={CommonPath.DEFAULT_PATH}>
          <img src={logoText} alt={env.appName} className="mw-100 logo" width="155" />
        </Link>
      ) : (
        <img src={logoText} alt={env.appName} className="mw-100 logo" width="155" />
      )}
    </div>
  );
};

export const BigLogo = (props: PropsInterface) => {
  return !props.collapsed ? <LogoFull canDirect={props.canDirect} /> : <LogoIcon canDirect={props.canDirect} />;
};
