import { CreateUploadSettingInput, SettingType } from '@/graphql/generated/graphql';
import ButtonOk from '@commons/components/modals/Button/ButtonOk';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import useUpdateSetting from '@modules/Setting/hooks/useUpdateSetting';
import { Card, Col, Divider, Form, Input, Row } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface SettingFormProps {
  data: any;
}

export default function SettingForm(props: SettingFormProps) {
  const { t } = useTranslation(['setting']);
  const { isLoading, mutate } = useUpdateSetting();

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue({ maxFileSize: props.data?.maxFileSize });
    }
    // eslint-disable-next-line
  }, [props.data]);

  const [form] = Form.useForm();
  const handleSubmit = (values: any) => {
    const payload: CreateUploadSettingInput = {
      key: SettingType.Upload,
      value: [
        {
          key: 'maxFileSize',
          value: values.maxFileSize,
        },
      ],
    };
    mutate({ input: payload });
  };
  const { setPageTitle } = useSetPageTitle();

  useEffect(() => {
    t && setPageTitle(t('setting'));
    // eslint-disable-next-line
  }, [t]);

  return (
    <Card
      bordered={false}
      headStyle={{ borderBottom: 'none' }}
      bodyStyle={{ marginLeft: '2rem', marginRight: '2rem' }}
      className="card-form"
    >
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name="maxFileSize"
          label={t('max_upload_file_size')}
          labelAlign="left"
          labelCol={{ xs: 24, md: 10, xl: 8 }}
          colon={false}
          rules={[
            { required: true, message: t('validate.required_max_file_size') },
            { pattern: /^[1-9][\d]*/, message: t('validate.required_min_max_file_size') },
          ]}
          preserve={false}
        >
          <Input style={{ width: '100%', maxWidth: 485 }} placeholder={t('max_upload_file_size')} type="number" />
        </Form.Item>

        <Divider className="mt-4 mt-lg-5" />
        <Row>
          <Col span={24} className="d-flex justify-content-end">
            <ButtonOk key="confirm" onClick={() => form.submit()} title={t('save')} isLoading={isLoading} />
          </Col>
        </Row>
      </Form>
    </Card>
  );
}
