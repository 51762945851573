import { Media } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFolder } from '../redux/actions';
import { SelectFolderState } from '../redux/reducers/select_folder';

const useSelectFolder = () => {
  const selectFolderState = useSelector<RootState, SelectFolderState>((state) => state.media.selectFolder);

  const dispatch = useDispatch();
  const onSelectFolder = useCallback(
    (input?: Media | null | undefined) => {
      dispatch(selectFolder(input));
    },
    [dispatch],
  );
  return {
    isLoading: selectFolderState.loading,
    selectedFolder: selectFolderState.selectedFolder,
    onSelectFolder,
  };
};
export default useSelectFolder;
