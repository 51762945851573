import { FileType, Media, MediaStatus, MediaType } from '@/graphql/generated/graphql';
import { FileExclamationOutlined } from '@ant-design/icons';
import Folder from '@assets/images/Folder.png';
import Loading from '@commons/components/Loading';
import { getHistory } from '@helpers/history';
import ItemDropdown from '@modules/Media/component/dropdown/ItemDropdown';
import { allowedMimeImageName, getTypeOfFile } from '@modules/Media/helper/file';
import useSelectFolder from '@modules/Media/hooks/useSelectFolder';
import { TypeViewMedia } from '@modules/Media/pages/index';
import { Image } from 'antd';
import React, { MouseEvent } from 'react';
import { FaFilePdf, FaFilePowerpoint } from 'react-icons/fa';

interface IFProps {
  item: Media;
  isShowMenu?: boolean;
  onShowMenu?: (e: Media) => void;
  onSelectMedia?: (e: Media, event: MouseEvent) => any;
  isChecked?: boolean;
  typeView: TypeViewMedia;
}

interface MediaFileTypeIF {
  media?: Media;
  src?: string;
}

export function MediaFileType(props: MediaFileTypeIF) {
  const { media, src } = props;
  const mediaType = getTypeOfFile(media?.mimetype || '');
  switch (mediaType) {
    case MediaType.Pdf:
      return <FaFilePdf size={50} />;
    case MediaType.Powerpoint:
      return <FaFilePowerpoint size={50} />;
    case MediaType.Image:
      return (
        <Image
          width="100%"
          preview={false}
          src={`${src ? src : media?.thumbs ? media?.thumbs[0].url : media?.optimizeUrl}`}
        />
      );
    case MediaType.Video:
      return (
        <Image width="100%" preview={false} src={`${media?.images ? media?.images[0].url : media?.optimizeUrl}`} />
      );
    default:
      return null;
  }
}

function MediaItemComponent(props: IFProps) {
  // const { t } = useTranslation(['media']);
  const { item } = props;
  const { onSelectFolder } = useSelectFolder();

  function contextMenu(e: any): void {
    e.preventDefault();
    props?.onShowMenu?.(item);
  }

  const selectFolder = () => {
    if (item && item.type === FileType.Folder) {
      getHistory().push(`${getHistory().location.pathname}?id=${item._id}`);
      onSelectFolder(item);
    }
  };

  return (
    <div
      className={`media-item ${props.isChecked ? 'media-item-checked' : ''}`}
      onContextMenu={contextMenu}
      onClick={(e: MouseEvent) => props.onSelectMedia?.(item, e)}
      onDoubleClick={selectFolder}
    >
      <ItemDropdown item={item} isShowMenu={props.isShowMenu} />
      <div className={`media-image`}>
        {item.status === MediaStatus.Processing ? (
          <Loading type="inline" />
        ) : item.status === MediaStatus.Failed ? (
          <FileExclamationOutlined size={50} />
        ) : item.type === FileType.File ? (
          <MediaFileType media={item} />
        ) : (
          <img src={Folder} alt="folder" />
        )}
      </div>
      <div className="info-media">
        <div className="info_1 text-2-line" style={{ flex: 2 }}>
          <p className={`viewFileName text-2-line`}>{item.filename}</p>
          {/* {(item.images?.[0] || (item.width && item.height)) && (
            <p className="viewFileType-px">{`${item.images?.[0]?.width || item.width || 0} x ${
              item.images?.[0]?.height || item.height || 0
            } `}</p>
          )} */}
        </div>
        <div className="info_2 text-2-line " style={{ flex: 1 }}>
          {item.mimetype && (
            <p className="viewFileType text-2-line">{`${allowedMimeImageName[item.mimetype] || 'document'}`}</p>
          )}
        </div>
        <div className="info_2 text-2-line" style={{ flex: 1 }}>
          {item.mimetype && <p className="viewFileType ">{`${item.size?.toFixed(3)} MB`}</p>}
        </div>
        {/* <div className="info_2 text-2-line" style={{ flex: 1 }}>
          <p className={`viewFileCreateAt`}>{moment(item.createdAt).local().format('DD.MM.yyyy HH:mm')}</p>
        </div> */}
      </div>
    </div>
  );
}

export default MediaItemComponent;
