import { ForgotPasswordInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const REQUEST_EMAIL = 'REQUEST_EMAIL';
export const REQUEST_EMAIL_SUCCESS = 'REQUEST_EMAIL_SUCCESS';
export const REQUEST_EMAIL_ERROR = 'REQUEST_EMAIL_ERROR';

export interface RequestEmailInput {
  email: string;
}
export interface RequestEmailState {
  email?: string;
  loading: boolean;
}
export interface RequestEmailAction {
  type: typeof REQUEST_EMAIL;
  payload: ForgotPasswordInput;
}
export interface RequestEmailActionSuccess {
  type: typeof REQUEST_EMAIL_SUCCESS;
  payload: RequestEmailInput;
}
export interface RequestEmailActionError {
  type: typeof REQUEST_EMAIL_ERROR;
  payload: AppError;
}
export type RequestEmailActionTypes = RequestEmailAction | RequestEmailActionSuccess | RequestEmailActionError;
