import { Media } from '@/graphql/generated/graphql';

export const DELETE_MEDIA_ITEM = 'DELETE_MEDIA_ITEM';

export interface DeleteMediaItemAction {
  type: typeof DELETE_MEDIA_ITEM;
  payload: Media;
}

export type DeleteMediaItemActionTypes = DeleteMediaItemAction;
