import { Roles } from '@/graphql/generated/graphql';
import addIcon from '@assets/images/icons/add.svg';
import TableWithNumber from '@commons/components/TableWithNumber';
import useProfile from '@modules/Auth/hooks/useProfile';
import useAssignUserToCompany from '@modules/Company/hooks/useAssignUserToCompany';
import useGetListUsersCompanyAssign from '@modules/Company/hooks/useGetListUsersCompanyAssign';
import { FilterUserInput } from '@modules/User/types';
import { Button, Card, Col, Form, Row, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';

const { Option } = Select;

interface IState {
  role: Roles | null;
  selectedUsers: string[];
}

export default function SelectStaff() {
  const { t } = useTranslation(['company', 'user']);
  const [userFilter, setUserFilter] = React.useState<FilterUserInput>({
    isSearchAll: true,
    page: 1,
    limit: 20,
  });
  const [role, setRole] = React.useState<Roles | null>(null);
  const { loginState } = useProfile();
  const { mutate, isLoading, isSuccess } = useAssignUserToCompany();
  const [form] = Form.useForm();
  const [state, setState] = React.useState<IState>({
    role: null,
    selectedUsers: [],
  });

  const { data: listUser } = useGetListUsersCompanyAssign({
    companyId: loginState.data?.companyId || '',
    limit: userFilter.limit || 20,
    query: userFilter.query,
    page: userFilter.page,
  });

  const columns: ColumnsType<any> = [
    {
      title: t('username'),
      key: 'username',
      dataIndex: 'username',
      align: 'left',
    },
    {
      title: t('email'),
      key: 'email',
      dataIndex: 'email',
      align: 'left',
    },
    {
      title: '',
      key: 'action',
      width: 60,
      className: 'd-flex justify-content-center align-items-center',
      render: (record: any) => (
        <MdClose
          className="cursor-pointer"
          color="#F80000"
          size={24}
          onClick={() => {
            setState({
              ...state,
              selectedUsers: state.selectedUsers?.filter((user: any) => user._id !== record._id) || [],
            });
          }}
        />
      ),
    },
  ];

  const onChangeRole = (role: Roles) => {
    setRole(role);
  };

  const onAssign = () => {
    const userIds = state.selectedUsers?.map((user: any) => user._id);
    if (userIds.length && role && loginState.data?.companyId) {
      mutate({
        input: {
          companyId: loginState.data.companyId,
          role,
          userIds,
        },
      });
    }
  };

  React.useEffect(() => {
    if (isSuccess) {
      form.resetFields(['users']);
      setState({ ...state, role: null, selectedUsers: [] });
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  return (
    <Card
      title={t('select_accounts_and_assign_role')}
      bordered={false}
      headStyle={{ borderBottom: 'none' }}
      bodyStyle={{ padding: '8px 16px' }}
      className="pb-2"
    >
      <Form form={form} onFinish={onAssign}>
        <Form.Item name="users" className="my-0">
          <Select
            dropdownClassName="mr-0"
            className="select-user w-100"
            placeholder={t('select_account')}
            labelInValue
            onChange={(e: any, option: any) => {
              let data = state.selectedUsers.concat(option.data);
              let resArr: any[] = [];
              data.filter(function (item: any) {
                var i = resArr.findIndex((x) => x._id === item._id);
                if (i <= -1) {
                  resArr.push(item);
                }
                return null;
              });
              setState({ ...state, selectedUsers: resArr });
              setTimeout(() => {
                form.resetFields(['users']);
              }, 200);
            }}
            showSearch={true}
            onSearch={(e) => {
              setUserFilter({
                ...userFilter,
                query: e,
                page: 1,
                limit: 20,
              });
            }}
            dropdownRender={(menu) => {
              return (
                <React.Fragment>
                  {menu}
                  <Row className="ml-2 mr-2 mt-2 mb-2 justify-content-center">
                    <Col className="mr-2">
                      <Button
                        onMouseDown={() => {
                          if (userFilter.page && userFilter.page <= 1) {
                            return;
                          }
                          setUserFilter({
                            ...userFilter,
                            page: 1,
                          });
                        }}
                      >
                        Reset
                      </Button>
                    </Col>
                    <Col className="mr-2">
                      <Button
                        onMouseDown={() => {
                          if (userFilter.page && userFilter.page <= 1) {
                            return;
                          }
                          setUserFilter({
                            ...userFilter,
                            page: (userFilter.page || 0) - 1,
                          });
                        }}
                      >
                        Pre
                      </Button>
                    </Col>
                    <Col className="mr-2">
                      <Button
                        onMouseDown={() => {
                          if (userFilter.page === listUser?.listUsersCanAddToCompany?.totalPage) {
                            return;
                          }
                          setUserFilter({
                            ...userFilter,
                            page: (userFilter.page || 0) + 1,
                          });
                        }}
                      >
                        Next
                      </Button>
                    </Col>
                    <Col className="mt-1">{`${listUser?.listUsersCanAddToCompany?.currentPage} / ${listUser?.listUsersCanAddToCompany?.totalPage}`}</Col>
                  </Row>
                </React.Fragment>
              );
            }}
          >
            {listUser &&
              listUser?.listUsersCanAddToCompany?.items &&
              listUser?.listUsersCanAddToCompany?.items.map((user: any) => (
                <Option
                  data={user}
                  key={user?._id}
                  value={`${user.username} - ${user.email}`}
                  title={`${user.username} - ${user.email}`}
                >
                  {user.username} - {user.email}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <div className="selected_users-table mt-3 add-more-table">
          <TableWithNumber
            className="table-striped-rows"
            rowKey={(data) => data._id}
            columns={columns}
            dataSource={state.selectedUsers || []}
            pagination={false}
            size="middle"
          />
        </div>
        <div className="assign-form mt-3">
          <Select placeholder={t('select_role')} className="select-role w-100" onChange={onChangeRole}>
            <Option key={Roles.Admin} value={Roles.Admin}>
              {Roles.Admin}
            </Option>
            <Option key={Roles.Editor} value={Roles.Editor}>
              {Roles.Editor}
            </Option>
          </Select>
          <Button
            disabled={role && state.selectedUsers.length ? false : true}
            icon={role && state.selectedUsers.length ? <img src={addIcon} alt={t('assign')} className="mr-2" /> : null}
            size="middle"
            className="mt-3 w-100 py-3 d-flex justify-content-center align-items-center assign-user-btn"
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            {t('assign')}
          </Button>
        </div>
      </Form>
    </Card>
  );
}
