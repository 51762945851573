import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { deleteMediaApi } from '@modules/Media/services/apis';
import { put } from 'redux-saga/effects';
import { DeleteMediaAction } from '../action-types';
import { deleteMediaActionError, deleteMediaActionSuccess } from '../actions';

export function* deleteMediaAsync(action: DeleteMediaAction) {
  try {
    const result: Boolean = yield deleteMediaApi(action.payload);
    if (result) {
      yield put(deleteMediaActionSuccess(action.payload._id));
    }
  } catch (error) {
    yield put(deleteMediaActionError(error));
  }
}

export function deleteMediaAsyncSuccess() {
  NotificationSuccess(i18n.t('notification'), i18n.t('delete_success'));
}
