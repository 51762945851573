import { getSDK } from '@/graphql/client';
import { QueryCompanyInput } from '@/graphql/generated/graphql';

export const calculateTotalStorage = async (input: QueryCompanyInput) => {
  // return getSDK().calculateTotalStorage({ input: { _id: input._id } });
  return new Promise((resolve, reject) => {
    //call api
    getSDK()
      .calculateTotalStorage({ input: { _id: input._id } })
      .then((user) => {
        resolve(user);
      })
      .catch((error) => reject(error?.response?.errors[0]));
  });
};
