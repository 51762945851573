import { Setting, SettingType } from '@/graphql/generated/graphql';
import FormHeader from '@commons/components/FormHeader';
import { setTitle } from '@helpers/dom';
import SettingDeviceForm from '@modules/Setting/componennts/Form/SettingDeviceForm';
import SettingForm from '@modules/Setting/componennts/Form/SettingForm';
import { getObjectSettingBySettingType } from '@modules/Setting/helpers/utils';
import useGetSettingDetail from '@modules/Setting/hooks/useGetSettingDetail';
import { Tabs } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;

export default function UpdateSettingPage() {
  const { t } = useTranslation(['setting']);
  const { data: dataSetting } = useGetSettingDetail();
  const dataUploadSetting = getObjectSettingBySettingType(dataSetting?.settings as Setting[] | any, SettingType.Upload);
  const dataDeviceSetting = getObjectSettingBySettingType(dataSetting?.settings as Setting[] | any, SettingType.Device);

  useEffect(() => {
    setTitle(t('setting'));
    // eslint-disable-next-line
  });

  return (
    <div>
      <FormHeader>
        <Tabs defaultActiveKey="1" tabBarStyle={{ paddingLeft: '0.5rem', backgroundColor: '#fff' }}>
          <TabPane tab={t('setting_upload')} key="setting_upload">
            <SettingForm data={dataUploadSetting} />
          </TabPane>
          <TabPane tab={t('setting_device')} key="setting_device">
            <SettingDeviceForm data={dataDeviceSetting} />
          </TabPane>
        </Tabs>
      </FormHeader>
    </div>
  );
}
