import {
  UpdateProfileActionTypes,
  UpdateProfileState,
  UPDATE_PROFILE,
  UPDATE_PROFILE_ERROR,
} from '../action-types/update';

const initialState = {
  loading: false,
  visible: false,
};

export default (state = initialState, action: UpdateProfileActionTypes): UpdateProfileState => {
  switch (action.type) {
    case UPDATE_PROFILE:
      return {
        ...state,
        loading: true,
        visible: true,
      };

    // case UPDATE_PROFILE_SUCCESS:
    //   return {
    //     ...state,
    //     data: {
    //       ...action.payload,
    //     },
    //     loading: false,
    //     visible: false,
    //   };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        visible: false,
      };

    default:
      return state;
  }
};
