import { QueryCompaniesInput, QueryUsersInput, Roles, User } from '@/graphql/generated/graphql';
import BtnAddNew from '@commons/components/Button/BtnAddNew';
import ModalConfirm from '@commons/components/modals/ModalConfirm';
import TableLayout from '@commons/components/TableLayout';
import TableWithNumber from '@commons/components/TableWithNumber';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { LoginState } from '@modules/Auth/redux/action-types';
import useGetAllCompany from '@modules/Company/hooks/useGetAllCompany';
import { PageSizeTable } from '@modules/Company/types';
import UserRowAction from '@modules/User/components/Action/UserRowAction';
import AddUserModal from '@modules/User/components/modal/AddUserModal';
import EditUserModal from '@modules/User/components/modal/EditUserModal';
import ResetPasswordModal from '@modules/User/components/modal/ResetPasswordModal';
import useDeleteUser from '@modules/User/hooks/useDeleteUser';
import useGetUsers from '@modules/User/hooks/useGetUsers';
import { RootState } from '@redux/reducers';
import { Button, Col, Form, Input, Row, Select, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { truncate } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import TextTruncate from 'react-text-truncate';
import '../../styled/index.scss';

interface IState {
  updateUserData?: User;
  deleteUserData?: User;
  visibleModalAddUser: boolean;
  resetPasswordModal?: User;
}

const { Option } = Select;

export default function AllUsersListPage() {
  const { isLoading, mutate: onDeleteUserMutate, isSuccess: isDeleteSuccess } = useDeleteUser();
  const { t } = useTranslation(['user']);
  const { setPageTitle } = useSetPageTitle();

  const [state, setState] = useState<IState>({
    updateUserData: undefined,
    deleteUserData: undefined,
    visibleModalAddUser: false,
    resetPasswordModal: undefined,
  });

  useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t('account'));
    // eslint-disable-next-line
  }, [t]);

  const columns: ColumnsType<any> = [
    {
      title: t('stt'),
      key: '#',
      dataIndex: 'STT',
      align: 'center',
      width: 100,
    },
    {
      title: t('full_name'),
      key: 'name',
      dataIndex: 'name',
      width: 250,
      responsive: ['xl'],
      render: (record) => {
        return (
          <TextTruncate line={3} containerClassName="word-break" element="div" truncateText="…" text={record || ''} />
        );
      },
    },
    {
      title: t('username'),
      key: 'username',
      dataIndex: 'username',
      width: 120,
      render: (record) => {
        return (
          <div
            style={{
              fontWeight: 500,
            }}
            className="word-break-all"
          >
            <TextTruncate line={3} element="span" truncateText="…" text={record || ''} />
          </div>
        );
      },
    },
    {
      title: t('phone'),
      key: 'phone',
      dataIndex: 'phone',
      width: 150,
    },
    {
      title: t('company'),
      key: 'company',
      width: 250,
      render: (record: User) => (
        <Tooltip title={record?.company?.name || ''}>{truncate(record?.company?.name, { length: 30 })}</Tooltip>
      ),
    },
    {
      title: t('role'),
      key: 'role',
      width: 200,
      render: (record: User) => {
        return record.role === Roles.Admin ? (
          <span>{t('status.checker')}</span>
        ) : (
          <span className="text-capitalize">{t('status.editor')?.toLowerCase()}</span>
        );
      },
    },
    {
      title: '',
      key: 'action',
      align: 'left',
      width: 200,
      render: (record: User) => {
        return (
          <UserRowAction
            record={record}
            onEdit={(record) => {
              setState({ ...state, updateUserData: record });
            }}
            onDelete={(record) => {
              setState({ ...state, deleteUserData: record });
            }}
            onResetPassword={(record) => {
              setState({ ...state, resetPasswordModal: record });
            }}
          />
        );
      },
    },
  ];
  const [filter, setFilter] = useState<QueryUsersInput>({
    page: 1,
    limit: PageSizeTable,
  });
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);

  const [form] = Form.useForm();

  const { data } = useGetUsers(filter);

  const [filterCompany, setFilterCompany] = useState<QueryCompaniesInput>({
    page: 1,
    limit: 8,
    query: undefined,
  });

  const { data: listCompanies } = useGetAllCompany(filterCompany);

  const onSearch = (values: any) => {
    setFilter({
      ...filter,
      page: 1,
      query: values.query ? values.query.trim() : undefined,
      role: values.role || undefined,
    });
  };

  const onCancel = () => {
    setState({
      ...state,
      updateUserData: undefined,
      deleteUserData: undefined,
      visibleModalAddUser: false,
      resetPasswordModal: undefined,
    });
  };

  const onDeleteUser = () => {
    if (state.deleteUserData) {
      onDeleteUserMutate({ input: { _id: state.deleteUserData._id } });
    }
  };

  React.useEffect(() => {
    if (isDeleteSuccess) onCancel();
    // eslint-disable-next-line
  }, [isDeleteSuccess]);

  return (
    <>
      <TableLayout
        tableHeader={
          <Form
            layout="inline"
            form={form}
            onFinish={onSearch}
            className="justify-content-start justify-content-md-end gap-3"
          >
            {loginState.data?.role === Roles.SuperAdmin && (
              <Form.Item className="bg_input_form" name="companyId" style={{ width: 300 }}>
                <Select
                  style={{ width: '100%', maxWidth: 485 }}
                  showSearch
                  dropdownClassName="mr-0"
                  allowClear
                  placeholder={t('company')}
                  onSearch={(e) => {
                    setFilterCompany({
                      ...filterCompany,
                      query: e,
                      page: 1,
                    });
                  }}
                  onChange={(e: any) => {
                    setFilter({
                      ...filter,
                      page: 1,
                      companyId: e ? e?.toString()?.split('/$/')[0] : undefined,
                    });
                  }}
                  filterOption={false}
                  dropdownRender={(menu) => {
                    return (
                      <React.Fragment>
                        {menu}
                        <Row className="ml-2 mr-2 mt-2 mb-2 justify-content-center">
                          <Col className="mr-2">
                            <Button
                              onMouseDown={() => {
                                if (filterCompany && filterCompany.page && filterCompany.page <= 1) {
                                  return;
                                }
                                setFilterCompany({
                                  ...filterCompany,
                                  page: (filterCompany.page || 0) - 1,
                                });
                              }}
                            >
                              {t('button:pre')}
                            </Button>
                          </Col>
                          <Col className="mr-2">
                            <Button
                              onMouseDown={() => {
                                if (
                                  (filterCompany &&
                                    filterCompany.page &&
                                    filterCompany.page === listCompanies?.companies?.totalPage) ||
                                  (listCompanies?.companies && listCompanies?.companies?.totalPage <= 0)
                                ) {
                                  return;
                                }
                                setFilterCompany({
                                  ...filterCompany,
                                  page: (filterCompany.page || 0) + 1,
                                });
                              }}
                            >
                              {t('button:next')}
                            </Button>
                          </Col>
                          <Col className="mt-1">{`${listCompanies?.companies?.currentPage} / ${listCompanies?.companies?.totalPage}`}</Col>
                        </Row>
                      </React.Fragment>
                    );
                  }}
                >
                  {listCompanies &&
                    listCompanies?.companies &&
                    listCompanies?.companies.items?.map((company: any) => (
                      <Option key={company?._id} value={`${company._id}/$/${company.name}`} title={`${company.name}`}>
                        {company.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item name={'role'}>
              <Select
                style={{ width: 140 }}
                placeholder={t('role')}
                allowClear
                onChange={(e: Roles) => {
                  setFilter({
                    ...filter,
                    page: 1,
                    role: e || undefined,
                  });
                }}
              >
                <Option value={Roles.Admin}>{t('status.checker')}</Option>
                <Option value={Roles.Editor}>{t('status.editor')}</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Input.Group compact>
                <Form.Item name="query" className="w-full mr-0">
                  <Input className="bd-form-search" placeholder={`${t('search_user')}`} />
                </Form.Item>
                <Button icon={<FiSearch color="#F80000" size={24} />} className="btn-search" htmlType="submit" />
              </Input.Group>
            </Form.Item>
            <BtnAddNew
              onClick={() =>
                setState({
                  ...state,
                  visibleModalAddUser: true,
                })
              }
            />
          </Form>
        }
      >
        <TableWithNumber
          className="table-striped-rows"
          rowKey={(data) => data._id}
          columns={columns}
          dataSource={data?.users?.items || []}
          loading={false}
          scroll={{
            x: true,
          }}
          tableLayout="fixed"
          totalTitle={t('account')}
          paginate={{
            pageSize: PageSizeTable || 1,
            total: data?.users?.total || 0,
            current: data?.users?.currentPage || 1,
            onChange: (page: number) => {
              setFilter({
                ...filter,
                page,
              });
            },
          }}
          pagination={false}
        />
      </TableLayout>
      <EditUserModal onCancel={onCancel} data={state.updateUserData} />
      <ModalConfirm
        isLoading={isLoading}
        data={state.deleteUserData}
        handleOk={onDeleteUser}
        handleCancel={onCancel}
        title={t('delete_title')}
        okTitle={t('delete')}
        content={
          <Trans
            i18nKey="delete_content"
            t={t}
            values={{ username: state.deleteUserData?.username }}
            components={[<span className="primary_color fw-500" key={''} />]}
          />
        }
      />
      <AddUserModal onCancel={onCancel} visible={state.visibleModalAddUser} />
      <ResetPasswordModal onCancel={onCancel} data={state.resetPasswordModal} />
    </>
  );
}
