import { getSDK } from '@/graphql/client';
import { ChangePasswordInput, ForgotPasswordInput, LoginInput, ResetPasswordInput } from '@/graphql/generated/graphql';
import { LogoutInput, VerifyTokenAutoLoginInput } from '../redux/action-types';

export const me = async () => {
  return new Promise((resolve, reject) => {
    //call api
  });
};

export const login = async (payload: LoginInput) => {
  return new Promise((resolve, reject) => {
    //call api
    getSDK()
      .login({ input: payload })
      .then((user) => {
        resolve(user);
      })
      .catch((error) => reject(error?.response?.errors[0]));
  });
};

export const logout = async (payload: LogoutInput) => {
  return getSDK().logout({ input: payload.token });
};

export const supperAdminAutoLogin = async (companyId: string) => {
  return new Promise((resolve, reject) => {
    //call api
    getSDK()
      .createTokenAutoLogin({
        input: {
          companyId,
        },
      })
      .then((token) => {
        resolve(token?.createTokenAutoLogin);
      })
      .catch((error) => reject(error?.response?.errors[0]));
  });
};

export const verifyTokenAutoLogin = async (data: VerifyTokenAutoLoginInput) => {
  return new Promise((resolve, reject) => {
    //call api
    getSDK()
      .verifyTokenAutoLogin({ input: data })
      .then((data) => {
        resolve(data?.verifyTokenAutoLogin);
      })
      .catch((error) => reject(error?.response?.errors[0]));
  });
};

export const forgotPasword = async (payload: ForgotPasswordInput) => {
  return new Promise((resolve, reject) => {
    //call api
    getSDK()
      .forgotPassword({ input: payload })
      .then((user) => {
        resolve(user);
      })
      .catch((error) => reject(error?.response?.errors[0]));
  });
};

export const resetPassword = async (payload: ResetPasswordInput) => {
  return new Promise((resolve, reject) => {
    //call api
    getSDK()
      .resetPassword({ input: payload })
      .then((user) => {
        resolve(user);
      })
      .catch((error) => reject(error?.response?.errors[0]));
  });
};

export const changePassword = async (payload: ChangePasswordInput) => {
  return new Promise((resolve, reject) => {
    //call api
    getSDK()
      .changePassword({ input: payload })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => reject(error?.response?.errors[0]));
  });
};

// export const register = async (data: RegisterInput) => {
//   return new Promise((resolve, reject) => {
//     commitMutation<RegisterMutation>(environment, {
//       mutation: registerMutation,
//       variables: {
//         ...data,
//       },
//       onCompleted: (response, errors) => {
//         if (errors?.length) {
//           return reject(errors);
//         }
//         resolve(response);
//       },
//       onError: (error: Error) => {
//         reject(error);
//       },
//     });
//   });
// };
