import { Company, Device, Group, QueryGroupsInput } from '@/graphql/generated/graphql';
import ButtonCancel from '@commons/components/modals/Button/ButtonCancel';
import ButtonOk from '@commons/components/modals/Button/ButtonOk';
import { LoginState } from '@modules/Auth/redux/action-types';
import useGetAllCompany from '@modules/Company/hooks/useGetAllCompany';
import { CompanyFilter } from '@modules/Company/types';
import useUpdateDevice from '@modules/Device/hooks/useUpdateDevice';
import useGetAllGroups from '@modules/GroupDevice/hooks/useGetAllGroups';
import { RootState } from '@redux/reducers';
import { Button, Checkbox, Col, Form, Input, Modal, Radio, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { OsObject, ScreenObject } from '../Form/DeviceFrom';

interface IProps {
  onCancel: () => void;
  deviceData?: Device;
}

export default function EditDeviceModal(props: IProps) {
  const [form] = Form.useForm();
  const { t } = useTranslation(['device', 'button']);
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);
  const { isSuccess, mutate, isLoading } = useUpdateDevice();
  const [value, setValue] = useState(1);
  // paginate, filter: list company
  const [paginate, setPaginate] = useState({
    page: 1,
    pageSize: 8,
  });
  const [filter, setFilter] = useState<CompanyFilter>({});

  const [filterGroup, setFilterGroup] = useState<QueryGroupsInput>({
    limit: 8,
    page: 1,
    companyId: loginState.data?.companyId,
  });

  useEffect(() => {
    if (loginState.data?.companyId) {
      setFilterGroup({
        limit: 8,
        page: 1,
        companyId: loginState.data?.companyId,
      });
    }
  }, [loginState.data]);

  const { data: listGroup } = useGetAllGroups(filterGroup);

  const { data: listCompany } = useGetAllCompany({
    page: paginate.page,
    limit: paginate.pageSize,
    query: filter?.query || undefined,
  });

  if (
    props?.deviceData?.groupId &&
    listGroup?.groups?.items?.findIndex((c) => c?._id === props?.deviceData?.groupId) === -1
  ) {
    listGroup?.groups?.items?.push(props?.deviceData?.group as Group);
  }

  if (
    props?.deviceData?.companyId &&
    listCompany?.companies?.items?.findIndex((c) => c?._id === props?.deviceData?.companyId) === -1
  ) {
    listCompany?.companies?.items?.push(props?.deviceData?.company as Company);
  }

  const { Option } = Select;

  const onFinish = (values: any) => {
    console.log('values', values);
    if (props?.deviceData && props?.deviceData?._id) {
      const payload: any = {
        name: values.name,
        // deviceCode: values.deviceCode,
        companyId: values.useOption === 1 ? null : values.companyId?.split('/$/')[0],
        deviceModel: values.deviceModel,
        os: values.os,
        screen: values.screen,
        yearOfManufacture: values.yearOfManufacture ? Number(values.yearOfManufacture) : null,
        _id: props.deviceData._id ?? '',
        groupId: values.groupId || null,
        hideClock: !!values.hideClock,
      };
      if (!!values.schedules) {
        payload.schedules = values.schedules;
      }
      mutate({ input: payload });
    }
  };

  const onOk = () => {
    form.submit();
  };

  React.useEffect(() => {
    if (props.deviceData) {
      console.log('props.deviceData', props.deviceData);
      if (props.deviceData?.companyId) {
        setValue(2);
      }
      form.setFieldsValue({
        ...props.deviceData,
        useOption: props.deviceData?.companyId ? 2 : 1,
        companyId: props.deviceData.companyId
          ? `${props.deviceData.company?._id}/$/${props.deviceData.company?.name}`
          : null,
        version: props.deviceData.appVersion || '',
        hideClock: !!props.deviceData.hideClock,
      });
    }
    // eslint-disable-next-line
  }, [props.deviceData]);

  React.useEffect(() => {
    if (isSuccess) {
      props.onCancel();
      form.resetFields();
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  const onCancel = () => {
    props.onCancel();
    form.resetFields();
    setValue(1);
  };

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  const listOs: OsObject[] = [
    {
      title: 'Android',
      value: 'ANDROID',
    },
    {
      title: 'Linux',
      value: 'LINUX',
    },
    {
      title: 'Tizen',
      value: 'TIZEN',
    },
    {
      title: 'Webos',
      value: 'WEBOS',
    },
  ];

  const listScreen: ScreenObject[] = [
    {
      title: 'Led',
      value: 'LED',
    },
    {
      title: 'Oled',
      value: 'OLED',
    },
    {
      title: 'Qled',
      value: 'QLED',
    },
  ];

  const selectOptionOS = listOs.map((e: OsObject, index: any) => {
    return (
      <Select.Option key={index} value={e.value}>
        {e.title}
      </Select.Option>
    );
  });

  const selectOptionScreen = listScreen.map((e: ScreenObject, index) => {
    return (
      <Select.Option key={index} value={e.value}>
        {e.title}
      </Select.Option>
    );
  });

  const layout = {
    labelCol: {
      sm: { span: 24 },
      xs: { span: 24 },
      md: { span: 8 },
      lg: { span: 6 },
    },
  };

  console.log(form.getFieldValue('version'));

  return (
    <Modal
      centered
      visible={!!props.deviceData}
      cancelText={t('cancel')}
      onCancel={() => props.onCancel()}
      className="modal_confirm_custom width_modal_add_device"
      title={t('update_device_info')}
      closeIcon={<MdClose />}
      footer={[
        <ButtonCancel key={'btn_cancel'} title={t('button:cancel')} classNameBtn={`mr-2 `} onClick={onCancel} />,
        <ButtonOk key={'btn_ok'} onClick={onOk} title={t('save')} classNameBtn={``} isLoading={isLoading} />,
      ]}
    >
      <Form key="form_add_device" form={form} onFinish={onFinish} colon={false} labelAlign="left" {...layout}>
        {!loginState.data?.companyId ? (
          <>
            <div className="title_content mb-4">{t('basic_info')}</div>
            <Col span={24}>
              <Form.Item label={t('use')} name="useOption" labelCol={{ span: 6 }}>
                <Radio.Group onChange={onChange} value={value} size="large">
                  <Radio className="mr-5" value={1}>
                    {t('available')}
                  </Radio>
                  <Radio value={2}>{t('deploying')}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </>
        ) : null}

        {value === 2 && !loginState.data?.companyId ? (
          <Form.Item
            shouldUpdate={true}
            label={t('select_company')}
            name="companyId"
            labelCol={{ span: 6 }}
            className="bg_input_form"
          >
            <Select
              style={{ width: '100%', maxWidth: 520 }}
              showSearch
              dropdownClassName="mr-0"
              allowClear
              placeholder={t('company')}
              searchValue={filter?.query?.trim() || ''}
              filterOption={false}
              onSearch={(e) => {
                setFilter({
                  ...filter,
                  query: e,
                });
              }}
              dropdownRender={(menu) => {
                return (
                  <React.Fragment>
                    {menu}
                    <Row className="ml-2 mr-2 mt-2 mb-2 justify-content-center">
                      <Col className="mr-2">
                        <Button
                          onMouseDown={() => {
                            if (paginate.page <= 1) {
                              return;
                            }
                            setPaginate({
                              ...paginate,
                              page: paginate.page - 1,
                            });
                          }}
                        >
                          {t('button:pre')}
                        </Button>
                      </Col>
                      <Col className="mr-2">
                        <Button
                          onMouseDown={() => {
                            if (
                              paginate.page === listCompany?.companies?.totalPage ||
                              (listCompany?.companies && listCompany?.companies.totalPage <= 0)
                            ) {
                              return;
                            }
                            setPaginate({
                              ...paginate,
                              page: paginate.page + 1,
                            });
                          }}
                        >
                          {t('button:next')}
                        </Button>
                      </Col>
                      <Col className="mt-1">{`${listCompany?.companies?.currentPage} / ${listCompany?.companies?.totalPage}`}</Col>
                    </Row>
                  </React.Fragment>
                );
              }}
            >
              {listCompany &&
                listCompany?.companies &&
                listCompany?.companies.items?.map((company: any) => (
                  <Option key={company?._id} value={`${company._id}/$/${company.name}`} title={`${company.name}`}>
                    {company.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        ) : null}

        {loginState.data?.companyId && (
          <Col>
            <div className="title_content mb-4">{t('group')}</div>
            <Form.Item label={t('select_group')} name="groupId" labelCol={{ span: 6 }} className="bg_input_form">
              <Select
                style={{ width: '100%' }}
                placeholder={t('select_group')}
                allowClear
                showSearch
                filterOption={false}
                onSearch={(e) => {
                  setFilterGroup({
                    ...filterGroup,
                    page: 1,
                    query: e,
                  });
                }}
                dropdownRender={(menu) => {
                  return (
                    <React.Fragment>
                      {menu}
                      <Row className="ml-2 mr-2 mt-2 mb-2 justify-content-center">
                        <Col className="mr-2">
                          <Button
                            onMouseDown={() => {
                              if (filterGroup.page && filterGroup.page <= 1) {
                                return;
                              }
                              setFilterGroup({
                                ...filterGroup,
                                page: filterGroup.page && filterGroup.page - 1,
                              });
                            }}
                          >
                            {t('button:pre')}
                          </Button>
                        </Col>
                        <Col className="mr-2">
                          <Button
                            onMouseDown={() => {
                              if (
                                (filterGroup.page && filterGroup.page === listGroup?.groups?.totalPage) ||
                                (listGroup?.groups && listGroup.groups.totalPage <= 0)
                              ) {
                                return;
                              }
                              setFilterGroup({
                                ...filterGroup,
                                page: filterGroup.page && filterGroup.page + 1,
                              });
                            }}
                          >
                            {t('button:next')}
                          </Button>
                        </Col>
                        <Col className="mt-1">{`${listGroup?.groups?.currentPage} / ${listGroup?.groups?.totalPage}`}</Col>
                      </Row>
                    </React.Fragment>
                  );
                }}
              >
                {listGroup &&
                  listGroup.groups &&
                  listGroup.groups.items?.map((group) => {
                    return (
                      <Option key={group?._id} value={group?._id} title={group?.name}>
                        {group?.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
        )}

        <Col>
          <div className="title_content mb-4">{t('device_info')}</div>
          <Form.Item
            label={t('name')}
            name="name"
            rules={[
              { required: true, message: t('validate.required_name') },
              { max: 150, message: t('validate.field_long') },
            ]}
            labelCol={{ span: 6 }}
            className="bg_input_form"
          >
            <Input style={{ width: '100%' }} type="text" placeholder={t('name')} />
          </Form.Item>
          <Row>
            <Col md={16} span={24}>
              <Form.Item
                label={t('device_code')}
                name="deviceCode"
                labelCol={{ md: { span: 9 }, span: 6 }}
                className="bg_input_form"
                // rules={[{ max: 8, message: t('validate.field_long') }]}
              >
                <Input
                  className="input_edit_device"
                  type="text"
                  placeholder={t('device_code')}
                  disabled
                  style={{ color: '#000' }}
                />
              </Form.Item>
            </Col>
            <Col md={8} span={24}>
              <Form.Item
                label={t('model')}
                name="deviceModel"
                labelCol={{ md: { span: 8 }, span: 6 }}
                className="bg_input_form"
              >
                <Input style={{ width: '100%' }} type="text" placeholder={t('model')} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col md={16} span={24}>
              <Form.Item label={t('os')} name="os" labelCol={{ md: { span: 9 }, span: 6 }} className="bg_input_form">
                <Select className="input_edit_device" placeholder={t('os')}>
                  {selectOptionOS}
                </Select>
              </Form.Item>
            </Col>
            <Col md={8} span={24}>
              <Form.Item
                label={t('screen')}
                name="screen"
                labelCol={{ md: { span: 8 }, span: 6 }}
                className="bg_input_form"
              >
                <Select placeholder={t('screen')}>{selectOptionScreen}</Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Row>
          <Col md={16} span={24}>
            <Form.Item
              label={t('year_of_manufacture')}
              name="yearOfManufacture"
              labelCol={{ md: { span: 9 }, span: 6 }}
              className="bg_input_form"
            >
              <Input className="input_edit_device" type="number" placeholder={t('year_of_manufacture')} />
            </Form.Item>
          </Col>
          <Col md={8} span={24}>
            <Form.Item
              label={t('version')}
              name="version"
              labelCol={{ md: { span: 8 }, span: 6 }}
              className="bg_input_form"
            >
              <Input type="text" placeholder={t('version')} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={16} span={24}>
            <Form.Item label={t('ip')} name="ip" labelCol={{ md: { span: 9 }, span: 6 }} className="bg_input_form">
              <Input className="input_edit_device" type="text" placeholder={t('ip')} />
            </Form.Item>
          </Col>
          <Col md={8} span={24}>
            <Form.Item label={t('RTC')} name="hasRTC" labelCol={{ md: { span: 8 }, span: 6 }} className="bg_input_form">
              <Input type="text" placeholder={'N/A'} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={16} span={24}>
            <Form.Item
              label={' '}
              name="hideClock"
              labelCol={{ md: { span: 9 }, span: 6 }}
              className="bg_input_form"
              valuePropName="checked"
            >
              <Checkbox>{t('hide_clock')}</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
