import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { resetPlaylistState } from '../redux/actions/reset_state';

const useResetPlaylistState = () => {
  const dispatch = useDispatch();
  const onResetPlaylistState = useCallback(() => {
    dispatch(resetPlaylistState());
  }, [dispatch]);
  return {
    onResetPlaylistState,
  };
};
export default useResetPlaylistState;
