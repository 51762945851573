import { QueryMediasInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMediasAction } from '../redux/actions';
import { ListMediasState } from '../redux/reducers/medias';

const useGetAllMedia = () => {
  const listMediasState = useSelector<RootState, ListMediasState>((state) => state.media.list);

  const dispatch = useDispatch();
  const onGetMedias = useCallback(
    (input: QueryMediasInput) => {
      dispatch(getMediasAction(input));
    },
    [dispatch],
  );
  return {
    loading: listMediasState.loading,
    data: listMediasState.data,
    breadcrumbs: listMediasState.breadcrumbs,
    onGetMedias,
  };
};
export default useGetAllMedia;
