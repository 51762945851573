import { appSubscription } from '@/graphql/client';
import { Device, DevicePubSubType, DeviceSubsDocument } from '@/graphql/generated/graphql';
import { FULL_DATE_TIME_REVERT } from '@helpers/date';
import axios from 'axios';
import moment from 'moment-timezone';
import { useEffect, useRef, useState } from 'react';

const getLastModified = async (url: string, timezone: string) => {
  const request = await axios.get(url);
  if (request.headers) {
    return moment.utc(request.headers['last-modified']).tz(timezone).format(FULL_DATE_TIME_REVERT);
  }
};

export const useDeviceSubscription = (deviceItem?: Device) => {
  const [screenshotUrl, setScreenshotUrl] = useState(deviceItem?.lastScreenshot || '');
  const [lastModified, setLastModified] = useState('');
  const listenRef = useRef<any>();

  const subscribe = () => {
    if (deviceItem) {
      forceUnsubscribe();
      const lastScreenshot = deviceItem?.lastScreenshot || '';
      const timezone = deviceItem.group?.timezone;
      setScreenshotUrl(lastScreenshot);
      if (lastScreenshot && timezone) {
        getLastModified(lastScreenshot, timezone).then((res) => res && setLastModified(res));
      }
      const subscription = appSubscription(DeviceSubsDocument);
      listenRef.current = subscription.subscribe(async ({ data }: { data: any }) => {
        if (
          deviceItem &&
          data?.deviceSubs?.type === DevicePubSubType.NewCaptureScreen &&
          deviceItem._id.toString() === data?.deviceSubs?.data?._id.toString()
        ) {
          const url = data?.deviceSubs?.data.lastScreenshot;
          setScreenshotUrl(`${url}?t=${moment().valueOf()}`);
          if (url && timezone) {
            getLastModified(url, timezone).then((res) => res && setLastModified(res));
          }
        }
      });
    }
  };

  useEffect(() => {
    if (deviceItem) {
      subscribe();
      return () => {
        setScreenshotUrl('');
        return listenRef.current.unsubscribe();
      };
    }
    return () => {
      setScreenshotUrl('');
    };

    // eslint-disable-next-line
  }, [deviceItem]);

  const forceUnsubscribe = () => {
    if (listenRef?.current) {
      listenRef.current.unsubscribe();
    }
  };

  return { screenshotUrl, lastModified, subscribe, forceUnsubscribe } as any;
};

export const useGetWifiList = (deviceItem?: Device) => {
  const [wifiList, setWifiList] = useState([]);
  useEffect(() => {
    if (deviceItem) {
      const subscription = appSubscription(DeviceSubsDocument);
      const listener = subscription.subscribe(async ({ data }: { data: any }) => {
        if (
          deviceItem &&
          data?.deviceSubs?.type === DevicePubSubType.SendWifi &&
          deviceItem._id.toString() === data?.deviceSubs?.data?._id.toString()
        ) {
          setWifiList(data?.deviceSubs?.data?.wifi);
        }
      });

      return () => {
        setWifiList([]);
        return listener.unsubscribe();
      };
    }
    // eslint-disable-next-line
  }, [deviceItem]);

  return wifiList;
};

export const useGetWifiConnectStatus = (deviceItem?: Device) => {
  const [status, setStatus] = useState<any>(undefined);
  useEffect(() => {
    if (deviceItem) {
      const subscription = appSubscription(DeviceSubsDocument);
      const listener = subscription.subscribe(async ({ data }: { data: any }) => {
        if (
          deviceItem &&
          data?.deviceSubs?.type === DevicePubSubType.StatusConnectWifi &&
          deviceItem._id.toString() === data?.deviceSubs?.data?._id.toString()
        ) {
          setStatus(data?.deviceSubs?.data);
        }
      });

      return () => {
        setStatus(false);
        return listener.unsubscribe();
      };
    }
    // eslint-disable-next-line
  }, [deviceItem]);

  return status;
};
