import { CreateGroupInput, Device } from '@/graphql/generated/graphql';
import { PlusOutlined } from '@ant-design/icons';
import timeZone from '@assets/file/timezone.json';
import ButtonCancel from '@commons/components/modals/Button/ButtonCancel';
import ButtonOk from '@commons/components/modals/Button/ButtonOk';
import useGetAllDevice from '@modules/Device/hooks/useGetAllDevice';
import { Button, Col, Form, Input, Modal, Row, Select, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionTableModalAdd from './ActionTableModalAdd';
const moment = require('moment-timezone');

interface IProps {
  onCancel: () => void;
  visible: boolean;
  onCreate: (input: CreateGroupInput) => void;
  isLoading: boolean;
  isSuccess: boolean;
}

interface FilterDevice {
  limit: number;
  page: number;
  query?: string;
}

interface SelectGroupDeviceType {
  _id: string;
  name?: string;
  deviceCode?: string;
}

const { Option } = Select;

export default function AddGroupDeviceModal(props: IProps) {
  const [form] = Form.useForm();
  const [formSelect] = Form.useForm();
  const { t } = useTranslation(['group-device', 'button']);
  const [filter, setFilter] = useState<FilterDevice>({ limit: 10, page: 1 });
  const [deviceIds, setDeviceIds] = useState<SelectGroupDeviceType[]>([]);
  const [devicesInGroup, setDeviceInGroup] = useState<Device[]>([]);
  // eslint-disable-next-line
  const { data: listDevice, refetch: getListDevice } = useGetAllDevice({
    limit: filter.limit,
    page: filter.page,
    query: filter.query,
  });

  useEffect(() => {
    //get list device in group
    if (listDevice && listDevice.devices) {
      setDeviceInGroup(
        (listDevice.devices.items?.filter((item) => {
          return item.groupId;
        }) as Device[]) || [],
      );
    }
  }, [listDevice]);

  const onFinish = (values: any) => {
    props.onCreate({ name: values.name, deviceIds: deviceIds.map((item) => item._id), timezone: values.timezone });
    formSelect.resetFields();
  };

  useEffect(() => {
    if (!!props.visible) {
      getListDevice();
    }

    // eslint-disable-next-line
  }, [props.visible]);

  const onOk = () => {
    form.submit();
  };

  const onFinishSelect = (value: any) => {
    // add device to table
    if (
      !deviceIds
        ?.map((item) => {
          return item._id;
        })
        .includes(value.deviceIds.split('$')[0])
    ) {
      setDeviceIds([
        ...(deviceIds || []),
        {
          _id: value.deviceIds.split('$')[0],
          name: value.deviceIds.split('$')[1],
          deviceCode: value.deviceIds.split('$')[2],
        },
      ]);
    }

    // thêm device vào mảng để check option select disable
    if (
      !devicesInGroup
        ?.map((item) => {
          return item._id;
        })
        .includes(value.deviceIds.split('$')[0])
    ) {
      if (listDevice && listDevice.devices && listDevice.devices.items) {
        const newDevice = listDevice.devices.items.find((item) => item._id === value.deviceIds.split('$')[0]);
        newDevice && setDeviceInGroup([...((devicesInGroup && devicesInGroup) || []), newDevice as Device]);
      }
    }

    formSelect.resetFields();
  };

  const onDeleteDevice = (record: any) => {
    // xóa device khỏi table
    setDeviceIds(
      deviceIds.filter((item) => {
        return item._id !== record._id;
      }),
    );

    // xóa device khỏi mảng, ( mảng dùng để check disable select option )
    setDeviceInGroup(
      devicesInGroup?.filter((item) => {
        return item._id !== record._id;
      }),
    );
  };

  const onCancel = () => {
    props.onCancel();
    form.resetFields();
    formSelect.resetFields();
    setDeviceIds([]);
    setDeviceInGroup([]);
  };

  useEffect(() => {
    if (props.isSuccess) {
      onCancel();
    }
    // eslint-disable-next-line
  }, [props.isSuccess]);

  const columns: ColumnsType<SelectGroupDeviceType> = [
    {
      title: t('device').toLocaleUpperCase(),
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: t('device_code').toLocaleUpperCase(),
      key: 'deviceCode',
      dataIndex: 'deviceCode',
    },
    {
      title: t('action').toLocaleUpperCase(),
      align: 'right',
      render: (record: any) => (
        <ActionTableModalAdd
          onDelete={() => {
            onDeleteDevice(record);
          }}
        />
      ),
    },
  ];

  return (
    <Modal
      centered
      visible={props.visible}
      cancelText={t('cancel')}
      onCancel={onCancel}
      className="modal_confirm_custom modal_group_device_custom"
      title={t('add_new_group')}
      footer={[
        <ButtonCancel key={'btn_cancel'} title={t('button:cancel')} classNameBtn={`mr-2 `} onClick={onCancel} />,
        <ButtonOk key={'btn_ok'} onClick={onOk} title={t('add_new')} classNameBtn={``} isLoading={props.isLoading} />,
      ]}
    >
      <Form key="form_add_group_device" form={form} onFinish={onFinish} colon={false} labelAlign="left">
        <Form.Item
          name="name"
          className="bg_input_form"
          label={t('name')}
          rules={[
            { required: true, message: t('validate.required_name') },
            { whitespace: true, message: t('validate.required') },
            { max: 150, message: t('validate.field_long') },
          ]}
          labelCol={{ span: 6 }}
        >
          <Input style={{ width: '100%', maxWidth: 485 }} placeholder={t('name')} />
        </Form.Item>
        <Form.Item
          name="timezone"
          label={t('timezone')}
          labelCol={{ span: 6 }}
          rules={[{ required: true, message: t('validate.required') }]}
        >
          <Select
            style={{ width: '100%', maxWidth: 485 }}
            placeholder={t('timezone')}
            showSearch
            allowClear
            filterOption={(input, option) => option?.value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
          >
            {timeZone.map((item) => {
              if (item === 'Europe/Oslo') {
                return (
                  <Option value={item} key={item} title={item}>
                    {item} {moment().tz(item).format('Z')}
                  </Option>
                );
              }
              return (
                <Option value={item} key={item} title={item}>
                  {item}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>

      <Form form={formSelect} onFinish={onFinishSelect}>
        <div className="title_content mb-2">{t('select_a_device')}</div>
        <div className="w-full select-device">
          <Form.Item
            name="deviceIds"
            className="mb-0 mr-2"
            rules={[{ required: true, message: t('validate.required') }]}
            labelCol={{ span: 6 }}
            style={{ width: '100%', maxWidth: 500 }}
          >
            <Select
              showSearch
              dropdownClassName="mr-0"
              allowClear
              placeholder={t('select_device')}
              filterOption={false}
              onSearch={(e) => {
                setFilter({
                  ...filter,
                  query: e,
                });
              }}
              dropdownRender={(menu) => {
                return (
                  <React.Fragment>
                    {menu}
                    <Row className="ml-2 mr-2 mt-2 mb-2 justify-content-center">
                      <Col className="mr-2">
                        <Button
                          onMouseDown={() => {
                            if (filter.page <= 1) {
                              return;
                            }
                            setFilter({
                              ...filter,
                              page: filter.page - 1,
                            });
                          }}
                        >
                          {t('button:pre')}
                        </Button>
                      </Col>
                      <Col className="mr-2">
                        <Button
                          onMouseDown={() => {
                            if (
                              filter.page === listDevice?.devices?.totalPage ||
                              (listDevice?.devices && listDevice?.devices?.totalPage <= 0)
                            ) {
                              return;
                            }
                            setFilter({
                              ...filter,
                              page: filter.page + 1,
                            });
                          }}
                        >
                          {t('button:next')}
                        </Button>
                      </Col>
                      <Col className="mt-1">{`${listDevice?.devices?.currentPage} / ${listDevice?.devices?.totalPage}`}</Col>
                    </Row>
                  </React.Fragment>
                );
              }}
            >
              {listDevice &&
                listDevice.devices &&
                listDevice.devices.items?.map((device: any) => (
                  <Option
                    disabled={devicesInGroup.map((item) => item._id).includes(device._id)}
                    key={device._id}
                    value={`${device._id}$${device.name}$${device.deviceCode}`}
                    title={`${device.name}`}
                  >
                    {`${device.name}  ${device.name && device.deviceCode ? '-' : ''}  ${
                      device.deviceCode ? device.deviceCode : ''
                    }`}
                    {device?.group && (
                      <strong className="ml-2">
                        ({t('group')}: {device.group.name})
                      </strong>
                    )}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Button icon={<PlusOutlined />} size="middle" htmlType="submit" className="btn-add-device">
            {t('add')}
          </Button>
        </div>
      </Form>
      <Table
        scroll={{ y: 240 }}
        className="mt-4 table_device"
        dataSource={deviceIds}
        rowKey={(data) => data._id}
        columns={columns}
        rowClassName={(record, index) =>
          index % 2 === 0 ? 'table-row-height table-row-white ' : 'table-row-height table-row-gray'
        }
      />
    </Modal>
  );
}
