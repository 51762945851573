import { UpdateCompanyInput } from '@/graphql/generated/graphql';
import { NotificationError } from '@commons/components/Notification';
import { getHistory } from '@helpers/history';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { LoginState } from '@modules/Auth/redux/action-types';
import CompanyForm from '@modules/Company/components/Form/UpdateCompany';
import useGetCompanyByID from '@modules/Company/hooks/useGetCompanyByID';
import useUpdateCompany from '@modules/Company/hooks/useUpdateCompany';
import { getQueryVariable } from '@modules/Setting/helpers/utils';
import { RootState } from '@redux/reducers';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

export default function UpdateCompanyDetailPage() {
  const { t } = useTranslation(['company']);
  const { id } = useParams<{ id: string }>();
  const { data } = useGetCompanyByID({
    _id: id,
  });
  const { isSuccess, mutate, isLoading: isSaving } = useUpdateCompany();
  const { setPageTitle } = useSetPageTitle();
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);
  React.useEffect(() => {
    t && setPageTitle(t('edit_customer'));
    // eslint-disable-next-line
  }, [t, data]);

  React.useEffect(() => {
    if (isSuccess && !loginState.data?.companyId) {
      const query = getQueryVariable('page');
      getHistory().replace(`/company/list?page=${query || 1}`, '/company/list');
    }
  }, [isSuccess, loginState]);

  function onFinish(values: any) {
    if (moment(values.startDate).isAfter(moment(values.endDate))) {
      NotificationError(t('warning'), t('smaller_than_date'));
      return;
    }
    const payload: UpdateCompanyInput = {
      _id: id,
      email: values.email,
      phone: values.phone,
      name: values.name,
      address: values.address,
      description: values.description,
      startDate: values.startDate ? moment(values.startDate).valueOf() : null,
      endDate: values.endDate ? moment(values.endDate).valueOf() : null,
      // domain: values.domain,
      address_latitude: values.address_latitude,
      address_longitude: values.address_longitude,
      limitUpload: values.limitUpload,
    };

    mutate({ input: payload });
  }

  return (
    <CompanyForm
      isLoading={isSaving}
      onFinish={onFinish}
      isUpdate={true}
      companyData={data?.company}
      classNameForm="h-auto"
    />
  );
}
