import { useSelector, useDispatch } from 'react-redux';
import { requestEmail } from '@modules/Auth/redux/actions';
import { useCallback } from 'react';
import { RequestEmailInput } from '../redux/action-types';
import { RootState } from '@redux/reducers';

const useRequestPassword = () => {
  const loading = useSelector<RootState>((state) => state.auth.requestEmailState.loading) as boolean;
  const dispatch = useDispatch();
  const onSending = useCallback(
    (email: RequestEmailInput) => {
      dispatch(requestEmail(email));
    },
    [dispatch],
  );
  return {
    onSending,
    loading,
  };
};
export default useRequestPassword;
