import { getSDK } from '@/graphql/client';
import { GroupStatus } from '@/graphql/generated/graphql';
import { useMutation, useQueryClient } from 'react-query';
import { GET_PENDING_GROUPS } from './useGetPendingGroup';
import i18n from '@/i18n';

export default function useUpdateGroupStatus() {
  const queryClient = useQueryClient();

  return useMutation(getSDK().updateGroupStatus, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(GET_PENDING_GROUPS);
      if (variables.input.status !== GroupStatus.WaitToApprove) {
        const messageData = {
          message: i18n.t('update_success'),
        };

        const globalOnSuccess = queryClient.getDefaultOptions().mutations?.onSuccess;
        // tslint:disable-next-line: no-unused-expression
        globalOnSuccess && globalOnSuccess(messageData, variables, context);
      }
    },
  });
}
