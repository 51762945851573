import { Media } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { GO_BACK_FOLDER, SELECT_FOLDER, SELECT_FOLDER_ERROR, SELECT_FOLDER_SUCCESS } from '../action-types';

export const selectFolder = (payload?: Media | null | undefined) => ({
  type: SELECT_FOLDER,
  payload,
});

export const selectFolderSuccess = (payload?: Media) => ({
  type: SELECT_FOLDER_SUCCESS,
  payload,
});

export const selectFolderError = (payload: AppError) => ({
  type: SELECT_FOLDER_ERROR,
  payload,
});

export const goBackFolder = (payload: Media) => ({
  type: GO_BACK_FOLDER,
  payload,
});
