import EmptyLayout from '@commons/components/layouts/Empty';
import MasterLayout from '@commons/components/layouts/MasterLayout';
import { protectedComponent } from '@hoc/protectedComponent';
import { AuthRouter, ChangePasswordRouter } from '@modules/Auth/router';
import { CompanyPageRoute } from '@modules/Company/router';
import { DashboardPageRoute } from '@modules/Dashboard/router';
import { DevicePageRoute } from '@modules/Device/router';
import { MediaPageRoute } from '@modules/Media/router';
import { PlaylistRouter } from '@modules/PlayList/router';
import { GroupDeviceRouter } from '@modules/GroupDevice/router';
import { ProfileRouter } from '@modules/Profile/router';
import { SettingPageRoute } from '@modules/Setting/router';
import { UserPageRoute } from '@modules/User/router';
import { RouteConfig } from 'react-router-config';

const routes: RouteConfig[] = [
  {
    component: EmptyLayout,
    routes: [
      ...AuthRouter,
      {
        component: protectedComponent(MasterLayout),
        routes: [
          ...DashboardPageRoute,
          ...MediaPageRoute,
          ...UserPageRoute,
          ...CompanyPageRoute,
          ...ProfileRouter,
          ...PlaylistRouter,
          ...DevicePageRoute,
          ...SettingPageRoute,
          ChangePasswordRouter,
          ...GroupDeviceRouter,
        ],
      },
    ],
  },
];

export default routes;
