import {
  Frame,
  FrameDirection,
  FrameType,
  MediaStatus,
  PlaylistScreenType,
  PlaylistStatus,
} from '@/graphql/generated/graphql';
import { FrameData, PlaylistResolutionInterface } from '../types';

let color: string = '#fff';

export function getFrameBackground() {
  return color;
}

export function setFrameBackground(s: string) {
  color = s;
  return color;
}

export const MIN_WIDTH = 640;
export const MIN_HEIGHT = 480;

export function calculateCanvasSize(
  w: number,
  h: number,
  clientW: number,
  clientH: number,
): { ratio: number; width: number; height: number } {
  if (clientH < h || clientW < w) {
    let hRatio, vRatio, ratio;
    if (clientW <= MIN_WIDTH || clientH <= MIN_HEIGHT) {
      const width = MIN_WIDTH;
      const height = (MIN_WIDTH * h) / w;
      hRatio = clientW / width;
      vRatio = clientH / height;
      ratio = Math.min(hRatio, vRatio);
      return {
        width,
        height,
        ratio,
      };
    } else {
      hRatio = clientW / w;
      vRatio = clientH / h;
      ratio = Math.min(hRatio, vRatio);
    }
    if (ratio > 1) {
      return {
        width: w / ratio,
        height: h / ratio,
        ratio,
      };
    } else {
      return {
        width: w * ratio,
        height: h * ratio,
        ratio,
      };
    }
  }
  return {
    width: w,
    height: h,
    ratio: 1,
  };
}

export function scaleCanvasBackground(canvasWidth: number, canvasHeight: number, bgImage: any) {
  var canvasAspect = canvasWidth / canvasHeight;
  var imgAspect = bgImage.width / bgImage.height;
  let left, top, scaleFactor;

  if (canvasAspect >= imgAspect) {
    scaleFactor = canvasWidth / bgImage.width;
    left = 0;
    top = -(bgImage.height * scaleFactor - canvasHeight) / 2;
  } else {
    scaleFactor = canvasHeight / bgImage.height;
    top = 0;
    left = -(bgImage.width * scaleFactor - canvasWidth) / 2;
  }
  return {
    top: top,
    left: left,
    originX: 'left',
    originY: 'top',
    scaleX: scaleFactor,
    scaleY: scaleFactor,
  };
}

export function getFrameTypeText(frameType: FrameType) {
  switch (frameType) {
    case FrameType.Document:
      return 'frame_type.document';
    case FrameType.Image:
      return 'frame_type.image';
    case FrameType.Video:
      return 'frame_type.video';
    case FrameType.WebUrl:
      return 'frame_type.web_url';
    // case FrameType.WebRss:
    //   return 'frame_type.web_rss';
    default:
      return '';
  }
}

export function getPlaylistStatusText(status?: PlaylistStatus) {
  switch (status) {
    case PlaylistStatus.Approved:
      return 'playlist_status.approved';
    case PlaylistStatus.Draft:
      return 'playlist_status.draft';
    case PlaylistStatus.Rejected:
      return 'playlist_status.rejected';
    case PlaylistStatus.WaitToApprove:
      return 'playlist_status.wait_to_approved';
    default:
      return '';
  }
}

export function getFrameCover(frame: FrameData | Frame) {
  switch (frame.type) {
    case FrameType.Document:
      return frame.documentImages ? frame.documentImages[0]?.url : '';
    case FrameType.Image:
    case FrameType.Video:
      return undefined;
    case FrameType.WebUrl:
      return '';
    default:
      break;
  }
}

export const getResolutionSize = (
  resolution: PlaylistResolutionInterface,
  screenType: PlaylistScreenType,
  frameDirection: FrameDirection,
): {
  w: number;
  h: number;
} => {
  let w = resolution.width;
  let h = resolution.height;
  if (screenType === PlaylistScreenType.SplitScreen) {
    if (frameDirection === FrameDirection.Horizontal) {
      return { w: w / 2, h };
    } else {
      return { w: h, h: w / 2 };
    }
  } else {
    if (frameDirection === FrameDirection.Horizontal) {
      return { w, h };
    } else {
      return { w: h, h: w };
    }
  }
};

export const convertFramesToMedia = (frames: any[]) => {
  let newFrames: (Frame & FrameData)[] = [...frames];
  let NewMediaData: any[] = [];
  NewMediaData = newFrames
    .filter((e) => {
      return (e.media && e.media?.status === MediaStatus.Done) || e.webUrl;
    })
    .map((element) => ({
      ...(element.media || {}),
      ...element,
      availability: element.availability,
      webUrl: element.webUrl,
      isLink: element.type === FrameType.WebUrl ? true : false,
    }));

  return NewMediaData;
};
