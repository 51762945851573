import { FrameInputType } from '@/graphql/generated/graphql';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addNewDataPlayList } from '../redux/actions/add_new_play_list';

const useAddNewPlayList = () => {
  const dispatch = useDispatch();
  const onAddNewAndUpatePlayList = useCallback(
    (data: FrameInputType[]) => {
      dispatch(addNewDataPlayList(data));
    },
    [dispatch],
  );
  return {
    onAddNewAndUpatePlayList,
  };
};
export default useAddNewPlayList;
