import TableHeader from '@commons/components/TableHeader';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import useProfile from '@modules/Auth/hooks/useProfile';
import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CompanyUsers from './CompanyUsers';
import SelectStaff from './SelectStaff';

export default function StaffListPage() {
  const { t } = useTranslation(['company']);
  const { setPageTitle } = useSetPageTitle();
  const { loginState } = useProfile();

  React.useEffect(() => {
    t && setPageTitle(t('sub_account'));
    // eslint-disable-next-line
  }, [t]);

  return loginState.data?.companyId ? (
    <div>
      <Row gutter={16}>
        <Col xs={24} lg={10} xl={8}>
          <TableHeader>
            {loginState.data?.companyId ? (
              <div className="table-header-flex d-block d-xl-flex justify-content-between align-items-center">
                <div className="table-total">
                  <h3 className="fw-500">{t('assign_role')}</h3>
                </div>
              </div>
            ) : null}
          </TableHeader>
          <SelectStaff />
        </Col>
        <Col xs={24} lg={14} xl={16} className="mt-3 mt-lg-0">
          <CompanyUsers companyId={loginState.data.companyId} />
        </Col>
      </Row>
    </div>
  ) : null;
}
