import { combineReducers } from 'redux';
import list, { ListMediasState } from './medias';
import selectFolder, { SelectFolderState } from './select_folder';
import uploadFile, { UploadFileState } from './upload_file';
import listFolders, { ListFoldersState } from './get_folders';

export interface MediaModuleState {
  uploadFile: UploadFileState;
  selectFolder: SelectFolderState;
  list: ListMediasState;
  listFolders: ListFoldersState;
}

export default combineReducers<MediaModuleState>({
  uploadFile,
  selectFolder,
  list,
  listFolders,
});
