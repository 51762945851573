import { QueryCompaniesInput, Roles } from '@/graphql/generated/graphql';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import ButtonCancel from '@commons/components/modals/Button/ButtonCancel';
import ButtonOk from '@commons/components/modals/Button/ButtonOk';
import { LoginState } from '@modules/Auth/redux/action-types';
import useGetAllCompany from '@modules/Company/hooks/useGetAllCompany';
import useAddNewUser from '@modules/User/hooks/useAddNewUser';
import { RootState } from '@redux/reducers';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { useSelector } from 'react-redux';

interface IProps {
  onCancel: () => void;
  visible: boolean;
}

const { Option } = Select;

export default function AddUserModal(props: IProps) {
  const [form] = Form.useForm();
  const { t } = useTranslation(['user', 'button']);
  const { isLoading, mutate, isSuccess } = useAddNewUser();
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);

  const onFinish = (values: any) => {
    const companyId = values.companyId?.split('/$/')[0];
    mutate({
      input: {
        username: values.username,
        name: values.name,
        password: values.password,
        phone: values.phone,
        email: values.email,
        companyId: companyId ? companyId : undefined,
        role: values.role ? values.role : undefined,
      },
    });
  };

  // eslint-disable-next-line
  const [filterCompany, setFilterCompany] = useState<QueryCompaniesInput>({
    page: 1,
    limit: 6,
    query: undefined,
  });

  const { data: listCompany } = useGetAllCompany(filterCompany);

  const onOk = () => {
    form.submit();
  };

  React.useEffect(() => {
    if (isSuccess) {
      onCancel();
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  const onCancel = () => {
    props.onCancel();
    form.resetFields();
  };

  return (
    <Modal
      centered
      visible={props.visible}
      cancelText={t('cancel')}
      onCancel={() => props.onCancel()}
      className="modal_confirm_custom"
      title={t('add_new_user')}
      closeIcon={<MdClose />}
      footer={[
        <ButtonCancel key={'btn_cancel'} title={t('button:cancel')} classNameBtn={`mr-2 `} onClick={onCancel} />,
        <ButtonOk key={'btn_ok'} onClick={onOk} title={t('add_new')} classNameBtn={``} isLoading={isLoading} />,
      ]}
    >
      <Form key="form_add_user" form={form} onFinish={onFinish} colon={false} labelAlign="left">
        <Form.Item
          name="name"
          className="bg_input_form"
          required={true}
          label={t('full_name')}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value: string) {
                // eslint-disable-next-line
                if (value?.trim()) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('validate.required_name')));
              },
            }),
            { max: 150, message: t('validate.field_long') },
          ]}
          labelCol={{ span: 6 }}
        >
          <Input style={{ width: '100%', maxWidth: 485 }} placeholder={t('full_name')} />
        </Form.Item>
        <Form.Item
          name="username"
          className="bg_input_form"
          label={t('username')}
          required={true}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value: string) {
                // eslint-disable-next-line
                if (value?.trim()) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('validate.required_username')));
              },
            }),
            { max: 15, message: t('validate.field_long') },
            { min: 6, message: t('validate.field_short') },
          ]}
          labelCol={{ span: 6 }}
        >
          <Input
            style={{ width: '100%', maxWidth: 485 }}
            placeholder={t('username')}
            autoCorrect="off"
            autoCapitalize="none"
          />
        </Form.Item>
        <Form.Item
          className="bg_input_form"
          name="password"
          label={t('password')}
          labelCol={{ span: 6 }}
          rules={[
            { required: true, message: t('validate.required_password') },
            { min: 6, message: t('validate.required_password_min') },
            { max: 50, message: t('validate.field_long') },
          ]}
        >
          <Input.Password
            autoComplete="new-password"
            style={{ width: '100%', maxWidth: 485 }}
            placeholder={t('password')}
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
        {(loginState.data?.role === Roles.SuperAdmin || loginState.data?.isSuperAdmin) && (
          <Form.Item className="bg_input_form" name="companyId" label={t('company')} labelCol={{ span: 6 }}>
            <Select
              style={{ width: '100%', maxWidth: 465 }}
              showSearch
              dropdownClassName="mr-0"
              allowClear
              placeholder={t('company')}
              onSearch={(e) => {
                setFilterCompany({
                  ...filterCompany,
                  query: e,
                  page: 1,
                });
              }}
              filterOption={false}
              dropdownRender={(menu) => {
                return (
                  <React.Fragment>
                    {menu}
                    <Row className="ml-2 mr-2 mt-2 mb-2 justify-content-center">
                      <Col className="mr-2">
                        <Button
                          onMouseDown={() => {
                            if (filterCompany && filterCompany.page && filterCompany.page <= 1) {
                              return;
                            }
                            setFilterCompany({
                              ...filterCompany,
                              page: (filterCompany.page || 0) - 1,
                            });
                          }}
                        >
                          {t('button:pre')}
                        </Button>
                      </Col>
                      <Col className="mr-2">
                        <Button
                          onMouseDown={() => {
                            if (
                              (filterCompany &&
                                filterCompany.page &&
                                filterCompany.page === listCompany?.companies?.totalPage) ||
                              (listCompany?.companies && listCompany?.companies?.totalPage <= 0)
                            ) {
                              return;
                            }
                            setFilterCompany({
                              ...filterCompany,
                              page: (filterCompany.page || 0) + 1,
                            });
                          }}
                        >
                          {t('button:next')}
                        </Button>
                      </Col>
                      <Col className="mt-1">{`${listCompany?.companies?.currentPage} / ${listCompany?.companies?.totalPage}`}</Col>
                    </Row>
                  </React.Fragment>
                );
              }}
            >
              {listCompany &&
                listCompany?.companies &&
                listCompany?.companies.items?.map((company: any) => (
                  <Option key={company?._id} value={`${company._id}/$/${company.name}`} title={`${company.name}`}>
                    {company.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item className="bg_input_form" name="role" label={t('role')} labelCol={{ span: 6 }}>
          <Select placeholder={t('role')} allowClear>
            <Option value={Roles.Admin}>{t('status.checker')}</Option>
            <Option value={Roles.Editor}>{t('status.editor')}</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="phone"
          className="bg_input_form"
          label={t('phone')}
          rules={[
            { whitespace: true, message: t('validate.white_space') },
            { pattern: /^[\d+]{1,13}$/, message: t('validate.regex_phone') },
          ]}
          labelCol={{ span: 6 }}
        >
          <Input style={{ width: '100%', maxWidth: 485 }} placeholder={t('phone')} />
        </Form.Item>
        <Form.Item
          name="email"
          className="bg_input_form"
          label={t('email')}
          labelCol={{ span: 6 }}
          style={{ marginBottom: 0 }}
          rules={[
            { required: true, message: t('validate.required_email') },
            {
              type: 'email',
              message: t('validate.invalid_email'),
            },
            // ({ getFieldValue }) => ({
            //   validator(_, value: string) {
            //     // eslint-disable-next-line
            //     const regex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
            //     if (regex.test(value?.trim())) {
            //       return Promise.resolve();
            //     }
            //     return Promise.reject(new Error(t('validate.invalid_email')));
            //   },
            // }),
            { max: 50, message: t('validate.field_long') },
          ]}
        >
          <Input
            style={{ width: '100%', maxWidth: 485 }}
            placeholder={t('email')}
            autoFocus={true}
            autoCorrect="off"
            autoCapitalize="none"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
