import { QueryMediasInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFolsersAction } from '../redux/actions';
import { ListFoldersState } from '../redux/reducers/get_folders';

const useGetAllFolder = () => {
  const listFoldersState = useSelector<RootState, ListFoldersState>((state) => state.media.listFolders);

  const dispatch = useDispatch();
  const onGetFolders = useCallback(
    (input: QueryMediasInput) => {
      dispatch(getFolsersAction(input));
    },
    [dispatch],
  );
  return {
    loading: listFoldersState.loading,
    data: listFoldersState.data,
    onGetFolders,
  };
};
export default useGetAllFolder;
