import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import CaretDownOutlined from '@ant-design/icons/lib/icons/CaretDownOutlined';
import ic_key from '@assets/images/Icon-key-login.png';
import ic_user from '@assets/images/Icon-user-login.png';
import flagN from '@assets/images/norway.svg';
import flagE from '@assets/images/united-kingdom.svg';
import flagV from '@assets/images/vietnam.svg';
import logo from '@assets/images/WinSmartSignLogoBlack.svg';
import { CommonPath } from '@commons/base-routes';
import { Languages } from '@modules/Auth/redux/action-types';
import { Button, Form, Input, Select } from 'antd';
import { FormProps } from 'antd/es/form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../../styled/LoginForm/index.scss';
import { setDefaultLanguage } from '@helpers/token';

interface Props extends FormProps {
  onLogin(username: string, password: string): void;
  loading: boolean;
}

const LoginForm = (props: Props) => {
  const onFinish = (values: any) => {
    props.onLogin(values.username, values.password);
  };

  const { i18n, t } = useTranslation(['login', 'forgot-password']);

  const changeLanguage = (lng: string) => {
    setDefaultLanguage(lng);
  };

  const { Option } = Select;
  return (
    <div className="login-container">
      <div className="login-content">
        <div className="change-language d-flex">
          <Select
            defaultValue={i18n?.language?.toString()}
            style={{ minWidth: 100 }}
            className="select_lang color_text_header ml-auto"
            onChange={(e) => changeLanguage(e)}
            placeholder={t('select_language')}
            value={i18n?.language?.toString()}
            suffixIcon={<CaretDownOutlined style={{ width: 12, color: '#999999' }} />}
          >
            {Languages.map((item) => {
              return (
                <Option key={item.key} value={item.key}>
                  <div className="d-flex align-items-center justify-content-start item_select_change_language">
                    <img
                      src={item.key === 'en' ? flagE : item.key === 'vi' ? flagV : flagN}
                      alt="flag"
                      className="mr-1 ic_lang"
                    />
                    {item.name}
                  </div>
                </Option>
              );
            })}
          </Select>
        </div>
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <Form name="login-form" className="login-form" onFinish={onFinish}>
          <Form.Item name="username" rules={[{ required: true, message: t('validate.required_username') }]}>
            <Input
              className="input_username"
              autoFocus={true}
              autoComplete="new-password"
              prefix={<img src={ic_user} alt="ic_user" width={16} height={18} style={{ marginRight: 18 }} />}
              placeholder={t('username')}
              autoCorrect="off"
              autoCapitalize="none"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              { required: true, message: t('validate.required_password') },
              { whitespace: true, message: t('validate.whitespace') },
              { min: 6, message: t('validate.min_lenght_password') },
            ]}
          >
            <Input.Password
              className="input_username mt-2"
              autoComplete="new-password"
              prefix={<img src={ic_key} alt="ic_key" width={19} height={19} style={{ marginRight: 15 }} />}
              placeholder={t('password')}
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>

          <Form.Item className="login-form-submit mb-3">
            <Button loading={props.loading} htmlType="submit" type="primary" className="login-form-button">
              {t('login')}
            </Button>
          </Form.Item>
          <Link to={CommonPath.FORGOT_PASSWORD_PATH}>
            <span className="text-link">{t('forgot-password:forgot_password')}</span>
          </Link>
          {/* <Link to={CommonPath.FORGOT_PASSWORD_PATH}>
            <span>{t('forgot-password:forgot_password')}</span>
          </Link> */}
        </Form>
      </div>
      {/* <Link to={CommonPath.REGISTER_PATH}>
        <span>{__('Tạo tài khoản mới')}</span>
      </Link> */}
    </div>
  );
};

export default LoginForm;
