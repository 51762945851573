import { UpdateCompanyInput } from '@/graphql/generated/graphql';
import FormHeader from '@commons/components/FormHeader';
import PageHeader from '@commons/components/PageHeader';
import FormSkeleton from '@commons/components/Skeletons/FormSkeleton';
import { getHistory } from '@helpers/history';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { LoginState } from '@modules/Auth/redux/action-types';
import CompanyForm from '@modules/Company/components/Form/UpdateCompany';
import useGetCompanyByID from '@modules/Company/hooks/useGetCompanyByID';
import useUpdateCompany from '@modules/Company/hooks/useUpdateCompany';
import { RootState } from '@redux/reducers';
import { Form, Tabs } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import '../../styled/index.scss';
import ListUserByCompany from '../ListUserByCompany';
import OpenTimePage from '../OpenTime';

const { TabPane } = Tabs;

export default function UpdateCompanyPage() {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);
  const { data, isLoading } = useGetCompanyByID({
    _id: id,
  });
  const { t } = useTranslation(['company']);
  const { isSuccess, mutate, isLoading: isSaving } = useUpdateCompany();
  const { setPageTitle } = useSetPageTitle();

  React.useEffect(() => {
    t && setPageTitle(t('company_information'));
    // eslint-disable-next-line
  }, [t]);

  function onFinish(values: any) {
    // const capacity = Capacities.filter((item) => item.key === values.capacity);
    // const userLimit = UserLimits.filter((item) => item.key === values.userLimit);

    const payload: UpdateCompanyInput = {
      _id: id,
      email: values.email,
      phone: values.phone,
      name: values.name,
      address: values.address,
      description: values.description,
      // domain: values.domain,
      limitUpload: values.limitUpload,
    };

    mutate({ input: payload });
  }

  useEffect(() => {
    if (data?.company) {
      form.setFieldsValue({
        ...data.company,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (isSuccess && !loginState.data?.companyId) {
      getHistory().push('/company/list');
    }
  }, [isSuccess, loginState]);

  return (
    <div>
      <PageHeader title={t('company')} subTitle={t('update_company')} />
      <FormHeader>
        <FormSkeleton loading={isLoading}>
          <Tabs defaultActiveKey="1">
            <TabPane tab={t('general_information')} key="1">
              <CompanyForm isLoading={isSaving} onFinish={onFinish} isUpdate={true} companyData={data?.company} />
            </TabPane>
            <TabPane tab={t('staff_list')} key="2">
              <ListUserByCompany />
            </TabPane>
            <TabPane tab={t('open_time')} key="3">
              <OpenTimePage />
            </TabPane>
          </Tabs>
        </FormSkeleton>
      </FormHeader>
    </div>
  );
}
