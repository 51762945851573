import { getSDK } from '@/graphql/client';
import { QueryDevicesInput } from '@/graphql/generated/graphql';
import { useQuery } from 'react-query';
import { GET_ALL_DEVICE } from '../types';

export default function useGetAllDeviceAutoRefetch(params: QueryDevicesInput) {
  return useQuery([GET_ALL_DEVICE, params], async () => getSDK().listDevice({ input: params }), {
    refetchInterval: 5000,
  });
}
