import { DeleteMediaInput } from '@/graphql/generated/graphql';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { deleteMediaAction } from '../redux/actions';

const useDeleteMedia = () => {
  const dispatch = useDispatch();
  const onDeleteMedia = useCallback(
    (input: DeleteMediaInput) => {
      dispatch(deleteMediaAction(input));
    },
    [dispatch],
  );
  return {
    onDeleteMedia,
  };
};
export default useDeleteMedia;
