import AuthLayout from '@commons/components/layouts/Auth';
import { setTitle } from '@helpers/dom';
import { __ } from '@helpers/string';
import useForgotPassword from '@modules/Auth/hooks/useForgotPassword';
import React, { useEffect } from 'react';
import ForgotPasswordForm from './components/index';

function ForgotPasswordPage() {
  const { loading, onSending } = useForgotPassword();

  useEffect(() => {
    setTitle(__('Forgot Password'));
  }, []);

  return (
    <AuthLayout>
      <ForgotPasswordForm onRequest={onSending} loading={loading} />
    </AuthLayout>
  );
}

export default ForgotPasswordPage;
