import * as React from 'react';
import icon_delete from '@assets/images/icons/icon_close.svg';

interface Props {
  onDelete: () => void;
}

export default function ActionTableModalAdd(props: Props) {
  return (
    <img
      src={icon_delete}
      width={12}
      height={12}
      alt="btn delete"
      style={{ cursor: 'pointer' }}
      onClick={(e) => {
        e.stopPropagation();
        props.onDelete();
      }}
    />
  );
}
