import { FrameType } from '@/graphql/generated/graphql';
import { getFrameTypeText } from '@modules/PlayList/helpers/frame';
import useActiveFrame from '@modules/PlayList/hooks/useActiveFrame';
import { FrameData } from '@modules/PlayList/types';
import { Badge } from 'antd';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

interface Props {
  index: number;
  frame: FrameData;
  activeKey?: string;
}

const FrameItem = (props: Props) => {
  const { onActiveFrame } = useActiveFrame();
  const { t } = useTranslation(['playlist']);

  const onChangeFrame = async () => {
    if (window.activeFrame && window.editor) {
      const data = await window.editor.canvas.toJSON();
      window.activeFrame.fabricData = data || {};
      onActiveFrame(props.frame);
      window.activeFrame = props.frame;
    }
  };

  return (
    <Draggable draggableId={props.frame.id} index={props.index} key={props.frame.id}>
      {(provided) => (
        <div
          key={props.index}
          className={`frame_item_container`}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          onClick={onChangeFrame}
        >
          <p className="title mb-1">
            {`${props.index + 1}`}. {props.frame.type ? <Badge>{t(getFrameTypeText(props.frame.type))}</Badge> : null}
          </p>
          <div
            className={`frame_item ${props.frame.id === props.activeKey ? 'active' : ''}`}
            style={{ backgroundImage: `url(${props.frame.frameCover})` }}
          >
            {props.frame.type === FrameType.WebUrl && props.frame?.fabricData?.objects[0]?.text && (
              <span>{props.frame?.fabricData?.objects[0]?.text}</span>
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default FrameItem;
