import { User } from '@/graphql/generated/graphql';
import { login, updateProfileLoginSuccess } from '@modules/Auth/redux/actions';
import { RootState } from '@redux/reducers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useLogin = () => {
  const loading = useSelector<RootState>((state) => state.auth.loginState.loading) as boolean;
  const dispatch = useDispatch();

  const onLogin = useCallback((username: string, password: string) => {
    dispatch(login({ username, password }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpdateProfileSuccess = useCallback((payload: User) => {
    dispatch(updateProfileLoginSuccess(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onLogin,
    onUpdateProfileSuccess,
    loading,
  };
};

export default useLogin;
