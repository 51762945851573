import { DeleteMediaInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const DELETE_MEDIA = 'DELETE_MEDIA';
export const DELETE_MEDIA_SUCCESS = 'DELETE_MEDIA_SUCCESS';
export const DELETE_MEDIA_ERROR = 'DELETE_MEDIA_ERROR';
export interface DeleteMediaAction {
  type: typeof DELETE_MEDIA;
  payload: DeleteMediaInput;
}

export interface DeleteMediaActionSuccess {
  type: typeof DELETE_MEDIA_SUCCESS;
  payload?: string;
}

export interface DeleteMediaActionError {
  type: typeof DELETE_MEDIA_ERROR;
  payload: AppError;
}

export type DeleteMediaActionTypes = DeleteMediaAction | DeleteMediaActionSuccess | DeleteMediaActionError;
