import { Media } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { moveFileApi } from '@modules/Media/services/apis';
import { put } from 'redux-saga/effects';
import { MoveFileAction } from '../action-types';
import { moveFileActionError, moveFileActionSuccess, updateUpdateAtFolderAction } from '../actions';

export function* moveFileAsync(action: MoveFileAction) {
  try {
    const media: Media = yield moveFileApi(action.payload);
    yield put(moveFileActionSuccess(media));
    if (media.parent && media.parent?.updatedAt) {
      yield put(
        updateUpdateAtFolderAction({
          id: media.parent?._id || '',
          updatedAt: media.parent?.updatedAt,
        }),
      );
    }
  } catch (error) {
    yield put(moveFileActionError(error));
  }
}

export function moveFileAsyncSuccess() {
  NotificationSuccess(i18n.t('notification'), i18n.t('move_success'));
}
