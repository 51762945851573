import env from '@/env';
import moment from 'moment';

const defaultDateFormat = env.formatDate || 'DD.MM.YYYY';

export const FULL_DATE = defaultDateFormat;
export const FULL_TIME = 'HH:mm:ss';
export const FULL_HOUR_MINUTE = 'HH:mm';
export const FULL_DATE_TIME = `${defaultDateFormat} ${FULL_TIME}`;
export const FULL_DATE_TIME_REVERT = `${FULL_TIME} ${defaultDateFormat}`;
export const FULL_DATE_TIME_HOUR_MINUTE = `${FULL_HOUR_MINUTE} ${defaultDateFormat}`;
export const HOUR_MINUTE = 'HH:mm';
export const DAY = 'DD';

export const formatMomentDate = (date: number | undefined | null | string, format?: string) => {
  if (date) return moment(date).format(format ? format : FULL_DATE_TIME);
  return '';
};

export const getDaysInMonth = (month: number) => {
  if (month === 2) {
    const dayOfFeb = moment(`${moment().year()}-02`, 'YYYY-MM').daysInMonth() || 29;
    return dayOfFeb;
  }
  return new Date(new Date().getFullYear(), month, 0).getDate();
};

export const renderFullDayInMonth = (month: number) => {
  const days: number[] = [];
  if (month === 2) {
    const dayOfFeb = moment(`${moment().year()}-02`, 'YYYY-MM').daysInMonth() || 29;
    for (let i = 1; i <= dayOfFeb; i++) {
      days.push(i);
    }
  } else {
    for (let i = 1; i <= new Date(new Date().getFullYear(), month, 0).getDate(); i++) {
      days.push(i);
    }
  }
  return days;
};

export const renderHourDisableEndTime = (h?: number) => {
  const time: number[] = [];
  if (h)
    for (let i = 0; i <= h; i++) {
      time.push(i);
    }
  return time;
};

export const renderHourDisableStartTime = (h?: number) => {
  const time: number[] = [];
  if (h)
    for (let i = h; i <= 24; i++) {
      time.push(i);
    }
  return time;
};

export const checkTimeInDay = (start: string, end: string) => {
  const _end = moment(end, HOUR_MINUTE);
  // check end is end of day
  if (_end.format(HOUR_MINUTE) === '00:00') {
    return true;
  }

  return moment(start, HOUR_MINUTE).valueOf() < moment(end, HOUR_MINUTE).valueOf();
};

/**
 *
 * @param day
 * @param startOfRange
 * @param endOfRange
 */
export const isDayInRange = (day: string, startOfRange: string, endOfRange?: string | null) => {
  const _day = moment.utc(day, 'DD.MM.YYYY');
  const _startOfRange = moment.utc(startOfRange, 'DD.MM.YYYY');
  if (endOfRange) {
    const _endOfRange = moment.utc(endOfRange, 'DD.MM.YYYY');
    return _startOfRange.isSameOrBefore(_day, 'day') && _day.isSameOrBefore(_endOfRange, 'day');
  }
  return _startOfRange.isSameOrBefore(_day, 'day');
};
