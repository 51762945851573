import { DirectionRotateType } from '@/graphql/generated/graphql';
import Loading from '@commons/components/Loading';
import { Select } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

interface RotateBtnProp {
  onRotate(id: string, direction: DirectionRotateType): void;
  recordId?: string;
  loading?: boolean;
  direction?: DirectionRotateType | null;
}

export default function RotateBtn(props: RotateBtnProp) {
  const { t } = useTranslation(['device']);
  const [direction, setDirection] = React.useState<DirectionRotateType | null>(DirectionRotateType.Normal);

  React.useEffect(() => {
    if (props.direction) {
      setDirection(props.direction);
    } else {
      setDirection(DirectionRotateType.Normal);
    }
    // eslint-disable-next-line
  }, [props.direction]);

  React.useEffect(() => {
    return () => {
      setDirection(null);
    };
  }, []);

  return (
    direction && (
      <div className="rotate-btn d-flex align-items-center justify-content-center">
        <span className="title">{t('orientation')}:</span>
        <Select
          value={direction}
          style={{ width: 120, textAlign: 'left' }}
          onChange={(value: DirectionRotateType) => props.recordId && props.onRotate(props.recordId, value)}
        >
          <Option value={DirectionRotateType.Normal}>{t(DirectionRotateType.Normal)}</Option>
          <Option value={DirectionRotateType.Inverted}>{t(DirectionRotateType.Inverted)}</Option>
        </Select>
        {props.loading && <Loading type="modal" />}
      </div>
    )
  );
}
