import ButtonCancel from '@commons/components/modals/Button/ButtonCancel';
import ButtonOk from '@commons/components/modals/Button/ButtonOk';
import TableHeader from '@commons/components/TableHeader';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import useProfile from '@modules/Auth/hooks/useProfile';
import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import DayOff from './DayOff/index';
import SpecialDay from './SpecialDay/index';
import './style.scss';
import Weekly from './Weekly';

export default function OpenTimePage() {
  const { setPageTitle } = useSetPageTitle();
  const { t } = useTranslation(['company', 'user']);
  const { loginState } = useProfile();
  const history = useHistory();

  React.useEffect(() => {
    t && setPageTitle(t('time_setting'));
    // eslint-disable-next-line
  }, [t]);

  const onSave = () => {
    console.log('save');
  };

  return (
    <div>
      {loginState.data?.companyId && (
        <TableHeader>
          <div className="table-header-flex d-block d-xl-flex justify-content-between">
            <div className="w-100 d-flex justify-content-end">
              <ButtonCancel
                classNameBtn="mr-3"
                onClick={() => {
                  history.goBack();
                }}
              />
              <ButtonOk onClick={onSave} title={t('save')} />
            </div>
          </div>

          <Row gutter={32}>
            <Col xs={24} xl={12} className="mb-3 mt-xl-0">
              <Weekly />
            </Col>
            <Col xs={24} xl={12} className="d-flex flex-column">
              <SpecialDay companyId={loginState?.data?.companyId} className="mb-3" />
              <DayOff companyId={loginState?.data?.companyId} />
            </Col>
          </Row>
        </TableHeader>
      )}
    </div>
  );
}
