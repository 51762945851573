import { FrameType, Media, MediaImage, MediaType } from '@/graphql/generated/graphql';
import { getTypeOfFile } from '@modules/Media/helper/file';
import React from 'react';

interface PropsPlayerPreview {
  MediaPreview?: Media;
  VerticalValue?: string;
  valueHd?: string;
}

function PlayerPreview(props: PropsPlayerPreview) {
  const { MediaPreview, VerticalValue, valueHd } = props;
  console.log('PlayerPreview ~ MediaPreview', MediaPreview);
  if ((MediaPreview?.type as any) === FrameType.WebUrl) {
    return (
      <iframe
        key={MediaPreview?._id || '' + new Date().getMilliseconds()}
        style={{
          width: VerticalValue !== '1' ? '60vh' : valueHd === 'fullhd' ? '100%' : '80%',
          transform:
            !VerticalValue || (VerticalValue && VerticalValue === '1') ? `unset` : `rotate(${VerticalValue}deg) `,
        }}
        height={valueHd === 'fullhd' ? '100%' : '80%'}
        src={(MediaPreview as any)?.webUrl || ''}
        title="123"
      ></iframe>
    );
  }

  if (getTypeOfFile(MediaPreview?.mimetype || '') === MediaType.Image) {
    return (
      <img
        key={MediaPreview?._id || '' + new Date().getMilliseconds()}
        src={MediaPreview?.thumbs ? MediaPreview?.thumbs[0].url : MediaPreview?.optimizeUrl || ''}
        alt="media-preview"
        style={{
          width: VerticalValue !== '1' ? '60vh' : valueHd === 'fullhd' ? '100%' : '80%',
          transform:
            !VerticalValue || (VerticalValue && VerticalValue === '1') ? `unset` : `rotate(${VerticalValue}deg) `,
          objectFit: 'contain',
        }}
        height={valueHd === 'fullhd' ? '100%' : '80%'}
      />
    );
  }
  if (getTypeOfFile(MediaPreview?.mimetype || '') === MediaType.Video) {
    return (
      <video
        style={{
          width: VerticalValue !== '1' ? '60vh' : '100%',
          transform:
            !VerticalValue || (VerticalValue && VerticalValue === '1') ? `unset` : `rotate(${VerticalValue}deg) `,
          border: '20px solid #77829a',
        }}
        key={MediaPreview?._id || '' + new Date().getMilliseconds()}
        height={valueHd === 'fullhd' ? '100%' : '80%'}
        controls
      >
        <source src={MediaPreview?.videoProfiles?.[0]?.url || MediaPreview?.optimizeUrl || ''} type="video/mp4" />
        <source src={MediaPreview?.videoProfiles?.[0]?.url || MediaPreview?.optimizeUrl || ''} type="video/ogg" />
      </video>
    );
  }
  if (
    getTypeOfFile(MediaPreview?.mimetype || '') === MediaType.Document ||
    getTypeOfFile(MediaPreview?.mimetype || '') === MediaType.Pdf ||
    getTypeOfFile(MediaPreview?.mimetype || '') === MediaType.Powerpoint
  ) {
    return (
      <div
        key={MediaPreview?._id || '' + new Date().getMilliseconds()}
        style={{
          width: VerticalValue !== '1' ? '60vh' : valueHd === 'fullhd' ? '100%' : '80%',
          height: valueHd === 'fullhd' ? '100%' : '100%',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          flexDirection: 'column',
          position: 'relative',
          background: 'white',
          display: 'flex',
          transform:
            !VerticalValue || (VerticalValue && VerticalValue === '1') ? `unset` : `rotate(${VerticalValue}deg) `,
        }}
      >
        {/* <Carousel dotPosition="top" fade={true} focusOnSelect={true} className="slide-card-preview"> */}

        <div style={{ overflowY: 'scroll', height: '100%', width: '100%' }}>
          {MediaPreview?.images?.map((image: MediaImage, index: number) => {
            return (
              <img
                className="img-responsive"
                style={{ height: '100%', width: '100%', objectFit: 'fill' }}
                key={index}
                src={image?.url || ''}
                alt=""
              />
            );
          })}
        </div>

        {/* </Carousel> */}
      </div>
    );
  }

  return <div />;
}

export default React.memo(PlayerPreview);
