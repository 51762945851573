import { PaginatedMedia } from '@/graphql/generated/graphql';
import { getMediasApi } from '@modules/Media/services/apis';
import { put } from 'redux-saga/effects';
import { GetMediasAction } from '../action-types';
import { getMediasActionError, getMediasActionSuccess } from '../actions';

export function* getMediasAsync(action: GetMediasAction) {
  try {
    const medias: PaginatedMedia = yield getMediasApi(action.payload);

    yield put(getMediasActionSuccess(medias));
  } catch (error) {
    yield put(getMediasActionError(error));
  }
}
