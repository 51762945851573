import { Media } from '@/graphql/generated/graphql';
import ButtonCancel from '@commons/components/modals/Button/ButtonCancel';
import ButtonOk from '@commons/components/modals/Button/ButtonOk';
import useGetAllFolder from '@modules/Media/hooks/useGetAllFolder';
import useMoveFileItem from '@modules/Media/hooks/useMoveFileItem';
import { ListMediasState } from '@modules/Media/redux/reducers/medias';
import { RootState } from '@redux/reducers';
import { Form, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

interface Props {
  onMoveFile: (item: Media, parentId: any) => void;
}

export default function MoveModal(props: Props) {
  const listMediasState = useSelector<RootState, ListMediasState>((state) => state.media.list);
  const { item, onMoveItem } = useMoveFileItem();
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation(['media']);

  const [form] = Form.useForm();

  useEffect(() => {
    if (item) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
    // eslint-disable-next-line
  }, [item]);

  const { data } = useGetAllFolder();

  const layout = {
    labelCol: {
      sm: { span: 24 },
      xs: { span: 24 },
      md: { span: 8 },
      lg: { span: 6 },
    },
  };

  const listFolders = data?.items?.filter((item) => item._id !== listMediasState.data?.currentFolder?._id);

  const onSubmit = (values: any) => {
    if (item) {
      props.onMoveFile(item, values.parentId.split('/$/')[0]);
    }
    onMoveItem(undefined);
    setIsVisible(false);
    form.resetFields();
  };

  const onOk = () => {
    form.submit();
  };

  const onCancel = () => {
    onMoveItem(undefined);
  };

  const { Option } = Select;
  return (
    <Modal
      centered
      visible={isVisible}
      cancelText={t('cancel')}
      onCancel={() => onCancel()}
      className="modal_confirm_custom "
      title={t('move_file')}
      footer={[
        <ButtonCancel key={'btn_cancel'} title={t('cancel')} classNameBtn={`mr-2 `} onClick={onCancel} />,
        <ButtonOk key={'btn_ok'} onClick={onOk} title={t('save')} />,
      ]}
    >
      <Form layout="inline" form={form} onFinish={onSubmit} labelAlign="left" {...layout}>
        <Form.Item
          name="parentId"
          className="mb-3 w-100"
          rules={[{ required: true, message: t('validates.required_folder_name') }]}
        >
          <Select style={{ width: '100%' }} allowClear placeholder={t('select_folder')} showSearch>
            {listFolders &&
              listFolders.map((folder) => (
                <Option key={folder._id} value={`${folder._id}/$/${folder.filename}`}>
                  {folder.filename}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}
