import React from 'react';
import icon_delete from '@assets/images/icons/icon_reject.svg';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { Button } from 'antd';

interface Props {
  onClick: (e: any) => void;
  classNameContainer?: string;
  isLoading?: boolean;
  title?: string;
}

const ButtonRejectRed = (props: Props) => {
  const { t } = useTranslation(['button']);
  const onDelete = (e: any) => {
    props.onClick(e);
  };

  return (
    <Button
      className={`btn_reject_red px-0 h-100 ${props.classNameContainer}`}
      onClick={onDelete}
      type="link"
      loading={props.isLoading || false}
    >
      <div className="container_icon">
        <img className={`icon_btn`} src={icon_delete} alt="ic_btn" />
      </div>
      <p className="title_btn">{props.title ? props.title : t('reject')}</p>
    </Button>
  );
};

export default ButtonRejectRed;
