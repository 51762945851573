import { getSDK } from '@/graphql/client';
import i18n from '@/i18n';
import { useMutation, useQueryClient } from 'react-query';
import { GET_ALL_DEVICE } from '../types';

export default function useUpdateSettingDevice() {
  const queryClient = useQueryClient();

  return useMutation(getSDK().SettingDevice, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(GET_ALL_DEVICE);
      const messageData = {
        message: i18n.t('update_success'),
      };

      const globalOnSuccess = queryClient.getDefaultOptions().mutations?.onSuccess;
      // tslint:disable-next-line: no-unused-expression
      globalOnSuccess && globalOnSuccess(messageData, variables, context);
    },
  });
}
