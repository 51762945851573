import { Device, Roles } from '@/graphql/generated/graphql';
import ModalConfirm from '@commons/components/modals/ModalConfirm';
import TableLayout from '@commons/components/TableLayout';
import TableWithNumber from '@commons/components/TableWithNumber';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { LoginState } from '@modules/Auth/redux/action-types';
import { PageSizeTable } from '@modules/Company/types';
import DeviceRowAction from '@modules/Device/components/Action/DeviceRowAction';
import CellDeviceInfo from '@modules/Device/components/Form/CellTable/CellDeviceInfo';
import AddDeviceModal from '@modules/Device/components/Modal/AddDeviceModal';
import DeviceInfoModal from '@modules/Device/components/Modal/DeviceInfoModal';
import EditDeviceModal from '@modules/Device/components/Modal/EditDeviceModal';
import SettingDeviceModal from '@modules/Device/components/Modal/SettingDeviceModal';
import useClearDeviceStorage from '@modules/Device/hooks/useClearDeviceStorage';
import useDeleteDevice from '@modules/Device/hooks/useDeleteDevice';
import useGetAllDevice from '@modules/Device/hooks/useGetAllDeviceAutoRefetch';
import { DeviceFilter } from '@modules/Device/types';
import { RootState } from '@redux/reducers';
import { Button, Form, Input } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';
import { useSelector } from 'react-redux';

interface IState {
  updateDeviceData?: Device;
  deleteDeviceData?: Device;
  deviceInfo?: Device;
  updateDeviceCode?: Device;
  settingDevice?: Device;
  clearStorageDevice?: Device;
}

export default function AvailableDevicesPage() {
  const { t } = useTranslation(['device']);
  const { setPageTitle } = useSetPageTitle();
  const [visibleAddDevice, setVisibleAddDevice] = useState<boolean>(false);
  const [state, setState] = useState<IState>({
    updateDeviceData: undefined,
    deleteDeviceData: undefined,
    deviceInfo: undefined,
    updateDeviceCode: undefined,
    settingDevice: undefined,
    clearStorageDevice: undefined,
  });
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);
  const { mutate: deleteDevice, isSuccess: deleteDeviceSuccess, isLoading: loadingDelete } = useDeleteDevice();
  const {
    mutate: clearDeviceStorage,
    isSuccess: clearDeviceStorageSuccess,
    isLoading: clearDeviceStorageLoading,
  } = useClearDeviceStorage();

  // STT > Code > Model > Operating System > Name > Screen > Restaurant > Status
  const columns: ColumnsType<any> = [
    {
      title: t('stt'),
      key: '#',
      dataIndex: '#',
      width: 50,
      align: 'center',
    },
    {
      title: t('device_info'),
      key: 'deviceModel',
      dataIndex: 'deviceModel',
      width: 300,
      render: (value: any, record: Device) => <CellDeviceInfo record={record} />,
    },
    {
      title: '',
      key: 'action',
      width: 100,
      render: (record: any) => (
        <DeviceRowAction
          record={record}
          onDeviceInfo={(record) => {
            setState((states) => {
              return { ...states, deviceInfo: record };
            });
          }}
          onDelete={(record) => {
            setState((states) => {
              return { ...states, deleteDeviceData: record };
            });
          }}
          onEdit={(record) => {
            setState((states) => {
              return { ...states, updateDeviceData: record };
            });
          }}
          onSettingDevice={(record) => {
            setState((states) => {
              return { ...states, settingDevice: record };
            });
          }}
          onClearDeviceStorage={(record) => {
            setState((states) => {
              return { ...states, clearStorageDevice: record };
            });
          }}
        />
      ),
    },
  ];

  const [paginate, setPaginate] = useState({
    page: 1,
    pageSize: PageSizeTable,
  });

  const [filter, setFilter] = useState<DeviceFilter>({});

  const { isLoading, data } = useGetAllDevice({
    page: paginate.page,
    limit: paginate.pageSize,
    query: filter?.query || undefined,
    os: filter?.os || undefined,
    companyId: filter?.companyId || undefined,
    status: filter?.status || undefined,
    availableCompany: true,
  });

  const dataSource = data?.devices?.items?.map((device: any, index) => {
    return {
      ...device,
      status: device?.status ? device?.status : 'NO CONNECTION',
      companyName: device?.company?.name || `${t('repository')}`,
    };
  });

  const [form] = Form.useForm();

  const onSearch = (values: any) => {
    const input = {
      query: values.query ? values.query : undefined,
      status: values.status ? values.status : undefined,
    };

    if (paginate.page !== 1) {
      setPaginate({
        ...paginate,
        page: 1,
      });
    }
    setFilter(input);
  };

  // const { Option } = Select;

  const onCancel = () => {
    setVisibleAddDevice(false);
    setState((states) => {
      return {
        ...states,
        updateDeviceData: undefined,
        deleteDeviceData: undefined,
        deviceInfo: undefined,
        updateDeviceCode: undefined,
        settingDevice: undefined,
        clearStorageDevice: undefined,
      };
    });
  };

  const renderAdminColumn = useCallback(() => {
    if ((loginState.data?.role === Roles.SuperAdmin || loginState.data?.isSuperAdmin) && !loginState.data?.companyId) {
      return columns;
    }
    return columns.filter((column) => column.key !== 'companyName');
  }, [columns, loginState.data]);

  // const onFilterDeviceByStatus = (status?: DeviceStatus) => {
  //   setFilter({ ...filter, status });
  // };

  const onDeleteDevice = () => {
    if (state.deleteDeviceData) {
      deleteDevice({ input: { _id: state.deleteDeviceData._id } });
    }
  };

  const onClearDeviceStorage = () => {
    if (state.clearStorageDevice) {
      clearDeviceStorage({ input: { _id: state.clearStorageDevice._id } });
    }
  };

  useEffect(() => {
    if (clearDeviceStorageSuccess) {
      setState({ ...state, clearStorageDevice: undefined });
    }
    // eslint-disable-next-line
  }, [clearDeviceStorageSuccess]);

  useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t('device'));
    // eslint-disable-next-line
  }, [t]);

  // delete device success, reload list device
  useEffect(() => {
    if (deleteDeviceSuccess) {
      setState((state) => {
        return { ...state, deleteDeviceData: undefined };
      });
    }
    // eslint-disable-next-line
  }, [deleteDeviceSuccess]);

  return (
    <div>
      <TableLayout
        tableHeader={
          <Form
            layout="inline"
            form={form}
            onFinish={onSearch}
            className="d-block d-sm-flex justify-content-start gap-3"
          >
            <Form.Item
              className={`${
                !loginState.data?.companyId ||
                loginState.data?.role === Roles.SuperAdmin ||
                loginState.data?.isSuperAdmin
                  ? ''
                  : 'mr-0'
              }`}
            >
              <Input.Group compact>
                <Form.Item name="query" className="w-full mr-0">
                  <Input className="bd-form-search" placeholder={`${t('search_device')}...`} />
                </Form.Item>
                <Button icon={<FiSearch color="#F80000" />} className="btn-search" htmlType="submit" />
              </Input.Group>
            </Form.Item>
          </Form>
        }
      >
        <TableWithNumber
          scroll={{
            x: true,
          }}
          rowKey={(data) => data._id}
          columns={renderAdminColumn()}
          rowClassName={(record, index) =>
            index % 2 === 0 ? 'table-row-height table-row-white ' : 'table-row-height table-row-gray'
          }
          dataSource={dataSource}
          loading={isLoading}
          pagination={false}
          totalTitle={t('device')}
          paginate={{
            pageSize: paginate.pageSize,
            total: data?.devices?.total || 0,
            current: data?.devices?.currentPage || 1,
            onChange: (page) => {
              setPaginate({
                ...paginate,
                page,
              });
            },
          }}
        />
      </TableLayout>
      <AddDeviceModal visible={visibleAddDevice} onCancel={onCancel} />
      <SettingDeviceModal settingDevice={state.settingDevice} onCancel={onCancel} />
      <EditDeviceModal onCancel={onCancel} deviceData={state.updateDeviceData} />
      <ModalConfirm
        isLoading={loadingDelete}
        data={state.deleteDeviceData}
        handleOk={onDeleteDevice}
        handleCancel={onCancel}
        title={t('delete_title')}
        okTitle={t('delete')}
        content={
          <Trans
            i18nKey="delete_content"
            t={t}
            values={{ name: state.deleteDeviceData?.name }}
            components={[<span className="primary_color fw-500" key={''} />]}
          />
        }
      />
      <ModalConfirm
        isLoading={clearDeviceStorageLoading}
        data={state.clearStorageDevice}
        handleOk={onClearDeviceStorage}
        handleCancel={onCancel}
        title={t('clear_device_storage')}
        okTitle={t('confirm')}
        content={
          <Trans
            i18nKey="clear_device_storage_content"
            t={t}
            values={{ name: state.clearStorageDevice?.name }}
            components={[<span className="primary_color fw-500" key={''} />]}
          />
        }
      />
      {!!state.deviceInfo && <DeviceInfoModal onCancel={onCancel} deviceData={state.deviceInfo} />}
    </div>
  );
}
