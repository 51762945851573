import { getSDK } from '@/graphql/client';
import {
  CreatePlaylistInput,
  ExportExcelQuery,
  FrameInputType,
  GetPlaylistDetailQuery,
  Playlist,
  QueryPlaylistInput,
  SetPlaylistToGroup,
  UpdatePlaylistInput,
} from '@/graphql/generated/graphql';
import queryClient from '@/query-client';
import { GET_GROUP_PLAYLIST } from '@modules/GroupDevice/hooks/useGetGroupPlaylist';

// const convertFrames = (framesState: FrameInputType[] = []) => {
//   const frames: FrameInputType[] = [];
//   if (framesState.length) {
//     framesState?.map((frame) => {
//       let documentImages: DocumentImageInputType[] | null = frame.documentImages || null;
//       if (documentImages) {
//         documentImages = documentImages.map((image: any) => {
//           delete image?.__typename;
//           return image;
//         });
//       }
//       let videoProfiles: VideoProfileInput[] | null = frame.videoProfiles || null;
//       if (videoProfiles) {
//         videoProfiles = videoProfiles.map((profile: any) => {
//           delete profile?.__typename;
//           return profile;
//         });
//       }
//       frames.push({
//         backgroundColor: frame.backgroundColor,
//         duration: frame.duration,
//         isEnabled: frame.isEnabled,
//         type: frame.type,
//         text: frame.text || '',
//         documentImages: frame.type === FrameType.Document ? documentImages : null,
//         videoProfiles: frame.type === FrameType.Video ? videoProfiles : null,
//         order: frame.order,
//       });
//     });
//   }

//   return frames;
// };

export const createPlaylistApi = async (payload: any): Promise<Playlist> => {
  return new Promise((resolve, reject) => {
    const frames: FrameInputType[] = payload.frames;

    if (payload.companyId) {
      const data: CreatePlaylistInput = {
        companyId: payload.companyId,
        name: payload.name,
        frames,
      };
      //call api
      getSDK()
        .createPlaylist({ input: data })
        .then((data) => {
          resolve(data.createPlaylist as Playlist);
        })
        .catch((error) => {
          console.log('returnnewPromise ~ error', error);
          reject(error?.response?.errors[0]);
        });
    }
  });
};

export const updatePlaylistApi = async (payload: any) => {
  return new Promise((resolve, reject) => {
    const frames: FrameInputType[] = payload.frames;

    if (payload.companyId && payload._id) {
      const data: UpdatePlaylistInput = {
        _id: payload._id,
        companyId: payload.companyId,
        frames,
        name: payload.name,
      };

      getSDK()
        .updatePlaylist({ input: data })
        .then((data) => {
          queryClient.invalidateQueries(GET_GROUP_PLAYLIST);
          resolve(data);
        })
        .catch((error) => reject(error?.response?.errors[0]));
    }
  });
};

export const updatePlaylistGroupApi = async (data: SetPlaylistToGroup) => {
  return getSDK().updatePlaylistGroup({ input: data });
};

export const getPlaylistApi = async (payload: QueryPlaylistInput): Promise<GetPlaylistDetailQuery> => {
  return new Promise((resolve, reject) => {
    getSDK()
      .getPlaylistDetail({ input: payload })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => reject(error?.response?.errors[0]));
  });
};

export const ExportExcelApi = async (): Promise<ExportExcelQuery> => {
  return new Promise((resolve, reject) => {
    getSDK()
      .exportExcel()
      .then((data) => {
        resolve(data);
      })
      .catch((error) => reject(error?.response?.errors[0]));
  });
};
