import { Roles } from '@/graphql/generated/graphql';
import { LoginState } from '@modules/Auth/redux/action-types';
import { RootState } from '@redux/reducers';
import React from 'react';
import { useSelector } from 'react-redux';
import AdminLeftNavigation from './Admin';
import EditorLeftNavigation from './Editor';
import SuperAdminViewCompanySelector from './SuperAdminViewCompanySelector';
import SupperAdminLeftNavigation from './SupperAdmin';

// tslint:disable-next-line:interface-name
interface IProps {
  isCollapsed: boolean;
}

export default function LeftNavigation({ isCollapsed }: IProps) {
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);

  return (
    <>
      <div className="left-menu">
        <SuperAdminViewCompanySelector isCollapsed={isCollapsed} />
        {/* <SuperAdminViewCompanySelector /> */}
        {/* Phân quyền truy cập cho các user */}
        {/* Role Super_Admin */}
        {(loginState.data?.role === Roles.SuperAdmin || loginState.data?.isSuperAdmin) &&
        !loginState.data?.companyId ? (
          <SupperAdminLeftNavigation isCollapsed={isCollapsed} />
        ) : // Role Admin
        loginState.data?.role === Roles.Admin ||
          (loginState.data?.role === Roles.SuperAdmin && loginState.data?.companyId) ? (
          <AdminLeftNavigation isCollapsed={isCollapsed} />
        ) : // Role Editor
        loginState.data?.role === Roles.Editor ? (
          <EditorLeftNavigation isCollapsed={isCollapsed} />
        ) : null}
      </div>
    </>
  );
}
