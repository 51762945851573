/* eslint-disable react-hooks/exhaustive-deps */
import { UpdateDeviceSchedulesInput } from '@/graphql/generated/graphql';
import { LoginState } from '@modules/Auth/redux/action-types';
import {
  updatePlaylistSchedulesGroup,
  updatePlaylistSchedulesSecondGroup,
  updateSchedulesGroupAction,
  updateSchedulesGroupSuccess,
  updateSchedulesSecondGroupSuccess,
} from '@modules/GroupDevice/redux/actions';
import { RootState } from '@redux/reducers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CurrentGroupState } from '../redux/reducers/current_group';
import { PlaylistSchedule } from '../types';

const useUpdateScheduleGroupDevice = () => {
  const currentGroupState = useSelector<RootState, CurrentGroupState>((state) => state.groupDevice.currentGroup);
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);
  const loading = useSelector<RootState>((state) => state.groupDevice.scheduleGroupDevice.loading) as boolean;
  const dispatch = useDispatch();

  const onUpdateScheduleGroupDevice = useCallback((data: UpdateDeviceSchedulesInput & { createdById?: string }) => {
    dispatch(updateSchedulesGroupAction({ ...data, createdById: loginState.data?._id }));
  }, []);

  const onUpdateScheduleGroupDeviceSuccess = useCallback((data: PlaylistSchedule[]) => {
    dispatch(updateSchedulesGroupSuccess(data));
  }, []);

  const onUpdateScheduleSecondGroupDeviceSuccess = useCallback((data: PlaylistSchedule[]) => {
    dispatch(updateSchedulesSecondGroupSuccess(data));
  }, []);

  const onUpdatePlaylistScheduleGroupDevice = useCallback(
    (input: PlaylistSchedule) => {
      if (currentGroupState.screenActive === 2) {
        dispatch(updatePlaylistSchedulesSecondGroup(input));
      } else {
        dispatch(updatePlaylistSchedulesGroup(input));
      }
    },
    [currentGroupState.screenActive],
  );

  return {
    onUpdateScheduleGroupDevice,
    onUpdateScheduleGroupDeviceSuccess,
    onUpdatePlaylistScheduleGroupDevice,
    onUpdateScheduleSecondGroupDeviceSuccess,
    loading,
  };
};

export default useUpdateScheduleGroupDevice;
