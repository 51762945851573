import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@redux/reducers';
import { register } from '@modules/Auth/redux/actions';
import { useCallback } from 'react';
import { RegisterInput } from '../redux/action-types';

const useRegister = () => {
  const loading = useSelector<RootState>((state) => state.auth.registerState.loading) as boolean;
  const dispatch = useDispatch();

  const onRegister = useCallback((data: RegisterInput) => {
    dispatch(register(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onRegister,
    loading,
  };
};

export default useRegister;
