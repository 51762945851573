import {
  DisplayMode,
  FrameDirection,
  Group,
  PlaylistScreenType,
  Resolutions,
  ScheduleLayoutSettingsInput,
} from '@/graphql/generated/graphql';
import useUpdateCurrentGroup from '@modules/GroupDevice/hooks/useUpdateCurrentGroup';
import useUpdateScheduleGroupDevice from '@modules/GroupDevice/hooks/useUpdateScheduleGroup';
import { GroupDeviceModuleState } from '@modules/GroupDevice/redux/reducers';
import { CurrentGroupState } from '@modules/GroupDevice/redux/reducers/current_group';
import { GroupSettingDisplayMode, PlaylistSchedule } from '@modules/GroupDevice/types';
import { PlaylistResolutions } from '@modules/PlayList/types';
import { RootState } from '@redux/reducers';
import { Button, Card, Form, Input, Popover, Radio, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { ChromePicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import DrawerLayoutGroup, { LayoutSettingI } from '../Drawer/LayoutGroup';
import FullScreen from '../GroupLayout/FullScreen';
import SplitScreen from '../GroupLayout/SplitScreen';

const { Option } = Select;
export interface GroupsFilter {
  query?: string;
}

export interface ErrorTimeInDayState {
  message: string;
  status: boolean;
}

interface ScheduleRightProps {
  currentPlaylistSchedule?: PlaylistSchedule;
  onCancelSchedule?: () => void;
  groupData?: Group;
  succeed?: boolean;
  timeZoneForm?: any;
}

export interface LayoutSettingsStateI {
  backgroundColor: string;
  backgroundColorSecond: string;
  chooseColor: boolean;
  screenType?: PlaylistScreenType;
  orientation?: FrameDirection;
}

const GroupContent = (props: ScheduleRightProps, ref: any) => {
  const { t } = useTranslation(['group-device']);
  const [form] = useForm();
  const { id }: any = useParams();
  const scheduleDeviceGroupState = useSelector<RootState, GroupDeviceModuleState>((state) => state?.groupDevice);
  const currentGroupState = useSelector<RootState, CurrentGroupState>((state) => state.groupDevice.currentGroup);

  const { onUpdateScheduleGroupDevice } = useUpdateScheduleGroupDevice();

  const { onUpdateCurrentGroup, onUpdateCurrentGroupActiveScreen } = useUpdateCurrentGroup();

  const refLayout: any = useRef(null);

  const [chooseLayoutState, setChooseLayoutState] = useState<boolean>(false);

  const [layoutState, setLayoutState] = useState<LayoutSettingsStateI>({
    backgroundColor: '#EEF0F4',
    backgroundColorSecond: '#EEF0F4',
    chooseColor: false,
    screenType: PlaylistScreenType.FulScreen,
    orientation: FrameDirection.Horizontal,
  });

  useImperativeHandle(
    ref,
    () => ({
      updateSchedule: () => {
        form.submit();
      },
    }),
    [form],
  );

  const onActiveScreen = (screen: number) => {
    setLayoutState({
      ...layoutState,
    });
    onUpdateCurrentGroupActiveScreen(screen);
  };

  const renderLayout = useCallback(() => {
    switch (layoutState.screenType) {
      case PlaylistScreenType.FulScreen:
        return <FullScreen background={layoutState.backgroundColor} orientation={layoutState.orientation} />;
      case PlaylistScreenType.SplitScreen:
        return (
          <SplitScreen
            isBorder={true}
            background={layoutState.backgroundColor}
            backgroundColorSecond={layoutState.backgroundColorSecond}
            orientation={layoutState.orientation}
            active={currentGroupState.screenActive}
            onActiveScreen={onActiveScreen}
          />
        );
      default:
        return <FullScreen background={layoutState.backgroundColor} />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGroupState.screenActive, onActiveScreen]);

  const onFinish = (values: any) => {
    // console.log('props?.timeZoneForm', props?.timeZoneForm.getFieldValue('timeZone'));

    const timeZone = props?.timeZoneForm?.getFieldValue('timeZone') || '';
    const layoutSettings: ScheduleLayoutSettingsInput = {
      ...values,
      screenType: layoutState.screenType,
      backgroundColor: layoutState.backgroundColor,
      backgroundColorSecond: layoutState.backgroundColorSecond,
      orientation: layoutState.orientation,
    };

    onUpdateScheduleGroupDevice({
      _id: id,
      schedules: scheduleDeviceGroupState.scheduleGroupDevice.data as any,
      schedulesSecond: scheduleDeviceGroupState.scheduleGroupDevice.schedulesSecond as any,
      layoutSettings,
      groupTimeZone: timeZone,
    });
  };

  useEffect(() => {
    if (props.groupData) {
      form.setFieldsValue({
        resolution: props.groupData.layoutSettings?.resolution || Resolutions.FullHd,
        displayMode: props.groupData.layoutSettings?.displayMode || DisplayMode.Stretch,
      });
      setLayoutState({
        backgroundColor: props.groupData.layoutSettings?.backgroundColor || '#EEF0F4',
        backgroundColorSecond: props.groupData.layoutSettings?.backgroundColorSecond || '#EEF0F4',
        chooseColor: false,
        screenType: props.groupData.layoutSettings?.screenType || PlaylistScreenType.FulScreen,
        orientation: props.groupData.layoutSettings?.orientation || FrameDirection.Horizontal,
      });
    } else {
      form.setFieldsValue({
        resolution: Resolutions.FullHd,
        displayMode: DisplayMode.Stretch,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.groupData]);

  const onChangeColor = (color: string) => {
    if (currentGroupState.screenActive === 1) {
      return setLayoutState({
        ...layoutState,
        backgroundColor: color || '#ffffff',
      });
    }
    return setLayoutState({
      ...layoutState,
      backgroundColorSecond: color || '#ffffff',
    });
  };

  return (
    <div className="group-right-content">
      <Card title={t('schedule.layout')} style={{ width: '100%', borderBottom: 0 }}>
        <Form form={form} className="w-100" onFinish={onFinish}>
          <label className="mb-2 d-block label">{t('screen')}</label>
          <Form.Item
            name="resolution"
            className="mb-0 mr-3"
            rules={[{ required: true, message: t('validate.required') }]}
            labelCol={{ span: 6 }}
            style={{ width: '100%' }}
          >
            <Select defaultValue={Resolutions.FullHd}>
              {PlaylistResolutions.map((item, index) => {
                return (
                  <Option value={item.key} key={item.key}>
                    {item.name} ({`${item.width}x${item.height}`})
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <div className="playlist-layout p-3">
            <div className="screen-layout-left" style={{ height: refLayout?.current?.clientWidth }} ref={refLayout}>
              {renderLayout()}
            </div>
            <div className="screen-layout-right pl-xl-4 pt-3 pt-xl-0">
              <Button className="btn-change-layout mb-4" onClick={() => setChooseLayoutState(true)}>
                {t('change_layout')}
              </Button>
              <p className="mb-2">{t('background')}</p>
              <div className="d-flex">
                <div
                  className="color"
                  style={{
                    backgroundColor:
                      currentGroupState.screenActive === 1
                        ? layoutState.backgroundColor
                        : layoutState.backgroundColorSecond,
                  }}
                  onClick={() => {
                    setLayoutState({
                      ...layoutState,
                      chooseColor: true,
                    });
                  }}
                >
                  <Popover
                    content={
                      <ChromePicker
                        disableAlpha={true}
                        color={
                          currentGroupState.screenActive === 1
                            ? layoutState.backgroundColor
                            : layoutState.backgroundColorSecond
                        }
                        onChange={(e: any) => {
                          onChangeColor(e?.hex || '#ffffff');
                        }}
                      />
                    }
                    overlayInnerStyle={{ boxShadow: 'unset', background: 'unset', padding: 0 }}
                    overlayStyle={{ padding: 0 }}
                    style={{ padding: 0 }}
                    arrowContent={null}
                    trigger="click"
                    placement="bottom"
                    visible={layoutState.chooseColor}
                    onVisibleChange={() => {
                      setLayoutState({
                        ...layoutState,
                        chooseColor: !layoutState.chooseColor,
                      });
                    }}
                  >
                    <div className="react-color"></div>
                  </Popover>
                </div>
                <Input
                  style={{ maxWidth: 200, background: '#fff' }}
                  value={
                    currentGroupState.screenActive === 1
                      ? layoutState.backgroundColor
                      : layoutState.backgroundColorSecond
                  }
                />
              </div>
            </div>
          </div>
          <div className="setting pt-3">
            <label className="mb-2 d-block label">{t('setting')}</label>
            <Form.Item
              name="displayMode"
              labelCol={{ span: 6, xl: 5 }}
              style={{ width: '100%' }}
              label={t('schedule.setting.display.display')}
              labelAlign="left"
            >
              <Radio.Group>
                {GroupSettingDisplayMode.map((item, index: number) => {
                  return (
                    <div key={index}>
                      <Radio value={item.value} key={item.value}>
                        {t(`schedule.setting.display.${item.key}`)}
                      </Radio>
                    </div>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          </div>
        </Form>
      </Card>
      <DrawerLayoutGroup
        layoutSettingData={layoutState}
        onCancel={() => setChooseLayoutState(false)}
        groupName={props.groupData?.name}
        visible={chooseLayoutState}
        onSave={(e: LayoutSettingI) => {
          onUpdateCurrentGroup({
            screenType: e.screenType,
            orientation: e.orientation,
          });
          setLayoutState({
            ...layoutState,
            screenType: e.screenType,
            orientation: e.orientation,
          });
          onUpdateCurrentGroupActiveScreen(1);
        }}
      />
    </div>
  );
};

export default forwardRef(GroupContent);
