import { Card } from 'antd';
import { CardProps } from 'antd/lib/card';
import React from 'react';
import './style.scss';

export default function FormHeader(props: CardProps) {
  return (
    <Card bordered={false} {...props} className={`${props.className} form-header-style`}>
      {props.children}
    </Card>
  );
}
