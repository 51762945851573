/* eslint-disable react-hooks/exhaustive-deps */
import { PlaylistChangeGroupAction } from '@/graphql/generated/graphql';
import ic_copy from '@assets/images/icons/ic_copy.svg';

import icon_close from '@assets/images/icons/icon_close_gray.svg';
import TableWithNumber from '@commons/components/TableWithNumber';
import { LocationInput, PageSizeTable } from '@modules/Company/types';
import useGetAllPlaylist from '@modules/PlayList/hooks/useGetAllPlaylist';
import { Button, Empty, Form, Input, Modal, Row, Spin, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';

import { FULL_DATE } from '@helpers/date';
import { PlaylistsFilter } from '@modules/PlayList/types';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import TextTruncate from 'react-text-truncate';

export enum ViewAssign {
  PLAYLIST = 'PLAYLIST',
  GROUP = 'GROUP',
}

export interface SetOtherGroupI {
  groupId: string;
  action: PlaylistChangeGroupAction;
}

interface Props {
  data?: LocationInput;
  visible?: boolean;
  isEdit?: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
  view?: ViewAssign;
  onSetOtherGroup?: (input: SetOtherGroupI, playlistId?: string) => void;
  groupId?: string;
}

const AssignPlayListModal = (props: Props, ref: any) => {
  const { t } = useTranslation(['playlist', 'button', 'dashboard']);

  // eslint-disable-next-line
  const [filter, setFilter] = useState<PlaylistsFilter>({});

  const [form] = Form.useForm();

  // eslint-disable-next-line
  const [paginate, setPaginate] = useState({
    page: 1,
    pageSize: PageSizeTable,
  });

  // eslint-disable-next-line
  const { isLoading, data, refetch } = useGetAllPlaylist({
    page: paginate.page,
    limit: paginate.pageSize,
    // resolution: filter?.resolution,
    // status: filter?.status,
    query: filter?.query || undefined,
    // diffApprove: true,
  });

  const onSearch = (values: any) => {
    const input: PlaylistsFilter = {
      query: values.query ? values.query : undefined,
      // capacity: values.capacity || undefined,
      resolution: values?.resolution || undefined,
      status: values.status || undefined,
    };
    if (paginate.page !== 1) {
      setPaginate({
        ...paginate,
        page: 1,
      });
    }
    setFilter(input);
  };

  const handleChangeGroup = (_id: string, action: PlaylistChangeGroupAction) => {
    props?.onCancel?.();
    if (props?.groupId) {
      return props?.onSetOtherGroup && props?.onSetOtherGroup({ groupId: props?.groupId, action }, _id);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: t('stt'),
      width: 80,
      align: 'center',
      render: (value: any, record, index) => {
        return (data?.playlists?.currentPage || 1) <= 1
          ? index + 1
          : 10 * ((data?.playlists?.currentPage || 1) - 1) + (index + 1);
      },
    },
    {
      title: t('name'),
      key: 'name',
      dataIndex: 'name',
      width: 300,
      render: (value: any, record) => (
        <Tooltip title={value || 'name'}>
          <TextTruncate
            containerClassName="w-100 d-block truncate-text"
            line={2}
            element="span"
            truncateText="…"
            text={value}
          />
        </Tooltip>
      ),
    },
    {
      title: t('created_date'),
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 300,
      render: (createdAt) => {
        return moment(createdAt).format(FULL_DATE);
      },
    },
    {
      title: t('assignend_group'),
      key: 'group',
      dataIndex: 'group',
      width: 350,
      render: (group) => {
        return group?.name || 'N/A';
      },
    },
    {
      title: '',
      key: 'action',
      width: 100,
      render: (record: any) => {
        return (
          <Tooltip title={t('copy')}>
            <div
              className="container_icon cursor-pointer"
              onClick={() => {
                handleChangeGroup(record._id, PlaylistChangeGroupAction.Copy);
              }}
            >
              <img className={`icon_btn`} src={ic_copy} alt="ic_btn" />
            </div>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Modal
      centered
      visible={props.visible}
      closeIcon={null}
      closable={false}
      focusTriggerAfterClose
      onCancel={() => {
        props?.onCancel?.();
      }}
      className="modal-assign-play-list"
      footer={null}
      width={800}
    >
      <div className="container_view">
        <Row justify="space-between" className="header-container">
          <div className="title">{props.view === ViewAssign.GROUP ? t('set_to_other_group') : t('set_to_group')}</div>
          <img
            src={icon_close}
            className="ic_close cursor-pointer"
            alt="ic_close"
            width={14}
            height={14}
            onClick={() => {
              props?.onCancel?.();
            }}
          />
        </Row>

        <Form form={form} onFinish={onSearch} className="form-input">
          <Row justify="space-between" className="header-container-input">
            <Form.Item name={'query'} className="w-full mr-0" style={{ flex: 1, margin: 0 }}>
              <Input
                style={{ width: '100%', paddingRight: 50, background: 'transparent' }}
                placeholder={`${t('search')}`}
                className=""
              />
            </Form.Item>
            <Button
              icon={<FiSearch color="#F80000" />}
              className="btn-search d-flex align-items-center justify-content-center"
              style={{ marginLeft: -40, background: '#FFE5E5' }}
              htmlType="submit"
            />
          </Row>
          <div className="total-group">
            <strong className="total-group-num">{data?.playlists?.total || 0}</strong>
            {(data?.playlists?.total || 0) > 1 ? t('dashboard:groups') : t('dashboard:group')}
          </div>

          <div className="contaier-body">
            {isLoading ? (
              <Row justify="center" className="cursor-pointer row-item">
                <Spin style={{ textAlign: 'center' }} />
              </Row>
            ) : data?.playlists?.items?.length === 0 ? (
              <Empty />
            ) : (
              <TableWithNumber
                scroll={{
                  x: true,
                  y: 400,
                }}
                rowKey={(data) => data._id}
                columns={columns}
                rowClassName={(record, index) =>
                  index % 2 === 0 ? 'table-row-height table-row-white ' : 'table-row-height table-row-gray'
                }
                dataSource={data?.playlists?.items || []}
                loading={isLoading}
                pagination={false}
                totalTitle={(data?.playlists?.total || 0) <= 1 ? t('title') : t('title') + 's'}
                paginate={{
                  pageSize: paginate.pageSize,
                  total: data?.playlists?.total || 0,
                  current: data?.playlists?.currentPage || 1,
                  onChange: (page) => {
                    setPaginate({
                      ...paginate,
                      page,
                    });
                  },
                }}
              />
            )}
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default React.memo(React.forwardRef(AssignPlayListModal));
