import { getSDK } from '@/graphql/client';
import { DevicePlaylistScheduleInput, UpdateDeviceSchedulesInput } from '@/graphql/generated/graphql';

export const updateScheduleDeviceGroup = async (payload: UpdateDeviceSchedulesInput & { createdById?: string }) => {
  const scheduleData: DevicePlaylistScheduleInput[] = [];
  payload.schedules?.map((schedule) =>
    scheduleData.push({
      playlistId: schedule.playlistId,
      type: schedule.type,
      dailyData: schedule.dailyData,
      weeklyData: schedule.weeklyData,
      monthlyData: schedule.monthlyData,
      specialDayData: schedule.specialDayData,
      createdById: schedule.createdById || payload.createdById || '',
    }),
  );
  const scheduleDataSecond: DevicePlaylistScheduleInput[] = [];
  payload.schedulesSecond?.map((schedule) =>
    scheduleDataSecond.push({
      playlistId: schedule.playlistId,
      type: schedule.type,
      dailyData: schedule.dailyData,
      weeklyData: schedule.weeklyData,
      monthlyData: schedule.monthlyData,
      specialDayData: schedule.specialDayData,
      createdById: schedule.createdById || payload.createdById || '',
    }),
  );
  return new Promise((resolve, reject) => {
    //call api
    getSDK()
      .UpdateSchedulesDeviceGroup({
        input: {
          _id: payload._id,
          schedules: scheduleData,
          schedulesSecond: scheduleDataSecond,
          layoutSettings: payload.layoutSettings,
          groupTimeZone: payload.groupTimeZone,
        },
      })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => reject(error?.response?.errors[0]));
  });
  //call api
  //   getSDK()
  //     .UpdateSchedulesDeviceGroup({
  //       input: {
  //         _id: payload._id,
  //         schedules: scheduleData,
  //       },
  //     })
  //     .then((data) => {
  //       resolve(data);
  //     })
  //     .catch((error) => reject(error?.response?.errors[0]));
  // });
};
