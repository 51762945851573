import { Media } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { createFolderApi } from '@modules/Media/services/apis';
import { put } from 'redux-saga/effects';
import { CreateFolderAction } from '../action-types';
import { createFolderActionError, createFolderActionSuccess } from '../actions';

export function* createFolderAsync(action: CreateFolderAction) {
  try {
    const media: Media = yield createFolderApi(action.payload);
    yield put(createFolderActionSuccess(media));
  } catch (error) {
    yield put(createFolderActionError(error));
  }
}

export function createFolderAsyncSuccess() {
  NotificationSuccess(i18n.t('notification'), i18n.t('add_success'));
}
