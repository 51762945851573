import { Media } from '@/graphql/generated/graphql';
import IcGrey from '@assets/images/icons/ic_close_grey.svg';
import ButtonCancel from '@commons/components/modals/Button/ButtonCancel';
import ButtonOk from '@commons/components/modals/Button/ButtonOk';
import { ErrorTimeInDayState } from '@modules/GroupDevice/Components/Shedule/Right';
import { Col, DatePicker, Form, Modal, Row, Switch, Tooltip } from 'antd';
import { truncate } from 'lodash';
import moment from 'moment';
import React, { useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PlayerPreview from '../../Preview/component/PlayerPreview';
import { IRight } from '../RightPlayListAddNew';

interface IFProps {
  onSubmit?: (values: Media & IRight) => void;
  onCancel?: () => void;
  item?: (Media & IRight) | any;
}

function PlayListAvailability(props: IFProps, ref: any) {
  const { item } = props;

  const [form] = Form.useForm();
  const { t } = useTranslation(['playlist']);
  const { t: tCompany } = useTranslation(['company']);

  const [visible, setVisible] = useState(false);

  const [errorTimeInDay, setErrorTimeInDay] = useState<ErrorTimeInDayState>({
    message: '',
    status: false,
  });

  React.useEffect(() => {
    if (visible) {
      if (item) {
        form.setFieldsValue({
          enabled: item.availability?.enabled === undefined ? true : item.availability?.enabled,
          fromDate: item.availability?.fromDate ? moment(item.availability?.fromDate) : null,
          toDate: item.availability?.toDate ? moment(item.availability?.toDate) : null,
        });
      } else {
      }
    }
    // eslint-disable-next-line
  }, [item, visible, form]);

  const handleOk = () => {
    const itemParams: any = {
      ...item,
      availability: form.getFieldsValue(),
    };

    if (
      itemParams.availability?.fromDate &&
      itemParams.availability?.toDate &&
      moment(itemParams.availability?.fromDate).isAfter(moment(itemParams?.availability?.toDate))
    ) {
      // NotificationError(t('warning'), );
      setErrorTimeInDay({
        message: tCompany('smaller_than_date'),
        status: true,
      });
      return;
    }
    setErrorTimeInDay({
      message: '',
      status: false,
    });
    props.onSubmit?.(itemParams);
    setVisible(false);
  };

  const onShow = () => {
    setVisible(true);
  };

  const onCancel = () => {
    props.onCancel?.();
    setVisible(false);
  };

  useImperativeHandle(ref, () => ({
    onShow,
  }));

  return (
    <Modal
      closable={false}
      centered
      visible={visible}
      destroyOnClose
      onCancel={onCancel}
      bodyStyle={{ padding: '10px 0px 0px 0px', borderRadius: '10px !important' }}
      maskStyle={{ borderRadius: 10 }}
      footer={undefined}
      className="media-modal-playlist"
    >
      <>
        <Row justify="space-between" className="px-3">
          <Tooltip title={item?.filename || item?.webUrl || 'filename'}>
            <h3 style={{ textTransform: 'capitalize' }}>
              {truncate(item?.filename || item?.webUrl || '', { length: 150 })}
            </h3>
          </Tooltip>

          <img src={IcGrey} className="cursor-pointer" onClick={onCancel} alt="close" />
        </Row>
        <div style={{ background: 'white' }} className="container-playlist-modal">
          <div className="ml-2 contaier-left">
            <div className="container-left-child">
              {/* <img
                src={item?.images?.[0]?.url || item?.optimizeUrl || ''}
                alt={item?.filename}
                style={{ maxHeight: '100%', objectFit: 'contain' }}
              /> */}
              <PlayerPreview MediaPreview={item} />
            </div>
          </div>
          <div className="contaier-right">
            <div className="container-right-child">
              <Form form={form} labelAlign="left" colon={false} preserve={false}>
                <Row justify="space-between">
                  <h5 style={{ marginTop: '0 !important', textTransform: 'capitalize' }} className="label-col">
                    {t('availability')}
                  </h5>
                  <Form.Item name="enabled" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Row>

                <h5 className="label-col">{t('from')}</h5>
                <Form.Item name="fromDate">
                  <DatePicker
                    style={{ width: '100%' }}
                    className="ant-input"
                    format={'YYYY-MM-DD HH:mm'}
                    disabledDate={(current) => {
                      const to = form.getFieldValue('toDate');
                      return to
                        ? current.isBefore(moment().subtract(1, 'days')) || current.isAfter(moment(to).add(1, 'day'))
                        : current && current.isBefore(moment().subtract(1, 'days'));
                    }}
                    showTime
                    placeholder={t('from')}
                  />
                </Form.Item>

                <h5 className="label-col ">{t('to')}</h5>

                <Form.Item name="toDate">
                  <DatePicker
                    showTime
                    format={'YYYY-MM-DD HH:mm'}
                    className="ant-input"
                    style={{ width: '100%' }}
                    placeholder={t('to')}
                    disabledDate={(current) => {
                      const from = form.getFieldValue('fromDate');
                      return from
                        ? moment(from).isAfter(current)
                        : current && moment(current).subtract(-1, 'days').isBefore(moment());
                    }}
                  />
                </Form.Item>

                <Col className="d-flex align-items-start justify-content-between" style={{ paddingLeft: 0 }}>
                  {errorTimeInDay?.status && (
                    <div className="ant-form-item-explain ant-form-item-explain-error w-100 text-left">
                      <div role="alert">{errorTimeInDay?.message}</div>
                    </div>
                  )}
                </Col>

                {!item.isLink && (
                  <>
                    <h4 className="label-col">{t('metadata')}</h4>
                    <Row justify="space-between">
                      <h5 className="label-col">{t('format')}</h5>
                      <div className="label-col-des">{item?.mimetype}</div>
                    </Row>
                    <Row justify="space-between">
                      <h5 className="label-col">{t('filesize')}</h5>
                      <div className="label-col-des">{item?.size?.toFixed(3) + ' MB'}</div>
                    </Row>
                    <Row justify="space-between" style={{ overflow: 'hidden' }}>
                      <h5 className="label-col">Url</h5>
                      <Tooltip title={item?.optimizeUrl || 'url'}>
                        <div className="label-col-des" style={{ overflow: 'hidden' }}>
                          {truncate(item?.optimizeUrl || 'url', { length: 50 })}
                        </div>
                      </Tooltip>
                    </Row>
                  </>
                )}
              </Form>
              <div style={{ height: 150 }} />
            </div>
            <div className="row-btn">
              <ButtonCancel key={'btn_cancel'} title={t('cancel')} classNameBtn={``} onClick={onCancel} />
              <ButtonOk key={'btn_ok'} onClick={() => handleOk()} title={t('save')} />
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
}

export default React.memo(React.forwardRef(PlayListAvailability));
