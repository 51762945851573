import { AppError } from '@commons/types';
import { PlayListData } from '@modules/PlayList/types';
import {
  UPDATE_PLAYLIST,
  UPDATE_PLAYLIST_ERROR,
  UPDATE_PLAYLIST_SUCCESS,
  UpdatePlaylistActionTypes,
} from '../action-types';

export const updatePlaylist = (payload: PlayListData): UpdatePlaylistActionTypes => ({
  type: UPDATE_PLAYLIST,
  payload,
});

export const updatePlaylistSuccess = (payload: PlayListData): UpdatePlaylistActionTypes => ({
  type: UPDATE_PLAYLIST_SUCCESS,
  payload,
});

export const updatePlaylistError = (payload?: AppError): UpdatePlaylistActionTypes => ({
  type: UPDATE_PLAYLIST_ERROR,
  payload,
});
