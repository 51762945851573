import { Device } from '@/graphql/generated/graphql';
import { DeviceItemActionTypes, DEVICE_ITEM } from '../action-types';

export interface DeviceItemState {
  loading?: boolean;
  isSuccess: boolean;
  deviceItem?: Device;
}

const initialState: DeviceItemState = {
  loading: false,
  isSuccess: false,
};

export default (state = initialState, action: DeviceItemActionTypes): DeviceItemState => {
  switch (action.type) {
    case DEVICE_ITEM:
      return {
        ...state,
        deviceItem: action.payload,
      };

    default:
      return {
        ...state,
        deviceItem: undefined,
      };
  }
};
