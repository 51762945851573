import { Group, GroupMessageDirection, GroupMessageSpeed } from '@/graphql/generated/graphql';
import ButtonCancel from '@commons/components/modals/Button/ButtonCancel';
import ButtonOk from '@commons/components/modals/Button/ButtonOk';
import useSendGroupMessage from '@modules/GroupDevice/hooks/useSendGroupMessage';
import { Form, Modal, Radio, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  onCancel: () => void;
  groupItem?: Group;
}

const { TextArea } = Input;

export default function GroupMessageModal(props: IProps) {
  const [form] = Form.useForm();
  const { t } = useTranslation(['group-device']);
  const [group, setGroup] = useState(props.groupItem);
  const { mutate: sendGroupMessage, isLoading } = useSendGroupMessage();

  useEffect(() => {
    setGroup(props.groupItem);
  }, [props.groupItem]);

  const onFinish = (values: any) => {
    if (group) {
      sendGroupMessage({
        input: {
          direction: values.direction,
          speed: values.speed,
          message: values?.message || '',
          groupId: group._id,
          loop: values.loop ? parseInt(values.loop) : 1,
        },
      });
    }
  };

  const onCancel = () => {
    form.resetFields();
    props.onCancel();
  };

  return (
    <Modal
      centered
      visible={!!group}
      cancelText={t('cancel')}
      onCancel={onCancel}
      className="modal_confirm_custom modal_group_device_custom"
      title={`${t('send_group_message_title')} - ${group?.name}`}
      footer={[
        <ButtonCancel key={'btn_cancel'} title={t('cancel')} classNameBtn={`mr-2 `} onClick={onCancel} />,
        <ButtonOk
          key={'btn_ok'}
          onClick={() => form.submit()}
          title={t('send')}
          classNameBtn={``}
          isLoading={isLoading}
        />,
      ]}
    >
      <Form form={form} onFinish={onFinish} colon={false} labelAlign="left">
        <Form.Item
          name="direction"
          label={t('direction')}
          labelCol={{ span: 6 }}
          initialValue={GroupMessageDirection.RightToLeft}
          rules={[{ required: true, message: t('validate.required') }]}
        >
          <Radio.Group>
            <Radio className="mr-3" value={GroupMessageDirection.RightToLeft}>
              {t('right_to_left')}
            </Radio>
            <Radio value={GroupMessageDirection.LeftToRight}>{t('left_to_right')}</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="speed"
          label={t('speed')}
          labelCol={{ span: 6 }}
          initialValue={GroupMessageSpeed.Slow}
          rules={[{ required: true, message: t('validate.required') }]}
        >
          <Radio.Group>
            <Radio className="mr-3" value={GroupMessageSpeed.Slow}>
              {t('slow')}
            </Radio>
            <Radio className="mr-3" value={GroupMessageSpeed.Medium}>
              {t('medium')}
            </Radio>
            <Radio value={GroupMessageSpeed.Fast}>{t('fast')}</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="loop"
          className="bg_input_form mb-0"
          label={t('loop')}
          rules={[{ required: true, message: t('validate.required') }]}
          labelCol={{ span: 6 }}
          initialValue={3}
        >
          <Input type="number" min={-1} style={{ width: '100%' }} placeholder={t('loop')} />
        </Form.Item>
        <Form.Item className="bg_input_form mt-0" labelCol={{ span: 6 }} label="&nbsp;">
          <div className="mt-2 w-100" style={{ fontSize: '14px', opacity: 0.8, fontStyle: 'italic' }}>
            <p className="mb-1">{t('loop_notice_1')}</p>
            <p className="mb-0">{t('loop_notice_2')}</p>
          </div>
        </Form.Item>
        <Form.Item
          name="message"
          label={t('message')}
          labelCol={{ span: 6 }}
          // rules={[{ required: true, message: t('validate.required') }]}
        >
          <TextArea rows={4} maxLength={300} placeholder={t('group_message_placeholder')} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
