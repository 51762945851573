import { Media } from '@/graphql/generated/graphql';

export const MOVE_FILE_ITEM = 'MOVE_FILE_ITEM';

export interface MoveFileItemAction {
  type: typeof MOVE_FILE_ITEM;
  payload: Media;
}

export type MoveFileItemActionTypes = MoveFileItemAction;
