import { FileType, FrameType, Media, MediaStatus, MediaType } from '@/graphql/generated/graphql';
import { FileExclamationOutlined } from '@ant-design/icons';
import Folder from '@assets/images/Folder.png';
import Loading from '@commons/components/Loading';
import { getNameMediaItem } from '@helpers/string';
import ItemDropdown from '@modules/Media/component/dropdown/ItemDropdown';
import { getTypeOfFile } from '@modules/Media/helper/file';
import useSelectFolder from '@modules/Media/hooks/useSelectFolder';
import { Image } from 'antd';
import React, { MouseEvent } from 'react';
import { FaFilePdf, FaFilePowerpoint } from 'react-icons/fa';
import { TypeViewMedia } from '@modules/Media/pages/index';
import IcLinkWeb from '@assets/images/icons/ic_link_web.svg';
import { getHistory } from '@helpers/history';

interface IFProps {
  item: Media;
  isShowMenu?: boolean;
  onShowMenu?: (e: Media) => void;
  onSelectMedia?: (e: Media, event: MouseEvent) => any;
  isChecked?: boolean;
  typeView: TypeViewMedia;
}

interface MediaFileTypeIF {
  media?: Media;
  src?: string;
}

export function MediaFileType(props: MediaFileTypeIF) {
  const { media, src } = props;

  const mediaType = getTypeOfFile(media?.mimetype || '');
  if ((media as any)?.type === FrameType.WebUrl) {
    return (
      <div className={`media-image`}>
        <img src={IcLinkWeb} alt="LinkWeb" style={{ marginRight: 10 }} />
      </div>
    );
  }
  switch (mediaType) {
    case MediaType.Pdf:
      return <FaFilePdf size={50} />;
    case MediaType.Powerpoint:
      return <FaFilePowerpoint size={50} />;
    case MediaType.Image:
      return (
        <Image
          width="100%"
          preview={false}
          style={{ objectFit: 'contain' }}
          src={`${src ? src : media?.thumbs ? media?.thumbs[0].url : media?.optimizeUrl}`}
        />
      );
    case MediaType.Video:
      return (
        <Image
          width="100%"
          style={{ objectFit: 'contain' }}
          preview={false}
          src={`${media?.images ? media?.images[0].url : media?.optimizeUrl}`}
        />
      );
    default:
      return null;
  }
}

function MediaItemComponent(props: IFProps) {
  const { item } = props;
  const { onSelectFolder } = useSelectFolder();

  function contextMenu(e: any): void {
    e.preventDefault();
    props?.onShowMenu?.(item);
  }

  const selectFolder = () => {
    if (item && item?.type === FileType.Folder) {
      getHistory().push(`${getHistory().location.pathname}?id=${item._id}`);
      onSelectFolder(item);
    }
  };

  return (
    <div
      className={`media-item ${props.isChecked ? 'media-item-checked' : ''}`}
      onContextMenu={contextMenu}
      onClick={(e: MouseEvent) => props.onSelectMedia?.(item, e)}
      onDoubleClick={selectFolder}
    >
      <ItemDropdown item={item} isShowMenu={props.isShowMenu} />
      <div className={`media-image ${props.isChecked ? 'media-image-checked' : ''}`}>
        {item?.status === MediaStatus.Processing ? (
          <Loading type="inline" />
        ) : item?.status === MediaStatus.Failed ? (
          <FileExclamationOutlined size={50} />
        ) : item?.type === FileType.File ? (
          <MediaFileType media={item} />
        ) : (
          <img src={Folder} alt="folder" />
        )}
      </div>
      <p className={`viewFileName`}>{getNameMediaItem(item?.filename || '')}</p>
    </div>
  );
}

export default MediaItemComponent;
