import React from 'react';
import { Button } from 'antd';
import './styles.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  title?: string;
  classNameBtn?: string;
  onClick: () => void;
}

const ButtonCancel = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Button className={`btn_cancel ${props.classNameBtn}`} onClick={props.onClick}>
      {props.title ? props.title.toLocaleUpperCase() : t('cancel')}
    </Button>
  );
};

export default ButtonCancel;
