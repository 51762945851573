/* eslint-disable no-duplicate-case */
import { Media, PaginatedMedia } from '@/graphql/generated/graphql';
import {
  CreateFolderActionTypes,
  // CreateFolderActionTypes,
  CREATE_FOLDER_SUCCESS,
  DeleteMediaActionTypes,
  DeleteMediaItemActionTypes,
  DELETE_MEDIA_ITEM,
  DELETE_MEDIA_SUCCESS,
  GetFoldersActionTypes,
  GetMediasActionTypes,
  GET_MEDIAS,
  GET_MEDIAS_ERROR,
  GET_MEDIAS_SUCCESS,
  GO_BACK_FOLDER,
  MoveFileActionTypes,
  MoveFileItemActionTypes,
  MOVE_FILE_ITEM,
  MOVE_FILE_SUCCESS,
  PreviewMediaActionTypes,
  PREVIEW_MEDIA,
  SelectFolderActionTypes,
  SELECT_FOLDER,
  UpdateFileActionTypes,
  UPDATE_FILE_SUCCEED,
  UPDATE_FILE_SUCCESS,
  UPDATE_UPDATE_AT_FOLDER,
  UploadFileActionTypes,
  UPLOAD_FILE_SUCCESS,
} from '../action-types';

export interface ListMediasState {
  loading?: boolean;
  data?: PaginatedMedia;
  breadcrumbs?: Media[];
  previewItem?: Media;
  deleteItem?: Media;
  moveItem?: Media;
}

const initialState: ListMediasState = {
  loading: false,
  breadcrumbs: [],
  data: {
    currentPage: 1,
    totalPage: 1,
    total: 0,
    items: [],
    hasMore: false,
    isLoadMore: false,
    currentFolder: undefined,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state = initialState,
  action:
    | CreateFolderActionTypes
    | DeleteMediaActionTypes
    | GetMediasActionTypes
    | UploadFileActionTypes
    | UpdateFileActionTypes
    | SelectFolderActionTypes
    | PreviewMediaActionTypes
    | DeleteMediaItemActionTypes
    | MoveFileActionTypes
    | GetFoldersActionTypes
    | MoveFileItemActionTypes,
): ListMediasState => {
  switch (action.type) {
    case GET_MEDIAS:
      return {
        ...state,
        // data: {
        //   ...state.data,
        //   currentPage: state.data?.currentPage || 1,
        //   total: 0,
        //   totalPage: 0,
        //   items: [],
        // },
      };

    case GET_MEDIAS_SUCCESS:
      return {
        ...state,
        data: {
          currentPage: action.payload.currentPage,
          totalPage: action.payload.totalPage,
          total: action.payload.total,
          currentFolder: action.payload.currentFolder,
          hasMore: action.payload.hasMore,
          items:
            action.payload.isLoadMore && state?.data?.items?.length
              ? [...state?.data?.items?.concat(action?.payload?.items || [])]
              : [...(action?.payload?.items || [])],
        },
        loading: false,
      };

    case GET_MEDIAS_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          currentPage: state.data?.currentPage || 1,
          total: 0,
          totalPage: 0,
          items: [],
        },
        loading: false,
      };

    case UPLOAD_FILE_SUCCESS:
    case CREATE_FOLDER_SUCCESS:
      const files: Media[] = [...(state.data?.items || [])];
      if (action.payload && state.data && state.data.items) {
        files.unshift(action.payload);
      }
      return {
        ...state,
        data: {
          ...state.data,
          total: state.data ? state.data.total + 1 : undefined,
          items: files,
        } as PaginatedMedia,
      };

    case DELETE_MEDIA_SUCCESS:
      let filesDelete: Media[] = [...(state.data?.items || [])];
      if (action.payload) {
        filesDelete = filesDelete.filter((file) => file._id !== action.payload);
      }
      return {
        ...state,
        data: {
          ...state.data,
          total: state.data ? state.data.total - 1 : undefined,
          items: filesDelete,
        } as PaginatedMedia,
      };

    case MOVE_FILE_SUCCESS:
      let filesMove: Media[] = [...(state.data?.items || [])];
      if (action.payload) {
        filesMove = filesMove.filter((file) => file._id !== action.payload?._id);
      }
      return {
        ...state,
        data: {
          ...state.data,
          total: state.data ? state.data.total - 1 : undefined,
          items: filesMove,
        } as PaginatedMedia,
      };

    case UPDATE_FILE_SUCCESS:
      let filesUpdate: Media[] = [...(state.data?.items || [])];

      if (filesUpdate.length) {
        filesUpdate = filesUpdate.map((file) => {
          return file._id === action.payload?._id
            ? { ...file, filename: action.payload.filename, updatedAt: action.payload.updatedAt }
            : file;
        });
      }

      return {
        ...state,
        data: {
          ...state.data,
          items: filesUpdate,
        } as PaginatedMedia,
      };
    case UPDATE_UPDATE_AT_FOLDER:
      let listFile: Media[] = [...(state.data?.items || [])];

      if (listFile.length) {
        listFile = listFile.map((file) => {
          return file._id === action.payload?.id ? { ...file, updatedAt: action.payload.updatedAt } : file;
        });
      }

      return {
        ...state,
        data: {
          ...state.data,
          items: listFile,
        } as PaginatedMedia,
      };
    case UPDATE_FILE_SUCCEED: {
      const data = state?.data?.items?.map((item) => {
        return item?._id?.toString() === action?.payload?._id?.toString() ? action?.payload : item;
      });
      return {
        ...state,
        data: {
          ...state.data,
          items: data ? [...data] : [],
        } as PaginatedMedia,
      };
    }

    case SELECT_FOLDER:
      let breadcrumbs = state.breadcrumbs;

      if (action.payload && breadcrumbs) {
        let i = 0;
        const newBreadcrumbs = [];
        while (breadcrumbs[i] && breadcrumbs[i]._id !== action.payload._id) {
          newBreadcrumbs.push(breadcrumbs[i]);
          i++;
        }
        newBreadcrumbs.push(action.payload);
        breadcrumbs = newBreadcrumbs;
      } else {
        breadcrumbs = [];
      }

      return {
        ...state,
        breadcrumbs,
        loading: true,
        data: {
          ...state.data,
          currentPage: 1,
          totalPage: 1,
          total: 0,
          items: state.data?.items,
          hasMore: false,
          currentFolder: action.payload,
        } as PaginatedMedia,
      };

    case GO_BACK_FOLDER:
      const gbBreadcrumbs: Media[] = [];
      state.breadcrumbs?.map((breadcrumb: Media) => {
        if (breadcrumb._id.toString() !== action.payload._id.toString()) {
          gbBreadcrumbs.push(breadcrumb);
        }
      });
      return {
        ...state,
        breadcrumbs: gbBreadcrumbs,
        data: {
          ...state.data,
          currentPage: state.data?.currentPage || 1,
          totalPage: state.data?.totalPage || 1,
          total: state.data?.total || 0,
          currentFolder: state?.breadcrumbs ? state?.breadcrumbs[state.breadcrumbs?.length - 2] : null,
        },
      };

    case PREVIEW_MEDIA:
      return {
        ...state,
        previewItem: action.payload,
      };
    case DELETE_MEDIA_ITEM:
      return {
        ...state,
        deleteItem: action.payload,
      };
    case MOVE_FILE_ITEM:
      return {
        ...state,
        moveItem: action.payload,
      };
    default:
      return {
        ...state,
        // loading: false,
      };
  }
};
