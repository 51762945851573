import { FrameData } from '@modules/PlayList/types';

export const UPDATE_FRAME = 'UPDATE_FRAME';
export const UPDATE_FRAMES_ORDER = 'UPDATE_FRAMES_ORDER';

export interface UpdateFrameAction {
  type: typeof UPDATE_FRAME;
  payload: FrameData;
}

export interface UpdateFramesOrderAction {
  type: typeof UPDATE_FRAMES_ORDER;
  payload: FrameData[];
}

export type UpdateFrameActionTypes = UpdateFrameAction | UpdateFramesOrderAction;
