import { Media, MoveMediaInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const MOVE_FILE = 'MOVE_FILE';
export const MOVE_FILE_SUCCESS = 'MOVE_FILE_SUCCESS';
export const MOVE_FILE_ERROR = 'MOVE_FILE_ERROR';

export interface MoveFileAction {
  type: typeof MOVE_FILE;
  payload: MoveMediaInput;
}

export interface MoveFileActionSuccess {
  type: typeof MOVE_FILE_SUCCESS;
  payload?: Media;
}

export interface MoveFileActionError {
  type: typeof MOVE_FILE_ERROR;
  payload: AppError;
}

export type MoveFileActionTypes = MoveFileAction | MoveFileActionSuccess | MoveFileActionError;
