import { useDispatch } from 'react-redux';
import { logout } from '@modules/Auth/redux/actions';
import { useCallback } from 'react';
const useLogout = () => {
  const dispatch = useDispatch();
  const onLogout = useCallback(
    (token: string) => {
      dispatch(logout({ token }));
    },
    [dispatch],
  );
  return {
    onLogout,
  };
};
export default useLogout;
