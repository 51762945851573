import { Roles, User } from '@/graphql/generated/graphql';
import ButtonDeleteRow from '@commons/components/ButtonRowActions/ButtonDeleteRed';
import ButtonEditRow from '@commons/components/ButtonRowActions/ButtonEditBlue';
import ButtonResetIcon from '@commons/components/ButtonRowActions/ButtonResetIcon';
// import { Space } from 'antd';
import * as React from 'react';
import UpdateStatus from './UpdateStatus';

interface UserRowActionProp {
  record: User;
  onEdit?(pck: User): void;
  onDelete?(pck: User): void;
  onResetPassword?(pck: User): void;
}

export default function UserRowAction(props: UserRowActionProp) {
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="d-flex justify-content-end align-items-center"
      style={{ opacity: props.record?.role === Roles.SuperAdmin ? 0 : 1 }}
    >
      <UpdateStatus record={props.record} className="ml-0" />
      <ButtonResetIcon
        onClick={(e) => {
          // tslint:disable-next-line:no-unused-expression
          props.onResetPassword && props.onResetPassword(props.record);
          return e.stopPropagation();
        }}
      />
      <ButtonEditRow
        onClick={(e) => {
          // tslint:disable-next-line:no-unused-expression
          props.onEdit && props.onEdit(props.record);
          e.stopPropagation();
        }}
      />
      <ButtonDeleteRow
        onClick={(e) => {
          // tslint:disable-next-line:no-unused-expression
          props.onDelete && props.onDelete(props.record);
          e.stopPropagation();
        }}
      />
    </div>
  );
}
