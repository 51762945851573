import { Media, UploadFileMutationVariables } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';

export interface UploadFileAction {
  type: typeof UPLOAD_FILE;
  payload: UploadFileMutationVariables;
}

export interface UploadFileActionSuccess {
  type: typeof UPLOAD_FILE_SUCCESS;
  payload?: Media;
}

export interface UploadFileActionError {
  type: typeof UPLOAD_FILE_ERROR;
  payload: AppError;
}

export type UploadFileActionTypes = UploadFileAction | UploadFileActionSuccess | UploadFileActionError;
