import AuthLayout from '@commons/components/layouts/Auth';

import { setTitle } from '@helpers/dom';
import React, { useEffect } from 'react';
import ResetPasswordForm from './components/index';
import useResetPassword from '@modules/Auth/hooks/useResetPassword';

function ForgotPasswordPage() {
  const { loading, onSending } = useResetPassword();
  useEffect(() => {
    setTitle('Đổi mật khẩu');
  }, []);

  return (
    <AuthLayout>
      <ResetPasswordForm onRequest={onSending} loading={loading} />
    </AuthLayout>
  );
}

export default ForgotPasswordPage;
