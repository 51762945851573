import { getSDK } from '@/graphql/client';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

export default function useAdminChangeScreenStatus() {
  const queryClient = useQueryClient();
  const { t } = useTranslation(['device']);

  return useMutation(getSDK().adminChangeScreenStatus, {
    onSuccess: (data, variables, context) => {
      const messageData = {
        message: t('change_screen_status_success'),
      };

      const globalOnSuccess = queryClient.getDefaultOptions().mutations?.onSuccess;
      // tslint:disable-next-line: no-unused-expression
      globalOnSuccess && globalOnSuccess(messageData, variables, context);
    },
  });
}
