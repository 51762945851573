import { AdminDeviceScreenStatus, Device, DirectionRotateType } from '@/graphql/generated/graphql';
import queryClient from '@/query-client';
import ButtonCustomModal from '@commons/components/modals/Button/ButtonModalCustom';
import useAdminChangeScreenStatus from '@modules/Device/hooks/useAdminChangeScreenStatus';
import useDetailDevice2 from '@modules/Device/hooks/useDetailDevice2';
import useRequestReloadApp from '@modules/Device/hooks/useRequestReloadApp';
import useRotateScreen from '@modules/Device/hooks/useRotateScreen';
import { useDeviceSubscription } from '@modules/Device/services/apis';
import { GET_ALL_DEVICE } from '@modules/Device/types';

import { Empty, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import RotateBtn from '../Action/RotateBtn';
import ScreenStatusBtn from '../Action/ScreenStatusBtn';
import useRequestContinuousScreenshots from '@modules/Device/hooks/useRequestContinuousScreenshots';
import useRequestCaptureScreenshot from '@modules/Device/hooks/useRequestCaptureScreen';

interface IProps {
  onCancel: () => void;
  deviceData?: Device;
}

function DeviceInfoModal(props: IProps) {
  const { t } = useTranslation(['device']);
  const { mutate: sendRequestCaptureScreen, isLoading: loadingTake } = useRequestCaptureScreenshot();
  const { requestContinuousScreenshots, cancelRequestContinuousScreenshots } = useRequestContinuousScreenshots();
  const { mutate: onRotateScreen, isLoading: rotateLoading } = useRotateScreen();
  const { mutate: onChangeScreenStatus, isLoading: changeScreenStatusLoading } = useAdminChangeScreenStatus();
  const { mutate: requestReloadApp, isLoading: loadingReload } = useRequestReloadApp();
  const { data: detailDevice, refetch, isSuccess: getDetailSuccess } = useDetailDevice2(props.deviceData?._id);

  const { screenshotUrl: lastScreenshot, lastModified, subscribe, forceUnsubscribe } = useDeviceSubscription(
    detailDevice && detailDevice.device ? (detailDevice.device as Device) : undefined,
  );

  const [direction, setDirection] = useState<DirectionRotateType | null>(
    props.deviceData?.direction || DirectionRotateType.Normal,
  );
  const [adminScreenStatus, setAdminScreenStatus] = useState<AdminDeviceScreenStatus | null>(
    props.deviceData?.adminScreenStatus || AdminDeviceScreenStatus.Auto,
  );
  const [imageLoading, setImageLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState(lastScreenshot);
  const [isCapturing, setCapturing] = useState(false);

  const onContinuousScreenshots = () => {
    if (props.deviceData) {
      if (!isCapturing) {
        subscribe();
        requestContinuousScreenshots(props.deviceData._id);
        setCapturing(true);
      } else {
        setCapturing(false);
        cancelRequestContinuousScreenshots();
        forceUnsubscribe();
      }
    }
  };

  const onTakeScreenshot = () => {
    setCapturing(false);
    subscribe();
    cancelRequestContinuousScreenshots();
    if (props.deviceData) {
      sendRequestCaptureScreen({
        input: {
          _id: props.deviceData._id,
        },
      });
    }
  };

  const onCancel = () => {
    props.onCancel();
    cancelRequestContinuousScreenshots();
  };

  const onRefreshApp = () => {
    if (props.deviceData) {
      requestReloadApp({
        input: {
          _id: props.deviceData._id,
        },
      });
    }
  };

  useEffect(() => {
    if (detailDevice && detailDevice.device && detailDevice.device.status && lastScreenshot) {
      queryClient.invalidateQueries([GET_ALL_DEVICE]);
    }
    // eslint-disable-next-line
  }, [lastScreenshot]);

  useEffect(() => {
    if (props.deviceData?._id) {
      setImageSrc(props.deviceData.lastScreenshot);
      refetch();
    }
    return () => {
      setImageSrc('');
    };
    // eslint-disable-next-line
  }, [props.deviceData?._id]);

  useEffect(() => {
    if (getDetailSuccess && props.deviceData) {
      setDirection(detailDevice?.device?.direction || DirectionRotateType.Normal);
      setAdminScreenStatus(detailDevice?.device?.adminScreenStatus || AdminDeviceScreenStatus.Auto);
    }
    // eslint-disable-next-line
  }, [getDetailSuccess, detailDevice]);

  const onRotate = (id: string, direction: DirectionRotateType) => {
    onRotateScreen({
      input: {
        _id: id,
        direction,
      },
    });
    setDirection(direction);
  };

  const onAdminChangeScreenStatus = (id: string, status: AdminDeviceScreenStatus) => {
    onChangeScreenStatus({
      input: {
        _id: id,
        status,
      },
    });
    setAdminScreenStatus(status);
  };

  useEffect(() => {
    if (!imageLoading && isCapturing && lastScreenshot) {
      setImageLoading(true);
      const img = new Image();

      img.onload = () => {
        setImageLoading(false);
        setImageSrc(img.src);
      };

      img.onerror = () => {
        setImageLoading(false);
      };

      img.src = lastScreenshot;
    }
    // eslint-disable-next-line
  }, [lastScreenshot, isCapturing, imageLoading]);

  return (
    <Modal
      centered
      forceRender
      visible={!!props.deviceData}
      cancelText={t('cancel')}
      onCancel={() => onCancel()}
      className="modal_confirm_custom modal_device_info_custom"
      closeIcon={<MdClose />}
      title={
        <div className="text-center">
          <span>
            {t('device_screen')}: {props.deviceData?.name}
          </span>
        </div>
      }
      destroyOnClose={true}
      footer={[
        <div key="custom_footer_modal" className="custom_footer_modal gap-3">
          <div className="w-100 mb-2">
            <div className="d-flex justify-content-end ">
              <strong className="mr-2">{t('capturedAt')}</strong> {lastModified}
            </div>
            <div>
              <ButtonCustomModal
                key="btn_take_screenshot"
                onClick={onTakeScreenshot}
                title={t('take_screenshot')}
                bg_color="#00BE39"
                // classNameBtn="w-50"
                isLoading={loadingTake}
              />
              <ButtonCustomModal
                key="btn_switch_mode"
                onClick={onContinuousScreenshots}
                title={isCapturing ? t('stop_view_screen') : t('view_screen')}
                // classNameBtn="w-50"
                // isLoading={videoReady}
                // bg_color="#ffB0E19"
              />
            </div>
          </div>
          <div className="d-flex justify-content-between gap-3 w-100">
            <ScreenStatusBtn
              onChangeScreenStatus={onAdminChangeScreenStatus}
              recordId={detailDevice?.device?._id}
              loading={changeScreenStatusLoading}
              status={adminScreenStatus}
            />
            <RotateBtn
              onRotate={onRotate}
              recordId={detailDevice?.device?._id}
              direction={direction}
              loading={rotateLoading}
            />
            <ButtonCustomModal
              key="btn_refresh_app"
              onClick={onRefreshApp}
              title={t('refresh_app')}
              classNameBtn="btn_refresh_app"
              bg_color="#0072F5"
              isLoading={loadingReload}
            />
          </div>
        </div>,
      ]}
    >
      <div className="d-flex w-100  justify-content-center align-items-center">
        <div
          className="h-50vh"
          style={{
            width: imageSrc ? 'auto' : '100%',
            border: '2px solid #7e96cd',
            padding: '2px',
          }}
        >
          {imageSrc ? (
            <img src={imageSrc} alt={props.deviceData?.name} className="h-100" />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      </div>
    </Modal>
  );
}

export default React.memo(DeviceInfoModal, (prevProps, nextProps) => {
  return prevProps.deviceData?._id.toString() === nextProps.deviceData?._id.toString();
});
