import { Media, UploadFileMutationVariables } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { UPLOAD_FILE, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_ERROR, UPDATE_FILE_SUCCEED } from '../action-types';

export const uploadFileAction = (payload: UploadFileMutationVariables) => ({
  type: UPLOAD_FILE,
  payload,
});

export const uploadFileActionSuccess = (payload?: Media) => ({
  type: UPLOAD_FILE_SUCCESS,
  payload,
});

export const uploadFileActionSucceed = (payload?: Media) => ({
  type: UPDATE_FILE_SUCCEED,
  payload,
});

export const uploadFileActionError = (payload: AppError) => ({
  type: UPLOAD_FILE_ERROR,
  payload,
});
