import { Jwt, LoginInput, User } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { UPDATE_PROFILE_SUCCESS } from '../action-types';
import {
  LOGIN,
  LoginActionTypes,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  SUPPER_ADMIN_AUTO_LOGIN,
  SUPPER_ADMIN_AUTO_LOGIN_SUCCESS,
  VerifyTokenAutoLoginInput,
  VERIFY_TOKEN_AUTO_LOGIN,
} from '../action-types/login';

export const login = (payload: LoginInput): LoginActionTypes => ({
  type: LOGIN,
  payload,
});

export const loginError = (payload: AppError): LoginActionTypes => ({
  type: LOGIN_ERROR,
  payload,
});

export const loginSuccess = (payload: Jwt): LoginActionTypes => ({
  type: LOGIN_SUCCESS,
  payload,
});

export const supperAdminAutoLogin = (payload: string): LoginActionTypes => ({
  type: SUPPER_ADMIN_AUTO_LOGIN,
  payload,
});

export const supperAdminAutoLoginSuccess = (payload: string): LoginActionTypes => ({
  type: SUPPER_ADMIN_AUTO_LOGIN_SUCCESS,
  payload,
});

export const onVerifyTokenAutoLogin = (payload: VerifyTokenAutoLoginInput): LoginActionTypes => ({
  type: VERIFY_TOKEN_AUTO_LOGIN,
  payload,
});

export const updateProfileLoginSuccess = (payload: User): LoginActionTypes => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload,
});
