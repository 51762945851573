import { Media, UpdateMediaInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const UPDATE_FILE = 'UPDATE_FILE';
export const UPDATE_FILE_SUCCESS = 'UPDATE_FILE_SUCCESS';
export const UPDATE_FILE_ERROR = 'UPDATE_FILE_ERROR';
export const UPDATE_FILE_SUCCEED = 'UPDATE_FILE_SUCCEED';
export const UPDATE_UPDATE_AT_FOLDER = 'UPDATE_UPDATE_AT_FOLDER';

export interface UpdateUpdateAtFolderInput {
  id: string;
  updatedAt: number;
}

export interface UpdateFileAction {
  type: typeof UPDATE_FILE;
  payload: UpdateMediaInput;
}

export interface UpdateFileActionSuccess {
  type: typeof UPDATE_FILE_SUCCESS;
  payload?: Media;
}

export interface UpdateFileActionSucceed {
  type: typeof UPDATE_FILE_SUCCEED;
  payload?: Media;
}

export interface UpdateFileActionError {
  type: typeof UPDATE_FILE_ERROR;
  payload: AppError;
}

export interface UpdateUpdateAtFolderAction {
  type: typeof UPDATE_UPDATE_AT_FOLDER;
  payload: UpdateUpdateAtFolderInput;
}

export type UpdateFileActionTypes =
  | UpdateFileAction
  | UpdateFileActionSuccess
  | UpdateFileActionSucceed
  | UpdateUpdateAtFolderAction
  | UpdateFileActionError;
