/* eslint-disable react-hooks/exhaustive-deps */
import { FileType, Media, MediaStatus } from '@/graphql/generated/graphql';
import { RollbackOutlined } from '@ant-design/icons';
import Loading from '@commons/components/Loading';
import { useCustomSelector } from '@hooks/useCustomSelector';
import useDeleteMediaItem from '@modules/Media/hooks/useDeleteMediaItem';
import useGetAllMedia from '@modules/Media/hooks/useGetAllMedia';
import { uploadFileActionSucceed } from '@modules/Media/redux/actions';
import { useMediaSubscriptionApi } from '@modules/Media/services/apis';
import { pageDefault } from '@modules/Media/types';
import { Empty } from 'antd';
import React, { Fragment, MouseEvent, Ref, useEffect, useImperativeHandle, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { TypeViewMedia } from '../../pages/index';
import MediaItemComponent from './media-item-grid.component';
import MediaItemListComponent from './media-item-list.component';

interface IFProps {
  onUpdateMediaInfo?: (media?: Media) => void;
  onSelectedMedias?: (values: Media[] | []) => void;
  isProvider: boolean;
  multiple: boolean;
  selectedTaskIds?: String[];
  setDataItem?: (media: Media[]) => void;
  onGoBack: () => void;
  filterType: any;
  typeView: TypeViewMedia;
  playlistId?: string;
}

const ContainerProvider = (props: any) => {
  const { e, i, mediaInfo, onShowMenuMedia, onSelectMedia, indexSelect } = props;
  const { item } = useDeleteMediaItem();
  if (props.isProvider) {
    return (
      <Draggable
        key={e._id}
        isDragDisabled={
          e.type === FileType.Folder || e.status === MediaStatus.Processing || e.status === MediaStatus.Failed
        }
        draggableId={e._id}
        index={i}
      >
        {(provided, snapshot: any) => (
          <Fragment key={i}>
            <div
              className="div-class-media"
              ref={provided?.innerRef}
              {...provided?.draggableProps}
              {...provided?.dragHandleProps}
              style={{
                ...provided?.draggableProps?.style,
                transform: snapshot.isDragging ? provided?.draggableProps?.style?.transform : '',
              }}
            >
              <MediaItemComponent
                typeView={props.typeView}
                isShowMenu={!item && e._id === mediaInfo?._id}
                item={e}
                onShowMenu={onShowMenuMedia}
                onSelectMedia={onSelectMedia}
                isChecked={indexSelect > -1}
              />
            </div>
            {snapshot?.isDragging && (
              <div>
                <MediaItemComponent
                  typeView={props.typeView}
                  isShowMenu={!item && e._id === mediaInfo?._id}
                  item={e}
                  onShowMenu={onShowMenuMedia}
                  onSelectMedia={onSelectMedia}
                  isChecked={indexSelect > -1}
                />
              </div>
            )}
          </Fragment>
        )}
      </Draggable>
    );
  }
  return (
    <Fragment key={i}>
      <MediaItemComponent
        typeView={props.typeView}
        isShowMenu={!item && e._id === mediaInfo?._id}
        item={e}
        onShowMenu={onShowMenuMedia}
        onSelectMedia={onSelectMedia}
        isChecked={indexSelect > -1}
      />
    </Fragment>
  );
};

const ContainerProviderItem2 = (props: any) => {
  const { e, i, mediaInfo, onShowMenuMedia, onSelectMedia, indexSelect } = props;
  const { item } = useDeleteMediaItem();
  if (props.isProvider) {
    return (
      <Draggable
        key={e._id}
        isDragDisabled={
          e.type === FileType.Folder || e.status === MediaStatus.Processing || e.status === MediaStatus.Failed
        }
        draggableId={e._id}
        index={i}
      >
        {(provided, snapshot: any) => (
          <Fragment key={i}>
            <div
              ref={provided?.innerRef}
              {...provided?.draggableProps}
              {...provided?.dragHandleProps}
              style={{
                ...provided?.draggableProps?.style,
                transform: snapshot.isDragging ? provided?.draggableProps?.style?.transform : '',
              }}
            >
              <MediaItemListComponent
                typeView={props.typeView}
                isShowMenu={!item && e._id === mediaInfo?._id}
                item={e}
                onShowMenu={onShowMenuMedia}
                onSelectMedia={onSelectMedia}
                isChecked={indexSelect > -1}
              />
            </div>
            {snapshot?.isDragging && (
              <div>
                <MediaItemListComponent
                  typeView={props.typeView}
                  isShowMenu={!item && e._id === mediaInfo?._id}
                  item={e}
                  onShowMenu={onShowMenuMedia}
                  onSelectMedia={onSelectMedia}
                  isChecked={indexSelect > -1}
                />
              </div>
            )}
          </Fragment>
        )}
      </Draggable>
    );
  }
  return (
    <Fragment key={i}>
      <MediaItemListComponent
        typeView={props.typeView}
        isShowMenu={!item && e._id === mediaInfo?._id}
        item={e}
        onShowMenu={onShowMenuMedia}
        onSelectMedia={onSelectMedia}
        isChecked={indexSelect > -1}
      />
    </Fragment>
  );
};

export interface IFRefMediaComponent {
  onResetSelectMedia: () => void;
  dataItem: Media[];
}

function MediaView(props: IFProps, ref: Ref<IFRefMediaComponent>) {
  const [mediaInfo, setMediaInfo] = React.useState<Media | undefined>(undefined);
  const [mediasSelected, setMediasSelected] = React.useState<Media[]>([]);
  const { loading } = useGetAllMedia();
  const dispatch = useDispatch();

  const mediasData = useCustomSelector((state) => state.media.list.data);

  const mediaProcess = useMediaSubscriptionApi();

  // eslint-disable-next-line
  const [paginate] = useState({
    page: pageDefault.page,
    pageSize: pageDefault.pageSize,
  });

  const { onGetMedias } = useGetAllMedia();

  React.useEffect(() => {
    props?.onUpdateMediaInfo?.(getInfoMedia());
    // eslint-disable-next-line
  }, [mediaInfo, mediasSelected]);

  useEffect(() => {
    if (mediaProcess) {
      setTimeout(() => dispatch(uploadFileActionSucceed(mediaProcess)), 500);
    }
    // eslint-disable-next-line
  }, [mediaProcess]);

  useImperativeHandle(
    ref,
    () => ({
      onResetSelectMedia,
      dataItem: mediasData?.items || [],
    }),
    [mediasData],
  );

  useEffect(() => {
    if (mediasData?.items) {
      props.setDataItem?.(mediasData?.items);
    }
    return () => {};
  }, [mediasData]);

  function onResetSelectMedia() {
    setMediaInfo(undefined);
  }

  function onShowMenuMedia(media: Media) {
    setMediaInfo(media);
  }

  const onSelectMedia = (media: Media, event: MouseEvent) => {
    if (media.status !== MediaStatus.Done) return;
    if ((!event.ctrlKey && !event.metaKey) || !props.multiple) {
      if (mediasSelected.length === 1 && media._id.toString() === mediasSelected[0]._id.toString()) {
        setMediasSelected([]);
        if (props.onSelectedMedias) {
          props.onSelectedMedias([]);
        }
      } else {
        setMediasSelected([media]);
        if (props.onSelectedMedias) {
          props.onSelectedMedias([media]);
        }
      }
      return;
    }

    let data = [...mediasSelected];

    const objIndex = data.findIndex((item: Media) => item._id === media._id);

    if (objIndex > -1) {
      data = data.filter((e) => e._id !== media._id);
    } else {
      data.push(media);
    }
    setMediasSelected(data);
    if (props.onSelectedMedias) {
      props.onSelectedMedias(data);
    }
  };

  function getInfoMedia(): Media | undefined {
    const obj = mediasSelected?.[mediasSelected.length - 1] || undefined;
    return mediaInfo || obj || undefined;
  }

  const fetchData = () => {
    if (mediasData) {
      const _lastMedia: null | undefined | Media = mediasData?.items && mediasData?.items[mediasData.items.length - 1];
      if (_lastMedia && mediasData?.hasMore) {
        onGetMedias({
          afterId: _lastMedia?._id.toString(),
          limit: pageDefault.pageSize,
          isLoadMore: true,
          filterType: props.filterType ? props.filterType : null,
        });
      }
    }
  };

  return (
    <>
      <div>
        {loading && <Loading type="modal" />}
        {!mediasData?.items?.length ? (
          <div className="d-flex justify-content-center w-100">
            <Empty />
          </div>
        ) : (
          <InfiniteScroll
            className="uk-animation-fade"
            dataLength={mediasData?.items?.length + 1}
            next={fetchData}
            hasMore={!!mediasData?.hasMore}
            loader={<h4>Loading...</h4>}
            height={'100vh'}
            style={{ paddingBottom: 20 }}
          >
            {props.typeView === TypeViewMedia.GRID ? (
              <div className="media-grid-content">
                {mediasData?.currentFolder && (
                  <div
                    className="media-item"
                    onDoubleClick={() => {
                      props.onGoBack();
                      onResetSelectMedia();
                    }}
                  >
                    <div className={`media-image`}>
                      <RollbackOutlined style={{ fontSize: 50 }} />
                    </div>
                    <div className="viewFileName">...</div>
                  </div>
                )}
                {mediasData?.items?.map((e: Media, i: number) => {
                  const indexSelect = props?.selectedTaskIds
                    ? props?.selectedTaskIds?.findIndex((j) => j === e._id)
                    : mediasSelected.findIndex((j: Media) => j._id === e._id);
                  return (
                    <ContainerProvider
                      e={e}
                      key={e._id}
                      i={i}
                      isProvider={props?.isProvider}
                      mediaInfo={mediaInfo}
                      onShowMenuMedia={props?.isProvider ? undefined : onShowMenuMedia}
                      onSelectMedia={onSelectMedia}
                      indexSelect={indexSelect}
                    />
                  );
                })}
              </div>
            ) : (
              <div className="media-list-content">
                {mediasData?.currentFolder && (
                  <div className="media-item" onDoubleClick={() => props.onGoBack()}>
                    <div className={`media-image`}>
                      <RollbackOutlined style={{ fontSize: 50 }} />
                    </div>
                    <div className="viewFileName">...</div>
                  </div>
                )}
                {mediasData?.items?.map((e: Media, i: number) => {
                  const indexSelect = props?.selectedTaskIds
                    ? props?.selectedTaskIds?.findIndex((j) => j === e._id)
                    : mediasSelected.findIndex((j: Media) => j._id === e._id);
                  return (
                    <ContainerProviderItem2
                      e={e}
                      i={i}
                      key={e._id}
                      isProvider={props?.isProvider}
                      mediaInfo={mediaInfo}
                      onShowMenuMedia={props?.isProvider ? undefined : onShowMenuMedia}
                      onSelectMedia={onSelectMedia}
                      indexSelect={indexSelect}
                    />
                  );
                })}
              </div>
            )}
          </InfiniteScroll>
        )}
      </div>
    </>
  );
}

const MediaComponent = React.memo(React.forwardRef(MediaView));

export default MediaComponent;
