import { Group } from '@/graphql/generated/graphql';
import ButtonDeleteRed from '@commons/components/ButtonRowActions/ButtonDeleteRed';
import ButtonEditBlue from '@commons/components/ButtonRowActions/ButtonEditBlue';
import ButtonGroupMessageSetting from '@commons/components/ButtonRowActions/ButtonGroupMessageSetting';
// import ButtonGroupSetting from '@commons/components/ButtonRowActions/ButtonGroupSetting';
import ButtonMoreGray from '@commons/components/ButtonRowActions/ButtonMoreGray';
import ButtonScheduleGreen from '@commons/components/ButtonRowActions/ButtonScheduleGreen';
import { LoginState } from '@modules/Auth/redux/action-types';
import { RootState } from '@redux/reducers';
import { Dropdown, Menu } from 'antd';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

interface GroupRowActionProp {
  record: Group;
  onEdit?(pck: Group): void;
  onDelete?(pck: Group): void;
}

export default function GroupRowAction(props: GroupRowActionProp) {
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);

  const history = useHistory();

  const menu = (
    <Menu className="dropdown_more_row_actions">
      {loginState.data?.companyId && (
        <>
          <Menu.Item key={'group_message'}>
            <ButtonGroupMessageSetting group={props.record} />
          </Menu.Item>
          {/* <Menu.Item key={'group_setting'}>
            <ButtonGroupSetting group={props.record} />
          </Menu.Item> */}
        </>
      )}
    </Menu>
  );

  return (
    <div onClick={(e) => e.stopPropagation()} className={`d-flex justify-content-end`}>
      <ButtonEditBlue
        onClick={() => {
          return props.onEdit && props.onEdit(props.record);
        }}
      />
      {loginState.data?.companyId && (
        <>
          <ButtonScheduleGreen
            onClick={() => {
              history.push(`/group-device/schedule/${props.record._id}`);
            }}
          />
        </>
      )}
      <ButtonDeleteRed
        onClick={() => {
          return props.onDelete && props.onDelete(props.record);
        }}
      />
      <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
        <ButtonMoreGray onClick={() => {}} />
      </Dropdown>
    </div>
  );
}
