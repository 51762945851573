import { Media } from '@/graphql/generated/graphql';
import { MediaFileType } from '@modules/Media/pages/components/media-item-grid.component';
import { Tooltip } from 'antd';
import { truncate } from 'lodash';
import React from 'react';

interface IPreview {
  data: Media[];
  MediaPreview?: Media;
  setPreviewMediaItem?: (item: Media) => void;
}

function PreviewRight(props: IPreview) {
  const { data, MediaPreview } = props;

  const onChangeFrame = (item: Media, index: number) => () => {
    props.setPreviewMediaItem?.({ ...item, _id: item._id + index });
  };

  return (
    <div className="preview-right">
      {data.map((item: any, index) => {
        return (
          <div
            key={item._id + index + new Date().getMilliseconds()}
            className="media-right-playing"
            style={{ background: MediaPreview?._id === item._id + index ? '#D5E9FF' : 'white' }}
            onClick={onChangeFrame(item, index)}
          >
            <div className={`media-image`}>
              <MediaFileType media={item} />
            </div>
            <Tooltip title={item?.filename || item?.webUrl || 'filename'}>
              <div
                style={{
                  color: MediaPreview?._id === item._id ? '#0072F5' : '#333333',
                  fontWeight: MediaPreview?._id === item._id ? 'bold' : 'normal',
                }}
                className="media-item-title"
              >
                {truncate(item?.filename || item?.webUrl || '', { length: 30 })}
              </div>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
}
export default React.memo(PreviewRight);
