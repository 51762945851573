import ini18 from '@/i18n';
import { notification } from 'antd';

export const NotificationInfo = (title: string, message: string) => {
  notification.info({
    message: title,
    description: message,
    placement: 'topRight',
  });
};

export const NotificationSuccess = (title: string, message: string) => {
  notification.success({
    message: ini18.t(title),
    description: ini18.t(message),
    placement: 'topRight',
    duration: 3,
  });
};

export const NotificationError = (title: string, message: string) => {
  notification.error({
    message: ini18.t(title),
    description: ini18.t(message),
    placement: 'topRight',
  });
};

export const NotificationWarning = (title: string, message: string) => {
  notification.warning({
    message: ini18.t(title),
    description: ini18.t(message),
    placement: 'topRight',
  });
};
