import { getSDK } from '@/graphql/client';
import { QueryCompaniesInput } from '@/graphql/generated/graphql';
import { LoginState } from '@modules/Auth/redux/action-types';
import { RootState } from '@redux/reducers';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { GET_ALL_COMPANY } from '../types';

export default function useGetAllCompany(params: QueryCompaniesInput) {
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);
  return useQuery([GET_ALL_COMPANY, params], async () => getSDK().getListCompany({ input: params }), {
    enabled: loginState.data?.isSuperAdmin ? true : false,
  });
}
