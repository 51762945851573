import { getSDK, appSubscription } from '@/graphql/client';
import {
  CreateFolderInput,
  DeleteMediaInput,
  MediaProcessDocument,
  MoveMediaInput,
  QueryMediasInput,
  UpdateMediaInput,
  UploadFileMutationVariables,
  Media,
} from '@/graphql/generated/graphql';
import { useEffect, useState } from 'react';

export const uploadFileApi = async (payload: UploadFileMutationVariables) => {
  return new Promise((resolve, reject) => {
    getSDK()
      .uploadFile(payload)
      .then((data) => {
        resolve(data.uploadFile);
      })
      .catch((error) => reject(error?.response?.errors[0]));
  });
};

export const getMediasApi = async (payload: QueryMediasInput) => {
  return new Promise((resolve, reject) => {
    getSDK()
      .listMedia({ input: payload })
      .then((data) => {
        resolve(data.medias);
      })
      .catch((error) => reject(error?.response?.errors[0]));
  });
};

export const createFolderApi = async (payload: CreateFolderInput) => {
  return new Promise((resolve, reject) => {
    getSDK()
      .createFolder({ input: payload })
      .then((data) => {
        resolve(data.createFolder);
      })
      .catch((error) => reject(error?.response?.errors[0]));
  });
};

export const updateFileApi = async (payload: UpdateMediaInput) => {
  return new Promise((resolve, reject) => {
    getSDK()
      .updateFile({ input: payload })
      .then((data) => {
        resolve(data.updateFile);
      })
      .catch((error) => reject(error?.response?.errors[0]));
  });
};

export const deleteMediaApi = async (payload: DeleteMediaInput) => {
  return new Promise((resolve, reject) => {
    getSDK()
      .deleteFile({ input: payload })
      .then((data) => {
        resolve(data.deleteFile);
      })
      .catch((error) => reject(error?.response?.errors[0]));
  });
};

export const useMediaSubscriptionApi = (): Media | undefined => {
  const [data, setData] = useState(undefined);
  useEffect(() => {
    const subscription = appSubscription(MediaProcessDocument);
    const listener = subscription.subscribe(async ({ data }: { data: any }) => {
      setData(data.mediaProcess);
    });
    return () => {
      if (listener) {
        listener.unsubscribe();
      }
    };
  }, []);
  return data;
};

export const moveFileApi = async (payload: MoveMediaInput) => {
  return new Promise((resolve, reject) => {
    getSDK()
      .moveFile({ input: payload })
      .then((data) => {
        resolve(data.moveFile);
      })
      .catch((error) => reject(error?.response?.errors[0]));
  });
};
