import { PlusOutlined } from '@ant-design/icons';
import useSetAppLayout from '@modules/App/hooks/useSetAppLayout';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onClick: () => void;
  className?: string;
  isLoading?: boolean;
  title?: string;
}

const BtnAddNew = (props: Props) => {
  const { layoutSize } = useSetAppLayout();
  const { t } = useTranslation(['button']);

  return (
    <Button
      icon={<PlusOutlined />}
      className={`btn-dangerous btn-add-new ${props.className}`}
      onClick={props.onClick}
      size={layoutSize}
    >
      {props.title ? props.title : t('add_new')}
    </Button>
  );
};

export default BtnAddNew;
