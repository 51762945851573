/* eslint-disable react-hooks/exhaustive-deps */
import { Playlist } from '@/graphql/generated/graphql';
import { PlusOutlined } from '@ant-design/icons';
import ModalConfirm from '@commons/components/modals/ModalConfirm';
import TableLayout from '@commons/components/TableLayout';
import TableWithNumber from '@commons/components/TableWithNumber';
import { FULL_DATE } from '@helpers/date';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { LoginState } from '@modules/Auth/redux/action-types';
import { PageSizeTable } from '@modules/Company/types';
import useUpdatePlaylistToOtherGroup from '@modules/GroupDevice/hooks/useUpdatePlaylistToOtherGroup';
import PlayListRowAction from '@modules/PlayList/components/Action/PlayListRowAction';
import ModalAssignGroup, { SetOtherGroupI } from '@modules/PlayList/components/assigngroup/AssignGroupModal';
import PreviewPlaylist from '@modules/PlayList/components/Preview/PreviewPlaylist';
import useDeletePlaylist from '@modules/PlayList/hooks/useDeletePlaylist';
import useGetAllPlaylist from '@modules/PlayList/hooks/useGetAllPlaylist';
import { PlaylistsFilter } from '@modules/PlayList/types';
import { RootState } from '@redux/reducers';
import { Button, Form, Input, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import TextTruncate from 'react-text-truncate';

interface IState {
  deletePlaylistData?: Playlist;
  publishPlaylistData?: Playlist;
  draftPlaylistData?: Playlist;
  rejectPlaylistData?: Playlist;
}

export default function PlaylistsPage() {
  const { t } = useTranslation(['playlist']);

  const { setPageTitle } = useSetPageTitle();

  const { mutate, isSuccess } = useUpdatePlaylistToOtherGroup();

  const [state, setState] = useState<IState>({
    deletePlaylistData: undefined,
  });

  const { mutate: deletePlaylist, isLoading: deleteLoading } = useDeletePlaylist();

  const [DataMediaPreview, setMediaPreview] = useState<any[]>([]);

  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);

  const history = useHistory();

  const rerefPreviewf = useRef<any>();

  const refAssignGroup = useRef<any>();

  const columns: ColumnsType<any> = [
    {
      title: t('stt'),
      width: 80,
      align: 'center',
      render: (value: any, record, index) => {
        return (data?.playlists?.currentPage || 1) <= 1
          ? index + 1
          : 10 * ((data?.playlists?.currentPage || 1) - 1) + (index + 1);
      },
    },
    {
      title: t('name'),
      key: 'name',
      dataIndex: 'name',
      width: 200,
      render: (value: any, record) => (
        <Tooltip title={value || 'name'}>
          <TextTruncate
            containerClassName="w-100 d-block truncate-text"
            line={2}
            element="span"
            truncateText="…"
            text={value}
          />
        </Tooltip>
      ),
    },
    {
      title: t('created_date'),
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 120,
      render: (createdAt) => {
        return moment(createdAt).format(FULL_DATE);
      },
    },
    {
      title: t('assignend_group'),
      key: 'group',
      dataIndex: 'group',
      width: 150,
      render: (group) => {
        return group?.name || 'N/A';
      },
    },
    // {
    //   title: t('assignend_group'),
    //   key: 'screenType',
    //   dataIndex: 'screenType',
    //   width: 150,
    //   render: (screenType) => {
    //     const getLabel = PlaylistScreenTypeArray.find((item) => item.key === screenType);
    //     return t(getLabel?.text || '') || screenType;
    //   },
    // },
    {
      title: '',
      key: 'action',
      width: 180,
      render: (record: any) => (
        <PlayListRowAction
          record={record}
          onEdit={(record) => {
            history.push(`/playlist/detail/${record._id}`);
          }}
          onView={(record) => {
            const newData = record.frames?.map((e) => ({ ...e, ...e.media }));
            setMediaPreview(newData);
            rerefPreviewf.current?.onShow();
          }}
          onAssignGroup={() => {
            refAssignGroup?.current.onShow?.(record);
          }}
          onDelete={(record) => {
            setState((state) => {
              return { ...state, deletePlaylistData: record };
            });
          }}
          onPublish={(record) => {
            setState((state) => {
              return { ...state, publishPlaylistData: record };
            });
          }}
          onDraft={(record) => {
            setState((state) => {
              return { ...state, draftPlaylistData: record };
            });
          }}
          onReject={(record) => {
            setState((state) => {
              return { ...state, rejectPlaylistData: record };
            });
          }}
        />
      ),
    },
  ];
  // eslint-disable-next-line
  const [paginate, setPaginate] = useState({
    page: 1,
    pageSize: PageSizeTable,
  });

  // eslint-disable-next-line
  const [filter, setFilter] = useState<PlaylistsFilter>({});

  // eslint-disable-next-line
  const { isLoading, data, refetch } = useGetAllPlaylist({
    page: paginate.page,
    limit: paginate.pageSize,
    // resolution: filter?.resolution,
    // status: filter?.status,
    query: filter?.query || undefined,
    // diffApprove: true,
  });

  const [form] = Form.useForm();

  const onSearch = (values: any) => {
    const input: PlaylistsFilter = {
      query: values.query ? values.query : undefined,
      // capacity: values.capacity || undefined,
      resolution: values?.resolution || undefined,
      status: values.status || undefined,
    };
    if (paginate.page !== 1) {
      setPaginate({
        ...paginate,
        page: 1,
      });
    }
    setFilter(input);
  };

  const onSetOtherGroup = (input: SetOtherGroupI, playlistId?: string) => {
    if (playlistId)
      mutate({
        input: {
          _id: playlistId,
          ...input,
        },
      });
  };

  const onCancel = () => {
    setState((state) => {
      return {
        ...state,
        deletePlaylistData: undefined,
        publishPlaylistData: undefined,
        draftPlaylistData: undefined,
        rejectPlaylistData: undefined,
      };
    });
  };

  const OnDelete = () => {
    if (state.deletePlaylistData) {
      deletePlaylist({ input: { _id: state.deletePlaylistData._id } });
      setState({ deletePlaylistData: undefined });
    }
  };

  useEffect(() => {
    t && setPageTitle(t('title'));
    // eslint-disable-next-line
  }, [t]);

  const onSubmitAssigned = () => {
    refetch({});
  };

  useEffect(() => {
    if (isSuccess) {
      refAssignGroup?.current?.onCancel?.();
      refetch({});
    }
    return () => {};
  }, [isSuccess]);

  return (
    <>
      <TableLayout
        tableHeader={
          <div className="table-header-flex d-block d-xl-flex justify-content-between align-items-center">
            <div className="table-actions">
              <Form
                layout="inline"
                form={form}
                onFinish={onSearch}
                className="d-flex justify-content-start justify-content-xl-end gap-3"
              >
                <Form.Item>
                  <Input.Group compact>
                    <Form.Item name="query" className="w-full mr-0">
                      <Input className="input_search_header" placeholder={`${t('search_playlist')}...`} />
                    </Form.Item>
                    <Button icon={<FiSearch color="#F80000" />} className="btn-search" htmlType="submit" />
                  </Input.Group>
                </Form.Item>

                {loginState.data?.companyId && (
                  <Button
                    icon={<PlusOutlined />}
                    size="middle"
                    onClick={() => {
                      history.push('/playlist/new');
                    }}
                    className="btn-dangerous btn-add-new"
                  >
                    {t('add_new')}
                  </Button>
                )}
              </Form>
            </div>
          </div>
        }
      >
        <TableWithNumber
          scroll={{
            x: true,
          }}
          rowKey={(data) => data._id}
          columns={columns}
          rowClassName={(record, index) =>
            index % 2 === 0 ? 'table-row-height table-row-white ' : 'table-row-height table-row-gray'
          }
          dataSource={data?.playlists?.items || []}
          loading={isLoading}
          pagination={false}
          totalTitle={(data?.playlists?.total || 0) <= 1 ? t('title') : t('title') + 's'}
          paginate={{
            pageSize: paginate.pageSize,
            total: data?.playlists?.total || 0,
            current: data?.playlists?.currentPage || 1,
            onChange: (page) => {
              setPaginate({
                ...paginate,
                page,
              });
            },
          }}
        />
        <ModalConfirm
          isLoading={deleteLoading}
          data={state.deletePlaylistData}
          handleOk={OnDelete}
          handleCancel={onCancel}
          title={t('delete_item')}
          okTitle={t('delete')}
          content={
            <Trans
              i18nKey="delete_content"
              t={t}
              values={{ name: state.deletePlaylistData?.name }}
              components={[<span className="primary_color fw-500"></span>]}
            />
          }
        />
      </TableLayout>
      <ModalAssignGroup onSetOtherGroup={onSetOtherGroup} ref={refAssignGroup} onSubmit={onSubmitAssigned} />
      <PreviewPlaylist onCancel={() => setMediaPreview([])} ref={rerefPreviewf} data={DataMediaPreview} />
    </>
  );
}
