/* eslint-disable import/no-anonymous-default-export */
import { Frame, FrameDirection, FrameInputType, PlaylistScreenType } from '@/graphql/generated/graphql';
import {
  DefaultFrameConfig,
  EditorConfig,
  FrameData,
  PlaylistResolutionInterface,
  PlaylistResolutions,
} from '@modules/PlayList/types';
import { nanoid } from 'nanoid';
import {
  ActiveFrameActionTypes,
  ACTIVE_FRAME,
  AddNewPlayListActionTypes,
  ADD_NEW_FRAME,
  ADD_NEW_PLAY_LIST_DATA,
  ChangePlaylistTypes,
  CHANGE_NEW_FRAME_TYPE,
  CHANGE_PLAYLIST,
  CreatePlaylistActionTypes,
  CreatePlaylistInputInterface,
  CREATE_PLAYLIST,
  CREATE_PLAYLIST_ERROR,
  CREATE_PLAYLIST_SUCCESS,
  DeleteFrameActionTypes,
  DELETE_FRAME,
  EditorActionTypes,
  GetPlaylistActionTypes,
  GET_PLAYLIST,
  GET_PLAYLIST_ERROR,
  GET_PLAYLIST_SUCCESS,
  ResetPlaylistActionTypes,
  RESET_PLAYLIST_STATE,
  SelectResolutionActionTypes,
  SELECT_RESOLUTION,
  SetPlaylistLayoutActionTypes,
  SET_PLAYLIST_LAYOUT,
  UpdateFrameActionTypes,
  UpdatePlaylistActionTypes,
  UpdatePlaylistStateActionTypes,
  UPDATE_EDITOR_CONFIG,
  UPDATE_FRAME,
  UPDATE_FRAMES_ORDER,
  UPDATE_PLAYLIST,
  UPDATE_PLAYLIST_ERROR,
  UPDATE_PLAYLIST_STATE,
  UPDATE_PLAYLIST_SUCCESS,
} from '../action-types';

export interface AddNewPlayListState {
  loading?: boolean;
  isSuccess: boolean;
  data: CreatePlaylistInputInterface;
  _id?: string;
  frameData?: FrameInputType[];
}

const initialState: AddNewPlayListState = {
  loading: false,
  isSuccess: false,
  _id: undefined,
  data: {
    isContinue: false,
    name: '',
    editor: {
      zoom: 1,
      width: 0,
      height: 0,
      resolution: PlaylistResolutions[0],
      orientation: FrameDirection.Horizontal,
    },
    screenType: PlaylistScreenType.FulScreen,
    currentPlaylist: 1,
    currentFrames: [], // Frames từ playlist đang được xử lý
    frames: [], // Frames của playlist 1
    secondFrames: [], // Frames của playlist 2
  },
};

const getCurrentFrames = (updatedState: AddNewPlayListState) => {
  switch (updatedState.data.currentPlaylist) {
    case 1:
      return {
        currentFrames: updatedState.data.frames || [],
      };
    case 2:
      return {
        currentFrames: updatedState.data.secondFrames || [],
      };
    default:
      return {
        currentFrames: updatedState.data.frames || [],
      };
  }
};

const updateFramesData = (updatedState: AddNewPlayListState) => {
  switch (updatedState.data.currentPlaylist) {
    case 1:
      return {
        frames: updatedState.data.currentFrames || [],
      };
    case 2:
      return {
        frames: updatedState.data.frames || [],
      };
    default:
      return {
        frames: updatedState.data.frames || [],
      };
  }
};

export default (
  state = initialState,
  action:
    | AddNewPlayListActionTypes
    | SelectResolutionActionTypes
    | EditorActionTypes
    | ActiveFrameActionTypes
    | UpdateFrameActionTypes
    | CreatePlaylistActionTypes
    | GetPlaylistActionTypes
    | ResetPlaylistActionTypes
    | UpdatePlaylistActionTypes
    | SetPlaylistLayoutActionTypes
    | DeleteFrameActionTypes
    | UpdatePlaylistStateActionTypes
    | ChangePlaylistTypes,
): AddNewPlayListState => {
  switch (action.type) {
    case RESET_PLAYLIST_STATE:
      return {
        ...state,
        ...initialState,
      };

    case CHANGE_PLAYLIST:
      let CHANGE_PLAYLIST_state = {
        ...state,
        data: {
          ...state.data,
          currentPlaylist: action.payload,
        },
      };
      CHANGE_PLAYLIST_state = {
        ...CHANGE_PLAYLIST_state,
        data: {
          ...CHANGE_PLAYLIST_state.data,
          ...getCurrentFrames(CHANGE_PLAYLIST_state),
        },
      };

      if (!CHANGE_PLAYLIST_state.data?.currentFrames?.length) {
        const firstFrame = {
          id: nanoid(),
          ...DefaultFrameConfig,
        };
        CHANGE_PLAYLIST_state.data.currentFrames = [{ ...firstFrame }];
        CHANGE_PLAYLIST_state.data.activeFrame = firstFrame;
      } else {
        CHANGE_PLAYLIST_state.data.activeFrame = CHANGE_PLAYLIST_state.data.currentFrames[0];
      }

      return {
        ...CHANGE_PLAYLIST_state,
      };

    case ADD_NEW_FRAME:
      let ADD_NEW_FRAME_states: any = {
        ...state,
        data: action.payload,
      };
      return {
        ...ADD_NEW_FRAME_states,
      };
    case ADD_NEW_PLAY_LIST_DATA:
      let ADD_NEW_PLAYLIST_states = {
        ...state,
        frameData: action.payload,
      };
      return ADD_NEW_PLAYLIST_states;

    case CHANGE_NEW_FRAME_TYPE:
      const CHANGE_NEW_FRAME_TYPE_frames = [...(state.data?.currentFrames || [])];
      const objIndex = CHANGE_NEW_FRAME_TYPE_frames.findIndex((item: any) => item?.id === action.payload.id);
      if (objIndex > -1) {
        CHANGE_NEW_FRAME_TYPE_frames[objIndex].type = action.payload.type || 'image';
      }
      let CHANGE_NEW_FRAME_TYPE_state = {
        ...state,
        data: {
          ...state.data,
          currentFrames: CHANGE_NEW_FRAME_TYPE_frames,
        },
      };

      return {
        ...CHANGE_NEW_FRAME_TYPE_state,
        data: {
          ...state.data,
          ...CHANGE_NEW_FRAME_TYPE_state.data,
          ...updateFramesData(CHANGE_NEW_FRAME_TYPE_state),
        },
      };

    case SELECT_RESOLUTION:
      return {
        ...state,
        data: {
          ...state.data,
          editor: {
            ...state.data?.editor,
            width: action.payload.width,
            height: action.payload.height,
            resolution: {
              ...action.payload,
            },
            orientation: FrameDirection.Horizontal,
          },
        },
      };

    case SET_PLAYLIST_LAYOUT:
      let SET_PLAYLIST_LAYOUT_state = {
        ...state,
        data: {
          ...state.data,
          editor: {
            ...state.data?.editor,
            width: action.payload.resolution.width,
            height: action.payload.resolution.height,
            resolution: {
              ...action.payload.resolution,
            },
            orientation: action.payload.orientation,
          },
          screenType: action.payload.screenType,
        },
      };

      return {
        ...SET_PLAYLIST_LAYOUT_state,
        data: {
          ...SET_PLAYLIST_LAYOUT_state.data,
          ...getCurrentFrames(SET_PLAYLIST_LAYOUT_state),
        },
      };

    case UPDATE_EDITOR_CONFIG:
      return {
        ...state,
        data: {
          ...state.data,
          editor: {
            ...state.data?.editor,
            ...action.payload,
          },
        },
      };

    case UPDATE_FRAME:
      const updateFrames: FrameData[] = [];
      let activeFrame: FrameData | undefined;
      state.data?.currentFrames?.map((frame: FrameData) => {
        if (action.payload.id === frame.id) {
          updateFrames.push(action.payload);
          activeFrame = action.payload;
        } else {
          updateFrames.push(frame);
        }
      });
      let UPDATE_FRAME_state = {
        ...state,
        data: {
          ...state.data,
          currentFrames: updateFrames,
          activeFrame,
        },
      };

      return {
        ...UPDATE_FRAME_state,
        data: {
          ...state.data,
          ...UPDATE_FRAME_state.data,
          ...updateFramesData(UPDATE_FRAME_state),
        },
      };

    case UPDATE_FRAMES_ORDER:
      const currentFrames: FrameData[] = [];
      // eslint-disable-next-line
      action.payload.map((frame: FrameData, index: number) => {
        currentFrames.push({ ...frame, order: index });
      });
      const twoFrames = {
        frames: state.data.frames,
        secondFrames: state.data.secondFrames,
      };
      if (state.data.currentPlaylist === 1) {
        twoFrames.frames = currentFrames;
      } else {
        twoFrames.secondFrames = currentFrames;
      }
      return {
        ...state,
        data: {
          ...state.data,
          currentFrames: currentFrames,
          ...twoFrames,
        },
      };

    case ACTIVE_FRAME:
      const ACTIVE_FRAME_frames: FrameData[] = [];
      state.data?.currentFrames?.map((frame: FrameData) => {
        if (frame.id === action.payload.id) {
          ACTIVE_FRAME_frames.push({
            ...frame,
            isEditing: true,
          });
        } else {
          ACTIVE_FRAME_frames.push({
            ...frame,
            isEditing: false,
          });
        }
      });
      let ACTIVE_FRAME_state = {
        ...state,
        data: {
          ...state.data,
          currentFrames: ACTIVE_FRAME_frames,
          activeFrame: action.payload,
        },
      };

      return {
        ...ACTIVE_FRAME_state,
        data: {
          ...state.data,
          ...ACTIVE_FRAME_state.data,
          ...updateFramesData(ACTIVE_FRAME_state),
        },
      };

    case GET_PLAYLIST:
      return {
        ...state,
        loading: true,
        _id: action.payload._id.toString(),
      };

    case GET_PLAYLIST_SUCCESS:
      const getPlaylistDataState: any = {
        editor: {
          zoom: 1,
          width: 0,
          height: 0,
          resolution: PlaylistResolutions[0],
          orientation: FrameDirection.Horizontal,
        },
        frames: [],
        currentFrames: [],
        activeFrame: undefined,
      };
      const frames = action.payload.frames?.map((frame: Frame, index: number) => {
        const isEditing = index === 0 ? true : false;
        const frameData: FrameData = {
          ...frame,
          order: frame.order || index,
          // frameCover: getFrameCover(frame),
          id: nanoid(),
          isEditing,
        };
        if (isEditing) {
          getPlaylistDataState.activeFrame = frameData;
        }
        return frameData;
      });

      // const secondFrames = action.payload.secondFrames?.map((frame: Frame, index: number) => {
      //   const isEditing = index === 0 ? true : false;
      //   const frameData: FrameData = {
      //     ...frame,
      //     order: frame.order || index,
      //     frameCover: getFrameCover(frame),
      //     id: nanoid(),
      //     isEditing,
      //   };
      //   if (isEditing) {
      //     getPlaylistDataState.activeFrame = frameData;
      //   }
      //   return frameData;
      // });
      getPlaylistDataState.frames = frames.sort((a: FrameData, b: FrameData) => (a.order || 0) - (b.order || 0));
      // getPlaylistDataState.secondFrames = secondFrames
      //   ? secondFrames.sort((a: FrameData, b: FrameData) => (a.order || 0) - (b.order || 0))
      //   : [];
      getPlaylistDataState.currentFrames = frames;
      let editor: EditorConfig = {
        zoom: 1,
        width: 0,
        height: 0,
        resolution: PlaylistResolutions[0],
        orientation: FrameDirection.Horizontal,
      };
      // eslint-disable-next-line
      PlaylistResolutions.map((resolutionData: PlaylistResolutionInterface) => {
        if (resolutionData.key === action.payload.resolution) {
          editor = {
            ...editor,
            ...resolutionData,
            resolution: resolutionData,
          };
        }
      });
      getPlaylistDataState.editor = editor;
      return {
        ...state,
        loading: false,
        isSuccess: true,
        data: {
          ...state.data,
          ...action.payload,
          name: action.payload.name,
          ...getPlaylistDataState,
          createdById: action.payload.createdById,
        },
      };

    case GET_PLAYLIST_ERROR:
      return {
        ...state,
        loading: false,
        isSuccess: false,
      };

    case CREATE_PLAYLIST:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        loading: true,
        isSuccess: false,
      };

    case CREATE_PLAYLIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        isSuccess: true,
        _id: action.payload._id,
      };
    }

    case CREATE_PLAYLIST_ERROR: {
      return {
        ...state,
        isSuccess: false,
      };
    }

    case UPDATE_PLAYLIST: {
      return {
        ...state,
        ...action.payload,
        loading: true,
        isSuccess: false,
      };
    }

    case UPDATE_PLAYLIST_SUCCESS: {
      const { frameData } = { ...state };
      return {
        // ...state,
        ...initialState,
        data: {
          ...initialState.data,
          createdBy: action.payload.createdBy,
          createdAt: action.payload.createdAt,
        },
        frameData,
        loading: false,
        isSuccess: true,
      };
    }

    case UPDATE_PLAYLIST_ERROR: {
      return {
        ...state,
        loading: false,
        isSuccess: false,
      };
    }

    case DELETE_FRAME:
      const DELETE_FRAME_frames: FrameData[] = [];
      state.data?.currentFrames?.map((frame: FrameData) => {
        if (frame.id !== action.payload.id) {
          DELETE_FRAME_frames.push({
            ...frame,
            isEditing: true,
          });
        }
      });
      let DELETE_FRAME_state = {
        ...state,
        data: {
          ...state.data,
          currentFrames: DELETE_FRAME_frames,
          activeFrame: DELETE_FRAME_frames[0] || null,
        },
      };

      return {
        ...DELETE_FRAME_state,
        data: {
          ...state.data,
          ...DELETE_FRAME_state.data,
          ...updateFramesData(DELETE_FRAME_state),
        },
      };

    case UPDATE_PLAYLIST_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };

    default:
      return {
        ...state,
        isSuccess: false,
      };
  }
};
