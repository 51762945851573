import { PaginatedMedia, QueryMediasInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { GET_FOLDERS, GET_FOLDERS_ERROR, GET_FOLDERS_SUCCESS } from '../action-types';

export const getFolsersAction = (payload: QueryMediasInput) => ({
  type: GET_FOLDERS,
  payload,
});

export const getFolsersActionSuccess = (payload: PaginatedMedia) => ({
  type: GET_FOLDERS_SUCCESS,
  payload,
});

export const getFolsersActionError = (payload: AppError) => ({
  type: GET_FOLDERS_ERROR,
  payload,
});
