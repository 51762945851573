import CaretDownOutlined from '@ant-design/icons/lib/icons/CaretDownOutlined';
import CaretUpOutlined from '@ant-design/icons/lib/icons/CaretUpOutlined';
import ic_user from '@assets/images/businessman.svg';
import flagN from '@assets/images/norway.svg';
import flagE from '@assets/images/united-kingdom.svg';
import flagV from '@assets/images/vietnam.svg';
import { getHistory } from '@helpers/history';
import { getToken, setDefaultLanguage } from '@helpers/token';
import useLogout from '@modules/Auth/hooks/useLogout';
import { Languages } from '@modules/Auth/redux/action-types';
import { Popover, Select, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const { Link, Text } = Typography;
const { Option } = Select;

export default function LoggedInUser() {
  const [popOverVisible, setPopOverVisible] = useState(false);
  const { onLogout } = useLogout();
  const { i18n, t } = useTranslation(['topNavigation', 'user']);

  const onVisibleChange = (visible: any) => {
    setPopOverVisible(visible);
  };

  const handleLogout = () => {
    const token = getToken();
    onLogout(token);
  };
  const changeLanguage = (lng: string) => {
    setDefaultLanguage(lng);
  };

  const onChangePage = (url: string) => {
    setPopOverVisible(false);
    getHistory().push(`/${url}`);
  };

  const content = (
    <ul className="header-dropdown mb-0">
      <li className="nav-link" onClick={() => onChangePage('profile')}>
        <Link>
          <Text type="secondary">{t('user:profile')}</Text>
        </Link>
      </li>
      <li className="nav-link" onClick={() => onChangePage('change-password')}>
        <Link>
          <Text type="secondary">{t('change_password')}</Text>
        </Link>
      </li>
      <li className="nav-link" onClick={() => handleLogout()}>
        <Link>
          <Text type="secondary">{t('logout')}</Text>
        </Link>
      </li>
    </ul>
  );

  return (
    <div className="d-flex align-items-center justify-content-start flex-shrink-0">
      <div className="change-language pd-0 pd-md-2 mr-md-4">
        <Select
          defaultValue={i18n?.language?.toString()}
          style={{ minWidth: 100 }}
          className="mr-2 select_lang color_text_header"
          onChange={(e) => changeLanguage(e)}
          placeholder={t('select_language')}
          value={i18n?.language?.toString()}
          suffixIcon={<CaretDownOutlined style={{ width: 12, color: '#999999', pointerEvents: 'none' }} />}
        >
          {Languages.map((item) => {
            return (
              <Option key={item.key} value={item.key}>
                <div className="d-flex align-items-center justify-content-start item_select_change_language">
                  <img
                    src={item.key === 'en' ? flagE : item.key === 'vi' ? flagV : flagN}
                    alt="flag"
                    className="mr-1 ic_lang"
                  />
                  {item.name}
                </div>
              </Option>
            );
          })}
        </Select>
      </div>
      <Popover
        content={content}
        title=""
        trigger="click"
        placement="bottomRight"
        visible={popOverVisible}
        onVisibleChange={onVisibleChange}
        className="px-0 userInfo"
      >
        <div
          style={{
            width: 'auto',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <img src={ic_user} className="mr-2 ic_user" alt="icon_user" />

          {!popOverVisible ? (
            <CaretDownOutlined style={{ width: 12, color: '#999999' }} />
          ) : (
            <CaretUpOutlined style={{ width: 12, color: '#999999' }} />
          )}
        </div>
      </Popover>
    </div>
  );
}
