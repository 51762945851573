import { Button, Card, Col, DatePicker, Form, Row, TimePicker } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SpecialDayTable from './SpecialDayTable';

const format = 'HH:mm';

interface IProps {
  companyId: string;
  className?: string;
}

export default function SpecialDay(props: IProps) {
  const { t } = useTranslation(['company']);
  const [form] = Form.useForm();

  const onAddSpecialDay = () => {
    console.log('onAddSpecialDay');
  };

  return (
    <Card
      title={t('special_day')}
      bordered={false}
      headStyle={{ borderBottom: 'none', fontSize: '20px' }}
      bodyStyle={{ padding: '8px 24px' }}
      className={`pb-2 special-day-card ${props?.className}`}
    >
      <Form form={form} layout="horizontal" onFinish={onAddSpecialDay}>
        <Form.Item
          name="special_day"
          label={t('choose_day')}
          colon={false}
          labelCol={{ span: 12, xs: 10, md: 8, xl: 6 }}
          labelAlign="left"
        >
          <DatePicker size="large" className="w-100" />
        </Form.Item>
        <Form.Item
          name="choose_time"
          label={t('choose_time')}
          labelCol={{ span: 12, xs: 10, md: 8, xl: 6 }}
          labelAlign="left"
          className="choose_time_box mb-0"
          rules={[
            ({ getFieldValue }) => ({
              validator(rule: any, value: any) {
                const openTime = getFieldValue('open_time')
                  ? moment()
                      .utc()
                      .set('hour', moment(getFieldValue('open_time')).hour())
                      .set('minute', moment(getFieldValue('open_time')).minute())
                      .set('millisecond', 0)
                      .valueOf()
                  : 0;
                const closedTime = getFieldValue('closed_time')
                  ? moment()
                      .utc()
                      .set('hour', moment(getFieldValue('closed_time')).hour())
                      .set('minute', moment(getFieldValue('closed_time')).minute())
                      .set('millisecond', 0)
                      .valueOf()
                  : 0;
                if (openTime && closedTime && openTime > closedTime) {
                  return Promise.reject(t('validate.open_time_greater_than_closed_time'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Row gutter={16} className="w-100 align-items-center">
            <Col className="position-relative">
              <Form.Item
                name="open_time"
                className="mr-0"
                trigger="onSelect"
                rules={[{ required: true, message: t('validate.required_opentime') }]}
              >
                <TimePicker
                  className="w-100 color-333"
                  format={format}
                  placeholder={t('time_open.select_time_open')}
                  minuteStep={5}
                  size="large"
                  allowClear={false}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name={`closed_time`}
                trigger="onSelect"
                rules={[{ required: true, message: t('validate.required_opentime') }]}
              >
                <TimePicker
                  className="color-333"
                  format={format}
                  placeholder={t('time_open.select_time_close')}
                  minuteStep={5}
                  size="large"
                  allowClear={false}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Button className="add-special-day fs-500 mt-3" type="dashed" htmlType="submit">
          {t('add_special_day')}
        </Button>
      </Form>
      <Row>
        <Col xs={24}>
          <SpecialDayTable companyId={props.companyId} />
        </Col>
      </Row>
    </Card>
  );
}
