import { Group } from '@/graphql/generated/graphql';
import GroupMessageModal from '@modules/GroupDevice/Components/Modal/GroupMessageModal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiMessageDetail } from 'react-icons/bi';

interface Props {
  group?: Group;
}

const ButtonGroupMessageSetting = (props: Props) => {
  const { t } = useTranslation(['group-device']);
  const [group, setGroup] = useState<Group | undefined>(undefined);

  useEffect(() => {
    return () => {
      setGroup(undefined);
    };
  }, []);

  return (
    <>
      <div
        className="d-flex align-items-center"
        onClick={() => {
          setGroup(props.group);
        }}
      >
        <BiMessageDetail color="#000" size={20} className="mr-3 ml-1" />
        <p className="mb-0">{t('message')}</p>
      </div>
      <GroupMessageModal groupItem={group} onCancel={() => setGroup(undefined)} />
    </>
  );
};

export default ButtonGroupMessageSetting;
