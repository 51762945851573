import icon_edit_blue from '@assets/images/icons/icon_edit_blue.svg';
import useSetAppLayout from '@modules/App/hooks/useSetAppLayout';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

interface Props {
  onClick: (e: any) => void;
  classNameContainer?: string;
  isLoading?: boolean;
  title?: String;
  showTitle?: boolean;
}

const ButtonEditBlue = (props: Props) => {
  const { layoutSize } = useSetAppLayout();
  const { t } = useTranslation(['button']);
  const onEdit = (e: any) => {
    props.onClick(e);
  };

  return (
    <Button
      className={`btn_edit_blue  px-0 h-100 ${props.classNameContainer}`}
      onClick={onEdit}
      type="link"
      loading={props.isLoading || false}
      size={layoutSize}
    >
      <div className="container_icon">
        <img className={`icon_btn`} src={icon_edit_blue} alt="ic_btn" />
      </div>
      {props.showTitle === false ? null : <p className="title_btn">{props.title ? props.title : t('edit')}</p>}
    </Button>
  );
};

export default ButtonEditBlue;
