import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import React from 'react';

export default function TopNavigation() {
  const { pageTitle } = useSetPageTitle();

  return (
    <div className="title_page color_text_header font-weight-bold">
      <p className="mb-0">{pageTitle}</p>
    </div>
  );
}
