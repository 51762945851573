import { CreateDeviceInput } from '@/graphql/generated/graphql';
import FormHeader from '@commons/components/FormHeader';
import PageHeader from '@commons/components/PageHeader';
import FormSkeleton from '@commons/components/Skeletons/FormSkeleton';
import { getHistory } from '@helpers/history';
import DeviceFrom from '@modules/Device/components/Form/DeviceFrom';
import useAddNewDevice from '@modules/Device/hooks/useAddNewDevice';
import { Form } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function AddNewDevicePage() {
  const { t } = useTranslation(['device']);
  const [form] = Form.useForm();
  const { isLoading, mutate, data } = useAddNewDevice();

  function onFinish(values: any) {
    const payload: CreateDeviceInput = {
      name: values.name,
      companyId: values.companyId,
      deviceModel: values.deviceModel,
      deviceCode: values.deviceCode,
      os: values.os,
      screen: values.screen,
      yearOfManufacture: values.yearOfManufacture,
    };
    mutate({ input: payload });
  }

  useEffect(() => {
    if (data && data.createDevice) {
      getHistory().push('/device/list');
    }
  }, [data]);

  return (
    <div>
      <PageHeader title={t('device')} subTitle={t('add_new_device')} />
      <FormHeader>
        <FormSkeleton loading={isLoading}>
          <DeviceFrom isLoading={false} onFinish={onFinish} form={form} />
        </FormSkeleton>
      </FormHeader>
    </div>
  );
}
