import AuthLayout from '@commons/components/layouts/Auth';
import { setTitle } from '@helpers/dom';
import { __ } from '@helpers/string';
import React, { useEffect } from 'react';
import useLogin from '../../hooks/useLogin';
import LoginForm from './components/Form';

function LoginPage() {
  const { loading, onLogin } = useLogin();

  useEffect(() => {
    setTitle(__('Login'));
  }, []);

  return (
    <AuthLayout>
      <LoginForm onLogin={onLogin} loading={loading} />
    </AuthLayout>
  );
}

export default LoginPage;
