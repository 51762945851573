import icon_schedule_green from '@assets/images/icons/icon_schedule_green.svg';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

interface Props {
  onClick: (e: any) => void;
  classNameContainer?: string;
  isLoading?: boolean;
  title?: string;
}

const ButtonScheduleGreen = (props: Props) => {
  const { t } = useTranslation(['button']);
  const onSchedule = (e: any) => {
    props.onClick(e);
  };

  return (
    <Button
      className={`btn_schedule_green px-0 h-100 ${props.classNameContainer}`}
      onClick={onSchedule}
      type="link"
      loading={props.isLoading || false}
    >
      <div className="container_icon">
        <img className={`icon_btn`} src={icon_schedule_green} alt="ic_btn" />
      </div>

      <p className="title_btn">{props.title ? props.title : t('schedule')}</p>
    </Button>
  );
};

export default ButtonScheduleGreen;
