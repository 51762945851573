import { Company } from '@/graphql/generated/graphql';
import ButtonDeleteRow from '@commons/components/ButtonRowActions/ButtonDeleteRed';
import ButtonEditRow from '@commons/components/ButtonRowActions/ButtonEditBlue';
// import ButtonLoginGreen from '@commons/components/ButtonRowActions/ButtonLoginGreen';
import { LoginState } from '@modules/Auth/redux/action-types';
import { RootState } from '@redux/reducers';
import * as React from 'react';
import { useSelector } from 'react-redux';

interface CompanyRowActionProp {
  record: Company;
  onEdit?(pck: Company): void;
  onDelete?(pck: Company): void;
  onLogin?(pck: Company): void;
}

export default function CompanyRowAction(props: CompanyRowActionProp) {
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);
  // eslint-disable-next-line

  return (
    <div onClick={(e) => e.stopPropagation()} className="d-flex justify-content-end align-items-center">
      <ButtonEditRow
        onClick={(e) => {
          props.onEdit && props.onEdit(props.record);
          e.stopPropagation();
        }}
      />
      {!loginState.data?.companyId ? (
        <ButtonDeleteRow
          onClick={(e) => {
            props.onDelete && props.onDelete(props.record);
            e.stopPropagation();
          }}
        />
      ) : null}
    </div>
  );
}
