import AddNewPlaylistPage from '@modules/PlayList/pages/AddNew/index';
// import PlaylistDetailPage from '@modules/PlayList/pages/Detail/index';
import PlaylistsPage from '@modules/PlayList/pages/List/index';

export const PlaylistRouter = [
  {
    path: '/playlist/new',
    exact: true,
    component: AddNewPlaylistPage,
  },
  {
    path: '/playlist/list',
    exact: true,
    component: PlaylistsPage,
  },
  {
    path: '/playlist/detail/:id',
    exact: true,
    component: AddNewPlaylistPage,
  },
];
