import { Media } from '@/graphql/generated/graphql';
import { Modal, Row, Select, Space } from 'antd';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import PreviewLeft from './component/preview-left';
import PreviewRight from './component/preview-right';
import icon_close from '@assets/images/icons/icon_close_gray.svg';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

function PreviewPlaylist(props: any, ref: any) {
  const { data } = props;

  const [visible, setVisible] = useState(false);

  const [MediaPreview, setPreviewMediaItem] = useState<Media | undefined>();

  const [VerticalValue, setValueVertical] = useState<string>('1');

  const [valueHd, setValueHD] = useState<string>('fullhd');

  const { t } = useTranslation('playlist');

  useEffect(() => {
    if (data?.length > 0) {
      setPreviewMediaItem({ ...data[0], _id: data[0]._id + 0 });
    }
    return () => {};
  }, [data]);

  const onCancel = () => {
    props.onCancel?.();
    setVisible(false);
    setPreviewMediaItem(undefined);
  };

  const onShow = () => {
    setVisible(true);
  };
  useImperativeHandle(ref, () => ({
    onShow,
  }));

  const handleChange = (value: string) => {
    setValueVertical(value);
  };

  const handleChangeHd = (value: string) => {
    setValueHD(value);
  };

  return (
    <Modal
      closable={false}
      visible={visible}
      destroyOnClose
      centered
      onCancel={onCancel}
      bodyStyle={{ padding: '0px 0px 10px 0px' }}
      maskStyle={{ borderRadius: 10 }}
      className="media-modal-preview"
    >
      <div className="playlist-page preview-page">
        {/* <HeaderPlaylist hideRight /> */}
        <Row
          justify="space-between"
          style={{ padding: '15px', background: '#EEF0F4' }}
          align="middle"
          className="header-container"
        >
          <Row>
            <Space>
              <Select defaultValue="fullhd" value={valueHd} onChange={handleChangeHd}>
                <Option value="fullhd">Full HD 1920 x 1080</Option>
                <Option value="hd">HD 1280 x 720</Option>
              </Select>
              <Select defaultValue="1" value={VerticalValue} onChange={handleChange}>
                <Option value="1">{t('lanscape')}</Option>
                <Option value="90">{t('VERTICAL')} 90°</Option>
                <Option value="270">{t('VERTICAL')} 270°</Option>
              </Select>
            </Space>
          </Row>
          <img
            src={icon_close}
            className="ic_close cursor-pointer"
            alt="ic_close"
            width={14}
            height={14}
            onClick={onCancel}
          />
        </Row>
        <div style={{ flexDirection: 'row', display: 'flex', height: '70vh' }}>
          <div className="playlist-col-left">
            <PreviewLeft MediaPreview={MediaPreview} valueHd={valueHd} VerticalValue={VerticalValue} />
          </div>
          <div className="playlist-col-right">
            <PreviewRight data={data} MediaPreview={MediaPreview} setPreviewMediaItem={setPreviewMediaItem} />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default React.memo(React.forwardRef(PreviewPlaylist));
