import { Device, Roles } from '@/graphql/generated/graphql';
import icon_setting from '@assets/images/icons/Icon_settings.svg';
import icon_trash from '@assets/images/icons/Icon_trash_2.svg';
import ButtonDeviceGreen from '@commons/components/ButtonRowActions/ButtonDeviceGreen';
import ButtonEditRow from '@commons/components/ButtonRowActions/ButtonEditBlue';
import ButtonMoreGray from '@commons/components/ButtonRowActions/ButtonMoreGray';
import { LoginState } from '@modules/Auth/redux/action-types';
import useRequestReloadApp from '@modules/Device/hooks/useRequestReloadApp';
import useRequestRestartDevice from '@modules/Device/hooks/useRequestRestartDevice';
import { RootState } from '@redux/reducers';
import { Dropdown, Menu } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GrPower, GrRefresh } from 'react-icons/gr';
import { MdOutlineSdStorage } from 'react-icons/md';

interface DeviceRowActionProp {
  record: Device;
  onEdit?(pck: Device): void;
  onDelete?(pck: Device): void;
  onDeviceInfo?(pck: Device): void;
  onSettingDevice?(pck: Device): void;
  onClearDeviceStorage?(pck: Device): void;
}

export default function DeviceRowAction(props: DeviceRowActionProp) {
  const { t } = useTranslation(['device']);
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);
  const { mutate: requestReloadApp } = useRequestReloadApp();
  const { mutate: requestRestartDevice } = useRequestRestartDevice();

  const reloadApp = () => {
    requestReloadApp({
      input: {
        _id: props.record._id,
      },
    });
  };

  const deleteDevice = () => {
    return props.onDelete && props.onDelete(props.record);
  };

  const showDeviceInfo = () => {
    return props.onDeviceInfo && props.onDeviceInfo(props.record);
  };

  const settingDevice = () => {
    return props.onSettingDevice && props.onSettingDevice(props.record);
  };

  const restartDevice = () => {
    requestRestartDevice({
      input: {
        _id: props.record._id,
      },
    });
  };

  const onClearDeviceStorage = () => {
    return props.onClearDeviceStorage && props.onClearDeviceStorage(props.record);
  };

  const menu = (
    <Menu className="dropdown_more_row_actions">
      {props.record.deviceCode && loginState.data?.companyId && (
        <Menu.Item key={'menu_refresh_app'} className="border-bottom-custom" onClick={reloadApp}>
          <div className="d-flex align-items-center">
            <GrRefresh className="mr-3 ml-1" size={20} />
            <p className="mb-0">{t('refresh_app')}</p>
          </div>
        </Menu.Item>
      )}

      {props.record.deviceCode &&
      loginState?.data?.role &&
      [Roles.SuperAdmin, Roles.Admin].includes(loginState?.data?.role) ? (
        <Menu.Item key={'restart_device'} onClick={restartDevice} className="border-bottom-custom">
          <div className="d-flex align-items-center">
            <GrPower className="mr-3 ml-1" size={20} />
            <p className="mb-0">{t('restart_device')}</p>
          </div>
        </Menu.Item>
      ) : null}

      {props.record.deviceCode &&
      (loginState?.data?.role === Roles.SuperAdmin || loginState?.data?.role === Roles.Admin) ? (
        <Menu.Item key={'menu_clear_storage'} onClick={onClearDeviceStorage}>
          <div className="d-flex align-items-center">
            <MdOutlineSdStorage className="mr-3 ml-1" size={20} />
            <p className="mb-0">{t('clear_device_storage')}</p>
          </div>
        </Menu.Item>
      ) : null}

      {loginState?.data?.role === Roles.SuperAdmin ? (
        <Menu.Item key={'setting_device'} onClick={settingDevice} className="border-bottom-custom">
          <div className="d-flex align-items-center">
            <img className="mr-3 ml-1" src={icon_setting} alt="icon setting" />
            <p className="mb-0">{t('setting_device')}</p>
          </div>
        </Menu.Item>
      ) : null}

      {loginState?.data?.role === Roles.SuperAdmin ? (
        <Menu.Item key={'menu_delete'} onClick={deleteDevice}>
          <div className="d-flex align-items-center">
            <img className="mr-3 ml-1" src={icon_trash} alt="icon trash" />
            <p className="mb-0">{t('delete_device')}</p>
          </div>
        </Menu.Item>
      ) : null}
    </Menu>
  );

  return (
    <div onClick={(e) => e.stopPropagation()} className="d-flex justify-content-end">
      <ButtonDeviceGreen onClick={showDeviceInfo} />
      {loginState?.data?.role && [Roles.SuperAdmin, Roles.Admin].includes(loginState?.data?.role) ? (
        <ButtonEditRow
          onClick={(e) => {
            return props.onEdit && props.onEdit(props.record);
          }}
        />
      ) : null}
      <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
        <ButtonMoreGray onClick={() => {}} />
      </Dropdown>
    </div>
  );
}
