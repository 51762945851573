import ButtonCancel from '@commons/components/modals/Button/ButtonCancel';
import ButtonOk from '@commons/components/modals/Button/ButtonOk';
import { LoginState } from '@modules/Auth/redux/action-types';
import useGetAllCompany from '@modules/Company/hooks/useGetAllCompany';
import { CompanyFilter } from '@modules/Company/types';
import useAddNewDevice from '@modules/Device/hooks/useAddNewDevice';
import useGetAllGroups from '@modules/GroupDevice/hooks/useGetAllGroups';
import { RootState } from '@redux/reducers';
import { Button, Col, Form, Input, Modal, Radio, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { OsObject, ScreenObject } from '../Form/DeviceFrom';

interface IProps {
  onCancel: () => void;
  visible: boolean;
}

export default function AddDeviceModal(props: IProps) {
  const [form] = Form.useForm();
  const { t } = useTranslation(['device', 'button']);
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);
  const { isLoading, mutate, isSuccess } = useAddNewDevice();
  const [value, setValue] = useState(1);

  // paginate, filter: list company
  const [paginate, setPaginate] = useState({
    page: 1,
    pageSize: 8,
  });
  const [filter, setFilter] = useState<CompanyFilter>({});

  // paginate, filter : list group device
  const [paginateGroup, setPaginateGroup] = useState({
    page: 1,
    pageSize: 8,
  });
  const [filterGroup, setFilterGroup] = useState<CompanyFilter>({});

  const { data: listGroup } = useGetAllGroups({
    limit: paginateGroup.pageSize,
    page: paginateGroup.page,
    query: filterGroup.query,
  });

  const { data: listCompany } = useGetAllCompany({
    page: paginate.page,
    limit: paginate.pageSize,
    query: filter?.query || undefined,
  });

  const { Option } = Select;

  useEffect(() => {
    form.setFieldsValue({ useOption: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values: any) => {
    const payload: any = {
      name: values.name,
      companyId: values.companyId?.split('/$/')[0],
      deviceCode: values.deviceCode,
      deviceModel: values.deviceModel,
      os: values.os,
      screen: values.screen,
      yearOfManufacture: values.yearOfManufacture ? Number(values.yearOfManufacture) : null,
      groupId: values.groupId || null,
    };

    if (loginState && loginState.data && loginState.data?.companyId) {
      mutate({
        input: {
          ...payload,
          companyId: loginState.data.companyId,
        },
      });
    } else {
      mutate({
        input: payload,
      });
    }
  };

  const onOk = () => {
    form.submit();
  };

  React.useEffect(() => {
    if (isSuccess) {
      props.onCancel();
      form.resetFields();
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  const onCancel = () => {
    props.onCancel();
    form.resetFields();
  };

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  const listOs: OsObject[] = [
    {
      title: 'Android',
      value: 'ANDROID',
    },
    {
      title: 'Linux',
      value: 'LINUX',
    },
    {
      title: 'Tizen',
      value: 'TIZEN',
    },
    {
      title: 'Webos',
      value: 'WEBOS',
    },
  ];

  const listScreen: ScreenObject[] = [
    {
      title: 'Led',
      value: 'LED',
    },
    {
      title: 'Oled',
      value: 'OLED',
    },
    {
      title: 'Qled',
      value: 'QLED',
    },
  ];

  const selectOptionOS = listOs.map((e: OsObject, index: any) => {
    return (
      <Select.Option key={index} value={e.value}>
        {e.title}
      </Select.Option>
    );
  });

  const selectOptionScreen = listScreen.map((e: ScreenObject, index) => {
    return (
      <Select.Option key={index} value={e.value}>
        {e.title}
      </Select.Option>
    );
  });

  return (
    <Modal
      centered
      visible={props.visible}
      cancelText={t('cancel')}
      onCancel={() => props.onCancel()}
      className="modal_confirm_custom width_modal_add_device"
      title={t('add_new_device')}
      closeIcon={<MdClose />}
      footer={[
        <ButtonCancel key={'btn_cancel'} title="Cancel" classNameBtn={`mr-2 `} onClick={onCancel} />,
        <ButtonOk key={'btn_ok'} onClick={onOk} title={t('add_new')} classNameBtn={``} isLoading={isLoading} />,
      ]}
    >
      <Form key="form_add_device" form={form} onFinish={onFinish} colon={false} labelAlign="left">
        {!loginState.data?.companyId ? (
          <>
            <div className="title_content mb-3">{t('basic_info')}</div>
            <Col span={24}>
              <Form.Item label={t('use')} name="useOption" labelCol={{ span: 6 }}>
                <Radio.Group onChange={onChange} value={value} size="large">
                  <Radio className="mr-5" value={1}>
                    {t('available')}
                  </Radio>
                  <Radio value={2}>{t('deploying')}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </>
        ) : null}

        {value === 2 && !loginState.data?.companyId ? (
          <Form.Item
            shouldUpdate={true}
            label={t('select_company')}
            name="companyId"
            labelCol={{ span: 6 }}
            className="bg_input_form"
          >
            <Select
              style={{ width: '100%' }}
              showSearch
              dropdownClassName="mr-0"
              allowClear
              placeholder={t('company')}
              searchValue={filter?.query?.trim() || ''}
              filterOption={false}
              onSearch={(e) => {
                setFilter({
                  ...filter,
                  query: e,
                });
              }}
              dropdownRender={(menu) => {
                return (
                  <React.Fragment>
                    {menu}
                    <Row className="ml-2 mr-2 mt-2 mb-2 justify-content-center">
                      <Col className="mr-2">
                        <Button
                          onMouseDown={() => {
                            if (paginate.page <= 1) {
                              return;
                            }
                            setPaginate({
                              ...paginate,
                              page: paginate.page - 1,
                            });
                          }}
                        >
                          {t('button:pre')}
                        </Button>
                      </Col>
                      <Col className="mr-2">
                        <Button
                          onMouseDown={() => {
                            if (
                              paginate.page === listCompany?.companies?.totalPage ||
                              (listCompany?.companies && listCompany?.companies?.totalPage <= 0)
                            ) {
                              return;
                            }
                            setPaginate({
                              ...paginate,
                              page: paginate.page + 1,
                            });
                          }}
                        >
                          {t('button:next')}
                        </Button>
                      </Col>
                      <Col className="mt-1">{`${listCompany?.companies?.currentPage} / ${listCompany?.companies?.totalPage}`}</Col>
                    </Row>
                  </React.Fragment>
                );
              }}
            >
              {listCompany &&
                listCompany?.companies &&
                listCompany?.companies.items?.map((company: any) => (
                  <Option key={company?._id} value={`${company._id}/$/${company.name}`} title={`${company.name}`}>
                    {company.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        ) : null}
        {loginState.data?.companyId && (
          <Col>
            <div className="title_content mb-3">{t('group')}</div>
            <Form.Item label={t('select_group')} name="groupId" labelCol={{ span: 6 }} className="bg_input_form">
              <Select
                style={{ width: '100%' }}
                placeholder={t('select_group')}
                allowClear
                showSearch
                filterOption={false}
                onSearch={(e) => {
                  setFilterGroup({
                    ...filter,
                    query: e,
                  });
                }}
                dropdownRender={(menu) => {
                  return (
                    <React.Fragment>
                      {menu}
                      <Row className="ml-2 mr-2 mt-2 mb-2 justify-content-center">
                        <Col className="mr-2">
                          <Button
                            onMouseDown={() => {
                              if (paginateGroup.page <= 1) {
                                return;
                              }
                              setPaginateGroup({
                                ...paginateGroup,
                                page: 1,
                              });
                            }}
                          >
                            Reset
                          </Button>
                        </Col>
                        <Col className="mr-2">
                          <Button
                            onMouseDown={() => {
                              if (paginateGroup.page <= 1) {
                                return;
                              }
                              setPaginateGroup({
                                ...paginateGroup,
                                page: paginateGroup.page - 1,
                              });
                            }}
                          >
                            Pre
                          </Button>
                        </Col>
                        <Col className="mr-2">
                          <Button
                            onMouseDown={() => {
                              if (paginateGroup.page === listGroup?.groups?.totalPage) {
                                return;
                              }
                              setPaginateGroup({
                                ...paginateGroup,
                                page: paginateGroup.page + 1,
                              });
                            }}
                          >
                            Next
                          </Button>
                        </Col>
                        <Col className="mt-1">{`${listGroup?.groups?.currentPage} / ${listGroup?.groups?.totalPage}`}</Col>
                      </Row>
                    </React.Fragment>
                  );
                }}
              >
                {listGroup &&
                  listGroup.groups &&
                  listGroup.groups.items?.map((group) => {
                    return (
                      <Option key={group?._id} value={group?._id}>
                        {group?.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
        )}
        <Col>
          <div className="title_content mb-3">{t('device_info')}</div>
          <Form.Item
            label={t('name')}
            name="name"
            rules={[
              { required: true, message: t('validate.required_name') },
              { max: 150, message: t('validate.field_long') },
            ]}
            labelCol={{ span: 6 }}
            className="bg_input_form"
          >
            <Input style={{ width: '100%' }} type="text" placeholder={t('name')} />
          </Form.Item>
          <Row>
            <Col md={16} span={24}>
              <Form.Item
                label={t('device_code')}
                name="deviceCode"
                labelCol={{ md: { span: 9 }, span: 6 }}
                className="bg_input_form"
                // rules={[{ max: 8, message: t('validate.field_long') }]}
              >
                <Input className="input_edit_device" type="text" placeholder={t('device_code')} />
              </Form.Item>
            </Col>
            <Col md={8} span={24}>
              <Form.Item
                label={t('model')}
                name="deviceModel"
                labelCol={{ md: { span: 8 }, span: 6 }}
                className="bg_input_form"
              >
                <Input style={{ width: '100%' }} type="text" placeholder={t('model')} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col md={16} span={24}>
              <Form.Item label={t('os')} name="os" labelCol={{ md: { span: 9 }, span: 6 }} className="bg_input_form">
                <Select className="input_edit_device" placeholder={t('os')}>
                  {selectOptionOS}
                </Select>
              </Form.Item>
            </Col>
            <Col md={8} span={24}>
              <Form.Item
                label={t('screen')}
                name="screen"
                labelCol={{ md: { span: 8 }, span: 6 }}
                className="bg_input_form"
              >
                <Select placeholder={t('screen')}>{selectOptionScreen}</Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Row>
          <Col md={16} span={24}>
            <Form.Item
              label={t('year_of_manufacture')}
              name="yearOfManufacture"
              labelCol={{ md: { span: 9 }, span: 6 }}
              className="bg_input_form"
            >
              <Input className="input_edit_device" type="number" placeholder={t('year_of_manufacture')} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
