import { PlaylistStatus } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UpdatePlaylistStateInput } from '../redux/action-types';
import { updatePlaylistState } from '../redux/actions/update_playlist_state';

const useUpdatePlaylistBasicState = () => {
  const name = useSelector<RootState, string | null | undefined>(
    (state) => state.playList.addNewPlayListState.data?.name,
  );

  const userName = useSelector<RootState, string | null | undefined>((state) => {
    return state.playList.addNewPlayListState.data?.createdBy?.username || '';
  });
  const createdAt = useSelector<RootState, string | null | undefined>((state: any) => {
    return state.playList.addNewPlayListState.data?.createdAt || 0;
  });
  // const CreatedOnn = useSelector<RootState, string | null | undefined>((state) => {
  //   return state.playList.addNewPlayListState.data?.createdO || '';
  // });

  const status = useSelector<RootState, PlaylistStatus | null | undefined>(
    (state) => state.playList.addNewPlayListState.data?.status,
  );
  const _id = useSelector<RootState, string | undefined>((state) => state.playList.addNewPlayListState.data?._id);

  const createdById = useSelector<RootState, string | undefined>(
    (state) => state.playList.addNewPlayListState.data?.createdById,
  );

  const dispatch = useDispatch();
  const onUpdatePlaylistBasicState = useCallback(
    (input: UpdatePlaylistStateInput) => {
      dispatch(updatePlaylistState(input));
    },
    [dispatch],
  );
  return {
    createdById,
    _id,
    name,
    userName,
    createdAt,
    status,
    onUpdatePlaylistBasicState,
  };
};
export default useUpdatePlaylistBasicState;
