import { Checkbox, Col, Form, Row, TimePicker } from 'antd';
import moment from 'moment';
import React, { Ref, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiArrowRightLine } from 'react-icons/ri';
import { CSSProperties } from 'styled-components';

const format = 'HH:mm';

interface IPropsOpenTime {
  nameForm: string;
  labelName: string;
  isEdit: boolean;
  formKey: any;
}
export interface RefDate {
  setCloseTimeError2: (value: string) => void;
  checked: boolean;
  onChangeCheck: (value: any) => void;
  isErr: boolean;
  validateRequire: (e: any, type: any) => void;
}

const styleTimeError: CSSProperties = {
  color: 'red',
};

function OpenTimeDayComponent(props: IPropsOpenTime, ref: Ref<RefDate>) {
  const { nameForm, labelName, isEdit } = props;
  const { t } = useTranslation(['company']);
  const [openTimeError, setOpenTimeError] = useState<string | undefined>();
  const [closeTimeError, setCloseTimeError] = useState<string | undefined>();
  const [closeTimeError2, setCloseTimeError2] = useState<string | undefined>();
  const [checked, setChecked] = useState<boolean>(false);

  const isErr = useRef(false);

  const validateRequire = (name: any, type: string) => {
    const value = typeof name === 'object' ? moment(name).format(format) : name || '';
    if (value.trim().length === 0) {
      switch (type) {
        case `${nameForm}_time_open`:
          isErr.current = true;
          setOpenTimeError(`${t('validate.required_opentime')}`);
          break;
        case `${nameForm}_time_close`:
          isErr.current = true;
          setCloseTimeError(`${t('validate.required_opentime')}`);
          break;
        default:
          break;
      }
    } else {
      switch (type) {
        case `${nameForm}_time_open`:
          setOpenTimeError('');
          break;
        case `${nameForm}_time_close`:
          setCloseTimeError('');
          isErr.current = false;
          break;
        default:
          break;
      }
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      setCloseTimeError2: setCloseTimeError2,
      checked: checked,
      onChangeCheck: onChangeCheck,
      isErr: isErr.current,
      validateRequire: validateRequire,
    }),
    // eslint-disable-next-line
    [checked, isErr.current],
  );

  const onChangeCheck = (event: any) => {
    setChecked(typeof event === 'object' ? event.target.checked : event);
    setOpenTimeError('');
    setCloseTimeError('');
    setCloseTimeError2('');
  };

  return (
    <>
      <Row gutter={16} className="w-100 align-items-center mb-2rem position-relative">
        <Col span={8}>
          <Checkbox disabled={!isEdit} checked={checked} onChange={onChangeCheck}>
            <span className="text-black fs-18">{labelName}</span>
          </Checkbox>
        </Col>
        <Col span={16}>
          <Row gutter={16} className="w-100 align-items-center">
            <Col className="position-relative">
              <Form.Item name={`${nameForm}_time_open`} className="mb-0 mr-0" trigger="onSelect">
                <TimePicker
                  className="w-100 color-333"
                  disabled={!checked || !isEdit}
                  onSelect={(e) => validateRequire(moment(e).format(format), `${nameForm}_time_open`)}
                  format={format}
                  placeholder={t('time_open.select_time_open')}
                  minuteStep={5}
                  size="large"
                />
              </Form.Item>
              <div style={styleTimeError} className="ant-form-item-explain-error position-absolute m-0 fs-14">
                {openTimeError && <div role="alert">{t(openTimeError)}</div>}
              </div>
            </Col>
            <Col className="text-center">
              <RiArrowRightLine color="#999999" size={24} />
            </Col>
            <Col>
              <Form.Item name={`${nameForm}_time_close`} className="mb-0" trigger="onSelect">
                <TimePicker
                  className="color-333"
                  disabled={!checked || !isEdit}
                  onSelect={(e) => validateRequire(moment(e).format(format), `${nameForm}_time_close`)}
                  format={format}
                  placeholder={t('time_open.select_time_close')}
                  minuteStep={5}
                  size="large"
                />
              </Form.Item>
              <div style={styleTimeError} className="ant-form-item-explain-error position-absolute m-0 fs-14">
                {closeTimeError && <div role="alert">{t(closeTimeError)}</div>}
              </div>
            </Col>
          </Row>
        </Col>
        <div
          style={{ ...styleTimeError, bottom: '-1.5rem' }}
          className="ant-form-item-explain-error position-absolute m-0 fs-14"
        >
          {closeTimeError2 && <div role="alert">{t(closeTimeError2)}</div>}
        </div>
      </Row>
    </>
  );
}

export default React.forwardRef(OpenTimeDayComponent);
