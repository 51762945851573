import { Media } from '@/graphql/generated/graphql';
import { SelectFolderActionTypes, SELECT_FOLDER } from '../action-types';

export interface SelectFolderState {
  loading?: boolean;
  selectedFolder?: Media;
  selectedParentFolder?: Media;
}

const initialState: SelectFolderState = {
  loading: false,
};

export default (state = initialState, action: SelectFolderActionTypes): SelectFolderState => {
  switch (action.type) {
    case SELECT_FOLDER:
      return {
        ...state,
        selectedFolder: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};
