import { Playlist } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { PlayListData } from '@modules/PlayList/types';

export const CREATE_PLAYLIST = 'CREATE_PLAYLIST';
export const CREATE_PLAYLIST_SUCCESS = 'CREATE_PLAYLIST_SUCCESS';
export const CREATE_PLAYLIST_ERROR = 'CREATE_PLAYLIST_ERROR';

export interface CreatePlaylistInputInterface extends PlayListData {
  isContinue?: boolean;
}

export interface CreatePlaylistAction {
  type: typeof CREATE_PLAYLIST;
  payload: CreatePlaylistInputInterface;
}

export interface CreatePlaylistActionSuccess {
  type: typeof CREATE_PLAYLIST_SUCCESS;
  payload: Playlist;
}

export interface CreatePlaylistActionError {
  type: typeof CREATE_PLAYLIST_ERROR;
  payload?: AppError;
}

export type CreatePlaylistActionTypes = CreatePlaylistAction | CreatePlaylistActionSuccess | CreatePlaylistActionError;
