import {
  RegisterActionTypes,
  RegisterInput,
  REGISTER,
  REGISTER_ERROR,
  REGISTER_SUCCESS,
  RegisterInfo,
} from '../action-types/register';
import { AppError } from '@commons/types';

export const register = (payload: RegisterInput): RegisterActionTypes => ({
  type: REGISTER,
  payload,
});

export const registerError = (payload: AppError): RegisterActionTypes => ({
  type: REGISTER_ERROR,
  payload,
});

export const registerSuccess = (payload: RegisterInfo): RegisterActionTypes => ({
  type: REGISTER_SUCCESS,
  payload,
});
