import { Media } from '@/graphql/generated/graphql';
import { UploadFileActionTypes, UPLOAD_FILE } from '../action-types';

export interface UploadFileState {
  loading?: boolean;
  isSuccess: boolean;
  data?: Media;
}

const initialState: UploadFileState = {
  loading: false,
  isSuccess: false,
};

export default (state = initialState, action: UploadFileActionTypes): UploadFileState => {
  switch (action.type) {
    case UPLOAD_FILE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return {
        ...state,
        isSuccess: false,
      };
  }
};
