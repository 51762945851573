import { Roles } from '@/graphql/generated/graphql';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { getHistory } from '@helpers/history';
import { LoginState } from '@modules/Auth/redux/action-types';
import { RootState } from '@redux/reducers';
import { Button, Col, Form, Input, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

interface PackageFormProps {
  isLoading: boolean;
  form: FormInstance;
  onFinish(values: any): void;
  isUpdate?: boolean;
  userData?: any;
}

export default function UpdateUserMemberForm(props: PackageFormProps) {
  const { t } = useTranslation(['user']);
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);

  const handleSubmit = (values: any) => {
    const data: any = {
      email: values.email,
      name: values.name,
      password: values.password,
      phone: values.phone,
    };
    props.onFinish(data);
  };

  return (
    <Form form={props.form} onFinish={handleSubmit} preserve={false}>
      <Form.Item name="username" label={t('username')} labelCol={{ span: 6 }}>
        {props.userData?.username}
      </Form.Item>
      <Form.Item
        name="name"
        label={t('full_name')}
        rules={[{ required: true, message: t('validate.required_name') }]}
        labelCol={{ span: 6 }}
      >
        <Input style={{ width: '100%', maxWidth: 485 }} placeholder={t('user_name')} />
      </Form.Item>
      <Form.Item
        name="phone"
        label={t('phone')}
        labelCol={{ span: 6 }}
        rules={[{ pattern: /^[\d+]{1,13}$/, message: t('validate.regex_phone') }]}
      >
        <Input style={{ width: '100%', maxWidth: 485 }} placeholder={t('phone')} />
      </Form.Item>
      <Form.Item
        name="password"
        label={t('password')}
        labelCol={{ span: 6 }}
        rules={[{ min: 6, message: t('validate.required_password_min') }]}
      >
        <Input.Password
          autoComplete="new-password"
          style={{ width: '100%', maxWidth: 485 }}
          placeholder={t('password')}
          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        />
      </Form.Item>
      {loginState.data?.role === Roles.SuperAdmin || loginState.data?.isSuperAdmin ? null : (
        <Form.Item name="role" label={t('role')} labelCol={{ span: 6 }}>
          <Input style={{ width: '100%', maxWidth: 485 }} placeholder={t('role')} disabled />
        </Form.Item>
      )}
      <Row>
        <Col sm={{ span: 18, offset: 6 }}>
          <Button htmlType="submit" key="submit" className="btn-dangerous" loading={props.isLoading}>
            {t('save')}
          </Button>
          <Button htmlType="button" type="default" onClick={() => getHistory().push(`/user/list`)} className="mx-3">
            {t('cancel_add')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
