/* eslint-disable array-callback-return */
import { PlaylistSchedule } from '@modules/GroupDevice/types';
import {
  ADD_PLAYLIST_SCHEDULES_GROUP,
  ADD_PLAYLIST_SCHEDULES_SECOND_GROUP,
  CANCEL_SCHEDULES_GROUP_ACTION,
  REMOVE_PLAYLIST_SCHEDULES_GROUP,
  REMOVE_PLAYLIST_SCHEDULES_SECOND_GROUP,
  UpdateSchedulesGroupActionTypes,
  UPDATE_PLAYLIST_SCHEDULES_GROUP,
  UPDATE_PLAYLIST_SCHEDULES_SECOND_GROUP,
  UPDATE_SCHEDULES_GROUP_ACTION,
  UPDATE_SCHEDULES_GROUP_ERROR,
  UPDATE_SCHEDULES_GROUP_SUCCESS,
  UPDATE_SCHEDULES_SECOND_GROUP_SUCCESS,
} from '../action-types';
import { UPDATE_PLAYLIST_SUCCESS, UpdatePlaylistActionTypes } from '@modules/PlayList/redux/action-types';

export interface UpdateGroupDeviceSchedulesState {
  loading?: boolean;
  isSuccess: boolean;
  data: PlaylistSchedule[];
  schedulesSecond: PlaylistSchedule[];
}

const initialState: UpdateGroupDeviceSchedulesState = {
  loading: false,
  isSuccess: false,
  data: [],
  schedulesSecond: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state = initialState,
  action: UpdateSchedulesGroupActionTypes | UpdatePlaylistActionTypes,
): UpdateGroupDeviceSchedulesState => {
  switch (action.type) {
    case UPDATE_SCHEDULES_GROUP_ACTION: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_PLAYLIST_SCHEDULES_GROUP: {
      const nData: PlaylistSchedule[] = state?.data ? [...state?.data] : [];
      if (
        nData.length > 0 &&
        nData.some((item) => item.playlistId === action.payload.playlistId && item.index === action.payload.index)
      ) {
        // eslint-disable-next-line array-callback-return
        nData?.map((item, index) => {
          if (item.playlistId === action.payload.playlistId && item.index === action.payload.index) {
            nData[index] = {
              ...item,
              ...action.payload,
            };
          }
        });
      } else {
        nData.push({
          ...action.payload,
          index: nData.length,
        });
      }
      return {
        ...state,
        data: nData,
        loading: false,
      };
    }
    case UPDATE_PLAYLIST_SCHEDULES_SECOND_GROUP: {
      const nDataSe: PlaylistSchedule[] = state?.schedulesSecond ? [...state?.schedulesSecond] : [];
      if (
        nDataSe.length > 0 &&
        nDataSe.some((item) => item.playlistId === action.payload.playlistId && item.index === action.payload.index)
      ) {
        // eslint-disable-next-line array-callback-return
        nDataSe?.map((item, index) => {
          if (item.playlistId === action.payload.playlistId && item.index === action.payload.index) {
            nDataSe[index] = {
              ...item,
              ...action.payload,
            };
          }
        });
      } else {
        nDataSe.push({
          ...action.payload,
          index: nDataSe.length,
        });
      }
      return {
        ...state,
        schedulesSecond: nDataSe,
        loading: false,
      };
    }
    case UPDATE_SCHEDULES_GROUP_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ADD_PLAYLIST_SCHEDULES_GROUP:
      const schedules: PlaylistSchedule[] = state?.data ? [...state?.data] : [];
      // if (!schedules.some((item) => item.playlistId === action.payload.playlistId)) {
      const newPlaylist = {
        playlistId: action.payload.playlistId,
        playlistName: action.payload.playlistName,
        type: action.payload.type,
        index: schedules.length,
        createdById: action.payload.createdById,
      };
      schedules.push(newPlaylist);
      // }
      return {
        ...state,
        data: schedules,
        loading: false,
      };
    case REMOVE_PLAYLIST_SCHEDULES_GROUP:
      const listSchedule = state.data.filter((item) => item.index !== action.payload.index);
      listSchedule.forEach((item, index) => (item.index = index));
      return {
        ...state,
        data: listSchedule,
        loading: false,
      };
    case REMOVE_PLAYLIST_SCHEDULES_SECOND_GROUP:
      const nScheduleSe = state.schedulesSecond.filter((item) => item.index !== action.payload.index);
      nScheduleSe.forEach((item, index) => (item.index = index));
      return {
        ...state,
        schedulesSecond: nScheduleSe,
        loading: false,
      };
    case CANCEL_SCHEDULES_GROUP_ACTION:
      return {
        ...state,
        data: [],
        loading: false,
      };
    case UPDATE_SCHEDULES_GROUP_SUCCESS:
      const newSchedules: PlaylistSchedule[] = [];
      action?.payload?.filter((item, index) => {
        newSchedules.push({
          ...item,
          index,
        });
      });
      return {
        ...state,
        data: newSchedules,
        loading: false,
      };
    case ADD_PLAYLIST_SCHEDULES_SECOND_GROUP:
      const schedulesSe: PlaylistSchedule[] = state?.schedulesSecond ? [...state?.schedulesSecond] : [];
      schedulesSe.push({
        playlistId: action.payload.playlistId,
        playlistName: action.payload.playlistName,
        type: action.payload.type,
        index: schedulesSe.length,
        createdById: action.payload.createdById,
      });
      return {
        ...state,
        schedulesSecond: schedulesSe,
        loading: false,
      };
    case UPDATE_SCHEDULES_SECOND_GROUP_SUCCESS: {
      const schedulesSecond: PlaylistSchedule[] = [];
      action?.payload?.filter((item, index) => {
        schedulesSecond.push({
          ...item,
          index,
        });
      });
      return {
        ...state,
        schedulesSecond,
        loading: false,
      };
    }
    case UPDATE_PLAYLIST_SUCCESS: {
      const playlistUpdated = action.payload;
      const playlists = state.data.map((playlist) => {
        if (playlistUpdated?._id === playlist.playlistId) {
          return {
            ...playlist,
            playlist: {
              _id: playlistUpdated._id || '',
              companyId: playlistUpdated.companyId || '',
              name: playlistUpdated.name,
              createdAt: playlistUpdated.createdAt || 0,
              createdById: playlistUpdated.createdById || '',
              frames: playlistUpdated.frames || [],
            },
          };
        }
        return playlist;
      });
      const playlistsSecond = state.schedulesSecond.map((playlist) => {
        if (playlistUpdated?._id === playlist.playlistId) {
          return {
            ...playlist,
            playlist: {
              _id: playlistUpdated._id || '',
              companyId: playlistUpdated.companyId || '',
              name: playlistUpdated.name,
              createdAt: playlistUpdated.createdAt || 0,
              createdById: playlistUpdated.createdById || '',
              frames: playlistUpdated.frames || [],
            },
          };
        }
        return playlist;
      });
      return {
        ...state,
        data: playlists,
        schedulesSecond: playlistsSecond,
      };
    }
    default:
      return {
        ...state,
        isSuccess: false,
      };
  }
};
