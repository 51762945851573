/* eslint-disable array-callback-return */
import { Group } from '@/graphql/generated/graphql';
import {
  ADD_CURRENT_GROUP_ACTION,
  CurrentGroupActionTypes,
  REMOVE_CURRENT_GROUP_ACTION,
  UPDATE_CURRENT_GROUP_ACTIVE_SCREEN_ACTION,
  UPDATE_LAYOUT_CURRENT_GROUP_ACTION,
} from '../action-types';

export interface CurrentGroupState {
  loading?: boolean;
  data?: Group;
  screenActive: number;
}

const initialState: CurrentGroupState = {
  data: undefined,
  screenActive: 1,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: CurrentGroupActionTypes): CurrentGroupState => {
  switch (action.type) {
    case ADD_CURRENT_GROUP_ACTION:
      return {
        ...state,
        data: action.payload,
      };
    case REMOVE_CURRENT_GROUP_ACTION:
      return {
        ...state,
        data: undefined,
      };
    case UPDATE_LAYOUT_CURRENT_GROUP_ACTION:
      return {
        ...state,
        data: {
          ...state.data,
          layoutSettings: {
            ...state.data?.layoutSettings,
            screenType: action.payload.screenType,
            orientation: action.payload.orientation,
          },
        } as Group,
      };
    case UPDATE_CURRENT_GROUP_ACTIVE_SCREEN_ACTION:
      return {
        ...state,
        screenActive: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};
