import { Capacity, CreateCompanyInput, UserLimit } from '@/graphql/generated/graphql';
import { getHistory } from '@helpers/history';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import CompanyForm from '@modules/Company/components/Form/UpdateCompany';
import useAddNewCompany from '@modules/Company/hooks/useAddNewCompany';
import { Capacities, UserLimits } from '@modules/Company/types';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function AddNewCompanyPage() {
  const { t } = useTranslation(['company']);
  const { isLoading, isSuccess, mutate, data } = useAddNewCompany();
  const { setPageTitle } = useSetPageTitle();

  useEffect(() => {
    t && setPageTitle(t('add_new_company'));
    // eslint-disable-next-line
  }, [t]);

  function onFinish(values: any) {
    const capacity = Capacities.filter((item) => item.key === values.capacity);
    const userLimit = UserLimits.filter((item) => item.key === values.userLimit);
    const payload: CreateCompanyInput = {
      email: values.email,
      phone: values.phone,
      name: values.name,
      address: values.address,
      startDate: values.startDate ? moment(values.startDate).valueOf() : null,
      endDate: values.endDate ? moment(values.endDate).valueOf() : null,
      capacity: capacity.length > 0 ? (capacity[0].name as Capacity) : undefined,
      userLimit: userLimit.length > 0 ? (userLimit[0].name as UserLimit) : undefined,
      description: values.description,
      // domain: values.domain,
      address_latitude: values.address_latitude,
      address_longitude: values.address_longitude,
      limitUpload: values.limitUpload,
    };
    mutate({ input: payload });
  }

  useEffect(() => {
    if (data && data.createCompany) {
      getHistory().push('/company/list');
    }
  }, [data]);

  React.useEffect(() => {
    if (isSuccess) getHistory().push('/company/list');
  }, [isSuccess]);

  return <CompanyForm isLoading={isLoading} onFinish={onFinish} classNameForm="h-auto" />;
}
