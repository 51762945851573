import { UpdateProfileInput } from '@/graphql/generated/graphql';
import FormSkeleton from '@commons/components/Skeletons/FormSkeleton';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import UpdateProfileForm from '@modules/Profile/components/Form/UpdateProfile';
import useQueryMe from '@modules/Profile/hooks/useQueryMe';
import useUpdateProfile from '@modules/Profile/hooks/useUpdateProfile';
import { Form } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function UpdateProfilePage() {
  const [form] = Form.useForm();

  const { setPageTitle } = useSetPageTitle();

  const { data, isLoading } = useQueryMe();
  const { t } = useTranslation(['user']);

  useEffect(() => {
    t && setPageTitle(t('update_profile'));
    // eslint-disable-next-line
  }, [t]);

  const { mutate, isLoading: isSaving } = useUpdateProfile();

  function onFinish(values: UpdateProfileInput) {
    mutate({ input: values });
  }

  useEffect(() => {
    if (data?.me) {
      form.setFieldsValue({
        ...data.me,
      });
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <div className="h-100">
      <FormSkeleton loading={isLoading}>
        <UpdateProfileForm isLoading={isSaving} onFinish={onFinish} form={form} isUpdate={true} userData={data?.me} />
      </FormSkeleton>
    </div>
  );
}
