import ic_dashboard from '@assets/images/left_nav/dashboard.svg';
import ic_device from '@assets/images/left_nav/device.svg';
import ic_group_device from '@assets/images/left_nav/group-device.svg';
import ic_media from '@assets/images/left_nav/media.svg';
import ic_pending_approval from '@assets/images/left_nav/pending_approval.svg';
import ic_playlist from '@assets/images/left_nav/playlist.svg';
import { getHistory } from '@helpers/history';
import { Menu } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

// tslint:disable-next-line:interface-name
interface IProps {
  isCollapsed: boolean;
}

export default function AdminLeftNavigation({ isCollapsed }: IProps) {
  const handleSelectMenu = ({ key }: any) => {
    getHistory().push(key);
  };

  const { t } = useTranslation(['leftNavigation']);

  const { pathname, search } = useLocation();

  const selectedKeys = useMemo(() => {
    const keys = `${pathname + search}`
      .split('/')
      .filter((r) => r)
      .reduce<string[]>((ac, cu) => {
        if (['company', 'playlist'].includes(cu)) {
          //hotfix menu for company and playlist
          ac.push(`/${cu}/list`);
        }
        if (ac.length) {
          ac.push(`${ac[ac.length - 1]}/${cu}`);
        } else {
          ac.push(`/${cu}`);
        }
        return ac;
      }, []);

    if (keys.length) return keys;
    return ['/'];
  }, [pathname, search]);

  const IconMenuItem = (key: string, icon: any, alt: string) => {
    return <img src={icon} alt={alt} />;
  };

  const mouseDownHandler = (event: any, key: any) => {
    if (event.button === 1) {
      window.open(key);
    }
  };

  return (
    <>
      {/* Role Admin */}
      <Menu
        className="left-menu-content"
        mode="inline"
        onSelect={handleSelectMenu}
        selectedKeys={selectedKeys}
        defaultOpenKeys={selectedKeys}
        theme={'dark'}
      >
        <Menu.Item
          key={'/'}
          icon={IconMenuItem('/', ic_dashboard, 'ic_dashboard')}
          onMouseDown={(env) => mouseDownHandler(env, '/')}
        >
          {t('dashboard')}
        </Menu.Item>
        <Menu.Item
          key={'/device/list'}
          icon={IconMenuItem(`/device/list`, ic_device, 'ic_device')}
          onMouseDown={(env) => mouseDownHandler(env, '/device/list')}
        >
          {t('device')}
        </Menu.Item>
        <Menu.Item
          key="/playlist/list"
          icon={IconMenuItem('/playlist/list', ic_playlist, 'ic_playlist')}
          onMouseDown={(env) => mouseDownHandler(env, '/playlist/list')}
        >
          {t('playlist')}
        </Menu.Item>
        <Menu.Item
          key={'/group-device/list'}
          icon={IconMenuItem(`/group-device/list`, ic_group_device, 'ic_group_device')}
          onMouseDown={(env) => mouseDownHandler(env, '/group-device/list')}
        >
          {t('group')}
        </Menu.Item>
        <Menu.Item
          key={'/group-device/pending-approve'}
          icon={IconMenuItem(`/group-device/list`, ic_pending_approval, 'ic_pending_approval')}
          onMouseDown={(env) => mouseDownHandler(env, '/group-device/pending-approve')}
        >
          {t('pending_approval')}
        </Menu.Item>
        <Menu.Item
          key={'/media'}
          icon={IconMenuItem(`/group-device/list`, ic_media, 'ic_media')}
          onMouseDown={(env) => mouseDownHandler(env, '/group-device/list')}
        >
          {t('media')}
        </Menu.Item>
      </Menu>
    </>
  );
}
