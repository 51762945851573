import { PlaylistStatus } from '@/graphql/generated/graphql';

export const UPDATE_PLAYLIST_STATE = 'UPDATE_PLAYLIST_STATE';

export interface UpdatePlaylistStateInput {
  name?: string;
  status?: PlaylistStatus;
}

export interface UpdatePlaylistStateAction {
  type: typeof UPDATE_PLAYLIST_STATE;
  payload: UpdatePlaylistStateInput;
}

export type UpdatePlaylistStateActionTypes = UpdatePlaylistStateAction;
