import { FileType, Media, MediaOrderField, QueryMediasInput, Sort } from '@/graphql/generated/graphql';
import icon_grid from '@assets/images/icons/Icon_grid.svg';
import icon_grid_active from '@assets/images/icons/Icon_grid_active.svg';
import icon_list from '@assets/images/icons/Icon_list.svg';
import icon_list_active from '@assets/images/icons/Icon_list_active.svg';
import ModalConfirm from '@commons/components/modals/ModalConfirm';
import { useCustomSelector } from '@hooks/useCustomSelector';
import { usePrevious } from '@hooks/usePrevious';
import DeleteModal from '@modules/Media/component/modal/DeleteModal';
import MoveModal from '@modules/Media/component/modal/MoveModal';
import useDeleteMedia from '@modules/Media/hooks/useDeleteMedia';
import useDeleteMediaItem from '@modules/Media/hooks/useDeleteMediaItem';
import useGetAllFolder from '@modules/Media/hooks/useGetAllFolder';
import useGetAllMedia from '@modules/Media/hooks/useGetAllMedia';
import useGoBackFolder from '@modules/Media/hooks/useGoBackFolder';
import useMoveFile from '@modules/Media/hooks/useMoveFile';
import useSelectFolder from '@modules/Media/hooks/useSelectFolder';
import { pageDefault } from '@modules/Media/types';
import { getQueryVariable } from '@modules/Setting/helpers/utils';
import { Breadcrumb, Form, Spin } from 'antd';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TypeViewMedia } from '..';
import MediaComponent, { IFRefMediaComponent } from './media-component';
import MediaHeaderComponent from './media-header.component';
import MediaInfoComponent from './media-info.component';

export interface Breadcrumb {
  id: string;
  folderId?: string;
  title: string;
  folder?: Media;
}

function MediaContentComponent(props: any) {
  const defaultSearchInput = {
    page: pageDefault.page,
    limit: pageDefault.pageSize,
    filterType: null,
    isLoadMore: false,
    parentId: null,
    query: undefined,
    order: { direction: Sort.Desc, field: MediaOrderField.CreatedAt },
  };

  // page: pageDefault.page,
  // limit: pageDefault.pageSize,
  // filterType: null,
  // isLoadMore: false,
  // parentId: null,
  // order: { direction: 'Desc', field: 'CREATED_AT' },
  // query: null,

  const { t } = useTranslation(['media']);

  const { onGetFolders } = useGetAllFolder();
  const { onGetMedias } = useGetAllMedia();
  const { onDeleteMedia } = useDeleteMedia();
  const { onMoveFile } = useMoveFile();

  const [typeView, setTypeView] = useState<TypeViewMedia>(TypeViewMedia.GRID);
  const { selectedFolder, onSelectFolder } = useSelectFolder();
  const { data: mediasData, loading: loadingMedia } = useCustomSelector((state) => {
    return {
      data: state?.media?.list?.data,
      loading: state.media.list.loading,
    };
  });

  const { onGoBack: onGoBackAction, breadcrumbs } = useGoBackFolder();
  const refMediaComponent = React.useRef<IFRefMediaComponent | null>(null);
  const [mediaInfo, setMediaInfo] = React.useState<Media | undefined>(undefined);
  const [currentStorage, setCurrentStorage] = useState<Number>(0);
  const [limitStorage, setLimitStorage] = useState<Number>(0);
  const [filterTitle, setFilterTitle] = useState<string>('');
  const [filterType, setFilterType] = useState<any>(null);
  const [form] = Form.useForm();
  const { item: deleteItem, onDeleteItem } = useDeleteMediaItem();

  const [searchState, setSearchState] = useState<QueryMediasInput>(defaultSearchInput);
  const prevSearchState = usePrevious(searchState);

  useEffect(() => {
    refMediaComponent?.current?.onResetSelectMedia?.();
    onSelectFolder(null);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isEqual(searchState, prevSearchState)) {
      onGetMedias(searchState);
      onGetFolders({
        limit: 100,
        type: FileType.Folder,
      });
    }
    // eslint-disable-next-line
  }, [searchState, prevSearchState]);

  const onRefreshSearch = (filter: QueryMediasInput) => {
    if (!isEqual(filter, prevSearchState)) {
      onGetMedias(filter);
      onGetFolders({
        limit: 100,
        type: FileType.Folder,
      });
    }
  };

  const idQuery = getQueryVariable('id');

  useEffect(() => {
    if (selectedFolder) {
      setSearchState({
        ...searchState,
        filterType: null,
        parentId: selectedFolder?._id.toString() || null,
        query: undefined,
      });
    } else {
      setSearchState({
        ...searchState,
        filterType: null,
        parentId: undefined,
        query: undefined,
      });
    }

    setFilterTitle('');
    form.resetFields();
    // eslint-disable-next-line
  }, [selectedFolder]);

  useEffect(() => {
    if (!idQuery) {
      onSelectFolder(null);
    } else {
      const folder = breadcrumbs?.find((e) => e._id === idQuery);
      if (folder) {
        onSelectFolder({ ...folder!, updatedById: 'anyUser' });
      }
    }
    // eslint-disable-next-line
  }, [idQuery]);

  function onUpdateMediaInfo(media?: Media) {
    setMediaInfo(media);
  }

  const onChangeTypeView = (type: TypeViewMedia) => {
    setTypeView(type);
  };

  function onClickContent() {
    refMediaComponent?.current?.onResetSelectMedia?.();
  }

  // Get selected media
  const onSelectedMedias = (values: Media[] | []) => {
    onUpdateMediaInfo(values[values.length - 1]);
    if (props?.onSelectedItem) {
      props?.onSelectedItem(values);
    }
  };

  const deleteMedia = () => {
    if (deleteItem) {
      onDeleteMedia({ _id: deleteItem._id });
      onDeleteItem(undefined);
      onUpdateMediaInfo();
      setCurrentStorage((storage) => Number(storage) - Number(deleteItem.size || 0));
    }
  };

  const moveFile = (item: Media, parentId: any) => {
    onMoveFile({
      _id: item?._id,
      parentId: parentId,
    });
    onUpdateMediaInfo();
  };

  const onGoBack = () => {
    onUpdateMediaInfo();
    if (mediasData?.currentFolder) {
      setSearchState({
        ...searchState,
        filterType: null,
        parentId: mediasData?.currentFolder.parentId,
        isLoadMore: false,
      });
      onGoBackAction(mediasData?.currentFolder);
    }
    setFilterTitle('');
  };

  const renderBreadcrumb = () => {
    const list: Breadcrumb[] = [
      {
        id: 'all-file',
        folderId: undefined,
        title: t('all_file'),
        folder: undefined,
      },
    ];
    if (mediasData?.currentFolder) {
      breadcrumbs?.map((breadcrumb: Media) => {
        list.push({
          id: breadcrumb._id,
          folderId: breadcrumb._id,
          title: breadcrumb.filename,
          folder: breadcrumb,
        });
      });
    }

    return (
      <div className="breadcrumb_media" style={{ background: 'white' }}>
        <Breadcrumb>
          {list.map((breadcrumb: Breadcrumb) => (
            <Breadcrumb.Item
              key={breadcrumb.id}
              onClick={() => {
                if (selectedFolder && breadcrumb.id !== selectedFolder?._id) {
                  onSelectFolder(breadcrumb.folder);
                }
                onUpdateMediaInfo();
              }}
              className={`breadcrumb_title  ${breadcrumb.id !== selectedFolder?._id ? 'cursor-pointer' : ''}`}
            >
              {breadcrumb.title}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <div style={{ color: currentStorage > limitStorage ? 'red' : 'black' }}>
          {t('uploaded')}: {currentStorage > 0 ? currentStorage.toFixed(2) : 0}/{limitStorage} MB
        </div>
        <div className="view_group">
          <img
            onClick={() => {
              onChangeTypeView(TypeViewMedia.LIST);
            }}
            src={typeView === TypeViewMedia.LIST ? icon_list_active : icon_list}
            width={22}
            height={20}
            alt="view list"
          />
          <img
            onClick={() => {
              onChangeTypeView(TypeViewMedia.GRID);
            }}
            src={typeView === TypeViewMedia.GRID ? icon_grid_active : icon_grid}
            width={20}
            height={20}
            alt="view grid"
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <MediaHeaderComponent
        filterType={filterType}
        setFilterType={setFilterType}
        filterTitle={filterTitle}
        setFilterTitle={setFilterTitle}
        searchState={searchState}
        setSearchState={onRefreshSearch}
        mediaInfo={mediaInfo}
        setMediaInfo={setMediaInfo}
        onSelectFolder={onSelectFolder}
        currentStorage={currentStorage}
        setLimitStorage={setLimitStorage}
        setCurrentStorage={setCurrentStorage}
      />
      <div id="media_container_page" className="media-container-page position-relative ">
        <div className="list-media-container" onClick={onClickContent}>
          {renderBreadcrumb()}

          {loadingMedia ? (
            <div className="bg-white d-flex justify-content-center mt-5" style={{ width: '100%', height: '100vh' }}>
              <Spin />
            </div>
          ) : (
            <div className="media-list">
              <MediaComponent
                ref={refMediaComponent}
                onUpdateMediaInfo={onUpdateMediaInfo}
                onSelectedMedias={onSelectedMedias}
                multiple={props.multiple !== undefined ? props.multiple : true}
                onGoBack={onGoBack}
                filterType={filterType}
                isProvider={false}
                typeView={typeView}
              />
            </div>
          )}
        </div>
        <MediaInfoComponent media={mediaInfo} />
      </div>
      <DeleteModal />
      <MoveModal onMoveFile={moveFile} />
      <ModalConfirm
        isLoading={false}
        data={!!deleteItem}
        handleOk={deleteMedia}
        handleCancel={() => {
          onDeleteItem(undefined);
        }}
        title={t('delete_title')}
        okTitle={t('delete')}
        content={
          <Trans
            i18nKey="delete_content"
            t={t}
            values={{ name: deleteItem?.filename }}
            components={[<span className="primary_color fw-500"></span>]}
          />
        }
      />
    </>
  );
}
export default React.memo(MediaContentComponent);
