import env from '@/env';
import { Device, WifiType } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { LockOutlined, RetweetOutlined, WifiOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import ButtonCancel from '@commons/components/modals/Button/ButtonCancel';
import ButtonOk from '@commons/components/modals/Button/ButtonOk';
import { NotificationSuccess } from '@commons/components/Notification';
import useConnectWifi from '@modules/Device/hooks/useConnectWifi';
import useScanWifi from '@modules/Device/hooks/useScanWifi';
import useUpdateSettingDevice from '@modules/Device/hooks/useUpdateSettingDevice';
import { useGetWifiConnectStatus, useGetWifiList } from '@modules/Device/services/apis';
import { Button, Form, Input, Modal, Select, DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;

interface IProps {
  onCancel: () => void;
  settingDevice?: Device;
}

interface IState {
  wifiList: WifiType[];
  password: string | null | undefined;
}

export default function SettingDeviceModal(props: IProps) {
  const { t } = useTranslation(['device']);
  const [form] = Form.useForm();
  const [selectWifi, setSelectWifi] = useState<WifiType>();
  const { mutate: getWifiList, isLoading: getWifiLoading } = useScanWifi();
  const { mutate: onUpdateSettingDevice, isLoading: loadingUpdateSetting } = useUpdateSettingDevice();
  const wifi = useGetWifiList(props.settingDevice);
  const wifiConnectStatus = useGetWifiConnectStatus(props.settingDevice);
  const { mutate: connectWifi, isLoading: loadingConnectWifi } = useConnectWifi();
  const [statusWifi, setStatusWifi] = useState<boolean | undefined | null>();

  const [state, setState] = useState<IState>({
    wifiList: [],
    password: null,
  });

  useEffect(() => {
    setState({ ...state, wifiList: wifi });
    if (wifi.length > 0) {
      NotificationSuccess(i18n.t('notification'), t('scan_wifi_success'));
    }
    // eslint-disable-next-line
  }, [wifi]);

  useEffect(() => {
    if (props.settingDevice) {
      const wifi = props.settingDevice.wifi ? props.settingDevice.wifi : undefined;
      form.setFieldsValue({
        wifi: wifi?.ssid,
        password: wifi?.password,
        subscription_date: [
          props.settingDevice?.subscriptionDate?.fromDate
            ? moment(props.settingDevice?.subscriptionDate?.fromDate, env.formatDate)
            : null,
          props.settingDevice?.subscriptionDate?.toDate
            ? moment(props.settingDevice?.subscriptionDate?.toDate, env.formatDate)
            : null,
        ],
      });
      if (props.settingDevice.wifi) {
        setSelectWifi(props.settingDevice.wifi);
        setStatusWifi(props.settingDevice.wifi.status);
        setState({ ...state, password: props.settingDevice.wifi.password });
      }
    }
    // eslint-disable-next-line
  }, [props.settingDevice]);

  const onOk = () => {
    form.submit();
  };

  const onFinish = (value: any) => {
    if (props.settingDevice?._id && props.settingDevice?.companyId) {
      if (selectWifi) {
        onUpdateSettingDevice({
          input: {
            _id: props.settingDevice?._id,
            subscriptionDate: {
              fromDate: value?.subscription_date[0].format(env.formatDate) || '',
              toDate: value?.subscription_date[1].format(env.formatDate) || '',
            },
            wifi: {
              ssid: selectWifi.ssid,
              signalLevel: Number(selectWifi.signalLevel),
              encryptMode: selectWifi.encryptMode,
              password: state.password,
              status: statusWifi,
            },
          },
        });
      } else {
        onUpdateSettingDevice({
          input: {
            _id: props.settingDevice?._id,
            subscriptionDate: {
              fromDate: value?.subscription_date[0].format(env.formatDate) || '',
              toDate: value?.subscription_date[1].format(env.formatDate) || '',
            },
          },
        });
      }
    }
  };

  const onScanWifi = () => {
    if (props.settingDevice?._id) {
      if (props.settingDevice?.companyId) {
        getWifiList({ input: { _id: props.settingDevice?._id, companyId: props.settingDevice?.companyId } });
      } else {
        getWifiList({ input: { _id: props.settingDevice?._id } });
      }
    }
  };

  const onSelectWifi = (value: any) => {
    if (state.wifiList.length) {
      const wifi = state.wifiList.find((w) => {
        return w.ssid === value;
      });
      if (wifi?.encryptMode === 'off' && props.settingDevice?._id) {
        connectWifi({
          input: {
            _id: props.settingDevice?._id,
            ssid: value,
          },
        });
      }
      setSelectWifi(wifi);
    }
  };

  const onConnectWifi = () => {
    setStatusWifi(undefined);
    if (selectWifi && props.settingDevice?._id && (state.password?.length || 0) >= 8) {
      connectWifi({
        input: {
          _id: props.settingDevice?._id,
          ssid: selectWifi.ssid,
          password: state.password,
        },
      });
    }
  };

  const labelStatusConnect = (status?: boolean) => {
    return (
      <div className="container_input">
        <span className="label_input" style={{ height: 'auto' }}>
          {t('status_connect_wifi')}
        </span>
        <div className="d-flex w-100">{status === true ? t('success') : status === false ? t('error') : ''}</div>
      </div>
    );
  };

  const onCancel = () => {
    props.onCancel();
    form.resetFields();
    setSelectWifi(undefined);
    setState({ wifiList: [], password: null });
    setStatusWifi(undefined);
    console.log('on cancel');
  };

  useEffect(() => {
    setStatusWifi(wifiConnectStatus?.status);
    if (wifiConnectStatus?.status) {
      NotificationSuccess(i18n.t('notification'), t('connect_wifi_success'));
    }
    // eslint-disable-next-line
  }, [wifiConnectStatus?.status]);

  return (
    <Modal
      centered
      visible={!!props.settingDevice}
      onCancel={onCancel}
      className="modal_confirm_custom modal_setting_deivce"
      title={t('setting_device')}
      footer={[
        <ButtonCancel key={'btn_cancel'} title="Cancel" classNameBtn={`mr-2 `} onClick={onCancel} />,
        <ButtonOk key={'btn_ok'} onClick={onOk} title={t('save')} classNameBtn={``} isLoading={loadingUpdateSetting} />,
      ]}
    >
      <div>
        <Form key="form_add_device" form={form} onFinish={onFinish} colon={false} labelAlign="left">
          <div className="container_input">
            <span className="label_input">{t('subscription_date')}</span>
            <Form.Item name="subscription_date" className="bg_input_form mb-0 select_input" style={{ flex: 1 }}>
              <RangePicker showTime={false} format={`${env.formatDate}`} />
            </Form.Item>
          </div>
          <div className="container_input">
            <span className="label_input">{t('setup_wifi')}</span>
            <div className="d-flex w-100">
              <Form.Item name="wifi" labelCol={{ span: 6 }} className="bg_input_form  mb-0 mr-4 w-100">
                <Select
                  style={{ width: '100%' }}
                  onChange={(value) => {
                    onSelectWifi(value);
                  }}
                >
                  {state.wifiList &&
                    state.wifiList.map((wifi, index) => {
                      return (
                        <Select.Option title={wifi.ssid} key={index} value={wifi.ssid}>
                          <div className="d-flex justify-content-between align-items-center w-full">
                            {' '}
                            {wifi.ssid} {wifi.encryptMode === 'on' ? <LockOutlined /> : undefined}{' '}
                          </div>
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Button className="btn_scan" icon={<WifiOutlined />} onClick={onScanWifi} loading={getWifiLoading}>
                {t('scan_wifi')}
              </Button>
            </div>
          </div>
          {selectWifi && selectWifi.encryptMode === 'on' && (
            <div className="container_input">
              <span className="label_input">{t('password')}</span>
              <div className="d-flex w-100">
                <Form.Item
                  name="password"
                  rules={[{ min: 8, message: t('validate.field_short') }]}
                  labelCol={{ span: 6 }}
                  className="bg_input_form  mb-0 mr-4 w-100"
                >
                  <Input.Password
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    onChange={(e) => {
                      setState({ ...state, password: e.target.value });
                    }}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Button
                  className="btn_scan"
                  icon={<RetweetOutlined />}
                  onClick={onConnectWifi}
                  loading={loadingConnectWifi}
                >
                  {t('connect')}
                </Button>
              </div>
            </div>
          )}
        </Form>
        {statusWifi === true
          ? labelStatusConnect(true)
          : statusWifi === false
          ? labelStatusConnect(false)
          : labelStatusConnect()}
      </div>
    </Modal>
  );
}
