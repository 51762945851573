import { DayOfWeek, OpenTimeInput, UpdateCompanyInput } from '@/graphql/generated/graphql';
import FormHeader from '@commons/components/FormHeader';
import TableHeader from '@commons/components/TableHeader';
import { getHistory } from '@helpers/history';
import { LoginState } from '@modules/Auth/redux/action-types';
import useGetCompanyByID from '@modules/Company/hooks/useGetCompanyByID';
import useUpdateCompany from '@modules/Company/hooks/useUpdateCompany';
import { RootState } from '@redux/reducers';
import { Button, Form, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import OpenTimeDayComponent, { RefDate } from './open-time-day-component';

const format = 'HH:mm';

const ArrayDay = [
  {
    day: DayOfWeek.Monday,
    name: 'Monday',
  },
  {
    day: DayOfWeek.Tuesday,
    name: 'Tuesday',
  },
  {
    day: DayOfWeek.Wednesday,
    name: 'Wednesday',
  },
  {
    day: DayOfWeek.Thursday,
    name: 'Thursday',
  },
  {
    day: DayOfWeek.Friday,
    name: 'Friday',
  },
  {
    day: DayOfWeek.Saturday,
    name: 'Saturday',
  },
  {
    day: DayOfWeek.Sunday,
    name: 'Sunday',
  },
];

export default function OpenTimePage() {
  const { t } = useTranslation(['company']);

  const { id } = useParams<{ id: string }>();

  const { data } = useGetCompanyByID({
    _id: id,
  });
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);
  const openTimeDetail = data?.company?.openTimes;

  //   const { openTimeDetail, getOpenTime, createOpenTime } = useOpenTime();
  const refForm = new Map<string, RefDate>();
  const [isEdit, setIsEdit] = useState(true);
  const [form] = useForm();

  const { mutate } = useUpdateCompany();

  useEffect(() => {
    if (openTimeDetail && openTimeDetail.length > 0) {
      openTimeDetail.forEach((e) => {
        form.setFieldsValue({
          [`${e.day}_time_open`]: e.openTime > 0 ? moment(moment(e.openTime).utc().format(format), format) : '',
          [`${e.day}_time_close`]: e.closeTime > 0 ? moment(moment(e.closeTime).utc().format(format), format) : '',
        });
        refForm.get(e.day)?.onChangeCheck(e.isOpen);
      });
    }

    return () => {};
    // eslint-disable-next-line
  }, [openTimeDetail]);

  const onFinish = (values: any) => {
    let isErrorDefault = false;
    for (let index = 0; index < ArrayDay.length; index++) {
      const element = ArrayDay[index];
      if (values[`${element.day}_time_open`] && values[`${element.day}_time_close`]) {
        if (
          moment(values[`${element.day}_time_close`]).diff(moment(values[`${element.day}_time_open`])) <= 0 &&
          refForm.get(element.day)?.checked
        ) {
          refForm.get(element.day)?.setCloseTimeError2('time_open.smaller_than');
          isErrorDefault = true;
        } else {
          refForm.get(element.day)?.setCloseTimeError2('');
        }
      } else if (refForm.get(element.day)?.checked) {
        refForm
          .get(element.day)
          ?.validateRequire(
            values[`${element.day}_time_open`] ? moment(values[`${element.day}_time_open`]).format(format) : '',
            `${element.day}_time_open`,
          );
        refForm
          .get(element.day)
          ?.validateRequire(
            values[`${element.day}_time_close`] ? moment(values[`${element.day}_time_close`]) : '',
            `${element.day}_time_close`,
          );
      }
      if (refForm.get(element.day)?.isErr) {
        isErrorDefault = true;
      }
    }
    if (isErrorDefault) return;

    const createSettingOpenTime = ArrayDay.map((e) => ({
      day: e.day,
      isOpen: refForm.get(e.day)?.checked,
      closeTime: values[`${e.day}_time_close`]
        ? moment()
            .utc()
            .set('hour', moment(values[`${e.day}_time_close`]).hour())
            .set('minute', moment(values[`${e.day}_time_close`]).minute())
            .set('millisecond', 0)
            .valueOf()
        : (0 as Number),
      openTime: values[`${e.day}_time_open`]
        ? moment()
            .utc()
            .set('hour', moment(values[`${e.day}_time_open`]).hour())
            .set('minute', moment(values[`${e.day}_time_open`]).minute())
            .set('millisecond', 0)
            .valueOf()
        : (0 as Number),
    })) as OpenTimeInput[];

    const payload: UpdateCompanyInput = {
      _id: id,
      email: data?.company?.email || '',
      phone: data?.company?.phone || '',
      name: data?.company?.name || '',
      address: data?.company?.address || '',
      openTimes: createSettingOpenTime,
      description: data?.company?.description || '',
      // domain: data?.company?.domain || '',
      limitUpload: data?.company?.limitUpload || 2,
    };

    mutate({ input: payload });
    setIsEdit(true);
  };

  const onEdit = () => {
    setIsEdit(!isEdit);
  };

  return (
    <>
      <TableHeader className="my-0">
        <FormHeader>
          <Form form={form} onFinish={!isEdit ? onEdit : onFinish}>
            <OpenTimeDayComponent
              isEdit={isEdit}
              key={DayOfWeek.Monday}
              ref={(ref: RefDate) => refForm.set(DayOfWeek.Monday, ref)}
              nameForm={DayOfWeek.Monday}
              labelName={t('day_of_week.Monday')}
            />
            <OpenTimeDayComponent
              isEdit={isEdit}
              key={DayOfWeek.Tuesday}
              ref={(ref: RefDate) => refForm.set(DayOfWeek.Tuesday, ref)}
              nameForm={DayOfWeek.Tuesday}
              labelName={t('day_of_week.Tuesday')}
            />
            <OpenTimeDayComponent
              isEdit={isEdit}
              key={DayOfWeek.Wednesday}
              ref={(ref: RefDate) => refForm.set(DayOfWeek.Wednesday, ref)}
              nameForm={DayOfWeek.Wednesday}
              labelName={t('day_of_week.Wednesday')}
            />
            <OpenTimeDayComponent
              isEdit={isEdit}
              key={DayOfWeek.Thursday}
              ref={(ref: RefDate) => refForm.set(DayOfWeek.Thursday, ref)}
              nameForm={DayOfWeek.Thursday}
              labelName={t('day_of_week.Thursday')}
            />
            <OpenTimeDayComponent
              isEdit={isEdit}
              key={DayOfWeek.Friday}
              ref={(ref: RefDate) => refForm.set(DayOfWeek.Friday, ref)}
              nameForm={DayOfWeek.Friday}
              labelName={t('day_of_week.Friday')}
            />
            <OpenTimeDayComponent
              isEdit={isEdit}
              key={DayOfWeek.Saturday}
              ref={(ref: RefDate) => refForm.set(DayOfWeek.Saturday, ref)}
              nameForm={DayOfWeek.Saturday}
              labelName={t('day_of_week.Saturday')}
            />
            <OpenTimeDayComponent
              isEdit={isEdit}
              key={DayOfWeek.Sunday}
              ref={(ref: RefDate) => refForm.set(DayOfWeek.Sunday, ref)}
              nameForm={DayOfWeek.Sunday}
              labelName={t('day_of_week.Sunday')}
            />

            <Form.Item>
              <Space>
                <Button type="primary" className="btn-dangerous" htmlType="submit">
                  {t('save')}
                </Button>
                {!loginState.data?.companyId ? (
                  <Button onClick={() => getHistory().push(`/company/list`)} type="default">
                    {t('cancel_add')}
                  </Button>
                ) : null}
              </Space>
            </Form.Item>
          </Form>
        </FormHeader>
      </TableHeader>
    </>
  );
}
