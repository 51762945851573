import { Roles } from '@/graphql/generated/graphql';

export interface FilterUserInput {
  query?: string;
  role?: Roles;
  isSearchAll?: boolean;
  page?: number;
  limit?: number;
}

export const GET_USERS = 'GET_USERS';
