import { Device } from '@/graphql/generated/graphql';

export const DEVICE_ITEM = 'DEVICE_ITEM';

export interface DeviceItemAction {
  type: typeof DEVICE_ITEM;
  payload: Device;
}

export type DeviceItemActionTypes = DeviceItemAction;
