import { PlaylistSchedule } from '@modules/GroupDevice/types';

export const ADD_CURRENT_PLAYLIST_SCHEDULE_ACTION = 'ADD_CURRENT_PLAYLIST_SCHEDULE_ACTION';

export interface AddCurrentPlaylistScheduleAction {
  type: typeof ADD_CURRENT_PLAYLIST_SCHEDULE_ACTION;
  payload?: PlaylistSchedule;
}

export type CurrentPlaylistScheduleActionTypes = AddCurrentPlaylistScheduleAction;
