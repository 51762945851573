import env from '@/env';
import { Jwt, User } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { HttpLink, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import moment from 'moment';
import { isJsonString } from './string';

const TOKEN_KEY = env.tokenKey;
const LANGUAGE_KEY = env.languageKey;

let TOKEN: any = '';
let wsLink: any;
let alertChangeSchedule = false;

export const setWsLink = (token?: string) => {
  const httpLink = new HttpLink({
    uri: env.apiEndPoint,
  });

  const ws = new WebSocketLink({
    uri: process.env.REACT_APP_API_WS || '',
    options: {
      reconnect: true,
      timeout: 20000,
      lazy: true,
      connectionParams: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    ws,
    httpLink,
  );
  wsLink = link;
};

export const getWsLink = () => {
  return wsLink;
};

export const setAuthData = (loginStorage: Jwt) => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(loginStorage || {}));
  TOKEN = loginStorage.token;
};

export const getAuthLocalData = () => {
  const loginStorage = parseTokenString(localStorage.getItem(TOKEN_KEY) || '');
  if (!tokenChecker(loginStorage)) {
    localStorage.removeItem(TOKEN_KEY);
    return null;
  }
  TOKEN = loginStorage?.token;
  return loginStorage;
};

export const setDefaultLanguage = (lng: string = '') => {
  if (['en', 'no', 'vi'].includes(lng)) {
    localStorage.setItem(LANGUAGE_KEY, lng);
    i18n.changeLanguage(lng);
  } else {
    const languageStorage = localStorage.getItem(LANGUAGE_KEY) || '';
    if (!languageStorage) {
      localStorage.setItem(LANGUAGE_KEY, 'no');
      i18n.changeLanguage('no');
    } else {
      i18n.changeLanguage(languageStorage);
    }
  }
};

export const getToken = () => {
  return `Bearer ${TOKEN}`;
};

export const getNewToken = () => {
  return `${TOKEN}`;
};

export const setToken = (token: string) => {
  TOKEN = token;
};

export const removeToken = () => {
  localStorage.removeItem(env.tokenKey);
  setToken('');
};

export const parseTokenString = (str: string) => {
  if (isJsonString(str)) {
    const authObject: Jwt = JSON.parse(str);
    return authObject;
  }
  return null;
};

export const tokenChecker = (loginStorage: Jwt | any) => {
  if (!loginStorage || !loginStorage.token || loginStorage.expiresAt < moment().unix()) return false;
  return true;
};

export const updateLocalToken = (userInfo: User) => {
  const loginStorage = parseTokenString(localStorage.getItem(TOKEN_KEY) || '');
  if (loginStorage && tokenChecker(loginStorage)) {
    loginStorage['payload'] = {
      ...localStorage['payload'],
      ...userInfo,
    };
    localStorage.setItem(TOKEN_KEY, JSON.stringify(loginStorage || {}));
    TOKEN = loginStorage.token;
  }
};

export const getLocalLanguage = () => {
  return localStorage.getItem(env.languageKey) || 'en';
};

export const setAlertChangeSchedule = (status: boolean) => {
  alertChangeSchedule = status;
};

export const getAlertChangeSchedule = () => {
  return alertChangeSchedule;
};
