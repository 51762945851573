import { RootState } from '@redux/reducers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreatePlaylistInputInterface } from '../redux/action-types';
import { createPlaylist } from '../redux/actions/create_playlist';
import { AddNewPlayListState } from '../redux/reducers/add_new_play_list';

const useCreateNewPlaylist = () => {
  const state = useSelector<RootState, AddNewPlayListState>((state) => state.playList.addNewPlayListState);

  const dispatch = useDispatch();

  const onCreatePlaylist = useCallback(
    (input: CreatePlaylistInputInterface) => {
      dispatch(createPlaylist(input));
    },
    [dispatch],
  );
  return {
    isLoading: state.loading,
    isSuccess: state.isSuccess,
    playlistId: state._id,
    data: state.data,
    onCreatePlaylist,
  };
};
export default useCreateNewPlaylist;
