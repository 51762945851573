import {
  ResetPasswordActionTypes,
  ResetPasswordState,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from '../action-types/reset-password';

const initialState = {
  loading: false,
};

export default (state = initialState, action: ResetPasswordActionTypes): ResetPasswordState => {
  switch (action.type) {
    case RESET_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
