import { Roles } from '@/graphql/generated/graphql';
import ic_pending_approval from '@assets/images/icons/db_approve.png';
import ic_device_menu from '@assets/images/icons/db_device.png';
import ic_user from '@assets/images/icons/db_user.png';
import ic_shop_gray from '@assets/images/icons/shop_gray.svg';
import ic_group_device from '@assets/images/left_nav/group_device_dashboard.svg';
import CardNumber from '@commons/components/Card/CardNumber';
import Loading from '@commons/components/Loading';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { LoginState } from '@modules/Auth/redux/action-types';
import { RootState } from '@redux/reducers';
import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useGetTotalSystem from '../hooks/useGetTotalSystem';

export default function TotalMoneyPage() {
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);
  const { t } = useTranslation(['dashboard']);

  const { setPageTitle } = useSetPageTitle();

  const { data, isLoading, refetch } = useGetTotalSystem();

  useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t('dashboard'));
    // eslint-disable-next-line
  }, [t]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [loginState?.data?.companyId]);

  return (
    <>
      <div style={{ height: '100%' }}>
        {isLoading ? (
          <Loading type="modal" />
        ) : (
          <Row gutter={20}>
            {data?.getAllTotalInSystem?.users !== null && (
              <Col span={24} md={{ span: 12 }} lg={{ span: 8 }} xxl={{ span: 6 }}>
                <CardNumber
                  title={t('account')}
                  number={data?.getAllTotalInSystem?.users || 0}
                  img={ic_user}
                  color="#F80000"
                  bgColor="#FFEBEB"
                  link="/user/list"
                />
              </Col>
            )}
            {data?.getAllTotalInSystem?.companies !== null && (
              <Col span={24} md={{ span: 12 }} lg={{ span: 8 }} xxl={{ span: 6 }}>
                <CardNumber
                  title={t('customer')}
                  number={data?.getAllTotalInSystem?.companies || 0}
                  img={ic_shop_gray}
                  color="#00BE39"
                  bgColor="#E3FFEC"
                  link="/company/list"
                />
              </Col>
            )}

            <Col span={24} md={{ span: 12 }} lg={{ span: 8 }} xxl={{ span: 6 }}>
              <CardNumber
                title={loginState.data?.role === Roles.SuperAdmin ? t('device_deployed') : t('device')}
                number={data?.getAllTotalInSystem?.devices || 0}
                img={ic_device_menu}
                color="#0072F5"
                bgColor="#ECF5FF"
                link="/device/list"
              />
            </Col>

            {loginState.data?.role === Roles.SuperAdmin && (
              <Col span={24} md={{ span: 12 }} lg={{ span: 8 }} xxl={{ span: 6 }}>
                <CardNumber
                  title={t('device_available')}
                  number={data?.getAllTotalInSystem?.availableDevices || 0}
                  img={ic_group_device}
                  color="#00BE39"
                  bgColor="#E3FFEC"
                  link="/device/available"
                />
              </Col>
            )}
            {loginState.data?.role !== Roles.SuperAdmin && (
              <Col span={24} md={{ span: 12 }} lg={{ span: 8 }} xxl={{ span: 6 }}>
                <CardNumber
                  title={t('group')}
                  number={data?.getAllTotalInSystem?.groups || 0}
                  img={ic_group_device}
                  color="#00BE39"
                  bgColor="#E3FFEC"
                  link="/group-device/list"
                />
              </Col>
            )}
            {loginState.data?.role !== Roles.SuperAdmin && (
              <Col span={24} md={{ span: 12 }} lg={{ span: 8 }} xxl={{ span: 6 }}>
                <CardNumber
                  title={t('pending_group')}
                  number={data?.getAllTotalInSystem?.pendingGroups || 0}
                  img={ic_pending_approval}
                  color="#F87400"
                  bgColor="#FFF1E5"
                  link="/group-device/pending-approve"
                />
              </Col>
            )}
          </Row>
        )}
        <div className="py-5" style={{ background: '#fff', minHeight: 400 }}>
          <h2 style={{ paddingTop: 15 }} className="text-center">
            {t('content')}
          </h2>
        </div>
      </div>
    </>
  );
}
