import ic_group_device from '@assets/images/left_nav/group-device.svg';
import useSetAppLayout from '@modules/App/hooks/useSetAppLayout';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onClick: () => void;
  className?: string;
  isLoading?: boolean;
  title?: string;
}

const BtnSetToGroup = (props: Props) => {
  const { layoutSize } = useSetAppLayout();
  const { t } = useTranslation(['button', 'playlist']);

  return (
    <Button
      icon={<img src={ic_group_device} alt="ic_group_device" />}
      className={`btn-set-to-group mb-2 mb-md-0 ${props.className}`}
      onClick={props.onClick}
      size={layoutSize}
    >
      {props.title ? props.title : t('playlist:set_to_other_group').toUpperCase()}
    </Button>
  );
};

export default BtnSetToGroup;
