import { ArrowRightOutlined } from '@ant-design/icons';
import { HOUR_MINUTE } from '@helpers/date';
import { TimeInDayI } from '@modules/GroupDevice/types';
import { Form, TimePicker } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorTimeInDayState } from './Right';

interface MonthlyProps {
  timeInDay?: TimeInDayI;
  changeStartOfDay?: (time?: string) => void;
  changeEndOfDay?: (time?: string) => void;
  errorTimeInDay?: ErrorTimeInDayState;
  className?: string;
  clName?: any;
  name?: any;
}

const OpenCloseTime = (props: MonthlyProps) => {
  const { t } = useTranslation(['device', 'group-device']);
  // const [form] = Form.useForm();
  // useEffect(() => {
  //   form.setFieldsValue({
  //     startOfDay: props.timeInDay?.startOfDay ? moment(props.timeInDay?.startOfDay, HOUR_MINUTE) : undefined,
  //     endOfDay: props.timeInDay?.endOfDay ? moment(props.timeInDay?.endOfDay, HOUR_MINUTE) : undefined,
  //   });
  // }, [form, props.timeInDay]);

  return (
    <>
      <div className={`d-flex align-items-center justify-content-start ${props.className}`}>
        <div>
          <Form.Item
            name={`${props.name ? [props.name, 'startOfDay'] : 'startOfDay'}`}
            className="mb-0"
            trigger="select"
          >
            <TimePicker
              format={HOUR_MINUTE}
              allowClear
              showNow={false}
              onSelect={(e) => {
                return e && props.changeStartOfDay && props.changeStartOfDay(e ? e?.format('HH:mm') : undefined);
              }}
              onChange={(e) => {
                return !e && props.changeStartOfDay && props.changeStartOfDay(undefined);
              }}
              placeholder={t('group-device:schedule.open_time')}
            />
          </Form.Item>
        </div>
        <ArrowRightOutlined style={{ fontSize: 14, margin: '0 15px', color: '#999999' }} />
        <div>
          <Form.Item name={`${props.clName ? [props.name, 'endOfDay'] : 'endOfDay'}`} className="mb-0" trigger="select">
            <TimePicker
              format={HOUR_MINUTE}
              allowClear
              showNow={false}
              onSelect={(e) => {
                // tslint:disable-next-line:no-unused-expression
                return e && props.changeEndOfDay && props.changeEndOfDay(e ? e?.format('HH:mm') : undefined);
              }}
              onChange={(e) => {
                return !e && props.changeEndOfDay && props.changeEndOfDay(undefined);
              }}
              placeholder={t('group-device:schedule.close_time')}
            />
          </Form.Item>
        </div>
      </div>
      {props.errorTimeInDay?.status && (
        <div className="ant-form-item-explain ant-form-item-explain-error">
          <div role="alert">{props.errorTimeInDay?.message}</div>
        </div>
      )}
    </>
  );
};

export default OpenCloseTime;
