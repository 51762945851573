import { take } from 'redux-saga/effects';
import { NotificationError, NotificationSuccess } from '@commons/components/Notification';
import { __ } from '@helpers/string';
// import { LOGIN_ERROR } from '@modules/Auth/redux/action-types';
// import { RootState } from './reducers';

export function* checkErrorAsync(): any {
  while (true) {
    const action: any = yield take((action: any) => /.+_ERROR/.test(action.type));
    NotificationError('warning', action.payload?.message || 'notification_title_default');
    // switch (action.type) {
    //   // case LOGIN_ERROR:
    //   //   const previousErrorMessage = yield select((state: RootState) => state.auth.loginState.previousErrorMessage);
    //   //   const failedCount = yield select((state: RootState) => state.auth.loginState.failedCount);
    //   //   if (failedCount === 1) {
    //   //     NotificationError(__('Cảnh báo!'), action.payload.message || __('Có lỗi xảy ra, vui lòng thử lại sau!'));
    //   //   } else {
    //   //     if (previousErrorMessage && previousErrorMessage !== action.payload.message) {
    //   //       NotificationError(__('Cảnh báo!'), action.payload.message || __('Có lỗi xảy ra, vui lòng thử lại sau!'));
    //   //     }
    //   //   }
    //   //   break;

    //   default:
    //     NotificationError(__('Cảnh báo!'), action.payload.message || __('Có lỗi xảy ra, vui lòng thử lại sau!'));
    //     break;
    // }
  }
}

export function* checkUpdateSuccessAsync(): any {
  while (true) {
    const action: any = yield take((action: any) => /.+_UPDATED_SUCCESS/.test(action.type));
    NotificationSuccess(__('Thông báo!'), action.payload.message || __('Cập nhập thành công!'));
  }
}
