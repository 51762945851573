import { Group, GroupStatus, QueryCompaniesInput, QueryPendingGroupsInput, Roles } from '@/graphql/generated/graphql';
import BtnApproveAll from '@commons/components/Button/BtnApproveALL';
import BtnRejectAll from '@commons/components/Button/BtnRejectAll';
import Loading from '@commons/components/Loading';
import TableLayout from '@commons/components/TableLayout';
import TableWithNumber from '@commons/components/TableWithNumber';
import { formatMomentDate, FULL_DATE_TIME } from '@helpers/date';
import { getHistory } from '@helpers/history';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { LoginState } from '@modules/Auth/redux/action-types';
import useGetAllCompany from '@modules/Company/hooks/useGetAllCompany';
import { PageSizeTable } from '@modules/Company/types';
import GroupPendingRowAction from '@modules/GroupDevice/Components/Action/GroupPendingRowAction';
import useApproveAllGroup from '@modules/GroupDevice/hooks/useApproveAllGroup';
import useGetPendingGroup from '@modules/GroupDevice/hooks/useGetPendingGroup';
import useRejectAllGroup from '@modules/GroupDevice/hooks/useRejectAllGroup';
import useUpdateGroupStatus from '@modules/GroupDevice/hooks/useUpdateGroupStatus';
import { RootState } from '@redux/reducers';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import TextTruncate from 'react-text-truncate';

export interface GroupsFilter {
  query?: string;
  companyId?: string;
}

const { Option } = Select;

interface IState {
  deleteGroup?: Group;
  editGroup?: Group;
}

const ListGroupDevice = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation(['group-device', 'button']);
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);
  const history = getHistory();
  const { setPageTitle } = useSetPageTitle();

  const [filter, setFilter] = useState<QueryPendingGroupsInput>({
    limit: PageSizeTable,
    page: 1,
  });
  const { data: listGroup, isLoading: loadingTable } = useGetPendingGroup(filter);

  useEffect(() => {
    if (loginState.data?.companyId) {
      setFilter({
        ...filter,
        companyId: loginState.data?.companyId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginState?.data]);

  const { mutate: updateGroupStatus, isLoading: updateStatusLoading } = useUpdateGroupStatus();
  const { mutate: onRejectAllGroup, isLoading: loadingRejectAll } = useRejectAllGroup();
  const { mutate: onApproveAllGroup, isLoading: loadingApproveAll } = useApproveAllGroup();

  useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t('group_device'));
    // eslint-disable-next-line
  });

  const onSearch = (values: any) => {
    setFilter({ ...filter, query: values.query });
  };

  // eslint-disable-next-line
  const [filterCompany, setFilterCompany] = useState<QueryCompaniesInput>({
    page: 1,
    limit: 8,
    query: undefined,
  });

  const { data: listCompany } = useGetAllCompany(filterCompany);

  const columns: ColumnsType<any> = [
    {
      title: t('stt'),
      key: '#',
      dataIndex: '#',
      width: 70,
      align: 'center',
    },
    {
      title: t('group'),
      key: 'name',
      dataIndex: 'name',
      width: 200,
      render: (value: any, record) => (
        <TextTruncate
          containerClassName="w-100 d-block word-break"
          line={2}
          element="span"
          truncateText="…"
          text={value}
        />
      ),
    },
    {
      title: t('submit_time'),
      key: 'submit_time',
      dataIndex: 'updatedAt',
      width: 150,
      render: (value, record) => {
        return (
          <TextTruncate
            containerClassName="w-100 d-block"
            line={1}
            element="span"
            truncateText="…"
            text={formatMomentDate(record.updatedAt, FULL_DATE_TIME)}
          />
        );
      },
    },
    {
      title: t('device'),
      key: 'qty',
      width: 80,
      align: 'center',
      render: (_, record: Group) => {
        return <span>{record.deviceIds?.length || 0}</span>;
      },
    },

    {
      title: '',
      key: 'action',
      width: 150,
      render: (record: any) => (
        <GroupPendingRowAction
          record={record}
          onView={(record) => {
            history.push(`/group-device/schedule/${record._id}`);
          }}
          onReject={(record) => {
            updateGroupStatus({
              input: {
                _id: record._id,
                status: GroupStatus.Rejected,
              },
            });
          }}
          onApprove={(record) => {
            updateGroupStatus({
              input: {
                _id: record._id,
                status: GroupStatus.Approved,
              },
            });
          }}
        />
      ),
    },
  ];

  return (
    <div className="position-relative">
      <TableLayout
        tableHeader={
          <div className={`d-flex align-items-center justify-content-end w-100`}>
            <Form
              layout="inline"
              form={form}
              onFinish={onSearch}
              className="d-flex justify-content-start justify-content-md-end gap-3"
            >
              {loginState.data?.role === Roles.SuperAdmin && (
                <Form.Item className="bg_input_form" name="companyId" style={{ width: 300 }}>
                  <Select
                    style={{ width: '100%', maxWidth: 485 }}
                    showSearch
                    dropdownClassName="mr-0"
                    allowClear
                    placeholder={t('company')}
                    onSearch={(e) => {
                      setFilterCompany({
                        ...filterCompany,
                        query: e,
                        page: 1,
                      });
                    }}
                    filterOption={false}
                    dropdownRender={(menu) => {
                      return (
                        <React.Fragment>
                          {menu}
                          <Row className="ml-2 mr-2 mt-2 mb-2 justify-content-center">
                            <Col className="mr-2">
                              <Button
                                onMouseDown={() => {
                                  if (filterCompany && filterCompany.page && filterCompany.page <= 1) {
                                    return;
                                  }
                                  setFilterCompany({
                                    ...filterCompany,
                                    page: (filterCompany.page || 0) - 1,
                                  });
                                }}
                              >
                                {t('button:pre')}
                              </Button>
                            </Col>
                            <Col className="mr-2">
                              <Button
                                onMouseDown={() => {
                                  if (
                                    (filterCompany &&
                                      filterCompany.page &&
                                      filterCompany.page === listCompany?.companies?.totalPage) ||
                                    (listCompany?.companies && listCompany?.companies.totalPage <= 0)
                                  ) {
                                    return;
                                  }
                                  setFilterCompany({
                                    ...filterCompany,
                                    page: (filterCompany.page || 0) + 1,
                                  });
                                }}
                              >
                                {t('button:next')}
                              </Button>
                            </Col>
                            <Col className="mt-1">{`${listCompany?.companies?.currentPage} / ${listCompany?.companies?.totalPage}`}</Col>
                          </Row>
                        </React.Fragment>
                      );
                    }}
                  >
                    {listCompany &&
                      listCompany?.companies &&
                      listCompany?.companies.items?.map((company: any) => (
                        <Option key={company?._id} value={`${company._id}/$/${company.name}`} title={`${company.name}`}>
                          {company.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
              <Form.Item>
                <Input.Group compact>
                  <Form.Item name="query" className="w-full mr-0">
                    <Input className="bd-form-search" placeholder={`${t('search_group_device')}...`} />
                  </Form.Item>
                  <Button icon={<FiSearch color="#F80000" />} className="btn-search" htmlType="submit" />
                </Input.Group>
              </Form.Item>
              {}
              <Form.Item>
                <BtnApproveAll
                  onClick={() => {
                    if (
                      listGroup?.pendingGroups &&
                      listGroup?.pendingGroups.items &&
                      listGroup?.pendingGroups.items?.length > 0
                    ) {
                      onApproveAllGroup({});
                    }
                  }}
                />
              </Form.Item>
              <Form.Item>
                <BtnRejectAll
                  onClick={() => {
                    if (
                      listGroup?.pendingGroups &&
                      listGroup?.pendingGroups.items &&
                      listGroup?.pendingGroups.items?.length > 0
                    ) {
                      onRejectAllGroup({});
                    }
                  }}
                />
              </Form.Item>
            </Form>
          </div>
        }
      >
        <TableWithNumber
          scroll={{
            x: true,
          }}
          rowKey={(data) => data._id}
          columns={
            loginState.data?.role === Roles.SuperAdmin || loginState.data?.isSuperAdmin
              ? columns
              : columns.filter((column) => column.key !== 'company')
          }
          rowClassName={(record, index) =>
            index % 2 === 0 ? 'table-row-height table-row-white ' : 'table-row-height table-row-gray'
          }
          dataSource={(listGroup && listGroup.pendingGroups && listGroup.pendingGroups.items) || []}
          loading={loadingTable}
          pagination={false}
          totalTitle={t('groups')}
          paginate={{
            pageSize: PageSizeTable,
            total: listGroup?.pendingGroups?.total || 0,
            current: listGroup?.pendingGroups?.currentPage || 1,
            onChange: (page) => {
              setFilter({
                ...filter,
                page,
              });
            },
          }}
        />
      </TableLayout>

      {(loadingRejectAll || loadingApproveAll || updateStatusLoading) && <Loading />}
    </div>
  );
};

export default ListGroupDevice;
