import { ScheduleType } from '@/graphql/generated/graphql';
import ButtonCancel from '@commons/components/modals/Button/ButtonCancel';
import ButtonOk from '@commons/components/modals/Button/ButtonOk';
import useAddCurrentPlaylistSchedule from '@modules/GroupDevice/hooks/useAddCurrentPlaylistSchedule';
import { CurrentPlaylistSchedulesState } from '@modules/GroupDevice/redux/reducers/current_playlist_schedule';
import { PlaylistSchedule } from '@modules/GroupDevice/types';
import { RootState } from '@redux/reducers';
import { Col, Drawer, DrawerProps, Radio, Row } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Daily1Component from '../ScheduleTypes/Daily1';
import Monthly1Component from '../ScheduleTypes/Monthly1';
import SpecialDay1Component from '../ScheduleTypes/SpecialDay1';
import Weekly2Component from '../ScheduleTypes/Weekly2';
import { ErrorTimeInDayState } from '../Shedule/GroupContent';

interface ScheduleTypesI {
  onCancel?: () => void;
  visible: boolean;
  isLoading?: boolean;
  placement?: DrawerProps['placement'];
  currentPlaylistSchedule?: PlaylistSchedule;
}

export default function ScheduleTypes(props: ScheduleTypesI) {
  const { t } = useTranslation(['group-device']);
  const { onAddCurrentPlaylistSchedule } = useAddCurrentPlaylistSchedule();
  const currentPlaylistScheduleState = useSelector<RootState, CurrentPlaylistSchedulesState>(
    (state) => state?.groupDevice?.currentPlaylistSchedule,
  );
  const [scheduleType, setScheduleType] = useState<string>(
    currentPlaylistScheduleState.data?.type || ScheduleType.Daily,
  );

  const formRef: any = useRef(null);

  useEffect(() => {
    if (currentPlaylistScheduleState?.data?.type) {
      setScheduleType(currentPlaylistScheduleState.data?.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlaylistScheduleState?.data]);

  const [errorTimeInDay, setErrorTimeInDay] = useState<ErrorTimeInDayState>({
    message: '',
    status: false,
  });

  const onOk = () => {
    formRef?.current?.onUpdatePlaylistScheduleGroup();
  };

  const handleCloseDrawer = () => {
    onAddCurrentPlaylistSchedule(undefined);
    return props.onCancel && props.onCancel();
  };

  const renderScheduleTypeContent = useCallback(() => {
    switch (scheduleType) {
      case ScheduleType.Daily:
        return <Daily1Component ref={formRef} errorTimeInDay={errorTimeInDay} onCancel={handleCloseDrawer} />;
      case ScheduleType.Monthly:
        return <Monthly1Component ref={formRef} errorTimeInDay={errorTimeInDay} onCancel={handleCloseDrawer} />;
      case ScheduleType.SpecialDay:
        return <SpecialDay1Component ref={formRef} errorTimeInDay={errorTimeInDay} onCancel={handleCloseDrawer} />;
      case ScheduleType.Weekly:
        return <Weekly2Component ref={formRef} errorTimeInDay={errorTimeInDay} onCancel={handleCloseDrawer} />;
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleType, props.currentPlaylistSchedule, errorTimeInDay, formRef]);

  return (
    <Drawer
      title={
        <div>
          {t('schedule.schedule')}: <span className="gr_name">{currentPlaylistScheduleState.data?.playlist?.name}</span>
        </div>
      }
      className="drawer-schedule_type"
      footer={[
        <ButtonCancel
          key={'btn_cancel'}
          title={t('button:cancel')}
          classNameBtn={`mr-3 `}
          onClick={() => {
            return handleCloseDrawer();
          }}
        />,
        <ButtonOk key={'btn_ok'} onClick={onOk} title={t('save')} classNameBtn={``} isLoading={props.isLoading} />,
      ]}
      placement={props?.placement || 'right'}
      maskClosable={true}
      closable={false}
      onClose={() => {
        return handleCloseDrawer();
      }}
      visible={props.visible}
    >
      <div className="select-type mb-4">
        <Row>
          <Col span={24} md={5} className="mb-2 mb-md-0">
            <label htmlFor="" className="key">
              {t('schedule.select_type')}:
            </label>
          </Col>
          <Col span={24} md={19}>
            <Radio.Group
              onChange={(e) => {
                setErrorTimeInDay({
                  status: false,
                  message: '',
                });
                setScheduleType(e.target.value);
              }}
              value={scheduleType}
              className="d-flex align-items-center justify-content-start"
            >
              <Radio value={ScheduleType.Daily} key={ScheduleType.Daily}>
                {t('schedule.type.daily')}
              </Radio>
              <Radio value={ScheduleType.Weekly} key={ScheduleType.Weekly}>
                {t('schedule.type.weekly')}
              </Radio>
              <Radio value={ScheduleType.Monthly} key={ScheduleType.Monthly}>
                {t('schedule.type.monthly')}
              </Radio>
              <Radio value={ScheduleType.SpecialDay} key={ScheduleType.SpecialDay}>
                {t('schedule.type.special_day')}
              </Radio>
            </Radio.Group>
          </Col>
        </Row>
      </div>
      <div className="schedule-type-content">{renderScheduleTypeContent()}</div>
    </Drawer>
  );
}
