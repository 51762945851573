import { Media } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { moveFileItemAction } from '../redux/actions';
import { ListMediasState } from '../redux/reducers/medias';

const useMoveFileItem = () => {
  const state = useSelector<RootState, ListMediasState>((state) => state.media.list);

  const dispatch = useDispatch();
  const onMoveItem = useCallback(
    (input?: Media) => {
      dispatch(moveFileItemAction(input));
    },
    [dispatch],
  );
  return {
    onMoveItem,
    item: state.moveItem,
  };
};
export default useMoveFileItem;
