import { getSDK } from '@/graphql/client';
import { QueryGroupPlaylistsInput } from '@/graphql/generated/graphql';
import { useQuery } from 'react-query';

export const GET_GROUP_PLAYLIST = 'GET_GROUP_PLAYLIST';

export default function useGetGroupPlaylist(params: QueryGroupPlaylistsInput) {
  const { data, isSuccess, isLoading } = useQuery(
    [GET_GROUP_PLAYLIST, params],
    async () => getSDK().getGroupPlaylists({ input: params }),
    {
      enabled: !!params.groupId,
    },
  );
  return {
    data,
    isSuccess,
    isLoading,
  };
}
