import { QuestionCircleOutlined } from '@ant-design/icons';
import icon_delete from '@assets/images/icons/icon_delete.svg';
import icon_plus from '@assets/images/icons/icon_plus.svg';
import { isViewPlaylist } from '@modules/PlayList/helpers/utils';
import useAddNewFrame from '@modules/PlayList/hooks/useAddNewFrame';
import useDeleteFrame from '@modules/PlayList/hooks/useDeleteFrame';
import useUpdateFramesOrder from '@modules/PlayList/hooks/useUpdateFrameorder';
import { AddNewPlayListState } from '@modules/PlayList/redux/reducers/add_new_play_list';
import { DefaultFrameConfig, FrameData } from '@modules/PlayList/types';
import { RootState } from '@redux/reducers';
import { Popconfirm, Tooltip } from 'antd';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import FrameItem from './FrameItem';

export const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const ListFrame = () => {
  const newPlayListState = useSelector<RootState, AddNewPlayListState>((state) => state.playList.addNewPlayListState);
  const { onAddNewFrame } = useAddNewFrame();
  const { onDeleteFrame } = useDeleteFrame();
  const { onUpdateFramesOrder } = useUpdateFramesOrder();
  const { t } = useTranslation(['playlist']);
  const [listFrames, setListFrames] = useState<FrameData[]>();
  const isViewOnly = isViewPlaylist(newPlayListState.data.status);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const items: FrameData[] = reorder(listFrames, result.source.index, result.destination.index) as FrameData[];
    onUpdateFramesOrder(items);
  };

  useEffect(() => {
    if (newPlayListState && newPlayListState.data && newPlayListState.data.currentFrames) {
      setListFrames(newPlayListState.data.currentFrames as FrameData[]);
    }
    // eslint-disable-next-line
  }, [newPlayListState?.data?.currentFrames]);

  return (
    <div className="list_frame_container" id="frame-items">
      {!isViewOnly && (
        <div className="group-actions">
          <Tooltip title={t('add_frame')}>
            <img
              src={icon_plus}
              onClick={() =>
                onAddNewFrame({
                  id: nanoid(),
                  ...DefaultFrameConfig,
                })
              }
              alt="icon add frame"
              width={24}
              height={24}
              style={{ cursor: 'pointer' }}
            />
          </Tooltip>

          {listFrames && listFrames.length > 1 ? (
            <Tooltip title={t('delete_selected_frame')}>
              <Popconfirm
                disabled={!newPlayListState.data.activeFrame}
                title={t('question_delete')}
                cancelText={t('cancel')}
                okText={t('ok')}
                placement="left"
                onConfirm={() => {
                  newPlayListState &&
                    newPlayListState.data.activeFrame &&
                    onDeleteFrame(newPlayListState.data.activeFrame);
                }}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              >
                <img src={icon_delete} alt="icon delete frame" width={24} height={24} className="cursor-pointer" />
              </Popconfirm>
            </Tooltip>
          ) : (
            <img src={icon_delete} alt="icon delete frame" width={24} height={24} />
          )}
        </div>
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId={`list_frame_${newPlayListState.data?.currentPlaylist}`}
          direction="horizontal"
          isDropDisabled={isViewOnly}
        >
          {(provided) => (
            <div className="list_frame" ref={provided.innerRef} {...provided.droppableProps}>
              {listFrames && listFrames.length > 0 && (
                <PerfectScrollbar className="d-flex w-100">
                  {listFrames.map((item: FrameData, index: number) => {
                    return (
                      <FrameItem
                        key={index}
                        index={index}
                        frame={item}
                        activeKey={newPlayListState.data?.activeFrame?.id}
                      />
                    );
                  })}
                </PerfectScrollbar>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default ListFrame;
