import { DeleteMediaInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { DELETE_MEDIA, DELETE_MEDIA_ERROR, DELETE_MEDIA_SUCCESS } from '../action-types';

export const deleteMediaAction = (payload: DeleteMediaInput) => ({
  type: DELETE_MEDIA,
  payload,
});

export const deleteMediaActionSuccess = (payload?: string) => ({
  type: DELETE_MEDIA_SUCCESS,
  payload,
});

export const deleteMediaActionError = (payload: AppError) => ({
  type: DELETE_MEDIA_ERROR,
  payload,
});
