import { AppError } from '@commons/types';
import { PlayListData } from '@modules/PlayList/types';

export const UPDATE_PLAYLIST = 'UPDATE_PLAYLIST';
export const UPDATE_PLAYLIST_SUCCESS = 'UPDATE_PLAYLIST_SUCCESS';
export const UPDATE_PLAYLIST_ERROR = 'UPDATE_PLAYLIST_ERROR';

export interface UpdatePlaylistAction {
  type: typeof UPDATE_PLAYLIST;
  payload: PlayListData;
}

export interface UpdatePlaylistActionSuccess {
  type: typeof UPDATE_PLAYLIST_SUCCESS;
  payload: PlayListData;
}

export interface UpdatePlaylistActionError {
  type: typeof UPDATE_PLAYLIST_ERROR;
  payload?: AppError;
}

export type UpdatePlaylistActionTypes = UpdatePlaylistAction | UpdatePlaylistActionSuccess | UpdatePlaylistActionError;
