import { FrameDirection } from '@/graphql/generated/graphql';
import React from 'react';

interface LandscapeSplitScreenI {
  background?: string;
  backgroundColorSecond?: string;
  className?: string;
  orientation?: FrameDirection;
  onActiveScreen?: (e: number) => void;
  active?: number;
  isBorder?: boolean;
}

export default function SplitScreen(props: LandscapeSplitScreenI) {
  const active = props.active ? props.active : 1;

  if (props.orientation === FrameDirection.Horizontal) {
    return (
      <div
        className={`bg-layout w-100 h-100 d-flex align-items-center justify-content-center ${props.className}`}
        style={{ gap: 3 }}
      >
        <div
          className={`landscape-split-screen cursor-pointer ${active === 1 && !!props.isBorder ? 'active' : ''}`}
          style={{ backgroundColor: props.background || '#77829A' }}
          onClick={() => {
            return props.onActiveScreen && props.onActiveScreen(1);
          }}
        />
        <div
          className={`landscape-split-screen cursor-pointer ${active === 2 && !!props.isBorder ? 'active' : ''}`}
          style={{ backgroundColor: props.backgroundColorSecond || '#77829a' }}
          onClick={() => {
            return props.onActiveScreen && props.onActiveScreen(2);
          }}
        />
      </div>
    );
  }
  return (
    <div
      className={`bg-layout w-100 h-100 d-flex flex-column align-items-center justify-content-center ${props.className}`}
      style={{ gap: 3 }}
    >
      <div
        className={`portrait-split-screen cursor-pointer ${active === 1 && !!props.isBorder ? 'active' : ''}`}
        style={{ backgroundColor: props.background || '#77829a' }}
        onClick={() => {
          return props.onActiveScreen && props.onActiveScreen(1);
        }}
      />
      <div
        className={`portrait-split-screen cursor-pointer ${active === 2 && !!props.isBorder ? 'active' : ''}`}
        style={{ backgroundColor: props.backgroundColorSecond || '#77829a' }}
        onClick={() => {
          return props.onActiveScreen && props.onActiveScreen(2);
        }}
      />
    </div>
  );
}
