/**
 * hoc component hỗ trợ router
 * các route khi được bọc bởi component này sẽ được kiểm tra đã đăng nhập chưa
 * nếu chưa đăng nhập sẽ đưa ra trang đăng nhập và lưu lại redirect url
 */
import { Roles } from '@/graphql/generated/graphql';
import { CommonPath } from '@commons/base-routes';
import Loading from '@commons/components/Loading';
import { useQuery } from '@helpers/history';
import { AppState } from '@modules/App/redux/reducers';
import useAutoLogin from '@modules/Auth/hooks/useAutoLogin';
import { LoginState } from '@modules/Auth/redux/action-types';
import { RootState } from '@redux/reducers';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

export function protectedComponent(WrappedComponent: React.ComponentType) {
  // tslint:disable-next-line: only-arrow-functions
  return function (props: any) {
    const history = useHistory();
    const query = useQuery();
    const { verifyTokenAutoLogin } = useAutoLogin();

    const state = useSelector<RootState, LoginState>((state) => state.auth.loginState);
    const appState = useSelector<RootState, AppState>((state) => state.app);
    const login = !!state.data;
    const loading = state.loading;

    useEffect(() => {
      if (!loading && !login && !query.get('autoLoginToken')) {
        history.push(CommonPath.LOGIN_PATH);
      } else {
        if (query.get('autoLoginToken') && !!appState.loaded && state.data?.role !== Roles.SuperAdmin) {
          verifyTokenAutoLogin({
            token: query.get('autoLoginToken')?.toString() || '',
          });
        }
      }
      // eslint-disable-next-line
    }, [login, loading, query.get('autoLoginToken'), appState]);

    if (loading || !login) {
      return <Loading />;
    }

    return <WrappedComponent {...props} />;
  };
}
