import { Media } from '@/graphql/generated/graphql';

export const PREVIEW_MEDIA = 'PREVIEW_MEDIA';

export interface PreviewMediaAction {
  type: typeof PREVIEW_MEDIA;
  payload: Media;
}

export type PreviewMediaActionTypes = PreviewMediaAction;
