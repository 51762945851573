export interface TotalUsers {
  totalUsers: number;
}

export interface TotalSystem {
  totalUsers: number;
  totalWins: number;
  totalLoses: number;
  totalTrades: number;
  totalProfitByUsdex: number;
}

export interface ProfitByWalletItem {
  wallet: string;
  total_users: number;
  total_trades: number;
  total_quantity: number;
  total_profit: number;
  total_fee: number;
  total_wins: number;
  total_loses: number;
  total_profit_by_usdex: number;
}

export interface TotalSystemInput {
  dateFrom?: number;
  dateTo?: number;
}

export interface CommissionPackageData {
  total_amount: number;
  total_receivers: number;
}

export interface CommissionTradingItem {
  wallet: string;
  total_receivers: number;
  total_amount: number;
}

export interface TotalMoney {
  wallet: string;
  totalBalance: number;
}

export const GET_TOTAL_USERS = 'GET_TOTAL_USERS';
export const GET_TOTAL_SYSTEM = 'GET_TOTAL_SYSTEM';
export const GET_TOTAL_BY_WALLET = 'GET_TOTAL_BY_WALLET';
export const GET_TOTAL_COMMISSION_PACKAGE = 'GET_TOTAL_COMMISSION_PACKAGE';
export const GET_TOTAL_BALANCE_SYSTEM = 'GET_TOTAL_BALANCE_SYSTEM';
