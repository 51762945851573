import { useCustomSelector } from '@hooks/useCustomSelector';
import { UserData } from '@modules/Auth/redux/action-types';
import { Roles } from '@/graphql/generated/graphql';

export const useRoles = () => {
  const dataLogin: UserData | undefined = useCustomSelector((state) => state.auth.loginState.data);

  const isSuperAdmin = () => {
    return dataLogin && dataLogin.role === Roles.SuperAdmin;
  };
  const isAdmin = () => {
    return (
      dataLogin && (dataLogin.role === Roles.Admin || dataLogin.role === Roles.SuperAdmin) && !!dataLogin.companyId
    );
  };
  const isEditor = () => {
    return dataLogin && dataLogin.role === Roles.Editor;
  };

  return {
    isSuperAdmin,
    isAdmin,
    isEditor,
  };
};
