import { all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '@modules/PlayList/redux/action-types';
import { createPlaylistAsync, createPlaylistAsyncSuccess } from '@modules/PlayList/redux/sagas/create_playlist';
import { getPlaylistAsync, getPlaylistAsyncError, getPlaylistAsyncSuccess } from './get_playlist';
import { updatePlaylistAsync, updatePlaylistAsyncSuccess } from './update_playlist';

export default function* root(): any {
  return all([
    yield takeLatest(actionTypes.CREATE_PLAYLIST, createPlaylistAsync),
    yield takeLatest(actionTypes.CREATE_PLAYLIST_SUCCESS, createPlaylistAsyncSuccess),
    yield takeLatest(actionTypes.UPDATE_PLAYLIST, updatePlaylistAsync),
    yield takeLatest(actionTypes.UPDATE_PLAYLIST_SUCCESS, updatePlaylistAsyncSuccess),
    yield takeLatest(actionTypes.GET_PLAYLIST, getPlaylistAsync),
    yield takeLatest(actionTypes.GET_PLAYLIST_SUCCESS, getPlaylistAsyncSuccess),
    yield takeLatest(actionTypes.GET_PLAYLIST_ERROR, getPlaylistAsyncError),
  ]);
}
