import { FrameDirection, Group, PlaylistScreenType } from '@/graphql/generated/graphql';

export const ADD_CURRENT_GROUP_ACTION = 'ADD_CURRENT_GROUP_ACTION';
export const REMOVE_CURRENT_GROUP_ACTION = 'REMOVE_CURRENT_GROUP_ACTION';
export const UPDATE_LAYOUT_CURRENT_GROUP_ACTION = 'UPDATE_LAYOUT_CURRENT_GROUP_ACTION';
export const UPDATE_CURRENT_GROUP_ACTIVE_SCREEN_ACTION = 'UPDATE_CURRENT_GROUP_ACTIVE_SCREEN_ACTION';

export interface UpdateLayoutCurrentGroupInput {
  screenType: PlaylistScreenType;
  orientation: FrameDirection;
  backgroundColor?: string;
  backgroundColorSecond?: string;
}

export interface AddCurrentGroupAction {
  type: typeof ADD_CURRENT_GROUP_ACTION;
  payload?: Group;
}

export interface RemoveCurrentGroupAction {
  type: typeof REMOVE_CURRENT_GROUP_ACTION;
}

export interface UpdateLayoutCurrentGroupAction {
  type: typeof UPDATE_LAYOUT_CURRENT_GROUP_ACTION;
  payload: UpdateLayoutCurrentGroupInput;
}

export interface UpdateLayoutCurrentGroupScreenActiveAction {
  type: typeof UPDATE_CURRENT_GROUP_ACTIVE_SCREEN_ACTION;
  payload: number;
}

export type CurrentGroupActionTypes =
  | AddCurrentGroupAction
  | RemoveCurrentGroupAction
  | UpdateLayoutCurrentGroupAction
  | UpdateLayoutCurrentGroupScreenActiveAction;
