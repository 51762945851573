import { Media, UpdateMediaInput } from '@/graphql/generated/graphql';
import { EditOutlined } from '@ant-design/icons';
import ButtonCancel from '@commons/components/modals/Button/ButtonCancel';
import ButtonOk from '@commons/components/modals/Button/ButtonOk';
import useUpdateFile from '@modules/Media/hooks/useUpdateFile';
import { Button, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'styled-components';

interface IProps {
  item: Media;
  isShowMenu?: boolean;
}

const styleMenuDropdown: CSSProperties = {
  flexDirection: 'row',
  alignItems: 'center',
  display: 'flex',
  padding: 10,
};

const styleTxtDropdown: CSSProperties = {
  margin: 0,
  marginLeft: 10,
  fontSize: 15,
  color: 'black',
};

export default function RenameModal({ item, isShowMenu }: IProps) {
  const { t } = useTranslation(['media']);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const { onUpdateFile } = useUpdateFile();

  const layout = {
    labelCol: {
      sm: { span: 24 },
      xs: { span: 24 },
      md: { span: 8 },
      lg: { span: 6 },
    },
  };

  // Edit filename
  const [form] = Form.useForm();
  const showModal = () => {
    form.setFieldsValue(item);
    setIsModalVisible(true);
  };
  const onFinishItem = (values: any) => {
    const payload: UpdateMediaInput = {
      _id: item._id,
      filename: values.filename,
    };
    onUpdateFile(payload);

    setIsModalVisible(false);
  };

  const onOk = () => {
    form.submit();
  };

  const onCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button type="text" onClick={showModal} style={styleMenuDropdown} className="w-100 text-left pl-0">
        <EditOutlined className="ml-2" />
        <p style={styleTxtDropdown}>{t('rename')}</p>
      </Button>
      <Modal
        centered
        visible={isModalVisible}
        cancelText={t('cancel')}
        onCancel={() => onCancel()}
        className="modal_confirm_custom "
        title={t('rename')}
        footer={[
          <ButtonCancel key={'btn_cancel'} title={t('cancel')} classNameBtn={`mr-2 `} onClick={onCancel} />,
          <ButtonOk key={'btn_ok'} onClick={onOk} title={t('save')} />,
        ]}
      >
        <Form layout="inline" form={form} onFinish={onFinishItem} labelAlign="left" {...layout}>
          <Form.Item
            name="filename"
            className="mb-3 w-100"
            rules={[{ required: true, message: t('validates.required_rename') }]}
          >
            <Input allowClear type="text" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
