import ButtonLoginAsAdmin from '@commons/components/ButtonRowActions/ButtonLoginAsAdmin';
import useAutoLogin from '@modules/Auth/hooks/useAutoLogin';
import { LoginState } from '@modules/Auth/redux/action-types';
import { RootState } from '@redux/reducers';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SelectCompanyModal from './modal/SelectCompanyModal';

interface IProps {
  isCollapsed: boolean;
}

export default function SuperAdminViewCompanySelector(props: IProps) {
  const { t } = useTranslation(['leftNavigation']);
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);
  const { onAutoLogin } = useAutoLogin();
  const [isShow, setIsShow] = useState(false);

  const loginAsAdmin = (value: any) => {
    const companyId = value?.split('/$/')[0];
    if (loginState?.data?.isSuperAdmin) {
      if (companyId) {
        onAutoLogin(companyId);
      } else {
        onAutoLogin('');
      }
    }
    setIsShow(false);
  };

  return loginState?.data?.isSuperAdmin ? (
    <div className="login-as-company-admin">
      {/* <span className="ant-menu-item-group-title">{t('login_as_company_admin')}</span> */}
      <ButtonLoginAsAdmin
        isCollapsed={props.isCollapsed}
        onClick={() => setIsShow(!isShow)}
        title={t('switch_admin')}
        classNameContainer="select-company-btn"
      />
      <SelectCompanyModal
        currentCompanyId={loginState?.data?.companyId || null}
        isShow={isShow}
        onCancel={() => setIsShow(false)}
        onOk={loginAsAdmin}
      />
    </div>
  ) : null;
}
