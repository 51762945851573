import { Capacity } from '@/graphql/generated/graphql';

export const GET_ALL_COMPANY = 'GET_ALL_COMPANY';
export const EXPORT_ALL_COMPANY = 'EXPORT_ALL_COMPANY';
export const GET_COMPANY_BY_ID = 'GET_COMPANY_BY_ID';
export const GET_COMPANY_USER = 'GET_COMPANY_USER';
export const GET_USERS_COMPANY_ASSIGN = 'GET_USERS_COMPANY_ASSIGN';

export interface CompanyFilter {
  query?: string;
  capacity?: Capacity;
}

export interface UserCompanyFilter {
  query?: string;
}

export const Capacities = [
  {
    key: '10',
    name: 'TEN',
  },
  {
    key: '20',
    name: 'TWENTY',
  },
  {
    key: '50',
    name: 'FIFTY',
  },
  {
    key: '100',
    name: 'HUNDRED',
  },
];

export const UserLimits = [
  {
    key: '20',
    name: 'TWENTY',
  },
  {
    key: '50',
    name: 'FIFTY',
  },
  {
    key: '100',
    name: 'HUNDRED',
  },
  {
    key: '1000',
    name: 'THOUSAND',
  },
];

export interface LocationInput {
  name?: string;
  lat?: number;
  lng?: number;
}

export const PageSizeTable = 10;
