import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { VerifyTokenAutoLoginInput } from '../redux/action-types';
import { onVerifyTokenAutoLogin, supperAdminAutoLogin } from '../redux/actions';

const useAutoLogin = () => {
  const dispatch = useDispatch();

  const onAutoLogin = useCallback((companyId: string) => {
    dispatch(supperAdminAutoLogin(companyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyTokenAutoLogin = useCallback((input: VerifyTokenAutoLoginInput) => {
    dispatch(onVerifyTokenAutoLogin(input));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onAutoLogin,
    verifyTokenAutoLogin,
  };
};

export default useAutoLogin;
