import { DailyScheduleData, ScheduleType, UpdateDeviceSchedulesInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { PlaylistSchedule } from '@modules/GroupDevice/types';

export const UPDATE_SCHEDULES_GROUP_ACTION = 'UPDATE_SCHEDULES_GROUP_ACTION';
export const UPDATE_SCHEDULES_GROUP_SUCCESS = 'UPDATE_SCHEDULES_GROUP_SUCCESS';
export const UPDATE_SCHEDULES_GROUP_ERROR = 'UPDATE_SCHEDULES_GROUP_ERROR';

export const UPDATE_SCHEDULES_SECOND_GROUP_ACTION = 'UPDATE_SCHEDULES_SECOND_GROUP_ACTION';
export const UPDATE_SCHEDULES_SECOND_GROUP_SUCCESS = 'UPDATE_SCHEDULES_SECOND_GROUP_SUCCESS';
export const UPDATE_SCHEDULES_SECOND_GROUP_ERROR = 'UPDATE_SCHEDULES_SECOND_GROUP_ERROR';

export const ADD_PLAYLIST_SCHEDULES_GROUP = 'ADD_PLAYLIST_SCHEDULES_GROUP';
export const CANCEL_SCHEDULES_GROUP_ACTION = 'CANCEL_SCHEDULES_GROUP_ACTION';
export const REMOVE_PLAYLIST_SCHEDULES_GROUP = 'REMOVE_PLAYLIST_SCHEDULES_GROUP';
export const UPDATE_PLAYLIST_SCHEDULES_GROUP = 'UPDATE_PLAYLIST_SCHEDULES_GROUP';

export const ADD_PLAYLIST_SCHEDULES_SECOND_GROUP = 'ADD_PLAYLIST_SCHEDULES_SECOND_GROUP';
export const UPDATE_PLAYLIST_SCHEDULES_SECOND_GROUP = 'UPDATE_PLAYLIST_SCHEDULES_SECOND_GROUP';
export const REMOVE_PLAYLIST_SCHEDULES_SECOND_GROUP = 'REMOVE_PLAYLIST_SCHEDULES_SECOND_GROUP';

export interface AddPlaylistScheduleInput {
  playlistId: string;
  playlistName?: string;
  dailyData?: DailyScheduleData;
  type: ScheduleType;
  index?: number;
  createdById?: string;
}
export interface RemovePlaylistScheduleInput {
  playlistId: string;
  index: number;
}

export interface UpdatePlaylistScheduleInput {
  playlistId: string;
}

export interface AddPlaylistSchedulesGroupAction {
  type: typeof ADD_PLAYLIST_SCHEDULES_GROUP;
  payload: AddPlaylistScheduleInput;
}

export interface AddPlaylistSchedulesSecondGroupAction {
  type: typeof ADD_PLAYLIST_SCHEDULES_SECOND_GROUP;
  payload: AddPlaylistScheduleInput;
}
export interface CancelSchedulesGroupAction {
  type: typeof CANCEL_SCHEDULES_GROUP_ACTION;
}
export interface RemovePlaylistSchedulesGroupAction {
  type: typeof REMOVE_PLAYLIST_SCHEDULES_GROUP;
  payload: RemovePlaylistScheduleInput;
}

export interface RemovePlaylistSchedulesSecondGroupAction {
  type: typeof REMOVE_PLAYLIST_SCHEDULES_SECOND_GROUP;
  payload: RemovePlaylistScheduleInput;
}

export interface UpdatePlaylistSchedulesGroup {
  type: typeof UPDATE_PLAYLIST_SCHEDULES_GROUP;
  payload: PlaylistSchedule;
}

export interface UpdatePlaylistSchedulesSecondGroup {
  type: typeof UPDATE_PLAYLIST_SCHEDULES_SECOND_GROUP;
  payload: PlaylistSchedule;
}

export interface UpdateSchedulesGroupAction {
  type: typeof UPDATE_SCHEDULES_GROUP_ACTION;
  payload: UpdateDeviceSchedulesInput;
}
export interface UpdateSchedulesGroupActionSuccess {
  type: typeof UPDATE_SCHEDULES_GROUP_SUCCESS;
  payload: PlaylistSchedule[];
}

export interface UpdateSchedulesSecondGroupActionSuccess {
  type: typeof UPDATE_SCHEDULES_SECOND_GROUP_SUCCESS;
  payload: PlaylistSchedule[];
}

export interface UpdateSchedulesGroupActionError {
  type: typeof UPDATE_SCHEDULES_GROUP_ERROR;
  payload?: AppError;
}

export type UpdateSchedulesGroupActionTypes =
  | AddPlaylistSchedulesGroupAction
  | CancelSchedulesGroupAction
  | RemovePlaylistSchedulesGroupAction
  | UpdatePlaylistSchedulesGroup
  | UpdateSchedulesGroupAction
  | UpdateSchedulesGroupActionSuccess
  | UpdateSchedulesSecondGroupActionSuccess
  | AddPlaylistSchedulesSecondGroupAction
  | UpdatePlaylistSchedulesSecondGroup
  | RemovePlaylistSchedulesSecondGroupAction
  | UpdateSchedulesGroupActionError;
