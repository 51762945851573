import { Pagination, Row } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import Table, { TablePaginationConfig, TableProps } from 'antd/lib/table';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface PaginationProps {
  paginate?: {
    pageSize: number;
    total: number;
    current: number;
    onChange: (page: number) => void;
  };
  totalTitle?: string;
}

export default function TableWithNumber<RecordType extends object = any>(
  props: TableProps<RecordType> & PaginationProps,
) {
  const { t } = useTranslation(['group-device', 'notification']);
  const [offset, setOffset] = useState(0);
  const [tableSize, setTableSize] = useState<SizeType>('large');

  useEffect(() => {
    onWindowResize();
  }, []);

  useEffect(() => {
    if (props.paginate && props.paginate?.current === 1) {
      setOffset(0);
    } else {
      if (props.paginate && props.paginate?.current && props.paginate?.current > 1) {
        setOffset(props.paginate?.current - 1);
      }
    }
  }, [props.paginate]);

  const onWindowResize = () => {
    if (window.innerWidth > 1366) {
      setTableSize('large');
    } else {
      if (window.innerWidth > 768) {
        setTableSize('small');
      } else {
        setTableSize('small');
      }
    }
  };

  const debouncedWindowResize = debounce(onWindowResize, 300);

  useEffect(() => {
    window.addEventListener('resize', debouncedWindowResize);
    return () => window.removeEventListener('resize', debouncedWindowResize);
    // eslint-disable-next-line
  }, []);

  const onChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<RecordType> | SorterResult<RecordType>[],
    extra: TableCurrentDataSource<RecordType>,
  ) => {
    if (pagination.pageSize && pagination.current) {
      setOffset(pagination.pageSize * (pagination.current - 1));
    }
    // tslint:disable-next-line: no-unused-expression
    document.getElementById('master-layout-content')?.scrollTo({ top: 0, behavior: 'smooth' });
    return props.onChange && props.onChange(pagination, filters, sorter, extra);
  };
  const tableColumns = useMemo(() => {
    if (!props.columns) return undefined;
    return props.columns.map((c) => {
      if (c.key === '#') {
        return {
          ...c,
          render: (_: any, __: any, index: number) => offset * (props.paginate?.pageSize || 1) + index + 1,
        };
      }
      return c;
    });
  }, [offset, props.columns, props.paginate]);

  return (
    <>
      <Table
        {...props}
        size={tableSize}
        pagination={false}
        columns={tableColumns}
        onChange={onChange}
        locale={{
          emptyText: t('notification:no_data'),
        }}
      />
      {props.paginate && (
        <Row justify="space-between" className="mt-2">
          <div className="text-total-count">
            <span className="text-capitalize mx-2">{props.paginate?.total > 1 ? t('totals') : t('total')}</span>
            <span style={{ color: 'red' }}>{`${props.paginate?.total || 0} `}</span>
          </div>
          <Pagination
            pageSize={props.paginate?.pageSize}
            total={props.paginate?.total || 0}
            current={props.paginate?.current}
            onChange={props.paginate?.onChange}
            showSizeChanger={false}
          />
        </Row>
      )}
    </>
  );
}
