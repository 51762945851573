import { combineReducers } from 'redux';
import currentGroup, { CurrentGroupState } from './current_group';
import currentPlaylistSchedule, { CurrentPlaylistSchedulesState } from './current_playlist_schedule';
import scheduleGroupDevice, { UpdateGroupDeviceSchedulesState } from './update_schedules_group';

export interface GroupDeviceModuleState {
  scheduleGroupDevice: UpdateGroupDeviceSchedulesState;
  currentPlaylistSchedule: CurrentPlaylistSchedulesState;
  currentGroup: CurrentGroupState;
}

export default combineReducers<GroupDeviceModuleState>({
  scheduleGroupDevice,
  currentPlaylistSchedule,
  currentGroup,
});
