import CompanyAddNewPage from './pages/AddNew';
import CompanyListPage from './pages/ListCompany';
import CompanyUpdatePage from './pages/UpdateCompany';
import CompanyUpdateDetailPage from './pages/UpdateCompany/detail';
import StaffListPage from './pages/Staff';
import OpenTimesPage from './pages/OpenTimes';

export const CompanyPageRoute = [
  {
    path: '/company/list_old',
    exact: true,
    component: CompanyUpdatePage,
  },
  {
    path: '/company/list',
    exact: true,
    component: CompanyListPage,
  },
  {
    path: '/company/new',
    exact: true,
    component: CompanyAddNewPage,
  },
  {
    path: '/company/staff_list',
    exact: true,
    component: StaffListPage,
  },
  {
    path: '/company/open_times',
    exact: true,
    component: OpenTimesPage,
  },
  {
    path: '/company/:id',
    exact: true,
    component: CompanyUpdateDetailPage,
  },
];
