import { PaginatedMedia } from '@/graphql/generated/graphql';
import { getMediasApi } from '@modules/Media/services/apis';
import { put } from 'redux-saga/effects';
import { GetFoldersAction } from '../action-types';
import { getFolsersActionError, getFolsersActionSuccess } from '../actions';

export function* getFoldersAsync(action: GetFoldersAction) {
  try {
    const folders: PaginatedMedia = yield getMediasApi(action.payload);
    yield put(getFolsersActionSuccess(folders));
  } catch (error) {
    yield put(getFolsersActionError(error));
  }
}
