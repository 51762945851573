import { RootState } from '@redux/reducers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePlaylist } from '../redux/actions/update_playlist';
import { AddNewPlayListState } from '../redux/reducers/add_new_play_list';
import { PlayListData } from '../types';

const useUpdatePlaylist = () => {
  const state = useSelector<RootState, AddNewPlayListState>((state) => state.playList.addNewPlayListState);

  const dispatch = useDispatch();
  const onUpdatePlaylist = useCallback(
    (input: PlayListData) => {
      dispatch(updatePlaylist(input));
    },
    [dispatch],
  );
  return {
    isLoading: state.loading,
    isSuccess: state.isSuccess,
    _id: state._id,
    data: state.data,
    onUpdatePlaylist,
  };
};
export default useUpdatePlaylist;
