import { Playlist, QueryPlaylistInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { GET_PLAYLIST, GetPlaylistActionTypes, GET_PLAYLIST_SUCCESS, GET_PLAYLIST_ERROR } from '../action-types';

export const getPlaylist = (payload: QueryPlaylistInput): GetPlaylistActionTypes => ({
  type: GET_PLAYLIST,
  payload,
});

export const getPlaylistSuccess = (payload: Playlist): GetPlaylistActionTypes => ({
  type: GET_PLAYLIST_SUCCESS,
  payload,
});

export const getPlaylistError = (payload: AppError): GetPlaylistActionTypes => ({
  type: GET_PLAYLIST_ERROR,
  payload,
});
