import AuthLayout from '@commons/components/layouts/Auth';
import { setTitle } from '@helpers/dom';
import React, { useEffect } from 'react';
import useRegister from '../../hooks/useRegister';
import RegisterForm from './components/Form';
import { __ } from '@helpers/string';

function RegisterPage() {
  const { loading, onRegister } = useRegister();

  useEffect(() => {
    setTitle(__('Đăng ký'));
  }, []);

  return (
    <AuthLayout>
      <RegisterForm onRegister={onRegister} loading={loading} />
    </AuthLayout>
  );
}

export default RegisterPage;
