import { Roles } from '@/graphql/generated/graphql';
import TableHeader from '@commons/components/TableHeader';
import TableWithNumber from '@commons/components/TableWithNumber';
import { getHistory } from '@helpers/history';
import { LoginState } from '@modules/Auth/redux/action-types';
import UserCompanyRowAction from '@modules/Company/components/Action/UserCompanyRowAction';
import useAssignUserToCompany from '@modules/Company/hooks/useAssignUserToCompany';
import useGetCompanyUsers from '@modules/Company/hooks/useGetCompanyUsers';
import useGetListUsersCompanyAssign from '@modules/Company/hooks/useGetListUsersCompanyAssign';
import { PageSizeTable, UserCompanyFilter } from '@modules/Company/types';
import { FilterUserInput } from '@modules/User/types';
import { RootState } from '@redux/reducers';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

const { Option } = Select;
export default function ListUserByCompany() {
  const { t } = useTranslation(['company', 'user']);

  const columns: ColumnsType<any> = [
    {
      title: '#',
      key: '#',
      dataIndex: '#',
    },

    {
      title: t('user:full_name'),
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: t('user:username'),
      key: 'username',
      dataIndex: 'username',
    },
    {
      title: t('user:role'),
      key: 'role',
      dataIndex: 'role',
    },
    {
      title: '',
      key: 'action',
      width: 60,
      render: (record: any) => (
        <UserCompanyRowAction
          record={record}
          onEdit={(record) => {
            getHistory().push(`/user/${record._id}`);
          }}
        />
      ),
    },
  ];
  // eslint-disable-next-line
  const [paginate, setPaginate] = useState({
    page: 1,
    pageSize: PageSizeTable,
  });

  const [userPaginate, setUserPaginate] = useState({
    page: 1,
    pageSize: 8,
  });

  const { id } = useParams<{ id: string }>();

  // eslint-disable-next-line
  const [filter, setFilter] = useState<UserCompanyFilter>({});
  const [listUserAssign, setListUserAssign] = useState<string[]>([]);
  const [userFilter, setUserFilter] = useState<FilterUserInput>({
    isSearchAll: true,
  });

  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);

  const { data: listUser } = useGetListUsersCompanyAssign({
    companyId:
      loginState.data?.role === Roles.SuperAdmin || loginState.data?.isSuperAdmin
        ? id
        : loginState.data?.companyId || '',
    limit: userPaginate.pageSize,
    page: userPaginate.page,
    query: userFilter.query,
  });

  const { isLoading, data: listCompanyUsers } = useGetCompanyUsers({
    limit: PageSizeTable,
    companyId: id,
    page: 1,
    query: filter.query,
  });

  // eslint-disable-next-line

  const { mutate, isLoading: loading, isSuccess } = useAssignUserToCompany();

  const [formSearch] = Form.useForm();
  const [form] = Form.useForm();

  const onSearch = (values: any) => {
    const input = {
      query: values.query ? values.query : undefined,
    };
    if (paginate.page !== 1) {
      setPaginate({
        ...paginate,
        page: 1,
      });
    }
    setFilter(input);
  };

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  // const onReset = () => {
  //   formSearch.resetFields();
  //   const input = {
  //   };
  //   if (paginate.page !== 1) {
  //     setPaginate({
  //       ...paginate,
  //       page: 1,
  //     });
  //   }
  //   setFilter(input);
  // };

  const onAssign = (values: any) => {
    const newListUserAssign: string[] = [];
    // eslint-disable-next-line
    listUserAssign.map((e: any) => {
      newListUserAssign.push(e?.key);
    });
    mutate({
      input: {
        companyId: id,
        role: values.role,
        userIds: newListUserAssign,
      },
    });
  };

  return (
    <TableHeader className="my-0" bodyStyle={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
      <div className="table-header-flex mx-0">
        <div className="company-user-filter">
          <Form layout="inline" form={formSearch} onFinish={onSearch} className="search-form">
            <Form.Item name="query" className="mb-3">
              <Input placeholder={t('search')} allowClear />
            </Form.Item>
            <Form.Item>
              <Button className="btn-dangerous mb-3" htmlType="submit">
                {t('search')}
              </Button>
            </Form.Item>
          </Form>
          <Form layout="inline" form={form} onFinish={onAssign} className="assign-form">
            <Form.Item
              name="userIds"
              shouldUpdate={true}
              rules={[{ required: true, message: t('validate.required_user') }]}
              className="mb-3"
            >
              <Select
                dropdownClassName="mr-0"
                className="select-user"
                allowClear
                mode="multiple"
                placeholder={t('select_user')}
                onSearch={(e) => {
                  setUserFilter({
                    ...userFilter,
                    query: e,
                  });
                }}
                labelInValue
                onChange={(e, option: any) => setListUserAssign(option)}
                dropdownRender={(menu) => {
                  return (
                    <React.Fragment>
                      {menu}
                      <Row className="ml-2 mr-2 mt-2 mb-2 justify-content-center">
                        <Col className="mr-2">
                          <Button
                            onMouseDown={() => {
                              if (userPaginate.page <= 1) {
                                return;
                              }
                              setUserPaginate({
                                ...userPaginate,
                                page: 1,
                              });
                            }}
                          >
                            Reset
                          </Button>
                        </Col>
                        <Col className="mr-2">
                          <Button
                            onMouseDown={() => {
                              if (userPaginate.page <= 1) {
                                return;
                              }
                              setUserPaginate({
                                ...userPaginate,
                                page: userPaginate.page - 1,
                              });
                            }}
                          >
                            Pre
                          </Button>
                        </Col>
                        <Col className="mr-2">
                          <Button
                            onMouseDown={() => {
                              if (userPaginate.page === listUser?.listUsersCanAddToCompany?.totalPage) {
                                return;
                              }
                              setUserPaginate({
                                ...userPaginate,
                                page: userPaginate.page + 1,
                              });
                            }}
                          >
                            Next
                          </Button>
                        </Col>
                        <Col className="mt-1">{`${listUser?.listUsersCanAddToCompany?.currentPage} / ${listUser?.listUsersCanAddToCompany?.totalPage}`}</Col>
                      </Row>
                    </React.Fragment>
                  );
                }}
              >
                {listUser &&
                  listUser?.listUsersCanAddToCompany?.items &&
                  listUser?.listUsersCanAddToCompany?.items.map((user: any) => (
                    <Option
                      key={user?._id}
                      value={`${user.username} - ${user.name}`}
                      title={`${user.username} - ${user.name}`}
                    >
                      {user.username} - {user.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="role"
              shouldUpdate={true}
              rules={[{ required: true, message: t('validate.required_role') }]}
              className="mb-3"
            >
              <Select allowClear placeholder={t('select_role')} className="select-role">
                <Option key={Roles.Admin} value={Roles.Admin}>
                  {t('status.checker')}
                </Option>
                <Option key={Roles.Editor} value={Roles.Editor}>
                  {t('status.editor')}
                </Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button className="btn-dangerous" htmlType="submit" loading={loading}>
                {t('assign')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>

      <TableWithNumber
        scroll={{
          x: 768,
        }}
        rowKey={(data) => data._id}
        columns={columns}
        dataSource={listCompanyUsers?.companyUsers?.items || []}
        loading={isLoading}
        // onChange={handleTableChange}
        paginate={{
          pageSize: paginate.pageSize,
          total: listCompanyUsers?.companyUsers?.total || 0,
          current: listCompanyUsers?.companyUsers?.currentPage || 1,
          onChange: (page) => {
            setPaginate({
              ...paginate,
              page,
            });
          },
        }}
      />
    </TableHeader>
  );
}
