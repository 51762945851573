import { getSDK } from '@/graphql/client';
import { QueryCompanyInput } from '@/graphql/generated/graphql';
import { useQuery } from 'react-query';
import { GET_COMPANY_BY_ID } from '../types';

export default function useGetCompanyByID(params: QueryCompanyInput) {
  return useQuery([GET_COMPANY_BY_ID, params], async () =>
    params._id ? getSDK().getCompanyByID({ input: params }) : null,
  );
}
