import i18n from '@/i18n';

export enum ErrorCode {
  USERNAME_EXISTED = 'USERNAME_EXISTED',
  EMAIL_EXISTED = 'EMAIL_EXISTED',
  CANCEL_REQUEST = 'CANCEL_REQUEST',
}

export default function getError(code: string) {
  switch (code) {
    case ErrorCode.USERNAME_EXISTED:
      return i18n.t(ErrorCode.USERNAME_EXISTED);
    case ErrorCode.EMAIL_EXISTED:
      return i18n.t(ErrorCode.EMAIL_EXISTED);
    default:
      return i18n.t('notification_title_default');
  }
}
