import ic_account from '@assets/images/left_nav/account.svg';
import ic_available_device from '@assets/images/left_nav/available_device.svg';
import ic_customer from '@assets/images/left_nav/customer.svg';
import ic_dashboard from '@assets/images/left_nav/dashboard.svg';
import ic_device from '@assets/images/left_nav/device.svg';
// import ic_group_device from '@assets/images/left_nav/group-device.svg';
// import ic_media from '@assets/images/left_nav/media.svg';
// import ic_pending_approval from '@assets/images/left_nav/pending_approval.svg';
import ic_setting from '@assets/images/left_nav/setting.svg';
import { getHistory } from '@helpers/history';
import { Menu } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

interface IProps {
  isCollapsed: boolean;
}

export default function SupperAdminLeftNavigation({ isCollapsed }: IProps) {
  const handleSelectMenu = ({ key }: any) => {
    getHistory().push(key);
  };

  const { t } = useTranslation(['leftNavigation']);

  const { pathname, search } = useLocation();

  const selectedKeys = useMemo(() => {
    const keys = `${pathname + search}`
      .split('/')
      .filter((r) => r)
      .reduce<string[]>((ac, cu) => {
        if (['company', 'playlist'].includes(cu)) {
          //hotfix menu for company and playlist
          ac.push(`/${cu}/list`);
        }
        if (ac.length) {
          ac.push(`${ac[ac.length - 1]}/${cu}`);
        } else {
          ac.push(`/${cu}`);
        }
        return ac;
      }, []);

    if (keys.length) return keys;
    return ['/'];
  }, [pathname, search]);

  const IconMenuItem = (key: string, icon: any, alt: string) => {
    return <img src={icon} alt={alt} />;
  };

  const mouseDownHandler = (event: any, key: any) => {
    if (event.button === 1) {
      window.open(key);
    }
  };

  return (
    <>
      <Menu
        className="left-menu-content"
        mode="inline"
        onSelect={handleSelectMenu}
        selectedKeys={selectedKeys}
        defaultOpenKeys={selectedKeys}
        theme={'dark'}
      >
        <Menu.Item
          key={'/'}
          icon={IconMenuItem('/', ic_dashboard, 'ic_dashboard')}
          onMouseDown={(env) => mouseDownHandler(env, '/')}
        >
          {t('dashboard')}
        </Menu.Item>

        <Menu.Item
          key={'/user/list'}
          icon={IconMenuItem('/user/list', ic_account, 'ic_account')}
          onMouseDown={(env) => mouseDownHandler(env, '/user/list')}
        >
          {t('account')}
        </Menu.Item>
        <Menu.Item
          key={`/company/list`}
          icon={IconMenuItem(`/company/list`, ic_customer, 'ic_customer')}
          onMouseDown={(env) => mouseDownHandler(env, '/company/list')}
        >
          {t('customer')}
        </Menu.Item>
        <Menu.Item
          key={'/device/list'}
          icon={IconMenuItem(`/device/list`, ic_device, 'ic_device')}
          onMouseDown={(env) => mouseDownHandler(env, '/device/list')}
        >
          {t('device_deployed')}
        </Menu.Item>
        <Menu.Item
          key={'/device/available'}
          icon={IconMenuItem(`/device/list`, ic_available_device, 'ic_available_device')}
          onMouseDown={(env) => mouseDownHandler(env, '/device/available')}
        >
          {t('device_available')}
        </Menu.Item>
        <Menu.Item
          key="/setting/update"
          icon={IconMenuItem(`/setting/update`, ic_setting, 'ic_setting')}
          onMouseDown={(env) => mouseDownHandler(env, '/setting/update')}
        >
          {t('setting')}
        </Menu.Item>
      </Menu>
    </>
  );
}
