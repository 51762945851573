/* eslint-disable array-callback-return */
import { PlaylistSchedule } from '@modules/GroupDevice/types';
import { ADD_CURRENT_PLAYLIST_SCHEDULE_ACTION, CurrentPlaylistScheduleActionTypes } from '../action-types';

export interface CurrentPlaylistSchedulesState {
  loading?: boolean;
  data?: PlaylistSchedule;
}

const initialState: CurrentPlaylistSchedulesState = {
  data: undefined,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: CurrentPlaylistScheduleActionTypes): CurrentPlaylistSchedulesState => {
  switch (action.type) {
    case ADD_CURRENT_PLAYLIST_SCHEDULE_ACTION: {
      return {
        ...state,
        data: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
