import { AdminDeviceScreenStatus } from '@/graphql/generated/graphql';
import Loading from '@commons/components/Loading';
import { Select } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

interface RotateBtnProp {
  onChangeScreenStatus(id: string, direction: AdminDeviceScreenStatus): void;
  recordId?: string;
  loading?: boolean;
  status?: AdminDeviceScreenStatus | null;
}

export default function ScreenStatusBtn(props: RotateBtnProp) {
  const { t } = useTranslation(['device']);
  const [status, setStatus] = React.useState<AdminDeviceScreenStatus | null>(null);

  React.useEffect(() => {
    if (props.status) {
      setStatus(props.status);
    } else {
      setStatus(AdminDeviceScreenStatus.Auto);
    }
    // eslint-disable-next-line
  }, [props.status]);

  React.useEffect(() => {
    return () => {
      setStatus(null);
    };
  }, []);

  return (
    status && (
      <div className="rotate-btn d-flex align-items-center justify-content-center">
        <span className="title">{t('screen_status')}:</span>
        <Select
          value={status}
          style={{ width: 200, textAlign: 'left' }}
          onChange={(value: AdminDeviceScreenStatus) =>
            props.recordId && props.onChangeScreenStatus(props.recordId, value)
          }
        >
          <Option value={AdminDeviceScreenStatus.Auto}>{t('auto_by_schedule')}</Option>
          <Option value={AdminDeviceScreenStatus.On}>{t(AdminDeviceScreenStatus.On)}</Option>
          <Option value={AdminDeviceScreenStatus.Off}>{t(AdminDeviceScreenStatus.Off)}</Option>
        </Select>
        {props.loading && <Loading type="modal" />}
      </div>
    )
  );
}
