import { Playlist, QueryPlaylistInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_PLAYLIST = 'GET_PLAYLIST';
export const GET_PLAYLIST_SUCCESS = 'GET_PLAYLIST_SUCCESS';
export const GET_PLAYLIST_ERROR = 'GET_PLAYLIST_ERROR';

export interface GetPlaylistAction {
  type: typeof GET_PLAYLIST;
  payload: QueryPlaylistInput;
}

export interface GetPlaylistActionSuccess {
  type: typeof GET_PLAYLIST_SUCCESS;
  payload: Playlist;
}

export interface GetPlaylistActionError {
  type: typeof GET_PLAYLIST_ERROR;
  payload: AppError;
}

export type GetPlaylistActionTypes = GetPlaylistAction | GetPlaylistActionSuccess | GetPlaylistActionError;
