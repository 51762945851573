import { Button, Card, Col, DatePicker, Form, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DayOffTable from './DayOffTable';

interface IProps {
  companyId: string;
}

export default function DayOff(props: IProps) {
  const { t } = useTranslation(['company']);
  const [form] = Form.useForm();

  const onAddDayOff = () => {
    console.log('onAddDayOff');
  };

  return (
    <Card
      title={t('day_off')}
      bordered={false}
      headStyle={{ borderBottom: 'none', fontSize: '20px' }}
      bodyStyle={{ padding: '8px 24px' }}
      className="pb-2 special-day-card"
    >
      <Form form={form} layout="horizontal" onFinish={onAddDayOff}>
        <Form.Item
          name="special_day"
          label={t('choose_day')}
          colon={false}
          labelCol={{ span: 12, xs: 10, md: 8, xl: 6 }}
          labelAlign="left"
        >
          <DatePicker size="large" className="w-100" />
        </Form.Item>
        <Button className="add-day-off fs-500 mt-3" type="dashed" htmlType="submit">
          {t('add_day_off')}
        </Button>
      </Form>
      <Row>
        <Col xs={24}>
          <DayOffTable companyId={props.companyId} />
        </Col>
      </Row>
    </Card>
  );
}
