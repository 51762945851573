import { Media, UpdateMediaInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  UPDATE_FILE,
  UPDATE_FILE_SUCCESS,
  UPDATE_FILE_ERROR,
  UPDATE_UPDATE_AT_FOLDER,
  UpdateUpdateAtFolderInput,
} from '../action-types';

export const updateFileAction = (payload: UpdateMediaInput) => ({
  type: UPDATE_FILE,
  payload,
});

export const updateFileActionSuccess = (payload?: Media) => ({
  type: UPDATE_FILE_SUCCESS,
  payload,
});

export const updateFileActionError = (payload: AppError) => ({
  type: UPDATE_FILE_ERROR,
  payload,
});

export const updateUpdateAtFolderAction = (payload: UpdateUpdateAtFolderInput) => ({
  type: UPDATE_UPDATE_AT_FOLDER,
  payload,
});
