import { Roles } from '@/graphql/generated/graphql';
import { getHistory } from '@helpers/history';
import { LoginState } from '@modules/Auth/redux/action-types';
import useGetAllCompany from '@modules/Company/hooks/useGetAllCompany';
import { CompanyFilter } from '@modules/Company/types';
import { GET_DETAIL_DEVICE } from '@modules/Device/types';
import { RootState } from '@redux/reducers';
import { Button, Col, Form, Input, Radio, Row, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

export interface OsObject {
  title: string;
  value: string;
}
export interface ScreenObject {
  title: string;
  value: string;
}

interface PackageFormProps {
  isLoading: boolean;
  form: FormInstance;
  onFinish(values: any): void;
  isUpdate?: boolean;
  deviceData?: any;
  idDevice?: any;
}

export default function DeviceFrom(props: PackageFormProps) {
  const [value, setValue] = useState(1);
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);
  const [canEdit, setCanEdit] = useState(true);

  const onChange = (e: any) => {
    setValue(e.target.value);
  };
  useEffect(() => {
    if (props.deviceData?.device) {
      if (props.deviceData?.device?.companyId) {
        setValue(2);
      }
      props.form.setFieldsValue({
        ...props.deviceData.device,
        companyId: props.deviceData.device.companyId
          ? `${props.deviceData.device.company?._id}/$/${props.deviceData.device.company?.name}`
          : null,
      });
    }
    // eslint-disable-next-line
  }, [props.deviceData]);

  const queryClient = useQueryClient();

  useEffect(() => {
    return () => queryClient.removeQueries(GET_DETAIL_DEVICE);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (loginState.data?.role === Roles.SuperAdmin || loginState.data?.isSuperAdmin) {
      setCanEdit(true);
    } else {
      setCanEdit(false);
    }
    // eslint-disable-next-line
  }, [loginState.data?.role]);

  const { t } = useTranslation('device');

  const handleSubmit = (values: any) => {
    const companyId = !loginState.data?.companyId ? values.companyId?.split('/$/')[0] : loginState.data?.companyId;
    const data: any = {
      companyId: companyId || undefined,
      deviceModel: values.deviceModel,
      name: values.name,
      deviceCode: values.deviceCode,
      os: values.os,
      screen: values.screen,
      yearOfManufacture: values.yearOfManufacture ? Number(values.yearOfManufacture) : null,
    };

    if (
      props.isUpdate &&
      !!companyId &&
      props.deviceData &&
      props.deviceData.device &&
      companyId !== props.deviceData.device.companyId
    ) {
      data.schedules = [];
    }

    props.onFinish(data);
  };

  const listOs: OsObject[] = [
    {
      title: 'Android',
      value: 'ANDROID',
    },
    {
      title: 'Linux',
      value: 'LINUX',
    },
    {
      title: 'Tizen',
      value: 'TIZEN',
    },
    {
      title: 'Webos',
      value: 'WEBOS',
    },
  ];

  const listScreen: ScreenObject[] = [
    {
      title: 'Led',
      value: 'LED',
    },
    {
      title: 'Oled',
      value: 'OLED',
    },
    {
      title: 'Qled',
      value: 'QLED',
    },
  ];

  const selectOptionOS = listOs.map((e: OsObject, index) => {
    return (
      <Select.Option key={index} value={e.value}>
        {e.title}
      </Select.Option>
    );
  });

  const selectOptionScreen = listScreen.map((e: ScreenObject, index) => {
    return (
      <Select.Option key={index} value={e.value}>
        {e.title}
      </Select.Option>
    );
  });

  /**
   * xu li input nha hang
   */

  // eslint-disable-next-line
  const [paginate, setPaginate] = useState({
    page: 1,
    pageSize: 8,
  });

  const { Option } = Select;

  // eslint-disable-next-line
  const [filter, setFilter] = useState<CompanyFilter>({});

  // eslint-disable-next-line
  const { data: listCompany } = useGetAllCompany({
    page: paginate.page,
    limit: paginate.pageSize,
    query: filter?.query || undefined,
  });

  if (
    props?.deviceData?.device?.companyId &&
    listCompany?.companies?.items?.findIndex((c) => c._id === props?.deviceData?.device?.companyId) === -1
  ) {
    listCompany?.companies?.items?.push(props?.deviceData?.device.company);
  }

  const layout = {
    labelCol: {
      sm: { span: 24 },
      xs: { span: 24 },
      md: { span: 8 },
      lg: { span: 6 },
    },
  };
  return (
    <Form form={props.form} onFinish={handleSubmit} preserve={true} {...layout}>
      <Form.Item label={t('name')} name="name" rules={[{ required: true, message: t('validate.required_name') }]}>
        <Input style={{ width: '100%', maxWidth: 485 }} type="text" disabled={!canEdit} />
      </Form.Item>
      <Form.Item label={t('device_code')} name="deviceCode">
        <Input style={{ width: '100%', maxWidth: 485 }} type="text" disabled={!canEdit} />
      </Form.Item>
      <Form.Item label={t('model')} name="deviceModel">
        <Input style={{ width: '100%', maxWidth: 485 }} type="text" disabled={!canEdit} />
      </Form.Item>

      <Form.Item label={t('os')} name="os">
        <Select style={{ width: '100%', maxWidth: 485 }} disabled={!canEdit}>
          {selectOptionOS}
        </Select>
      </Form.Item>
      <Form.Item label={t('screen')} name="screen">
        <Select style={{ width: '100%', maxWidth: 485 }} disabled={!canEdit}>
          {selectOptionScreen}
        </Select>
      </Form.Item>
      <Form.Item label={t('year_of_manufacture')} name="yearOfManufacture">
        <Input style={{ width: '100%', maxWidth: 485 }} type="number" disabled={!canEdit} />
      </Form.Item>

      {!loginState.data?.companyId ? (
        <Form.Item label={t('use')} name="useOption" initialValue={props.deviceData?.device?.companyId ? 2 : 1}>
          <Radio.Group onChange={onChange} value={value} disabled={!canEdit}>
            <Radio className="mr-5" value={1}>
              {t('repository')}
            </Radio>
            <Radio value={2}>{t('company')}</Radio>
          </Radio.Group>
        </Form.Item>
      ) : null}

      {value === 2 && !loginState.data?.companyId ? (
        <Form.Item shouldUpdate={true} label={t('select_company')} name="companyId">
          <Select
            disabled={!canEdit}
            style={{ width: '100%', maxWidth: 485 }}
            showSearch
            dropdownClassName="mr-0"
            allowClear
            placeholder={t('company')}
            filterOption={false}
            onSearch={(e) => {
              setFilter({
                ...filter,
                query: e,
              });
            }}
            dropdownRender={(menu) => {
              return (
                <React.Fragment>
                  {menu}
                  <Row className="ml-2 mr-2 mt-2 mb-2 justify-content-center">
                    <Col className="mr-2">
                      <Button
                        onMouseDown={() => {
                          if (paginate.page <= 1) {
                            return;
                          }
                          setPaginate({
                            ...paginate,
                            page: 1,
                          });
                        }}
                      >
                        Reset
                      </Button>
                    </Col>
                    <Col className="mr-2">
                      <Button
                        onMouseDown={() => {
                          if (paginate.page <= 1) {
                            return;
                          }
                          setPaginate({
                            ...paginate,
                            page: paginate.page - 1,
                          });
                        }}
                      >
                        Pre
                      </Button>
                    </Col>
                    <Col className="mr-2">
                      <Button
                        onMouseDown={() => {
                          if (paginate.page === listCompany?.companies?.totalPage) {
                            return;
                          }
                          setPaginate({
                            ...paginate,
                            page: paginate.page + 1,
                          });
                        }}
                      >
                        Next
                      </Button>
                    </Col>
                    <Col className="mt-1">{`${listCompany?.companies?.currentPage} / ${listCompany?.companies?.totalPage}`}</Col>
                  </Row>
                </React.Fragment>
              );
            }}
          >
            {listCompany &&
              listCompany?.companies &&
              listCompany?.companies.items?.map((company: any) => (
                <Option key={company?._id} value={`${company._id}/$/${company.name}`} title={`${company.name}`}>
                  {company.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      ) : null}
      <Row>
        <Col md={8} lg={6}>
          {''}
        </Col>
        <Col md={16} lg={18}>
          <Button
            disabled={!canEdit}
            htmlType="submit"
            key="submit"
            className="btn-dangerous"
            loading={props.isLoading}
          >
            {t('save')}
          </Button>
          <Button htmlType="button" type="default" onClick={() => getHistory().push(`/device/list`)} className="mx-3">
            {t('cancel_add')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
