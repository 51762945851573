import {
  CreateGroupInput,
  Group,
  GroupStatus,
  QueryCompaniesInput,
  QueryGroupsInput,
  Roles,
  UpdateGroupInput,
} from '@/graphql/generated/graphql';
import BtnAddNew from '@commons/components/Button/BtnAddNew';
import ModalConfirm from '@commons/components/modals/ModalConfirm';
import TableLayout from '@commons/components/TableLayout';
import TableWithNumber from '@commons/components/TableWithNumber';
import { renderGroupStatus } from '@helpers/string';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { LoginState } from '@modules/Auth/redux/action-types';
import useGetAllCompany from '@modules/Company/hooks/useGetAllCompany';
import GroupRowAction from '@modules/GroupDevice/Components/Action/GroupRowAction';
import AddGroupDeviceModal from '@modules/GroupDevice/Components/Modal/AddGroupDeviceModal';
import EditGroupDeviceModal from '@modules/GroupDevice/Components/Modal/EditGroupDeviceModal';
import useAddNewGroupDevice from '@modules/GroupDevice/hooks/useAddNewGroup';
import useDeleteGroup from '@modules/GroupDevice/hooks/useDeleteGroup';
import useGetAllGroups from '@modules/GroupDevice/hooks/useGetAllGroups';
import useUpdateGroup from '@modules/GroupDevice/hooks/useUpdateGroup';
import { RootState } from '@redux/reducers';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import TextTruncate from 'react-text-truncate';

export interface GroupsFilter {
  query?: string;
  companyId?: string;
}

const { Option } = Select;

interface IState {
  deleteGroup?: Group;
  editGroup?: Group;
}

const ListGroupDevice = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation(['group-device', 'button']);
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);
  const [visibleAddGroup, setVisibleAddGroup] = useState<boolean>(false);
  const { setPageTitle } = useSetPageTitle();
  const [state, setState] = useState<IState>({
    deleteGroup: undefined,
    editGroup: undefined,
  });

  const [filterGroups, setFilterGroups] = useState<QueryGroupsInput>({
    limit: 10,
    page: 1,
    companyId: loginState.data?.companyId || undefined,
  });

  const { data: listGroup, isLoading: loadingTable } = useGetAllGroups(filterGroups);
  const { mutate: deleteGroup, isSuccess: deleteSuccess, isLoading: deleteLoading } = useDeleteGroup();
  const { mutate: createGroup, isSuccess: createSuccess, isLoading } = useAddNewGroupDevice();
  const { mutate: updateGroup, isSuccess: updateSuccess, isLoading: updateLoading } = useUpdateGroup();

  useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t('group_device'));
    // eslint-disable-next-line
  });

  const onSearch = (values: any) => {
    setFilterGroups({ ...filterGroups, query: values.query });
    form.setFieldsValue({
      query: values.query ? values.query.trim() : null,
    });
  };

  // eslint-disable-next-line
  const [filterCompany, setFilterCompany] = useState<QueryCompaniesInput>({
    page: 1,
    limit: 8,
    query: undefined,
  });

  const { data: listCompany } = useGetAllCompany(filterCompany);

  const columns: ColumnsType<any> = [
    {
      title: t('stt'),
      key: '#',
      dataIndex: '#',
      width: 70,
      align: 'center',
    },
    {
      title: t('group'),
      key: 'name',
      dataIndex: 'name',
      width: 200,
      render: (value: any, record) => (
        <TextTruncate
          containerClassName="w-100 d-block word-break"
          line={2}
          element="span"
          truncateText="…"
          text={value}
        />
      ),
    },
    {
      title: t('timezone'),
      key: 'timezone',
      dataIndex: 'timezone',
      width: 150,
      render: (value, record) => {
        return (
          <TextTruncate containerClassName="w-100 d-block" line={1} element="span" truncateText="…" text={value} />
        );
      },
    },
    {
      title: t('device'),
      key: 'qty',
      width: 80,
      align: 'center',
      render: (_, record: Group) => {
        return <span>{record.deviceIds?.length || 0}</span>;
      },
    },
    {
      title: t('status.status'),
      key: 'status',
      width: 150,
      align: 'center',
      dataIndex: 'status',
      render: (record) => {
        return <span className="word-break">{t(`status.${renderGroupStatus(record)}`)}</span>;
      },
    },
    {
      title: t('company'),
      key: 'company',
      width: 200,
      render: (record: Group) => {
        return <span>{record.company?.name}</span>;
      },
    },
    // {
    //   title: t('devices'),
    //   key: 'devices',
    //   width: 300,
    //   render: (_, record: Group) => {
    //     const deviceNames: string[] = [];
    //     record.devices?.map((item, index) => {
    //       deviceNames.push(item.name);
    //     });
    //     return (
    //       <TextTruncate
    //         containerClassName="w-100 d-block"
    //         line={2}
    //         element="span"
    //         truncateText="…"
    //         text={deviceNames.join(', ')}
    //       />
    //     );
    //   },
    // },
    {
      title: '',
      key: 'action',
      width: 150,
      render: (record: any) => (
        <GroupRowAction
          record={record}
          onDelete={(record) => {
            setState((state) => {
              return { ...state, deleteGroup: record };
            });
          }}
          onEdit={(record) => {
            setState((state) => {
              return { ...state, editGroup: record };
            });
          }}
        />
      ),
    },
  ];

  const onCreateGroup = (input: CreateGroupInput) => {
    createGroup({ input });
  };

  const onUpdateGroup = (input: UpdateGroupInput) => {
    updateGroup({ input });
  };

  const onDeleteGroup = () => {
    if (state.deleteGroup && state.deleteGroup._id) {
      deleteGroup({ input: { _id: state.deleteGroup._id } });
    }
  };

  const onCancel = () => {
    setVisibleAddGroup(false);
    setState((state) => {
      return { ...state, deleteGroup: undefined, editGroup: undefined };
    });
  };

  useEffect(() => {
    if (deleteSuccess || createSuccess || updateSuccess) {
      onCancel();
    }
    // eslint-disable-next-line
  }, [deleteSuccess, createSuccess, updateSuccess]);

  return (
    <div>
      <TableLayout
        tableHeader={
          <div className={`d-flex align-items-center justify-content-end w-100`}>
            <Form
              layout="inline"
              form={form}
              onFinish={onSearch}
              className="d-flex justify-content-start justify-content-md-end gap-3"
            >
              {loginState.data?.role === Roles.SuperAdmin && (
                <Form.Item className="bg_input_form" name="companyId" style={{ width: 300 }}>
                  <Select
                    style={{ width: '100%', maxWidth: 485 }}
                    showSearch
                    dropdownClassName="mr-0"
                    allowClear
                    placeholder={t('company')}
                    onSearch={(e) => {
                      setFilterCompany({
                        ...filterCompany,
                        query: e,
                        page: 1,
                      });
                    }}
                    onChange={(e) => {
                      setFilterGroups({
                        ...filterGroups,
                        page: 1,
                        query: undefined,
                        companyId: e ? e.toString().split('/$/')[0] : undefined,
                      });
                    }}
                    filterOption={false}
                    dropdownRender={(menu) => {
                      return (
                        <React.Fragment>
                          {menu}
                          <Row className="ml-2 mr-2 mt-2 mb-2 justify-content-center">
                            <Col className="mr-2">
                              <Button
                                onMouseDown={() => {
                                  if (filterCompany && filterCompany.page && filterCompany.page <= 1) {
                                    return;
                                  }
                                  setFilterCompany({
                                    ...filterCompany,
                                    page: (filterCompany.page || 0) - 1,
                                  });
                                }}
                              >
                                {t('button:pre')}
                              </Button>
                            </Col>
                            <Col className="mr-2">
                              <Button
                                onMouseDown={() => {
                                  if (
                                    (filterCompany &&
                                      filterCompany.page &&
                                      filterCompany.page === listCompany?.companies?.totalPage) ||
                                    (listCompany?.companies && listCompany?.companies?.totalPage <= 0)
                                  ) {
                                    return;
                                  }
                                  setFilterCompany({
                                    ...filterCompany,
                                    page: (filterCompany.page || 0) + 1,
                                  });
                                }}
                              >
                                {t('button:next')}
                              </Button>
                            </Col>
                            <Col className="mt-1">{`${listCompany?.companies?.currentPage} / ${listCompany?.companies?.totalPage}`}</Col>
                          </Row>
                        </React.Fragment>
                      );
                    }}
                  >
                    {listCompany &&
                      listCompany?.companies &&
                      listCompany?.companies.items?.map((company: any) => (
                        <Option key={company?._id} value={`${company._id}/$/${company.name}`} title={`${company.name}`}>
                          {company.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
              <Form.Item name={'status'}>
                <Select
                  style={{ width: 150 }}
                  placeholder={t('status.status')}
                  allowClear
                  onChange={(e: GroupStatus) => {
                    setFilterGroups({
                      ...filterGroups,
                      page: 1,
                      status: e ? [e] : undefined,
                    });
                  }}
                >
                  <Option value={GroupStatus.Approved}>{t('status.Approved')}</Option>
                  <Option value={GroupStatus.WaitToApprove}>{t('status.Waiting_To_Approve')}</Option>
                  <Option value={GroupStatus.Pending}>{t('status.Pending')}</Option>
                  <Option value={GroupStatus.Rejected}>{t('status.Rejected')}</Option>
                </Select>
              </Form.Item>
              <Form.Item>
                <Input.Group compact>
                  <Form.Item name="query" className="w-full mr-0">
                    <Input className="bd-form-search" placeholder={`${t('search_group_device')}...`} />
                  </Form.Item>
                  <Button icon={<FiSearch color="#F80000" />} className="btn-search" htmlType="submit" />
                </Input.Group>
              </Form.Item>
              {loginState.data?.companyId && (
                <BtnAddNew
                  onClick={() => {
                    setVisibleAddGroup(true);
                  }}
                />
              )}
            </Form>
          </div>
        }
      >
        <TableWithNumber
          scroll={{
            x: true,
          }}
          rowKey={(data) => data._id}
          columns={
            loginState.data?.role === Roles.SuperAdmin ? columns : columns.filter((column) => column.key !== 'company')
          }
          rowClassName={(record, index) =>
            index % 2 === 0 ? 'table-row-height table-row-white ' : 'table-row-height table-row-gray'
          }
          dataSource={(listGroup && listGroup.groups && listGroup.groups.items) || []}
          loading={loadingTable}
          pagination={false}
          totalTitle={t('groups')}
          paginate={{
            pageSize: 10,
            total: listGroup?.groups?.total || 0,
            current: listGroup?.groups?.currentPage || 1,
            onChange: (page) => {
              setFilterGroups({
                ...filterGroups,
                page,
              });
            },
          }}
        />
      </TableLayout>
      <AddGroupDeviceModal
        onCreate={onCreateGroup}
        onCancel={onCancel}
        visible={visibleAddGroup}
        isLoading={isLoading}
        isSuccess={createSuccess}
      />
      <EditGroupDeviceModal
        isSuccess={updateSuccess}
        groupItem={state.editGroup}
        onCancel={onCancel}
        onUpdate={onUpdateGroup}
        isLoading={updateLoading}
      />
      <ModalConfirm
        isLoading={deleteLoading}
        data={state.deleteGroup}
        handleOk={onDeleteGroup}
        handleCancel={onCancel}
        title={t('delete_title')}
        okTitle={t('delete')}
        content={
          <Trans
            i18nKey="delete_content"
            t={t}
            values={{ name: state.deleteGroup?.name }}
            components={[<span className="primary_color fw-500" key={''} />]}
          />
        }
      />
    </div>
  );
};

export default ListGroupDevice;
