import { Media } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMediaItemAction } from '../redux/actions';
import { ListMediasState } from '../redux/reducers/medias';

const useDeleteMediaItem = () => {
  const state = useSelector<RootState, ListMediasState>((state) => state.media.list);

  const dispatch = useDispatch();
  const onDeleteItem = useCallback(
    (input?: Media) => {
      dispatch(deleteMediaItemAction(input));
    },
    [dispatch],
  );
  return {
    onDeleteItem,
    item: state.deleteItem,
  };
};
export default useDeleteMediaItem;
