import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import * as apis from '@modules/GroupDevice/services/apis';
import { PlaylistSchedule } from '@modules/GroupDevice/types';
import { put } from 'redux-saga/effects';
import { UpdateSchedulesGroupAction } from '../action-types';
import { updateSchedulesGroupError, updateSchedulesGroupSuccess, updateSchedulesSecondGroupSuccess } from '../actions';

export function* updateScheduleDeviceGroupAsync(action: UpdateSchedulesGroupAction) {
  try {
    const updateScheduleDeviceGroup = yield apis.updateScheduleDeviceGroup(action.payload);
    if (updateScheduleDeviceGroup) {
      yield put(
        updateSchedulesGroupSuccess(
          updateScheduleDeviceGroup?.updateGroupPlaylistSchedules?.schedules as PlaylistSchedule[],
        ),
      );
      yield put(
        updateSchedulesSecondGroupSuccess(
          updateScheduleDeviceGroup?.updateGroupPlaylistSchedules.schedulesSecond as PlaylistSchedule[],
        ),
      );
      NotificationSuccess(i18n.t('notification'), i18n.t('update_success'));
    }
  } catch (error) {
    yield put(updateSchedulesGroupError(error));
  }
}
