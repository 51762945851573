/* eslint-disable react-hooks/exhaustive-deps */
import { AvailabilityInput, FrameInputType, FrameType, Media, VideoProfile } from '@/graphql/generated/graphql';
import { getTypeOfFileNoPDf } from '@modules/Media/helper/file';
import useAddNewPlayList from '@modules/PlayList/hooks/useAddNewPlayListData';
import '@modules/PlayList/styled/RightPlayListAddew.scss';
import { Button } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import PlaylistRightItem from './component/PlaylistRightItem';

interface DataItemProps {
  data: any[];
  isLoading?: boolean;
  onRemoveDataItem2: (item: Media[]) => void;
  onSetDurationValue: (key: string, value: any) => void;
}

export interface IRight {
  id?: string;
  isLink?: boolean;
  webUrl?: string;
  filename?: string;
  timeDuration?: any;
  availability?: AvailabilityInput;
  width?: number;
  height?: number;
}

export default function RightPlayListAddNew(props: DataItemProps) {
  const { data } = props;

  const [mediaData, setMedia] = useState<(Media & IRight)[]>([]);

  const { onAddNewAndUpatePlayList } = useAddNewPlayList();

  const { t } = useTranslation(['media']);
  const { t: tgroup } = useTranslation(['group-device']);

  useEffect(() => {
    if (data.length >= 0) {
      setMedia([...data]);
    }
  }, [data]);

  useEffect(() => {
    if (mediaData.length >= 0) {
      const Data: FrameInputType[] = [];
      mediaData.forEach((element: any, i) => {
        props?.onSetDurationValue(`input_duration_${element._id}_${i}`, element?.duration || 5);
        props?.onSetDurationValue(`input_url_${element._id}_${i}`, element?.webUrl || '');
        delete element.thumbs;
        const inputData: FrameInputType = {
          availability: {
            enabled: element.availability?.enabled === undefined ? true : element.availability?.enabled,
            fromDate: element.availability?.fromDate ? moment(element.availability!.fromDate).valueOf() : null,
            toDate: element.availability?.toDate ? moment(element.availability!.toDate).valueOf() : null,
          },
          media: element?.isLink
            ? null
            : {
                _id: element?._id,
                filename: element?.filename,
                type: (element.media?.type || element?.mediaType || element.type) as any,
                companyId: element?.companyId,
                mimetype: element?.mimetype,
                optimizePath: element?.optimizePath,
                optimizeUrl: element?.optimizeUrl,
                path: element?.path,
                size: element?.size,
                status: element?.status,
                canEdit: true,
                parentId: element?.parentId,
                encoding: element?.encoding,
                images: element?.images?.map((e: any) => ({
                  width: e.width,
                  height: e.height,
                  mimetype: e.mimetype,
                  path: e.path,
                  url: e.url,
                })),
                videoProfiles: element?.videoProfiles?.map((e: VideoProfile) => ({
                  path: e.path,
                  preset: e.preset,
                  url: e.url,
                })),
                duration: element?.duration,
              },
          webUrl: element?.webUrl || null,
          duration: element?.duration || 5,
          mediaId: element?._id,
          type: element?.isLink ? FrameType.WebUrl : (getTypeOfFileNoPDf(element.mimetype || '') as any),
        };

        Data.push(inputData);
      });

      onAddNewAndUpatePlayList(Data);
    }
  }, [mediaData]);

  const onClickAddLink = useCallback(() => {
    const newItem: any = {
      id: new Date().valueOf(),
      isLink: true,
      webUrl: '',
      type: FrameType.WebUrl,
      timeDuration: '',
    };

    // setMedia();
    props.onRemoveDataItem2([...mediaData, newItem]);
  }, [mediaData, props]);

  const onPressRemove = useCallback(
    (item: Media | any) => {
      let newData = [...mediaData];
      newData = newData.filter((e, i) => i !== item.index || e._id !== item._id);
      // setMedia(newData);
      props.onRemoveDataItem2(newData);
    },
    [mediaData, props],
  );

  const onPressAddLink = useCallback(
    (item: (Media & IRight) | any, i?: number) => {
      const newData = [...mediaData];
      const index = newData.findIndex((e, index) => index === item.index && e._id === item._id);
      if (index >= 0) {
        newData[index] = item;
      }
      props.onRemoveDataItem2(newData);
    },
    [mediaData, props],
  );

  return (
    <div className="right-playlist-add-new">
      <div className="play-list-header">
        <div style={{ flex: 4 }}>{t('ITEMS').toUpperCase()}</div>
        <div style={{ flex: 2, textAlign: 'center' }}>
          {tgroup('schedule.time').toUpperCase()}({tgroup('schedule.seconds')})
        </div>
        <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            type="default"
            style={{ minWidth: 'unset', marginRight: -10 }}
            className="btn-header btn-submit text-white"
            onClick={onClickAddLink}
          >
            +{' URL'}
          </Button>
        </div>
      </div>

      <div style={{}}>
        {mediaData?.map((e, index) => {
          return (
            <Draggable key={e?.id} draggableId={`${e?.id}`} index={index}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={{
                    ...provided?.draggableProps?.style,
                  }}
                >
                  <PlaylistRightItem
                    item={e}
                    index={index}
                    onPressRemove={onPressRemove}
                    onPressAddLink={onPressAddLink}
                  />
                </div>
              )}
            </Draggable>
          );
        })}
      </div>
    </div>
  );
}
