import { AdminDeviceScreenStatus, Device, DeviceScreenStatus, DeviceStatus } from '@/graphql/generated/graphql';
import { Tooltip } from 'antd';
import moment from 'moment';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import TextTruncate from 'react-text-truncate';
import { FULL_DATE_TIME_HOUR_MINUTE, FULL_DATE_TIME_REVERT } from '@helpers/date';

interface Props {
  record: Device;
}

function humanReadableDateTimeZone(dateTimeZone?: string) {
  if (dateTimeZone) {
    const [timezone, time] = dateTimeZone.split(' - ');
    return `${timezone.replace(':00', '')} ${moment(time, FULL_DATE_TIME_REVERT).format(FULL_DATE_TIME_HOUR_MINUTE)}`;
  }
  return dateTimeZone;
}

const CellDeviceInfo = ({ record }: Props) => {
  const { t } = useTranslation(['device']);
  const renderStatusClassName = useCallback(() => {
    let status = '';
    if (!record.lastGetInfoAt || moment().valueOf() - 5 * 60 * 1000 - record.lastGetInfoAt > 0) {
      status = 'off-connect';
    } else {
      if (record.adminScreenStatus === AdminDeviceScreenStatus.Auto) {
        if (record.status === DeviceStatus.On) {
          if (record.screenStatus === DeviceScreenStatus.On) {
            status = 'on-connect-with-screen-on';
          } else {
            status = 'on-connect-with-screen-off';
          }
        } else {
          status = 'off-connect';
        }
      } else {
        if (record.adminScreenStatus === AdminDeviceScreenStatus.On) {
          if (record.status === DeviceStatus.On) {
            status = 'on-connect-with-screen-on';
          } else {
            status = 'off-connect';
          }
        } else {
          if (record.status === DeviceStatus.On) {
            status = 'on-connect-with-screen-off';
          } else {
            status = 'off-connect';
          }
        }
      }
    }

    return status;
    // eslint-disable-next-line
  }, [record.status, record.adminScreenStatus, record.lastGetInfoAt]);

  return (
    <div className="cell-device-info">
      <Tooltip title={t(renderStatusClassName())}>
        <div className="">
          <span className={`dot-status ${renderStatusClassName()}`}>{''}</span>
          <span className="mb-0 device-name">
            {record.name?.length > 20 ? (
              <TextTruncate line={2} element="span" truncateText="…" text={record.deviceCode || ''} />
            ) : (
              record.deviceCode
            )}
          </span>
        </div>
        <span className="mb-0 device-time">
          {record.name?.length > 20 ? (
            <TextTruncate line={2} element="span" truncateText="…" text={record.name} />
          ) : (
            record.name
          )}
        </span>
        {record.adminScreenStatus === AdminDeviceScreenStatus.Off ? (
          <p
            style={{ fontStyle: 'italic', color: '#c4c4c4', fontSize: '12px', marginBottom: 0 }}
            className="word-break"
          >
            {' '}
            {t('off_screen_by_admin')}
          </p>
        ) : record.adminScreenStatus === AdminDeviceScreenStatus.On ? (
          <p
            style={{ fontStyle: 'italic', color: '#c4c4c4', fontSize: '12px', marginBottom: 0 }}
            className="word-break"
          >
            {' '}
            {t('on_screen_by_admin')}
          </p>
        ) : null}
      </Tooltip>
      {record?.localTime || record?.lastGetInfoAt ? (
        <div className="d-flex align-items-center">
          <ul className="device-time mb-0">
            {record?.localTime && (
              <li>
                {t('local_time')}: <span className="value"> {humanReadableDateTimeZone(record?.localTime)}</span>
              </li>
            )}
          </ul>
        </div>
      ) : null}
      {record?.appVersion ? (
        <div className="d-flex align-items-center">
          <ul className="device-time mb-0">
            <li>
              {t('app_version')}: <span className="value"> {record.appVersion}</span>
            </li>
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default CellDeviceInfo;
