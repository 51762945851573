import { getSDK } from '@/graphql/client';
import { User } from '@/graphql/generated/graphql';
import { getHistory } from '@helpers/history';
import useLogin from '@modules/Auth/hooks/useLogin';
import { LoginState } from '@modules/Auth/redux/action-types';
import { RootState } from '@redux/reducers';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { GET_ME } from '../types';

export default function useUpdateProfile() {
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);
  const queryClient = useQueryClient();
  const { onUpdateProfileSuccess } = useLogin();

  return useMutation(getSDK().updateProfile, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(GET_ME);
      const newData: any = data.updateProfile;
      newData.role = loginState.data?.role;
      onUpdateProfileSuccess(newData as User);

      getHistory().push('/');
      const globalOnSuccess = queryClient.getDefaultOptions().mutations?.onSuccess;
      // tslint:disable-next-line: no-unused-expression
      globalOnSuccess && globalOnSuccess(data, variables, context);
    },
  });
}
