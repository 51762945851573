import { CommonPath } from '@commons/base-routes';
import ChangePasswordPage from '@modules/Auth/pages/ChangePassword';
import ForgotPasswordPage from '@modules/Auth/pages/Forgot';
import LoginPage from '@modules/Auth/pages/Login';
import RegisterPage from '@modules/Auth/pages/Register';
import ResetPasswordPage from '@modules/Auth/pages/Reset';

export const AuthRouter = [
  {
    path: CommonPath.LOGIN_PATH,
    exact: true,
    component: LoginPage,
  },
  {
    path: CommonPath.REGISTER_PATH,
    exact: true,
    component: RegisterPage,
  },
  {
    path: CommonPath.FORGOT_PASSWORD_PATH,
    exact: true,
    component: ForgotPasswordPage,
  },
  {
    path: CommonPath.RESET_PASSWORD_PATH,
    exact: true,
    component: ResetPasswordPage,
  },
];

export const ChangePasswordRouter = {
  path: CommonPath.CHANGE_PASSWORD_PATH,
  exact: true,
  component: ChangePasswordPage,
};
