import { getSDK } from '@/graphql/client';
import i18n from '@/i18n';
import { useMutation, useQueryClient } from 'react-query';
import { GET_SETTING_DETAIL } from '../types';

export default function useUpdateSetting() {
  const queryClient = useQueryClient();

  return useMutation(getSDK().createUploadSetting, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(GET_SETTING_DETAIL);
      const messageData = {
        message: i18n.t('update_success'),
      };

      const globalOnSuccess = queryClient.getDefaultOptions().mutations?.onSuccess;
      // tslint:disable-next-line: no-unused-expression
      globalOnSuccess && globalOnSuccess(messageData, variables, context);
    },
  });
}
