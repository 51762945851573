import { CreateUserInput } from '@/graphql/generated/graphql';
import FormHeader from '@commons/components/FormHeader';
import PageHeader from '@commons/components/PageHeader';
import FormSkeleton from '@commons/components/Skeletons/FormSkeleton';
import { getHistory } from '@helpers/history';
import AddNewUserForm from '@modules/User/components/Form/index';
import useAddNewUser from '@modules/User/hooks/useAddNewUser';
import { Form } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function AddNewCompanyPage() {
  const [form] = Form.useForm();
  const { isLoading, mutate, isSuccess } = useAddNewUser();
  const { t } = useTranslation(['user']);

  function onFinish(values: any) {
    const data: CreateUserInput = {
      username: values.username,
      name: values.name,
      password: values.password,
      phone: values.phone,
      email: values.email,
    };
    mutate({ input: data });
  }

  useEffect(() => {
    if (isSuccess) {
      getHistory().push('/user/list');
    }
  }, [isSuccess]);

  return (
    <div>
      <PageHeader title={t('user')} subTitle={t('add_new_user')} />
      <FormHeader>
        <FormSkeleton loading={isLoading}>
          <AddNewUserForm isLoading={false} onFinish={onFinish} form={form} />
        </FormSkeleton>
      </FormHeader>
    </div>
  );
}
