import React from 'react';
import { __ } from '@helpers/string';

interface TitleProps {
  title: string;
  className?: string;
}

export default function ModalTitle(props: TitleProps) {
  return <h3 className={`mb-0 ${props?.className}`}>{__(props.title)}</h3>;
}
