import { getSDK } from '@/graphql/client';
import i18n from '@/i18n';
import { useMutation, useQueryClient } from 'react-query';
import { GET_USERS } from '../types';
import { GET_COMPANY_USER } from '@/modules/Company/types';

export default function useAddNewUser() {
  const queryClient = useQueryClient();

  return useMutation(getSDK().addNewUser, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([GET_USERS]);
      queryClient.invalidateQueries([GET_COMPANY_USER]);
      const messageData = {
        message: i18n.t('add_success'),
      };
      const globalOnSuccess = queryClient.getDefaultOptions().mutations?.onSuccess;
      // tslint:disable-next-line: no-unused-expression
      globalOnSuccess && globalOnSuccess(messageData, variables, context);
    },
  });
}
