import ListGroupDevicePage from '@modules/GroupDevice/pages/List/index';
import SchedulePage from './pages/Schedule';
import PendingApprovePage from './pages/PendingApprove';

export const GroupDeviceRouter = [
  {
    path: '/group-device/list',
    exact: true,
    component: ListGroupDevicePage,
  },
  {
    path: '/group-device/pending-approve',
    exact: true,
    component: PendingApprovePage,
  },
  {
    path: '/group-device/schedule/:id',
    exact: true,
    component: SchedulePage,
  },
];
