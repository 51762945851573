import { CommonPath } from '@commons/base-routes';
import { NotificationSuccess } from '@commons/components/Notification';
import { getHistory } from '@helpers/history';
import { removeToken } from '@helpers/token';
import { logout } from '@modules/Auth/services/apis';
import { put } from 'redux-saga/effects';
import { LogoutAction, LogoutSuccess } from '../action-types';
import { logoutError, logoutSuccess } from '../actions';

export function* logoutAsync(action: LogoutAction) {
  try {
    removeToken();
    const result = yield logout(action.payload);
    if (result.logout) {
      getHistory().replace(CommonPath.LOGIN_PATH);
      yield put(logoutSuccess({ token: '' }));
    }
  } catch (error) {
    yield put(logoutError(error));
  }
}
export function logoutSuccessAsync(action: LogoutSuccess) {
  NotificationSuccess('notification', 'logout_success');
}
