import { Media } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { previewMediaAction } from '../redux/actions';
import { ListMediasState } from '../redux/reducers/medias';

const usePreviewMedia = () => {
  const state = useSelector<RootState, ListMediasState>((state) => state.media.list);

  const dispatch = useDispatch();
  const onPreview = useCallback(
    (input?: Media) => {
      dispatch(previewMediaAction(input));
    },
    [dispatch],
  );
  return {
    onPreview,
    item: state.previewItem,
  };
};
export default usePreviewMedia;
