import { Playlist } from '@/graphql/generated/graphql';
import ButtonAssigneGroup from '@commons/components/ButtonRowActions/ButtonAssignGroup';
import ButtonDeleteRed from '@commons/components/ButtonRowActions/ButtonDeleteRed';
import ButtonEditBlue from '@commons/components/ButtonRowActions/ButtonEditBlue';
import ButtonViewBlue from '@commons/components/ButtonRowActions/ButtonViewBlue';
import { useRoles } from '@hooks/useRoles';
import { LoginState } from '@modules/Auth/redux/action-types';
import { RootState } from '@redux/reducers';
import * as React from 'react';
import { useSelector } from 'react-redux';

interface PlayListRowActionProp {
  record: Playlist;
  onEdit?(pck: Playlist): void;
  onView?(pck: Playlist): void;
  onAssignGroup?(pck: Playlist): void;
  onDelete?: (pck: Playlist) => void;
  onPublish?: (pck: Playlist) => void;
  onDraft?: (pck: Playlist) => void;
  onReject?: (pck: Playlist) => void;
}

export default function PlayListRowAction(props: PlayListRowActionProp) {
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);
  const { isAdmin: _isAdmin } = useRoles();
  return (
    <div onClick={(e) => e.stopPropagation()} className="d-flex justify-content-end">
      <ButtonViewBlue
        classNameContainer="btn_reset_yellow"
        onClick={(e) => {
          props.onView && props.onView(props.record);
          e.stopPropagation();
        }}
      />
      {(_isAdmin() || props.record.createdById?.toString() === loginState.data?._id.toString()) && (
        <ButtonAssigneGroup
          onClick={(e) => {
            props.onAssignGroup && props.onAssignGroup(props.record);
            e.stopPropagation();
          }}
        />
      )}
      {(_isAdmin() || props.record.createdById?.toString() === loginState.data?._id.toString()) && (
        <>
          <ButtonEditBlue
            onClick={(e) => {
              props.onEdit && props.onEdit(props.record);
              e.stopPropagation();
            }}
          />
        </>
      )}
      {_isAdmin() || props.record.createdById?.toString() === loginState.data?._id.toString() ? (
        <ButtonDeleteRed
          onClick={() => {
            props?.onDelete && props.onDelete(props.record);
          }}
        />
      ) : null}
    </div>
  );
}
