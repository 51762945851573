import { CreateUserInput } from '@/graphql/generated/graphql';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { getHistory } from '@helpers/history';
import { Button, Col, Form, Input, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface PackageFormProps {
  isLoading: boolean;
  form: FormInstance;
  onFinish(values: any): void;
  isUpdate?: boolean;
}

export default function AddNewUserForm(props: PackageFormProps) {
  const { t } = useTranslation(['user']);

  const handleSubmit = (values: any) => {
    const data: CreateUserInput = {
      username: values.username,
      name: values.name,
      password: values.password,
      phone: values.phone,
      email: values.email,
    };
    props.onFinish(data);
  };

  return (
    <Form form={props.form} onFinish={handleSubmit}>
      <Form.Item
        name="name"
        label={t('full_name')}
        rules={[{ required: true, message: t('validate.required_name') }, { max: 100 }]}
        labelCol={{ span: 6 }}
      >
        <Input style={{ width: '100%', maxWidth: 485 }} placeholder={t('full_name')} />
      </Form.Item>
      <Form.Item
        name="username"
        label={t('username')}
        rules={[{ required: true, message: t('validate.required_username') }, { max: 15 }]}
        labelCol={{ span: 6 }}
      >
        <Input
          style={{ width: '100%', maxWidth: 485 }}
          placeholder={t('username')}
          autoCorrect="off"
          autoCapitalize="none"
        />
      </Form.Item>
      <Form.Item
        name="password"
        label={t('password')}
        labelCol={{ span: 6 }}
        rules={[
          { required: true, message: t('validate.required_password') },
          { min: 6, message: t('validate.required_password_min') },
        ]}
      >
        <Input.Password
          autoComplete="new-password"
          style={{ width: '100%', maxWidth: 485 }}
          placeholder={t('password')}
          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        />
      </Form.Item>
      <Form.Item
        name="phone"
        label={t('phone')}
        rules={[
          { whitespace: true, message: t('validate.white_space') },
          { pattern: /^[\d+]{1,13}$/, message: t('validate.regex_phone') },
        ]}
        labelCol={{ span: 6 }}
      >
        <Input style={{ width: '100%', maxWidth: 485 }} placeholder={t('phone')} />
      </Form.Item>
      <Row>
        <Col span={6}>{''}</Col>
        <Col span={18}>
          <Button htmlType="submit" key="submit" className="btn-dangerous" loading={props.isLoading}>
            {t('save')}
          </Button>
          <Button htmlType="button" type="default" onClick={() => getHistory().push(`/user/list`)} className="mx-3">
            {t('cancel_add')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
