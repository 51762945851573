import { User } from '@/graphql/generated/graphql';
import ButtonCancel from '@commons/components/modals/Button/ButtonCancel';
import ButtonOk from '@commons/components/modals/Button/ButtonOk';
import useResetPasswordUser from '@modules/User/hooks/useResetPasswordUser';
import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';

interface IProps {
  onCancel: () => void;
  data?: User;
}

export default function ResetPasswordModal(props: IProps) {
  const { t } = useTranslation(['user', 'button']);
  const { isLoading, mutate, isSuccess } = useResetPasswordUser();

  const onOk = () => {
    if (props.data?.email)
      mutate({
        input: {
          email: props.data.email,
        },
      });
    onCancel();
  };

  React.useEffect(() => {
    if (isSuccess) {
      props.onCancel();
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  const onCancel = () => {
    props.onCancel();
  };

  return (
    <Modal
      centered
      visible={!!props.data}
      cancelText={t('cancel')}
      onCancel={() => props.onCancel()}
      className="modal_confirm_custom modal-reset-password"
      title={t('reset_password')}
      closeIcon={<MdClose />}
      footer={[
        <ButtonCancel key={'btn_cancel'} title={t('button:cancel')} classNameBtn={`mr-2 `} onClick={onCancel} />,
        <ButtonOk
          key={'btn_ok'}
          onClick={onOk}
          title={t('reset_password')}
          isLoading={isLoading}
          classNameBtn={'px-4'}
        />,
      ]}
    >
      <div className="px-4 text-center">
        {t('reset_password_description')} <br /> <span className="email">"{props.data?.email}"</span> ?
      </div>
    </Modal>
  );
}
