import { EditorConfig } from '@modules/PlayList/types';

export const RESIZE_EDITOR = 'RESIZE_EDITOR';
export const UPDATE_EDITOR_CONFIG = 'UPDATE_EDITOR_CONFIG';

export interface UpdateEditorConfigAction {
  type: typeof UPDATE_EDITOR_CONFIG;
  payload: EditorConfig;
}

export type EditorActionTypes = UpdateEditorConfigAction;
