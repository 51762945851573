import {
  RequestEmailActionTypes,
  RequestEmailState,
  REQUEST_EMAIL,
  REQUEST_EMAIL_SUCCESS,
  REQUEST_EMAIL_ERROR,
} from '../action-types/forgot-password';

const initialState = {
  loading: false,
};

export default (state = initialState, action: RequestEmailActionTypes): RequestEmailState => {
  switch (action.type) {
    case REQUEST_EMAIL:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_EMAIL_SUCCESS:
      return {
        ...state,
        email: '',
        loading: false,
      };
    case REQUEST_EMAIL_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
