import { CreateFolderInput } from '@/graphql/generated/graphql';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { createFolderAction } from '../redux/actions';

const useCreateFolder = () => {
  const dispatch = useDispatch();
  const onCreateFolder = useCallback(
    (input: CreateFolderInput) => {
      dispatch(createFolderAction(input));
    },
    [dispatch],
  );
  return {
    onCreateFolder,
  };
};
export default useCreateFolder;
