import { Media } from '@/graphql/generated/graphql';
import '@modules/PlayList/styled/fabric.scss';
import React from 'react';
import PlayerPreview from './PlayerPreview';

interface IPropsMedia {
  MediaPreview?: Media;
  VerticalValue?: any;
  valueHd?: any;
}

function PreviewLeft(props: IPropsMedia) {
  const { MediaPreview, VerticalValue, valueHd } = props;

  return <PlayerPreview valueHd={valueHd} VerticalValue={VerticalValue} MediaPreview={MediaPreview} />;
}

export default React.memo(PreviewLeft);
