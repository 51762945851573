import { User } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';

export interface UpdateProfileInput {
  firstName: string;
  lastName: string;
  email: string;
  company?: string;
  jobTitle?: string;
  phoneNumber?: string;
}

export interface UpdateProfileState {
  loading: boolean;
  visible: boolean;
  data?: UpdateProfileInput;
}

export interface UpdateProfileAction {
  type: typeof UPDATE_PROFILE;
  payload: UpdateProfileInput;
}

export interface UpdateProfileInfo extends User {}

export interface UpdateProfileActionSuccess {
  type: typeof UPDATE_PROFILE_SUCCESS;
  payload: UpdateProfileInput;
}

export interface UpdateProfileActionError {
  type: typeof UPDATE_PROFILE_ERROR;
  payload: AppError;
}

export type UpdateProfileActionTypes = UpdateProfileAction | UpdateProfileActionSuccess | UpdateProfileActionError;
