import { getSDK } from '@/graphql/client';
import i18n from '@/i18n';
import { GET_COMPANY_USER } from '@modules/Company/types';
import { useMutation, useQueryClient } from 'react-query';
import { GET_USERS } from '../types';

export default function useUpdateUserAdmin() {
  const queryClient = useQueryClient();

  return useMutation(getSDK().updateUser, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(GET_USERS);
      queryClient.invalidateQueries(GET_COMPANY_USER);
      const messageData = {
        message: i18n.t('update_success'),
      };
      const globalOnSuccess = queryClient.getDefaultOptions().mutations?.onSuccess;
      // tslint:disable-next-line: no-unused-expression
      globalOnSuccess && globalOnSuccess(messageData, variables, context);
    },
  });
}
