import { Checkbox, Col, Form, Row, TimePicker } from 'antd';
import moment from 'moment';
import React, { Ref, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'styled-components';
const format = 'HH:mm';

interface IPropsOpenTime {
  nameForm: string;
  labelName: string;
  isEdit: boolean;
  key: any;
}
export interface RefDate {
  setCloseTimeError2: (value: string) => void;
  checked: boolean;
  onChangeCheck: (value: any) => void;
  isErr: boolean;
  validateRequire: (e: any, type: any) => void;
}

const styleTimeError: CSSProperties = {
  color: 'red',
  marginTop: -10,
  marginBottom: 10,
};

function OpenTimeDayComponent(props: IPropsOpenTime, ref: Ref<RefDate>) {
  const { nameForm, labelName, isEdit } = props;
  const { t } = useTranslation(['company']);
  const [openTimeError, setOpenTimeError] = useState<string | undefined>();
  const [closeTimeError, setCloseTimeError] = useState<string | undefined>();
  const [closeTimeError2, setCloseTimeError2] = useState<string | undefined>();
  const [checked, setChecked] = useState<boolean>(false);

  const isErr = useRef(false);

  const validateRequire = (name: any, type: string) => {
    const value = typeof name === 'object' ? moment(name).format(format) : name || '';
    if (value.trim().length === 0) {
      switch (type) {
        case `${nameForm}_time_open`:
          isErr.current = true;
          setOpenTimeError(`${t('validate.required_opentime')}`);
          break;
        case `${nameForm}_time_close`:
          isErr.current = true;
          setCloseTimeError(`${t('validate.required_opentime')}`);
          break;
        default:
          break;
      }
    } else {
      switch (type) {
        case `${nameForm}_time_open`:
          setOpenTimeError('');
          break;
        case `${nameForm}_time_close`:
          setCloseTimeError('');
          isErr.current = false;
          break;
        default:
          break;
      }
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      setCloseTimeError2: setCloseTimeError2,
      checked: checked,
      onChangeCheck: onChangeCheck,
      isErr: isErr.current,
      validateRequire: validateRequire,
    }),
    // eslint-disable-next-line
    [checked, isErr.current],
  );

  const onChangeCheck = (event: any) => {
    setChecked(typeof event === 'object' ? event.target.checked : event);
    setOpenTimeError('');
    setCloseTimeError('');
    setCloseTimeError2('');
  };

  return (
    <Col span={24}>
      <Row className="d-flex align-items-center">
        <Col xl={3} xs={24} md={12} className="mb-4">
          <Checkbox disabled={!isEdit} checked={checked} onChange={onChangeCheck} className="">
            {labelName}
          </Checkbox>
        </Col>
        <Col xl={{ span: 3 }} xs={24} md={{ span: 12 }} className="mr-2">
          <Form.Item name={`${nameForm}_time_open`}>
            <TimePicker
              disabled={!checked || !isEdit}
              onChange={(e) => validateRequire(moment(e).format(format), `${nameForm}_time_open`)}
              format={format}
              placeholder={t('time_open.select_time_open')}
              minuteStep={5}
            />
          </Form.Item>
          <div style={styleTimeError} className="ant-form-item-explain-error">
            {openTimeError && <div role="alert">{t(openTimeError)}</div>}
          </div>
        </Col>
        <Col xl={{ span: 3 }} xs={24} md={{ span: 12, offset: 0 }} className="">
          <Form.Item name={`${nameForm}_time_close`}>
            <TimePicker
              disabled={!checked || !isEdit}
              onChange={(e) => validateRequire(moment(e).format(format), `${nameForm}_time_close`)}
              format={format}
              placeholder={t('time_open.select_time_close')}
              minuteStep={5}
            />
          </Form.Item>
          <div style={styleTimeError} className="ant-form-item-explain-error">
            {closeTimeError && <div role="alert">{t(closeTimeError)}</div>}
          </div>
        </Col>
      </Row>
      <div style={styleTimeError} className="ant-form-item-explain-error">
        {closeTimeError2 && <div role="alert">{t(closeTimeError2)}</div>}
      </div>
    </Col>
  );
}

export default React.forwardRef(OpenTimeDayComponent);
