import React from 'react';
import { useHistory } from 'react-router';

interface CardNumberProps {
  icon?: any;
  number: number;
  title: string;
  img?: any;
  color?: string;
  bgColor?: string;
  link?: string;
}

export default function CardNumber(props: CardNumberProps) {
  const history = useHistory();

  const onRedirectUrl = () => {
    if (props.link) history.push(props?.link);
  };
  return (
    <div className="card-number cursor-pointer" onClick={onRedirectUrl}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-start justify-content-between flex-column">
          <p className="number" style={{ color: props?.color }}>
            {props.number}
          </p>
          <p className="mb-0 title">{props.title}</p>
        </div>
        {props?.icon ? (
          props.icon
        ) : (
          <div className="icon" style={{ background: props?.bgColor }}>
            <img style={{ width: 44 }} src={props.img} alt="ic_playlist" />
          </div>
        )}
      </div>
    </div>
  );
}
