import { APP_STARTED, APP_STARTED_SUCCESS, APP_STARTED_ERROR, SET_PAGE_TITLE, SET_APP_LAYOUT } from './action-types';

export const appStart = () => {
  return {
    type: APP_STARTED,
  };
};

export const appStartSuccess = () => {
  return {
    type: APP_STARTED_SUCCESS,
  };
};

export const appStartError = () => {
  return {
    type: APP_STARTED_ERROR,
  };
};

export const setPageTitle = (payload: string) => {
  return {
    type: SET_PAGE_TITLE,
    payload,
  };
};

export const setAppLayout = (payload: 'small' | 'middle' | 'large') => {
  return {
    type: SET_APP_LAYOUT,
    payload,
  };
};
