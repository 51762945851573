import { LogoutInput, LogoutActionTypes, LOGOUT, LOGOUT_SUCCESS, LOGOUT_ERROR } from '../action-types/logout';
import { AppError } from '@commons/types';
export const logout = (payload: LogoutInput): LogoutActionTypes => ({
  type: LOGOUT,
  payload,
});
export const logoutSuccess = (payload: LogoutInput): LogoutActionTypes => ({
  type: LOGOUT_SUCCESS,
  payload,
});
export const logoutError = (payload: AppError): LogoutActionTypes => ({
  type: LOGOUT_ERROR,
  payload,
});
