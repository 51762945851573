const LOGIN_PATH = '/login';
const DEFAULT_PATH = '/';
const REGISTER_PATH = '/register';
const FORGOT_PASSWORD_PATH = '/forgot-password';
const RESET_PASSWORD_PATH = '/reset-password';
const INVITATION_PATH = '/invitation';
const USER_MANAGEMENT_PATH = '/user-management';
const ACTIVE_ORGANIZATION_PATH = '/authenticate-account';
const CHANGE_PASSWORD_PATH = '/change-password';
export const CommonPath = {
  REGISTER_PATH,
  LOGIN_PATH,
  FORGOT_PASSWORD_PATH,
  DEFAULT_PATH,
  RESET_PASSWORD_PATH,
  INVITATION_PATH,
  USER_MANAGEMENT_PATH,
  ACTIVE_ORGANIZATION_PATH,
  CHANGE_PASSWORD_PATH,
};
