import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { updatePlaylistApi } from '@modules/PlayList/services/apis';
import { put } from 'redux-saga/effects';
import { UpdatePlaylistAction } from '../action-types';
import { updatePlaylistError, updatePlaylistSuccess } from '../actions/update_playlist';

export function* updatePlaylistAsync(action: UpdatePlaylistAction) {
  try {
    let pLayList: any = {};
    yield updatePlaylistApi(action.payload).then((res: any) => {
      pLayList = res.updatePlaylist;
    });

    yield put(updatePlaylistSuccess(pLayList));
  } catch (error) {
    yield put(updatePlaylistError(error));
  }
}

export function updatePlaylistAsyncSuccess() {
  NotificationSuccess(i18n.t('notification'), i18n.t('update_success'));
}
