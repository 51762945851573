import { Media, MoveMediaInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { MOVE_FILE, MOVE_FILE_SUCCESS, MOVE_FILE_ERROR } from '../action-types';

export const moveFileAction = (payload: MoveMediaInput) => ({
  type: MOVE_FILE,
  payload,
});

export const moveFileActionSuccess = (payload?: Media) => ({
  type: MOVE_FILE_SUCCESS,
  payload,
});

export const moveFileActionError = (payload: AppError) => ({
  type: MOVE_FILE_ERROR,
  payload,
});
