import { getSDK } from '@/graphql/client';
import { Group, QueryGroupInput } from '@/graphql/generated/graphql';
import { useState, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import useUpdateCurrentGroup from './useUpdateCurrentGroup';

export const GET_GROUP = 'GET_GROUP';

export default function useGetGroup(params: QueryGroupInput) {
  const [succeed, setSucceed] = useState<boolean>(false);
  const { onAddCurrentGroup } = useUpdateCurrentGroup();
  const queryClient = useQueryClient();

  useEffect(() => {
    succeed && setSucceed(false);
  }, [succeed]);

  const { data, isSuccess, isLoading } = useQuery(
    [GET_GROUP, params],
    async () => getSDK().getGroup({ input: params }),
    {
      onSuccess: (data) => {
        if (data?.group) {
          onAddCurrentGroup(data.group as Group);
        }
        setSucceed(true);
      },
      onError: (error: any) => {
        const globalOnError = queryClient.getDefaultOptions().queries?.onError;
        // tslint:disable-next-line: no-unused-expression
        globalOnError && globalOnError(error);
      },
      enabled: !!params._id,
    },
  );
  return {
    data,
    isSuccess,
    succeed,
    isLoading,
  };
}
