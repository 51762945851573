/* eslint-disable react-hooks/exhaustive-deps */
import { PlaylistStatus, Roles } from '@/graphql/generated/graphql';
import Icback from '@assets/images/icons/back_arrow_left.svg';
import BtnSetToGroup from '@commons/components/Button/BtnSetToGroup';
import { FULL_DATE } from '@helpers/date';
import { getHistory } from '@helpers/history';
import { LoginState } from '@modules/Auth/redux/action-types';
import useCreateNewPlaylist from '@modules/PlayList/hooks/useCreateNewPlaylist';
import useUpdatePlaylistBasicState from '@modules/PlayList/hooks/useUpdatePlaylistState';
import { AddNewPlayListState } from '@modules/PlayList/redux/reducers/add_new_play_list';
import { RootState } from '@redux/reducers';
import { Button, Col, Form, Input, Row, Space, Tooltip } from 'antd';
import { truncate } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEye } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { ViewAssign } from '../assigngroup/AssignGroupModal';

const HeaderPlaylist = (props: any) => {
  const { t } = useTranslation(['playlist']);
  const { t: translate } = useTranslation(['media']);
  const addNewPlaylistState = useSelector<RootState, AddNewPlayListState>(
    (state) => state.playList.addNewPlayListState,
  );

  const history = useHistory();

  const params = useParams<{ id: string }>();

  const refInput = useRef<any>();

  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);

  const [isFocus, setFocusInput] = useState(false);
  const [valueName, setValueName] = useState(props?.name || '');

  useEffect(() => {
    if (props?.name) {
      setValueName(props?.name);
    }
    return () => {};
  }, [props?.name]);

  const { isSuccess: onCreatePlaylistSuccess, playlistId, data } = useCreateNewPlaylist();

  const { name, createdAt, userName } = useUpdatePlaylistBasicState();
  // const [requiredName, setRequiredName] = useState<boolean>(false);

  const onSubmit = (status?: PlaylistStatus) => {
    if (!name) {
      // setRequiredName(true);
      return;
    }
    // if (loginState.data?.companyId) {
    //   if (status === PlaylistStatus.Rejected) {
    //     getHistory().push('/playlist/list-wait-approve');
    //   } else {
    //     getHistory().push('/playlist/list');
    //   }
    // }
  };

  useEffect(() => {
    if (onCreatePlaylistSuccess && playlistId && data.isContinue && props?.view !== ViewAssign.GROUP) {
      getHistory().push(`/playlist/detail/${playlistId?.toString()}`);
    }
    // eslint-disable-next-line
  }, [onCreatePlaylistSuccess, props?.view]);

  const onPreview = () => {
    props.onPreview?.();
  };

  const onFocus = () => {
    refInput.current?.focus();
    setFocusInput(true);
  };

  const onKeyPress = (event: any) => {
    if (event.charCode === 13) {
      event.preventDefault();
      setValueName(event.target.value);
      setFocusInput(false);
    } else {
      return true;
    }
  };

  return (
    <div className="header_playlist">
      <div className="container_input_name pb-2 d-flex align-items-center justify-content-start" style={{ flex: 1 }}>
        <img
          src={Icback}
          alt="back"
          className="mr-3 cursor-pointer"
          onClick={() => {
            return props?.view === ViewAssign.GROUP ? props?.onCloseDrawer?.() : history.goBack();
          }}
        />
        <Col style={{ flex: 1 }}>
          <Row style={{ maxWidth: '50%', position: 'relative' }}>
            <Form.Item
              name="name"
              style={{ margin: 0, maxWidth: '100%' }}
              rules={[
                { required: true, message: t('validate.required') },
                { whitespace: true, message: t('validate.required') },
              ]}
            >
              <Input
                maxLength={150}
                max={150}
                ref={refInput}
                onFocus={(e) => {
                  e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
                }}
                className={`input_name_playlist`}
                // ref={refInputName}
                onBlur={() => {
                  setFocusInput(false);
                }}
                autoFocus={false}
                // readOnly={!isFocus}
                contentEditable={isFocus}
                onKeyPress={onKeyPress}
                onChange={(event) => {
                  setValueName(event.target.value);
                }}
                placeholder={t('playlist_name_holder')}
              />
            </Form.Item>
            {!isFocus && (
              <Tooltip title={valueName}>
                <div
                  onClick={onFocus}
                  style={{
                    marginBottom: 6,
                    marginTop: 7,
                    position: 'absolute',
                    top: 0,
                    zIndex: 10,
                    maxWidth: '50%',
                    color: !!valueName ? 'black' : '#848181EE',
                  }}
                  className={`input_name_playlist`}
                >
                  {truncate(valueName || t('playlist_name_holder'), { length: 35 })}
                </div>
              </Tooltip>
            )}
          </Row>
          {!!userName && (
            <div style={{ fontSize: 14 }}>
              {t('created_on_by', { date: createdAt ? moment(createdAt).format(FULL_DATE) : '' })}
              <strong>{props?.userName || userName}</strong>
            </div>
          )}
        </Col>
      </div>
      {!props?.hideRight && (
        <Space align="center" wrap className="group_btn pb-3">
          <Button size="middle" onClick={onPreview} className=" btn-header btn-submit-preview text-white">
            <FaEye style={{ marginRight: 10 }} />
            {translate('preview')}
          </Button>
          <Button
            size="middle"
            htmlType="submit"
            onClick={() => {
              onSubmit(PlaylistStatus.WaitToApprove);
            }}
            className=" btn-header btn-submit text-white"
          >
            {!params?.id ? t('save') : t('update_btn')}
          </Button>
          {props.view !== ViewAssign.GROUP && !props.hidenSetother && (
            <BtnSetToGroup
              onClick={() => {
                props?.onSetToOtherGroup();
              }}
            />
          )}
          {loginState.data?.role &&
          [Roles.Admin, Roles.SuperAdmin].includes(loginState.data?.role) &&
          addNewPlaylistState.data?.status === PlaylistStatus.WaitToApprove ? (
            <>
              <Button
                size="middle"
                onClick={() => {
                  onSubmit(PlaylistStatus.Rejected);
                }}
                className=" btn-header bg-white text-gray"
              >
                {t('reject')}
              </Button>
              <Button
                size="middle"
                onClick={() => {
                  onSubmit(PlaylistStatus.Approved);
                }}
                className=" btn-header btn-success text-white"
              >
                {t('approve')}
              </Button>
            </>
          ) : null}
        </Space>
      )}
    </div>
  );
};

export default HeaderPlaylist;
