import { Playlist } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  CreatePlaylistActionTypes,
  CreatePlaylistInputInterface,
  CREATE_PLAYLIST,
  CREATE_PLAYLIST_ERROR,
  CREATE_PLAYLIST_SUCCESS,
} from '../action-types';

export const createPlaylist = (payload: CreatePlaylistInputInterface): CreatePlaylistActionTypes => {
  return {
    type: CREATE_PLAYLIST,
    payload,
  };
};

export const createPlaylistSuccess = (payload: Playlist): CreatePlaylistActionTypes => ({
  type: CREATE_PLAYLIST_SUCCESS,
  payload,
});

export const createPlaylistError = (payload?: AppError): CreatePlaylistActionTypes => ({
  type: CREATE_PLAYLIST_ERROR,
  payload,
});
