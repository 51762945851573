import FormHeader from '@commons/components/FormHeader';
import PageHeader from '@commons/components/PageHeader';
import FormSkeleton from '@commons/components/Skeletons/FormSkeleton';
import { getHistory } from '@helpers/history';
import DeviceFrom from '@modules/Device/components/Form/DeviceFrom';
import useDetailDevice from '@modules/Device/hooks/useDetailDevice';
import useUpdateDevice from '@modules/Device/hooks/useUpdateDevice';
import { Form, Tabs } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

const { TabPane } = Tabs;

export default function EditDevicePage() {
  const params = useParams<{ id: string }>();
  const { t } = useTranslation(['device']);
  const [form] = Form.useForm();
  const { isLoading, data } = useDetailDevice({
    _id: params.id,
  });
  const { isSuccess, mutate } = useUpdateDevice();

  useEffect(() => {
    if (isSuccess) {
      getHistory().push('/device/list');
    }
  }, [isSuccess]);

  function onFinish(values: any) {
    const payload: any = {
      name: values.name,
      deviceCode: values.deviceCode,
      companyId: values.companyId,
      deviceModel: values.deviceModel,
      os: values.os,
      screen: values.screen,
      yearOfManufacture: values.yearOfManufacture,
      _id: params.id ?? '',
    };
    if (!!values.schedules) {
      payload.schedules = values.schedules;
    }
    mutate({ input: payload });
  }

  return (
    <div>
      <PageHeader title={t('device')} subTitle={t('update_device')} />
      <FormHeader>
        <FormSkeleton loading={isLoading}>
          <Tabs defaultActiveKey="1" tabBarStyle={{ paddingLeft: '0.5rem', backgroundColor: '#fff' }}>
            <TabPane tab={t('basic_info')} key="basic_info">
              <DeviceFrom
                isLoading={false}
                onFinish={onFinish}
                deviceData={data}
                isUpdate={true}
                idDevice={params.id}
                form={form}
              />
            </TabPane>
          </Tabs>
        </FormSkeleton>
      </FormHeader>
    </div>
  );
}
