import { FrameDirection, PlaylistScreenType } from '@/graphql/generated/graphql';
import { PlaylistResolutionInterface } from '@modules/PlayList/types';

export const SET_PLAYLIST_LAYOUT = 'SET_PLAYLIST_LAYOUT';

export interface SetPlaylistLayoutInput {
  resolution: PlaylistResolutionInterface;
  orientation: FrameDirection;
  screenType: PlaylistScreenType;
}

export interface SetPlaylistLayoutAction {
  type: typeof SET_PLAYLIST_LAYOUT;
  payload: SetPlaylistLayoutInput;
}

export type SetPlaylistLayoutActionTypes = SetPlaylistLayoutAction;
