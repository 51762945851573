import { UpdateProfileInput } from '@/graphql/generated/graphql';
import ButtonOk from '@commons/components/modals/Button/ButtonOk';
import { Card, Form, Input } from 'antd';
import { FormInstance } from 'antd/es/form';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface PackageFormProps {
  isLoading: boolean;
  form: FormInstance;
  onFinish(values: UpdateProfileInput): void;
  isUpdate?: boolean;
  userData?: any;
}

export default function CompanyForm(props: PackageFormProps) {
  const { t } = useTranslation(['user']);

  const handleSubmit = (values: any) => {
    const data: UpdateProfileInput = {
      name: values.name,
      phone: values.phone,
    };
    props.onFinish(data);
  };

  return (
    <Card
      title={t('profile')}
      bordered={false}
      headStyle={{ borderBottom: 'none' }}
      bodyStyle={{ marginLeft: '2rem', marginRight: '2rem' }}
      className="card-form form-profile"
    >
      <Form
        onFinish={handleSubmit}
        form={props.form}
        labelAlign="left"
        labelCol={{ xs: 24, md: 6, xl: 4 }}
        colon={false}
      >
        <Form.Item name="username" label={t('username')}>
          <Input placeholder={t('username')} disabled={true} className="username" />
        </Form.Item>
        <Form.Item
          name="name"
          label={t('full_name')}
          rules={[{ required: true, message: t('validate.required_name') }]}
        >
          <Input placeholder={t('full_name')} />
        </Form.Item>
        <Form.Item name="phone" label={t('phone')}>
          <Input placeholder={t('phone')} />
        </Form.Item>
        <div className="d-flex align-items-center justify-content-end">
          <Form.Item>
            <ButtonOk key="confirm" onClick={() => props.form.submit()} title={t('save')} isLoading={props.isLoading} />
          </Form.Item>
        </div>
      </Form>
    </Card>
  );
}
