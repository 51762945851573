import {
  RequestEmailActionTypes,
  RequestEmailInput,
  REQUEST_EMAIL,
  REQUEST_EMAIL_SUCCESS,
  REQUEST_EMAIL_ERROR,
} from '../action-types/forgot-password';
import { AppError } from '@commons/types';

export const requestEmail = (payload: RequestEmailInput): RequestEmailActionTypes => ({
  type: REQUEST_EMAIL,
  payload,
});
export const requestEmailSuccess = (payload: RequestEmailInput): RequestEmailActionTypes => ({
  type: REQUEST_EMAIL_SUCCESS,
  payload,
});
export const requestEmailError = (payload: AppError): RequestEmailActionTypes => ({
  type: REQUEST_EMAIL_ERROR,
  payload,
});
