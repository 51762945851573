import { Group } from '@/graphql/generated/graphql';
import {
  ADD_CURRENT_GROUP_ACTION,
  CurrentGroupActionTypes,
  REMOVE_CURRENT_GROUP_ACTION,
  UpdateLayoutCurrentGroupInput,
  UPDATE_LAYOUT_CURRENT_GROUP_ACTION,
} from '../action-types';

export const addCurrentGroupAction = (payload?: Group): CurrentGroupActionTypes => ({
  type: ADD_CURRENT_GROUP_ACTION,
  payload,
});

export const removeCurrentGroupAction = (): CurrentGroupActionTypes => ({
  type: REMOVE_CURRENT_GROUP_ACTION,
});

export const updateLayoutCurrentGroupAction = (payload: UpdateLayoutCurrentGroupInput): CurrentGroupActionTypes => ({
  type: UPDATE_LAYOUT_CURRENT_GROUP_ACTION,
  payload,
});
