import {
  ChangePasswordActionTypes,
  ChangePasswordState,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_DONE,
} from '../action-types/change-password';

const initialState = {
  loading: false,
};

export default (state = initialState, action: ChangePasswordActionTypes): ChangePasswordState => {
  switch (action.type) {
    case CHANGE_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        status: false,
      };
    case CHANGE_PASSWORD_DONE:
      return {
        ...state,
        loading: false,
        status: false,
      };

    default:
      return state;
  }
};
