import { AppError } from '@commons/types';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const CHANGE_PASSWORD_DONE = 'CHANGE_PASSWORD_DONE';

export interface ChangePasswordInput {
  oldPassword: string;
  newPassword: string;
}

export interface ChangePasswordState {
  loading: boolean;
  status?: boolean;
}

export interface ChangePasswordAction {
  type: typeof CHANGE_PASSWORD;
  payload: ChangePasswordInput;
}

export interface ChangePasswordActionSuccess {
  type: typeof CHANGE_PASSWORD_SUCCESS;
}

export interface ChangePasswordActionError {
  type: typeof CHANGE_PASSWORD_ERROR;
  payload: AppError;
}
export interface ChangePasswordActionDone {
  type: typeof CHANGE_PASSWORD_DONE;
}

export type ChangePasswordActionTypes =
  | ChangePasswordAction
  | ChangePasswordActionSuccess
  | ChangePasswordActionError
  | ChangePasswordActionDone;
