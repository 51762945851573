import TableWithNumber from '@commons/components/TableWithNumber';
import useAssignUserToCompany from '@modules/Company/hooks/useAssignUserToCompany';
import { Form } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';

interface IProps {
  companyId: string;
}

interface IState {
  selectedUsers: string[];
}

export default function SpecialDayTable(props: IProps) {
  const { t } = useTranslation(['company']);

  const { isSuccess } = useAssignUserToCompany();
  const [form] = Form.useForm();
  const [state, setState] = React.useState<IState>({
    selectedUsers: [],
  });

  const columns: ColumnsType<any> = [
    {
      title: t('day'),
      key: 'day',
      dataIndex: 'day',
      align: 'left',
    },
    {
      title: t('open_time'),
      key: 'open_time',
      dataIndex: 'open_time',
      align: 'left',
    },
    {
      title: t('closed_time'),
      key: 'closed_time',
      dataIndex: 'closed_time',
      align: 'left',
    },
    {
      title: '',
      key: 'action',
      width: 60,
      className: 'd-flex justify-content-center align-items-center',
      render: (record: any) => (
        <MdClose
          className="cursor-pointer"
          color="#F80000"
          size={24}
          onClick={() => {
            setState({
              ...state,
              selectedUsers: state.selectedUsers?.filter((user: any) => user._id !== record._id) || [],
            });
          }}
        />
      ),
    },
  ];

  React.useEffect(() => {
    if (isSuccess) {
      form.resetFields(['users']);
      setState({ ...state, selectedUsers: [] });
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  return (
    <div className="selected_users-table mt-3 add-more-table">
      <TableWithNumber
        className="table-striped-rows"
        rowKey={(data) => data._id}
        columns={columns}
        dataSource={state.selectedUsers || []}
        pagination={false}
        size="middle"
      />
    </div>
  );
}
