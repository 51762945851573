import { Group, GroupStatus } from '@/graphql/generated/graphql';
import timeZone from '@assets/file/timezone.json';
import BtnPublish from '@commons/components/Button/BtnPublish';
import BtnSave from '@commons/components/Button/BtnSave';
import { getHistory } from '@helpers/history';
import { getAlertChangeSchedule, setAlertChangeSchedule } from '@helpers/token';
import ScheduleTypes from '@modules/GroupDevice/Components/Drawer/ScheduleTypes';
import GroupContent from '@modules/GroupDevice/Components/Shedule/GroupContent';
import ScheduleLeftComponent from '@modules/GroupDevice/Components/Shedule/Left';
import useGetGroup from '@modules/GroupDevice/hooks/useGetGroup';
import useUpdateGroupStatus from '@modules/GroupDevice/hooks/useUpdateGroupStatus';
import useUpdateScheduleGroupDevice from '@modules/GroupDevice/hooks/useUpdateScheduleGroup';
import { GroupDeviceModuleState } from '@modules/GroupDevice/redux/reducers';
import { CurrentGroupState } from '@modules/GroupDevice/redux/reducers/current_group';
import { PlaylistSchedule } from '@modules/GroupDevice/types';
import { RootState } from '@redux/reducers';
import { Form, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router';
import { useParams } from 'react-router-dom';
import TextTruncate from 'react-text-truncate';
const moment = require('moment-timezone');

export interface GroupsFilter {
  query?: string;
}

const { Option } = Select;

const SchedulePage = () => {
  const { t } = useTranslation(['group-device']);
  const { id }: any = useParams();
  const scheduleDeviceGroupState = useSelector<RootState, GroupDeviceModuleState>((state) => state?.groupDevice);
  const currentGroupState = useSelector<RootState, CurrentGroupState>((state) => state?.groupDevice.currentGroup);

  const ref: any = useRef();

  const {
    onUpdateScheduleGroupDeviceSuccess,
    onUpdateScheduleSecondGroupDeviceSuccess,
  } = useUpdateScheduleGroupDevice();

  const { mutate: updateGroupStatus, isSuccess: updateStatusSuccess, isLoading } = useUpdateGroupStatus();

  const [form] = Form.useForm();

  const { data: groupData, succeed } = useGetGroup({
    _id: id,
  });

  // const history = useHistory();
  // const [location, setLocation] = useState<LocationInput>();
  // const [visibleModalViewMap, setVisibleModalViewMap] = useState<boolean>(false);

  // const { data: companyData } = useGetCompanyByID({
  //   _id: groupData?.group?.companyId || '',
  // });

  useEffect(() => {
    form.setFieldsValue({
      timeZone: groupData?.group?.timezone,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData?.group?.timezone]);

  useEffect(() => {
    setAlertChangeSchedule(false);
  }, []);

  useEffect(() => {
    if (updateStatusSuccess) {
      history?.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatusSuccess]);

  useEffect(() => {
    if (succeed) {
      onUpdateScheduleSecondGroupDeviceSuccess(groupData?.group?.schedulesSecond as any);
      onUpdateScheduleGroupDeviceSuccess(groupData?.group?.schedules as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [succeed]);

  const history = getHistory();

  const [currentPlaylistSchedule, setCurrentPlaylistSchedule] = useState<PlaylistSchedule>();

  const onSelectedPlaylistSchedule = (value: PlaylistSchedule) => {
    setCurrentPlaylistSchedule(value);
  };

  // const onUpdateSchedule = () => {
  //   setAlertChangeSchedule(false);
  //   onUpdateScheduleGroupDevice({
  //     _id: id,
  //     schedules: scheduleDeviceGroupState.scheduleGroupDevice.data as any,
  //     layoutSettings: LayoutSettingsDefault,
  //   });
  // };

  // const onCancelSchedule = () => {
  //   // Huy dat lich cho playlist hien tai
  //   if (currentPlaylistSchedule?.playlistId) {
  //     // onRemovePlaylistScheduleGroup({ playlistId: currentPlaylistSchedule?.playlistId });
  //     setCurrentPlaylistSchedule(undefined);
  //   }
  // };

  const handleFinish = async () => {
    await ref?.current?.updateSchedule();
    return updateGroupStatus({
      input: {
        _id: id,
        status: GroupStatus.WaitToApprove,
      },
    });
  };

  return (
    <div className="schedule-group-device">
      <div className="d-flex flex-column flex-md-row schedule-group-header">
        <div className="back-action px-4 py-3">
          <span className="d-flex align-items-center w-100">
            <FiChevronLeft className="cursor-pointer" size={26} onClick={() => history?.goBack()} />
            <TextTruncate
              containerClassName="w-100 group-name word-break"
              line={2}
              element="span"
              truncateText="…"
              text={groupData?.group?.name}
            />
          </span>
        </div>
        <div className="time-zone px-4 py-3 d-flex flex-row flex-md-column flex-xl-row align-items-start justify-content-between gap-3 mx-xs-0">
          <Form form={form} className="w-100" onFinish={handleFinish}>
            <Form.Item name={'timeZone'} className="mb-0" rules={[{ required: true, message: t('validate.required') }]}>
              <Select
                style={{ width: '100%', maxWidth: 220 }}
                placeholder={t('timezone')}
                showSearch
                allowClear
                filterOption={(input, option) => option?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {timeZone.map((item) => {
                  if (item === 'Europe/Oslo') {
                    return (
                      <Option value={item} key={item} title={item}>
                        {item} {moment().tz(item).format('Z')}
                      </Option>
                    );
                  }
                  return (
                    <Option value={item} key={item} title={item}>
                      {item}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form>

          <div className="d-flex align-items-center justify-content-end gap-3">
            {currentGroupState.data?.status !== GroupStatus.Approved &&
            currentGroupState.data?.status !== GroupStatus.WaitToApprove ? (
              <BtnSave
                onClick={() => {
                  const error = form?.getFieldError('timeZone');
                  if (error.length <= 0) {
                    ref?.current?.updateSchedule();
                  }
                }}
                loading={scheduleDeviceGroupState?.scheduleGroupDevice?.loading}
              />
            ) : null}

            {currentGroupState.data?.status !== GroupStatus.Approved &&
            currentGroupState.data?.status !== GroupStatus.WaitToApprove ? (
              <BtnPublish
                title={t('button:submit').toUpperCase()}
                isLoading={isLoading}
                onClick={() => {
                  const error = form?.getFieldError('timeZone');
                  if (error.length <= 0) {
                    ref?.current?.updateSchedule();
                    updateGroupStatus({
                      input: {
                        _id: id,
                        status: GroupStatus.WaitToApprove,
                      },
                    });
                  }
                }}
              />
            ) : (
              <BtnPublish
                title={t('button:submit').toUpperCase()}
                htmlType="submit"
                onClick={() => {
                  form.submit();
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="schedule-content">
        <ScheduleLeftComponent
          onSelectedPlaylistSchedule={onSelectedPlaylistSchedule}
          currentPlaylistSchedule={currentPlaylistSchedule}
        />
        <GroupContent timeZoneForm={form} groupData={groupData?.group as Group} ref={ref} succeed={succeed} />
        <ScheduleTypes visible={!!scheduleDeviceGroupState.currentPlaylistSchedule.data} />
        <Prompt when={getAlertChangeSchedule()} message={t('schedule.alert_content')} />
      </div>
    </div>
  );
};

export default SchedulePage;
