import { Jwt, Roles } from '@/graphql/generated/graphql';
import { getHistory, getRedirectUrl, setRedirectUrl } from '@/helpers/history';
import { getAuthLocalData, setAuthData, setWsLink } from '@/helpers/token';
import { CommonPath } from '@commons/base-routes';
import { NotificationSuccess } from '@commons/components/Notification';
import { removeToken } from '@helpers/token';
import { put, take } from 'redux-saga/effects';
import * as apis from '../../services/apis';
import * as companyServices from '../../../Company/services/apis';
import {
  LoginAction,
  LoginActionSuccess,
  LoginData,
  LOGOUT,
  SupperAdminLoginAction,
  VerifyTokenAutoLoginAction,
} from '../action-types';
import { loginError, loginSuccess, supperAdminAutoLoginSuccess } from '../actions';

export function* loginAsync(action: LoginAction) {
  try {
    const data: LoginData = yield apis.login(action.payload);
    setAuthData(data.login);
    yield put(loginSuccess(data.login));
    NotificationSuccess('notification', 'login_success');
  } catch (error) {
    yield put(loginError(error));
  }
}

export function* loginSuccessAsync(action: LoginActionSuccess) {
  try {
    setWsLink(getAuthLocalData()?.token);
    if (action.payload?.companyId) {
      yield companyServices.calculateTotalStorage({ _id: action.payload?.companyId });
    }

    const redirectUrl = getRedirectUrl();
    if (redirectUrl !== 'noredirect') {
      if (redirectUrl) {
        const rUrl = new URL(redirectUrl);
        const cOrigin = window.location.origin;
        if (cOrigin === rUrl.origin) {
          const newPath = rUrl.pathname + rUrl.search;
          if (newPath.indexOf(CommonPath.LOGIN_PATH) === 0) {
            getHistory()?.replace(CommonPath.DEFAULT_PATH);
          } else {
            getHistory()?.replace(newPath);
          }
        } else {
          window.location.href = redirectUrl;
        }
      } else {
        getHistory()?.push(CommonPath.DEFAULT_PATH);
      }
      setRedirectUrl('');
      return;
    }

    if (action.payload.role === Roles.Editor) {
      getHistory().replace('/device/list');
    }
  } catch (error) {}
}

export function* supperAdminAutoLoginAsync(action: SupperAdminLoginAction) {
  try {
    const companyId = action.payload;
    if (companyId) {
      yield companyServices.calculateTotalStorage({ _id: companyId });
    }
    const data = yield apis.supperAdminAutoLogin(companyId);
    const newAuthData = {
      ...getAuthLocalData(),
      role: companyId ? Roles.Admin : Roles.SuperAdmin,
      token: data.token,
      companyId,
    };
    setAuthData(newAuthData as Jwt);
    yield put(supperAdminAutoLoginSuccess(companyId));
    getHistory()?.replace('/');
    // window.open(renderUrlAutoLogin('localhost:3000/', data.token), '_blank');
  } catch (error) {
    yield put(loginError(error));
  }
}

export function* verifyTokenAutoLoginAsync(action: VerifyTokenAutoLoginAction) {
  try {
    const data: Jwt = yield apis.verifyTokenAutoLogin(action.payload);
    removeToken();
    setAuthData(data);
    yield put(loginSuccess(data));
    NotificationSuccess('notification', 'login_success');
  } catch (error) {
    yield put(loginError(error));
    getHistory().replace(CommonPath.LOGIN_PATH);
  }
}

export function* autoLoginFlow() {
  while (1) {
    const authData = getAuthLocalData();
    if (authData) {
      // authData.isAutoLogin = true;
      yield put(loginSuccess(authData as any));
    }
    yield take(LOGOUT);
  }
}
