import { QueryPlaylistInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPlaylist } from '../redux/actions/get_playlist';
import { AddNewPlayListState } from '../redux/reducers/add_new_play_list';

const useGetPlaylist = () => {
  const newPlayListState = useSelector<RootState, AddNewPlayListState>((state) => state.playList.addNewPlayListState);

  const dispatch = useDispatch();
  const onGetPlaylist = useCallback(
    (input: QueryPlaylistInput) => {
      dispatch(getPlaylist(input));
    },
    [dispatch],
  );
  return {
    onGetPlaylist,
    isLoading: newPlayListState.loading,
    data: newPlayListState.data,
  };
};
export default useGetPlaylist;
