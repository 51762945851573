import { FrameData } from '@modules/PlayList/types';

export const ACTIVE_FRAME = 'ACTIVE_FRAME';

export interface ActiveFrameAction {
  type: typeof ACTIVE_FRAME;
  payload: FrameData;
}

export interface ActiveFrameState {
  loading: boolean;
  data?: FrameData;
}

export type ActiveFrameActionTypes = ActiveFrameAction;
