import { getSDK } from '@/graphql/client';
import i18n from '@/i18n';
import { useMutation, useQueryClient } from 'react-query';
import { GET_ALL_GROUPS } from './useGetAllGroups';

export default function useAddNewGroupDevice() {
  const queryClient = useQueryClient();

  return useMutation(getSDK().CreateGroup, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(GET_ALL_GROUPS);
      const messageData = {
        message: i18n.t('add_success'),
      };

      const globalOnSuccess = queryClient.getDefaultOptions().mutations?.onSuccess;
      // tslint:disable-next-line: no-unused-expression
      globalOnSuccess && globalOnSuccess(messageData, variables, context);
    },
  });
}
