import { Media, CreateFolderInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const CREATE_FOLDER = 'CREATE_FOLDER';
export const CREATE_FOLDER_SUCCESS = 'CREATE_FOLDER_SUCCESS';
export const CREATE_FOLDER_ERROR = 'CREATE_FOLDER_ERROR';

export interface CreateFolderAction {
  type: typeof CREATE_FOLDER;
  payload: CreateFolderInput;
}

export interface CreateFolderActionSuccess {
  type: typeof CREATE_FOLDER_SUCCESS;
  payload?: Media;
}

export interface CreateFolderActionError {
  type: typeof CREATE_FOLDER_ERROR;
  payload: AppError;
}

export type CreateFolderActionTypes = CreateFolderAction | CreateFolderActionSuccess | CreateFolderActionError;
