import { Media } from '@/graphql/generated/graphql';
import IcClose from '@assets/images/icons/ic_close_playlist.svg';
import IcLinkWeb from '@assets/images/icons/ic_link_web.svg';
import { validURL } from '@helpers/regex';
import { allowedMimeImageName } from '@modules/Media/helper/file';
import { MediaFileType } from '@modules/Media/pages/components/media-item-grid.component';
import { Form, Input } from 'antd';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IRight } from '../RightPlayListAddNew';
import InputDuration from './InputDuration';
import PlayListAvailability from './PlayListAvailability';

interface ItemProps {
  item: Media & IRight;
  index: number;
  onPressRemove: (item: Media | any) => void;
  onPressAddLink: (item: Media & IRight, index?: number) => void;
}

export default function PlaylistRightItem(props: ItemProps) {
  const { item, onPressRemove, index } = props;

  const [url, setStringUrl] = useState<string>(item.webUrl || '');

  const [errorLink, setError] = useState<string>('');

  const { t } = useTranslation(['media']);

  const { t: tPlaylist } = useTranslation(['playlist']);

  const refPlaylistAvailable = useRef<any>();

  const onPressRemoveItem = () => {
    onPressRemove({ ...item, index });
  };

  const [EditLink, setLinkEdit] = useState<{ string: boolean } | any>({});

  const onKeyPress = (event: any) => {
    if (event.charCode === 13) {
      event.preventDefault();
      if (!validURL(event.target.value) || event.target.value.trim().length === 0) {
        setError(tPlaylist('url_valid'));
        return;
      }
      setError('');
      let itemValue = { ...item, webUrl: event.target.value, index };
      props.onPressAddLink(itemValue, index);
      setLinkEdit({ ...EditLink, [item._id]: false });

      // returning false will prevent the event from bubbling up.
    } else {
      return true;
    }
  };

  const onUpdateItem = (itemValue: any) => {
    props.onPressAddLink({ ...itemValue, index });
  };

  const onUpdateDuration = ({ _id, duration }: any) => {
    let itemValue = { ...item, duration, index, _id };
    props.onPressAddLink(itemValue, index);
  };

  const onChangeText = (event: any) => {
    setStringUrl(event.target.value);
    if (event.target.value.trim().length === 0) {
      setError('');
    }
  };

  const onBlurInput = (event: any) => {
    if (event.target.value.trim().length === 0) {
      setError('');
      return;
    }
    if (
      !validURL(event.target.value) ||
      !String(event.target.value).startsWith('http') ||
      event.target.value.trim().length === 0
    ) {
      setError(tPlaylist('url_valid'));
      return;
    }
    if (errorLink) {
      setError('');
    }
    let itemValue = { ...item, webUrl: event.target.value, index };
    props.onPressAddLink(itemValue, index);
    setLinkEdit({ ...EditLink, [item._id]: false });
  };

  return (
    <div className="play-list-right-item mt-2">
      {item.isLink ? (
        <div className="media-item-image" style={{ alignItems: 'center' }}>
          <div className={`media-image`}>
            <img src={IcLinkWeb} alt="LinkWeb" style={{ marginRight: 10, objectFit: 'contain' }} />
          </div>
          <div style={{ flex: 1, paddingRight: 10 }}>
            <div style={{ position: 'relative' }}>
              <Form.Item
                name={`input_url_${item._id}_${index}`}
                style={{ margin: 0 }}
                rules={[
                  { required: true, message: tPlaylist('validate.required') },
                  {
                    type: 'url',
                    message: 'This field must be a valid url.',
                  },
                ]}
                initialValue={url}
              >
                <Input
                  placeholder={t('add_url')}
                  value={url}
                  style={{ border: 'unset', background: 'transparent' }}
                  maxLength={200}
                  autoFocus
                  className="input_url"
                  onBlur={onBlurInput}
                  onChange={onChangeText}
                  onKeyPress={onKeyPress}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      ) : (
        <div className="media-item-image">
          <div className={`media-image`}>
            <MediaFileType media={item} />
          </div>
          <div style={{ flex: 1 }}>
            <div className="media-item-title text-2-line">{item.filename}</div>
            {(item.images?.[0] || (item.width && item.height)) && (
              <div className="media-item-des">
                {(item?.width || item?.images?.[0]?.width) + ' x ' + (item?.height || item.images?.[0]?.height)}
              </div>
            )}
            {!!item.mimetype && <div className="media-item-des">{allowedMimeImageName[item.mimetype || '']}</div>}
          </div>
        </div>
      )}
      <div className="media-item-duration" style={{ marginLeft: 15 }}>
        <InputDuration
          onUpdateDuration={onUpdateDuration}
          _id={item._id}
          item={item}
          index={index}
          onClickAvailability={() => {
            refPlaylistAvailable.current?.onShow();
          }}
        />
      </div>
      <div className="view-edit-remove">
        <img src={IcClose} className="cursor-pointer mb-3" alt="remove" onClick={onPressRemoveItem} />
      </div>
      <PlayListAvailability item={item} ref={refPlaylistAvailable} onSubmit={onUpdateItem} />
    </div>
  );
}
