import { getSDK } from '@/graphql/client';
import i18n from '@/i18n';
import { useMutation, useQueryClient } from 'react-query';
import { GET_COMPANY_USER } from '../types';

export default function useKickOutCompany() {
  const queryClient = useQueryClient();

  return useMutation(getSDK().kickOutCompany, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(GET_COMPANY_USER);
      const messageData = {
        message: i18n.t('update_success'),
      };

      // global on success
      const globalOnSuccess = queryClient.getDefaultOptions().mutations?.onSuccess;
      // tslint:disable-next-line: no-unused-expression
      globalOnSuccess && globalOnSuccess(messageData, variables, context);
    },
  });
}
