import ShowSideBar from '@assets/images/icons/icon-menu.svg';
import logo from '@assets/images/WinSmartSignLogo.svg';
// import Logo from '@assets/images/logo.svg';
import logoVertical from '@assets/images/small_logo.png';
import { useWindowSize } from '@hooks/useWindowSize';
import useSetAppLayout from '@modules/App/hooks/useSetAppLayout';
import { LoginState } from '@modules/Auth/redux/action-types';
import useGetCompanyByID from '@modules/Company/hooks/useGetCompanyByID';
import { RootState } from '@redux/reducers';
import { Button, Layout } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import LoggedInUser from '../Header/LoggedInUser';
import LeftNavigation from '../Navigation/Left';
import TopNavigation from '../Navigation/Top';
import './style.scss';

const { Header, Sider, Content } = Layout;

const MasterLayout = (props: any) => {
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);
  const size = useWindowSize();
  const { setAppLayout } = useSetAppLayout();
  const { data } = useGetCompanyByID({
    _id: loginState.data?.companyId || '',
  });

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [collapsedWidth, setCollapsedWidth] = useState(80);
  // const [menuWidth, setMenuWidth] = useState(250);
  const toggle = () => {
    setIsCollapsed(!isCollapsed);
  };
  const isFirst = useRef(true);
  useEffect(() => {
    if (size.width < 1366) {
      // setMenuWidth(185);
      if (size.width <= 1024 && isFirst) {
        setAppLayout('small');
        setIsCollapsed(true);
        isFirst.current = false;
        setCollapsedWidth(0);
      } else {
        setAppLayout('middle');
        setIsCollapsed(false);
        setCollapsedWidth(80);
      }
    } else {
      setAppLayout('large');
      // setMenuWidth(235);
      setCollapsedWidth(80);
    }
    // eslint-disable-next-line
  }, [size]);

  return (
    <Layout className="master-layout">
      <Layout className="master-content" hasSider={true}>
        <Sider
          trigger={null}
          collapsible
          collapsed={isCollapsed}
          theme="dark"
          collapsedWidth={collapsedWidth}
          width={250}
          className={`left-menu-sidebar`}
        >
          <div className="logo flex-column">
            {!isCollapsed ? (
              <>
                <img style={{ maxWidth: '100%' }} src={logo} alt="logo" />
                {/* <p>Cloud base digital signage for success</p> */}
              </>
            ) : (
              <img style={{ maxWidth: '50px' }} src={logoVertical} alt="logo" />
            )}
          </div>
          <LeftNavigation isCollapsed={isCollapsed} />
          {!isCollapsed ? (
            <div className="app-version">
              <span className="text-white">Powered by Win Solution AS, Norway</span>
            </div>
          ) : null}
        </Sider>
        <Layout>
          {/* <Breadcrumbs /> */}

          <Header
            id="header"
            className="header d-flex justify-content-between pr-3 align-items-center bg-white"
            style={{ gap: '1rem' }}
          >
            <div className="d-flex align-items-center header_left flex-shrink-0">
              <img className="mr-3 cursor-pointer icon-menu" onClick={toggle} src={ShowSideBar} alt="ic_show" />
              <TopNavigation />
            </div>
            {data?.company?.name ? (
              <div className="header_center d-flex">
                <Button type="dashed" className="content d-flex align-items-center">
                  <span className="title_page font-weight-bold">{data?.company?.name}</span>
                </Button>
              </div>
            ) : null}
            <LoggedInUser />
          </Header>

          <Content
            className="master-layout master-layout-content"
            style={{
              minHeight: 280,
            }}
            id="master-layout-content"
          >
            {props.children}
            {!!props.route && renderRoutes(props.route.routes)}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
export default React.memo(MasterLayout);
