import { FrameDirection, PlaylistScreenType } from '@/graphql/generated/graphql';
import ButtonCancel from '@commons/components/modals/Button/ButtonCancel';
import ButtonOk from '@commons/components/modals/Button/ButtonOk';
import { Col, Drawer, DrawerProps, Radio, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextTruncate from 'react-text-truncate';
import FullScreen from '../GroupLayout/FullScreen';
import SplitScreen from '../GroupLayout/SplitScreen';
import { LayoutSettingsStateI } from '../Shedule/GroupContent';

interface LayoutGroupDrawerI {
  onCancel?: () => void;
  onSave: (data: any) => void;
  visible: boolean;
  isLoading?: boolean;
  placement?: DrawerProps['placement'];
  layoutSettingData: LayoutSettingsStateI;
  groupName?: string;
}

export interface LayoutSettingI {
  screenType?: PlaylistScreenType;
  orientation?: FrameDirection;
  backgroundColor?: string;
  backgroundColorSecond?: string;
}

export default function DrawerLayoutGroup(props: LayoutGroupDrawerI) {
  const { t } = useTranslation(['group-device', 'button']);

  const [layoutSetting, setLayoutSetting] = useState<LayoutSettingI>({
    orientation: props.layoutSettingData.orientation,
    screenType: props.layoutSettingData.screenType,
    backgroundColor: props.layoutSettingData.backgroundColor,
    backgroundColorSecond: props.layoutSettingData.backgroundColorSecond,
  });

  useEffect(() => {
    setLayoutSetting({
      orientation: props.layoutSettingData.orientation,
      screenType: props.layoutSettingData.screenType,
      backgroundColor: props.layoutSettingData.backgroundColor,
      backgroundColorSecond: props.layoutSettingData.backgroundColorSecond,
    });
  }, [props]);

  const handleChooseLayout = (e: LayoutSettingI) => {
    setLayoutSetting(e);
  };

  const onOk = () => {
    props.onSave(layoutSetting);
    return props.onCancel && props.onCancel();
  };

  return (
    <Drawer
      title={
        <div className="d-flex align-items-center">
          <span className="mr-2">{t('choose_layout_for')}:</span>
          <TextTruncate
            containerClassName="gr_name word-break flex-1"
            line={2}
            element="span"
            truncateText="…"
            text={`${props?.groupName}`}
          />
        </div>
      }
      footer={[
        <ButtonCancel
          key={'btn_cancel'}
          title={t('button:cancel')}
          classNameBtn={`mr-3 `}
          onClick={() => {
            return props?.onCancel && props?.onCancel();
          }}
        />,
        <ButtonOk key={'btn_ok'} onClick={onOk} title={t('save')} classNameBtn={``} isLoading={props.isLoading} />,
      ]}
      placement={props?.placement || 'right'}
      closable={false}
      onClose={() => {
        return props.onCancel && props.onCancel();
      }}
      visible={props.visible}
      width={600}
      className="drawer-choose_layout"
    >
      <Row gutter={40}>
        <Col span={12} className="pb-4">
          <div
            className="item"
            onClick={() => {
              handleChooseLayout({
                orientation: FrameDirection.Horizontal,
                screenType: PlaylistScreenType.FulScreen,
              });
            }}
          >
            <div
              className={`view-layout ${
                layoutSetting.orientation === FrameDirection.Horizontal &&
                layoutSetting.screenType === PlaylistScreenType.FulScreen
                  ? 'active'
                  : ''
              }`}
            >
              <div className="bg-layout p-3 d-flex align-items-center justify-content-center">
                <FullScreen orientation={FrameDirection.Horizontal} />
              </div>
              <p className="mb-0">{t('schedule.landscape_full_screen')}</p>
            </div>
            <div className="d-flex align-items-center justify-content-center pt-4">
              <Radio
                checked={
                  layoutSetting.orientation === FrameDirection.Horizontal &&
                  layoutSetting.screenType === PlaylistScreenType.FulScreen
                    ? true
                    : false
                }
              />
            </div>
          </div>
        </Col>
        <Col span={12} className="pb-4">
          <div
            className="item"
            onClick={() => {
              handleChooseLayout({
                orientation: FrameDirection.Vertical,
                screenType: PlaylistScreenType.FulScreen,
              });
            }}
          >
            <div
              className={`view-layout ${
                layoutSetting.orientation === FrameDirection.Vertical &&
                layoutSetting.screenType === PlaylistScreenType.FulScreen
                  ? 'active'
                  : ''
              }`}
            >
              <div className="bg-layout p-3 d-flex align-items-center justify-content-center">
                <FullScreen orientation={FrameDirection.Vertical} />
              </div>
              <p className="mb-0">{t('schedule.portrait_full_screen')}</p>
            </div>
            <div className="d-flex align-items-center justify-content-center pt-4">
              <Radio
                checked={
                  layoutSetting.orientation === FrameDirection.Vertical &&
                  layoutSetting.screenType === PlaylistScreenType.FulScreen
                    ? true
                    : false
                }
              />
            </div>
          </div>
        </Col>
        <Col span={12} className="pb-4">
          <div
            className="item"
            onClick={() => {
              handleChooseLayout({
                orientation: FrameDirection.Horizontal,
                screenType: PlaylistScreenType.SplitScreen,
              });
            }}
          >
            <div
              className={`view-layout ${
                layoutSetting.orientation === FrameDirection.Horizontal &&
                layoutSetting.screenType === PlaylistScreenType.SplitScreen
                  ? 'active'
                  : ''
              }`}
            >
              <SplitScreen className="p-3" orientation={FrameDirection.Horizontal} />
              <p className="mb-0">{t('schedule.landscape_split_screen')}</p>
            </div>
            <div className="d-flex align-items-center justify-content-center pt-4">
              <Radio
                checked={
                  layoutSetting.orientation === FrameDirection.Horizontal &&
                  layoutSetting.screenType === PlaylistScreenType.SplitScreen
                    ? true
                    : false
                }
              />
            </div>
          </div>
        </Col>
        <Col span={12} className="pb-4">
          <div
            className="item"
            onClick={() => {
              handleChooseLayout({
                orientation: FrameDirection.Vertical,
                screenType: PlaylistScreenType.SplitScreen,
              });
            }}
          >
            <div
              className={`view-layout ${
                layoutSetting.orientation === FrameDirection.Vertical &&
                layoutSetting.screenType === PlaylistScreenType.SplitScreen
                  ? 'active'
                  : ''
              }`}
            >
              <SplitScreen className="p-3" orientation={FrameDirection.Vertical} />
              <p className="mb-0">{t('schedule.portrait_split_screen')}</p>
            </div>
            <div className="d-flex align-items-center justify-content-center pt-4">
              <Radio
                checked={
                  layoutSetting.orientation === FrameDirection.Vertical &&
                  layoutSetting.screenType === PlaylistScreenType.SplitScreen
                    ? true
                    : false
                }
              />
            </div>
          </div>
        </Col>
      </Row>
    </Drawer>
  );
}
