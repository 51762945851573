import { NotificationSuccess } from '@commons/components/Notification';
import { createPlaylistApi } from '@modules/PlayList/services/apis';
import { put } from 'redux-saga/effects';
import { CreatePlaylistAction } from '../action-types';
import { createPlaylistError, createPlaylistSuccess } from '@modules/PlayList/redux/actions/create_playlist';
import i18n from '@/i18n';
import { Playlist } from '@/graphql/generated/graphql';

export function* createPlaylistAsync(action: CreatePlaylistAction) {
  try {
    const playlist: Playlist = yield createPlaylistApi(action.payload);
    yield put(createPlaylistSuccess(playlist));
  } catch (error) {
    yield put(createPlaylistError(error));
  }
}

export function createPlaylistAsyncSuccess() {
  NotificationSuccess(i18n.t('notification'), i18n.t('add_success'));
}
