import { RootState } from '@redux/reducers';
import { useSelector } from 'react-redux';
import { LoginState } from '../redux/action-types';

const useProfile = () => {
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);

  return {
    loginState,
  };
};

export default useProfile;
