import { ResetPasswordInput } from '@/graphql/generated/graphql';
import { LockOutlined } from '@ant-design/icons';
import CaretDownOutlined from '@ant-design/icons/lib/icons/CaretDownOutlined';
import flagN from '@assets/images/norway.svg';
import flagE from '@assets/images/united-kingdom.svg';
import flagV from '@assets/images/vietnam.svg';
import logo from '@assets/images/logo-login.svg';
import { CommonPath } from '@commons/base-routes';
import { Languages } from '@modules/Auth/redux/action-types';
import { Button, Form, Input, Select } from 'antd';
import { FormProps } from 'antd/es/form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../../Login/styled/LoginForm/index.scss';
import { setDefaultLanguage } from '@helpers/token';

interface Props extends FormProps {
  onRequest(data: ResetPasswordInput): void;
  loading: boolean;
}
const ResetPasswordForm = (props: Props) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get('token') as string;

  const { i18n, t } = useTranslation(['reset-password', 'login']);

  const changeLanguage = (lng: string) => {
    setDefaultLanguage(lng);
  };

  const onFinish = (values: any) => {
    props.onRequest({
      password: values.password,
      token,
    });
  };

  const { Option } = Select;
  return (
    <div className="login-container">
      <div className="login-content">
        <div className="change-language d-flex">
          <Select
            defaultValue={i18n?.language?.toString()}
            style={{ minWidth: 100 }}
            className="select_lang color_text_header ml-auto"
            onChange={(e) => changeLanguage(e)}
            placeholder={t('select_language')}
            value={i18n?.language?.toString()}
            suffixIcon={<CaretDownOutlined style={{ width: 12, color: '#999999' }} />}
          >
            {Languages.map((item) => {
              return (
                <Option key={item.key} value={item.key}>
                  <div className="d-flex align-items-center justify-content-start item_select_change_language">
                    <img
                      src={item.key === 'en' ? flagE : item.key === 'vi' ? flagV : flagN}
                      alt="flag"
                      className="mr-1 ic_lang"
                    />
                    {item.name}
                  </div>
                </Option>
              );
            })}
          </Select>
        </div>
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <Form name="login-form" className="login-form" onFinish={onFinish}>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: t('validate.required_new_password'),
              },
              {
                min: 6,
                message: t('validate.min_lenght_password'),
              },
              { whitespace: true, message: t('validate.whitespace') },
            ]}
          >
            <Input
              autoFocus={true}
              type="password"
              className="input_username"
              prefix={<LockOutlined className="site-form-item-icon" style={{ marginRight: 18 }} />}
              placeholder={t('new_password')}
            />
          </Form.Item>

          <Form.Item
            name="passwordConfirm"
            dependencies={['newPassword']}
            rules={[
              { required: true, message: t('validate.required_confirm_password') },
              ({ getFieldValue }) => ({
                validator(rule: any, value: any) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(t('validate.password_confirm'));
                },
              }),
            ]}
          >
            <Input
              type="password"
              prefix={<LockOutlined className="site-form-item-icon" style={{ marginRight: 18 }} />}
              placeholder={t('confirm_password')}
              className="input_username"
            />
          </Form.Item>

          <Form.Item className="login-form-submit mb-3">
            <Button loading={props.loading} htmlType="submit" type="primary" className="login-form-button">
              {t('reset_password')}
            </Button>
          </Form.Item>
          <Link to={CommonPath.LOGIN_PATH}>
            <span className="text-link">{t('login:login')}</span>
          </Link>
          {/* <Link to={CommonPath.FORGOT_PASSWORD_PATH}>
            <span>{t('forgot-password:forgot_password')}</span>
          </Link> */}
        </Form>
      </div>
      {/* <Link to={CommonPath.REGISTER_PATH}>
        <span>{__('Tạo tài khoản mới')}</span>
      </Link> */}
    </div>
  );
};

export default ResetPasswordForm;
