/* eslint-disable react-hooks/exhaustive-deps */
import {
  FileType,
  Media,
  MediaOrderField,
  QueryMediasInput,
  Setting,
  SettingType,
  Sort,
} from '@/graphql/generated/graphql';
import icon_grid from '@assets/images/icons/Icon_grid.svg';
import icon_grid_active from '@assets/images/icons/Icon_grid_active.svg';
import icon_list from '@assets/images/icons/Icon_list.svg';
import icon_list_active from '@assets/images/icons/Icon_list_active.svg';
import icon_upload from '@assets/images/icons/icon_upload.svg';
import { useCustomSelector } from '@hooks/useCustomSelector';
import { usePrevious } from '@hooks/usePrevious';
import useGetAllFolder from '@modules/Media/hooks/useGetAllFolder';
import useGetAllMedia from '@modules/Media/hooks/useGetAllMedia';
import useGoBackFolder from '@modules/Media/hooks/useGoBackFolder';
import { TypeViewMedia } from '@modules/Media/pages';
import MediaComponent, { IFRefMediaComponent } from '@modules/Media/pages/components/media-component';
import { pageDefault } from '@modules/Media/types';
import ModalAssignGroup, {
  SetOtherGroupI,
  ViewAssign,
} from '@modules/PlayList/components/assigngroup/AssignGroupModal';
import HeaderPlaylist from '@modules/PlayList/components/Header/headerPlaylist';
import RightPlayListAddNew from '@modules/PlayList/components/PlaylistRight/RightPlayListAddNew';
import useResetPlaylistState from '@modules/PlayList/hooks/useResetPlaylistState';
import '@modules/PlayList/styled/index.scss';
import Uppy, { UppyFile } from '@uppy/core';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { DashboardModal as DashBoardModal } from '@uppy/react';
import '@uppy/status-bar/dist/style.css';
import XHRUpload from '@uppy/xhr-upload';

import { Button, Form, Input, message, Row } from 'antd';
import { isEqual } from 'lodash';

import env from '@/env';
import { getHistory } from '@helpers/history';
import { converBytesToMB } from '@helpers/regex';
import { getAuthLocalData } from '@helpers/token';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { LoginState } from '@modules/Auth/redux/action-types';
import useGetCompanyByID from '@modules/Company/hooks/useGetCompanyByID';
import useUpdatePlaylistToOtherGroup from '@modules/GroupDevice/hooks/useUpdatePlaylistToOtherGroup';
import { allowedMimeTypes } from '@modules/Media/helper/file';
import useSelectFolder from '@modules/Media/hooks/useSelectFolder';
import { uploadFileActionSuccess } from '@modules/Media/redux/actions';
import PreviewPlaylist from '@modules/PlayList/components/Preview/PreviewPlaylist';
import { convertFramesToMedia } from '@modules/PlayList/helpers/frame';
import useCreateNewPlaylist from '@modules/PlayList/hooks/useCreateNewPlaylist';
import useGetPlaylist from '@modules/PlayList/hooks/useGetPlaylist';
import useUpdatePlaylist from '@modules/PlayList/hooks/useUpdatePlaylist';
import { AddNewPlayListState } from '@modules/PlayList/redux/reducers/add_new_play_list';
import { getObjectSettingBySettingType } from '@modules/Setting/helpers/utils';
import useGetSettingDetail from '@modules/Setting/hooks/useGetSettingDetail';
import { RootState } from '@redux/reducers';
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { BeforeCapture, DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { mutliDragAwareReorder } from '../ultils/DragUltils';
import vi_VN from '@helpers/uppystringlocale/Uppy_vi';
import EN from '@helpers/uppystringlocale/Uppy_en';
import NOR from '@helpers/uppystringlocale/Uppy_no';
import i18n from '@/i18n';
import { NotificationError } from '@commons/components/Notification';

function Index(props: any, ref?: any) {
  const defaultSearchInput = {
    limit: pageDefault.pageSize,
    filterType: null,
    isLoadMore: false,
    parentId: null,
    query: null,
    order: { direction: Sort.Desc, field: MediaOrderField.CreatedAt },
  };

  const { setPageTitle } = useSetPageTitle();

  const { mutate, isSuccess } = useUpdatePlaylistToOtherGroup();

  const uppy = useRef(new Uppy());

  const addNewPlaylistState = useSelector<RootState, AddNewPlayListState>(
    (state) => state.playList.addNewPlayListState,
  );

  // const mediasData = useCustomSelector((state) => state.media.list.data);

  // console.log('Index ~ mediasData', mediasData);

  const { selectedFolder } = useSelectFolder();

  const { data: dataSetting } = useGetSettingDetail();

  const params = useParams<{ id: string }>();

  const { onCreatePlaylist, isSuccess: onCreatePlaylistSuccess } = useCreateNewPlaylist();

  const refAssignGroup = useRef<any>();

  const { data: DataPlaylist, onGetPlaylist } = useGetPlaylist();

  const onSetOtherGroup = (input: SetOtherGroupI) => {
    if (props?.playlistId || params.id)
      mutate({
        input: {
          _id: props?.playlistId || params.id,
          ...input,
        },
      });
  };

  useEffect(() => {
    if (isSuccess) {
      refAssignGroup.current?.onCancel?.();
    }
  }, [isSuccess]);

  // const { onResetPlaylistState } = useResetPlaylistState();

  useEffect(() => {
    if (props?.playlistId || params?.id) {
      onGetPlaylist({ _id: props?.playlistId || params.id });
    }
    // eslint-disable-next-line
  }, [params?.id, props?.playlistId]);

  const { onUpdatePlaylist, isLoading } = useUpdatePlaylist();

  // drag multiple
  const [selectedTaskIds, setSelectedTaskIds] = useState<string[]>([]);

  /**
   * On window click
   */
  const onWindowClick = useCallback((e) => {
    if (e.defaultPrevented || e.metaKey || e.ctrlKey) {
      return;
    }

    setSelectedTaskIds([]);
  }, []);

  /**
   * On window key down
   */
  const onWindowKeyDown = useCallback((e) => {
    if (e.defaultPrevented || e.metaKey || e.ctrlKey) {
      return;
    }

    if (e.key === 'Escape') {
      setSelectedTaskIds([]);
    }
  }, []);

  /**
   * On window touch end
   */
  const onWindowTouchEnd = useCallback((e) => {
    if (e.defaultPrevented || e.metaKey || e.ctrlKey) {
      return;
    }

    setSelectedTaskIds([]);
  }, []);

  /**
   * Event Listener
   */
  useEffect(() => {
    window.addEventListener('click', onWindowClick);
    window.addEventListener('keydown', onWindowKeyDown);
    window.addEventListener('touchend', onWindowTouchEnd);

    return () => {
      window.removeEventListener('click', onWindowClick);
      window.removeEventListener('keydown', onWindowKeyDown);
      window.removeEventListener('touchend', onWindowTouchEnd);
    };
  }, [onWindowClick, onWindowKeyDown, onWindowTouchEnd]);

  // drag multiple

  const dataUploadSetting = getObjectSettingBySettingType(dataSetting?.settings as Setting[] | any, SettingType.Upload);

  const authData = getAuthLocalData();

  const rerefPreviewf = useRef<any>();

  const dispatch = useDispatch();

  const [dataItem, setDataItem] = useState<[Media[], Media[]]>([[], []]);

  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);

  const { onGetFolders } = useGetAllFolder();

  const [openUploadModal, setOpenUploadModal] = useState(false);

  const { onGetMedias } = useGetAllMedia();

  const { onResetPlaylistState } = useResetPlaylistState();

  const [filterType] = useState<any>(null);

  const [typeView, setTypeView] = useState<TypeViewMedia>(TypeViewMedia.LIST);

  const [form] = Form.useForm();

  const [formRight] = Form.useForm();

  const refMediaComponent = React.useRef<IFRefMediaComponent | null>(null);

  const { t: tPlaylist } = useTranslation(['playlist']);

  const [currentMedia, setCurrentMedia] = useState<number | undefined>(undefined);

  const { t } = useTranslation(['media']);

  const mediasData = useCustomSelector((state) => state?.media?.list?.data);

  const { onGoBack: onGoBackAction } = useGoBackFolder();

  const [searchState, setSearchState] = useState<QueryMediasInput>(defaultSearchInput);

  const prevSearchState = usePrevious(searchState);

  const { data: company } = useGetCompanyByID({
    _id: loginState.data?.companyId || '',
  });

  useEffect(() => {
    if (tPlaylist) {
      setPageTitle(tPlaylist('title'));
    }
    // eslint-disable-next-line
  }, [tPlaylist]);

  useEffect(() => {
    onResetPlaylistState();

    return () => {
      onResetPlaylistState();
      setDataItem([dataItem[0], []]);
    };
    // eslint-disable-next-line
  }, []);

  useImperativeHandle(ref, () => ({ onResetDataItem }), []);

  const onResetDataItem = useCallback(() => {
    setDataItem([dataItem[0], []]);
  }, [dataItem]);

  useEffect(() => {
    if (props?.playlistId || !isEqual(searchState, prevSearchState)) {
      onGetMedias(searchState);
      onGetFolders({
        limit: 100,
        type: FileType.Folder,
      });
    }
    // eslint-disable-next-line
  }, [searchState, prevSearchState, props?.playlistId]);

  useEffect(() => {
    if (DataPlaylist && DataPlaylist.name) {
      formRight.setFieldsValue({ name: DataPlaylist.name });
    }
  }, [DataPlaylist, formRight]);

  const onChangeTypeView = (type: TypeViewMedia) => {
    setTypeView(type);
  };

  const onGoBack = () => {
    if (mediasData?.currentFolder) {
      setSearchState({
        ...searchState,
        filterType: null,
        parentId: mediasData?.currentFolder.parentId,
        isLoadMore: false,
      });
      onGoBackAction(mediasData?.currentFolder);
    }
  };

  const onSearch = (value: any) => {
    setSearchState({ ...searchState, filterType: null, query: value.query });
    // setFilterTitle('');
  };

  const renderBreadcrumb = useMemo(() => {
    return (
      <div className="breadcrumb_media">
        <Row className="row-breadcrumb">
          <Button className="buttonHeader mr-2" onClick={() => setOpenUploadModal(true)}>
            <img src={icon_upload} className="icon-btn" alt="icon_create" />
            <span className="upload-btn">{t('upload')}</span>
          </Button>

          <Form layout="inline" style={{ flex: 1 }} form={form} onFinish={onSearch}>
            <Form.Item>
              <Input.Group compact>
                <Form.Item name="query" className="w-full mr-0">
                  <Input placeholder={`${t('search')}`} style={{ backgroundColor: 'white' }} className="search_input" />
                </Form.Item>
                <Button icon={<FiSearch style={{ color: '#F80000' }} />} className="btn-search" htmlType="submit" />
              </Input.Group>
            </Form.Item>
          </Form>
        </Row>

        <div className="view_group mt-sm-2 ml-sm-1">
          <img
            onClick={() => {
              onChangeTypeView(TypeViewMedia.LIST);
            }}
            src={typeView === TypeViewMedia.LIST ? icon_list_active : icon_list}
            width={22}
            height={20}
            alt="view list"
          />
          <img
            onClick={() => {
              onChangeTypeView(TypeViewMedia.GRID);
            }}
            src={typeView === TypeViewMedia.GRID ? icon_grid_active : icon_grid}
            width={20}
            height={20}
            alt="view grid"
          />
        </div>
      </div>
    );
  }, [t, typeView]);

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = useCallback(
    (result2: any) => {
      const { source, destination } = result2;
      // dropped outside the list
      if (!destination) {
        return;
      }

      // nothing to do
      if (!destination || result2.reason === 'CANCEL') {
        return;
      }

      const sInd = +source.droppableId;
      const dInd = +destination.droppableId;

      const newState: any = [...dataItem];

      if (sInd === dInd && destination?.droppableId === '2') {
        try {
          newState[dInd - 1] = reorder(newState[dInd - 1], source.index, destination.index);
          setDataItem(newState);
        } catch (error) {
          console.log('error>>>', error);
        }
        return;
      }
      if (destination?.droppableId === '2') {
        const result = mutliDragAwareReorder({
          entities: newState,
          selectedTaskIds,
          source,
          destination,
        });

        newState[sInd] = copy(newState[1], result.entities[sInd], source, destination);
        newState[1] = result.entities[1];

        setDataItem(newState.filter((group: Media[]) => group.length));
        setSelectedTaskIds([]);
      }
    },
    [dataItem],
  );

  const copy = (source: any, destination: any, droppableSource: any, droppableDestination: any) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const item: any = sourceClone[droppableSource.index];

    destClone.splice(droppableDestination.index, 0, { ...item, _id: new Date().getMilliseconds() });
    return destClone;
  };

  const onSetDataItem = useCallback(
    (data: Media[]) => {
      setDataItem([data || [], [...dataItem[1]]]);
    },
    [dataItem],
  );

  const onRemoveDataItem2 = useCallback(
    (data: Media[]) => {
      setDataItem([[...dataItem[0]], [...data]]);
    },
    [dataItem],
  );

  const onSubmitAssigned = () => {
    onGetPlaylist({ _id: props?.playlistId || params.id });
    // refetch({});
  };

  useEffect(() => {
    if (company) {
      if (currentMedia !== undefined && currentMedia === mediasData?.items?.length) return;
      setCurrentMedia(mediasData?.items?.length || 0);
      uppy.current = new Uppy({
        restrictions: {
          maxFileSize: Number(dataUploadSetting.maxFileSize) * 1048576,
          allowedFileTypes: allowedMimeTypes,
        },
        meta: {
          parentId: mediasData?.currentFolder?._id.toString() || '',
        },

        onBeforeUpload: (allFiles: { [key: string]: UppyFile<any> }) => {
          // props.setMediaInfo(undefined);
          let size = 0;
          // eslint-disable-next-line
          for (const [key, file] of Object.entries(allFiles)) {
            size += file?.size;
          }

          const limitUpload = company?.company?.limitUpload || 0;

          const fileSize = converBytesToMB(size);

          const curentSize = fileSize + (company?.company?.currentStorageSize || 0);

          if (Number(curentSize.toFixed(2)) > limitUpload) {
            message.error(`${t('max_file_size')} ${limitUpload - (company?.company?.currentStorageSize || 0)}MB`, 5);
            return false;
          }

          if (fileSize > Number(dataUploadSetting.maxFileSize)) {
            message.error(`${t('max_file_size')} ${dataUploadSetting.maxFileSize}MB`, 5);
            return false;
          }

          if (
            limitUpload > 0 &&
            Number(converBytesToMB(company?.company?.currentStorageSize || 0)) + Number(converBytesToMB(size)) <=
              limitUpload
          ) {
            return true;
          }
          message.error(`${t('out_of_storage_space')}`, 9999);
          return false;
        },
      });
      uppy.current
        .use(XHRUpload, {
          id: company.company?._id,
          endpoint: `${env.apiHost}/medias/uploadFile`,
          formData: true,
          headers: {
            authorization: `Bearer ${authData?.token}`,
          },
        })
        .on('file-added', (file: any) => {
          uppy.current.setFileMeta(file.id, {
            size: file.size,
            parentId: mediasData?.currentFolder?._id.toString() || '',
          });
        })
        .on('complete', (data: any) => {
          data?.successful?.map((media: any) => {
            if (media?.response?.body && media?.response?.body?._id) {
              dispatch(uploadFileActionSuccess(media?.response?.body));
            }
          });
        });
    }
  }, [company, mediasData?.currentFolder, t, dataUploadSetting, mediasData?.items?.length]);

  const onFinish = async (values: any) => {
    if (loginState.data?.companyId) {
      if (addNewPlaylistState.frameData?.length === 0) {
        NotificationError(t('warring'), t('playlist_empty'));
        return;
      }
      const dataInput: any = {
        frames: addNewPlaylistState.frameData,
        name: values?.name || '',
        companyId: loginState!.data?.companyId,
      };
      if (props?.playlistId || params.id) {
        await onUpdatePlaylist({ _id: props?.playlistId || params.id, ...dataInput });
        if (!props?.playlistId) {
          getHistory().push('/playlist/list');
        }
        return;
      }
      if (!props?.playlistId) {
        onCreatePlaylist(dataInput);
      }
    }
  };

  useEffect(() => {
    if (onCreatePlaylistSuccess && !params?.id) {
      getHistory().push('/playlist/list');
    }
  }, [onCreatePlaylistSuccess, params]);

  const onSetDurationValue = (key: string, value: any) => {
    formRight.setFieldsValue({ [key]: value });
  };

  useEffect(() => {
    if (DataPlaylist && DataPlaylist._id) {
      const dataMedia: Media[] = convertFramesToMedia(DataPlaylist.frames || []);
      setDataItem([dataItem[0], [...dataMedia]]);
    }
  }, [DataPlaylist, dataItem.length, DataPlaylist.frames?.length]);

  const onSelectedMedias = useCallback(
    (values: Media[] | []) => {
      if (values && values.length > 0) {
        toggleSelectionInGroup(values[0]._id);
      }
    },
    [selectedTaskIds],
  );

  useEffect(() => {
    setSearchState({
      ...searchState,
      filterType: null,
      parentId: selectedFolder?._id.toString() || null,
      query: undefined,
    });
    // eslint-disable-next-line
  }, [selectedFolder]);

  const onUpdateMediaInfo = () => {
    console.log('onUpdateMediaInfo>>>');
  };

  const onBeforeCapture = (start: BeforeCapture) => {
    const draggableId = start.draggableId;
    const selected = selectedTaskIds.find((taskId) => taskId === draggableId);

    // if dragging an item that is not selected - unselect all items
    if (!selected) {
      setSelectedTaskIds([]);
    }
  };

  const toggleSelectionInGroup = useCallback(
    (taskId: any) => {
      const NewDataListIds = [...selectedTaskIds];
      const index = NewDataListIds.indexOf(taskId);
      // if not selected - add it to the selected items
      if (index === -1) {
        setSelectedTaskIds([...NewDataListIds, taskId]);

        return;
      }

      const shallow = [...NewDataListIds];
      shallow.splice(index, 1);

      setSelectedTaskIds(shallow);
    },
    [selectedTaskIds],
  );

  return (
    <div className="playlist-page user-select-none">
      <Form form={formRight} onFinish={onFinish}>
        <HeaderPlaylist
          name={DataPlaylist.name || form?.getFieldValue('name')}
          onPreview={() => rerefPreviewf.current?.onShow()}
          onSetToOtherGroup={() => refAssignGroup.current?.onShow(DataPlaylist)}
          view={!props?.view ? undefined : ViewAssign.GROUP}
          hidenSetother={!params.id}
          onCloseDrawer={() => {
            props?.onClose();
          }}
        />
        <Row gutter={16} className="playlist-row" style={{ marginRight: 0, marginTop: 20 }}>
          <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
            <div className="playlist-col playlist-col-center" style={{ maxWidth: '50%' }}>
              <div id="media_container_page" className="media-container-page position-relative ">
                <div className="list-media-playlist-container">
                  {renderBreadcrumb}
                  <Droppable key={1} droppableId={`${1}`} isCombineEnabled>
                    {(provided, snapshot) => (
                      <div
                        className="media-list"
                        ref={provided.innerRef}
                        // style={getListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}
                      >
                        <MediaComponent
                          setDataItem={onSetDataItem}
                          ref={refMediaComponent}
                          selectedTaskIds={selectedTaskIds}
                          onUpdateMediaInfo={onUpdateMediaInfo}
                          onSelectedMedias={onSelectedMedias}
                          multiple={false}
                          onGoBack={onGoBack}
                          playlistId={props?.playlistId}
                          isProvider={true}
                          filterType={filterType}
                          typeView={typeView}
                        />
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  <div className="text-total-count px-2">
                    <span style={{ color: 'red' }}>{`${mediasData?.total || 0} `}</span>
                    <span className="text-capitalize mx-2">
                      {t(`${(mediasData?.total || 0) > 1 ? 'items' : 'item'} `)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="playlist-col playlist-col-right">
              <Droppable key={2} droppableId={`${2}`}>
                {(provided, snapshot) => (
                  <div className="list-media-playlist-container" ref={provided.innerRef} {...provided.droppableProps}>
                    <RightPlayListAddNew
                      isLoading={isLoading}
                      onSetDurationValue={onSetDurationValue}
                      data={dataItem[dataItem.length - 1]}
                      onRemoveDataItem2={onRemoveDataItem2}
                    />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </DragDropContext>
        </Row>
        B
      </Form>
      <DashBoardModal
        uppy={uppy.current}
        open={openUploadModal}
        translate="yes"
        locale={i18n.language === 'no' ? NOR : i18n.language === 'en' ? EN : vi_VN}
        closeAfterFinish={true}
        animateOpenClose={true}
        closeModalOnClickOutside={true}
        proudlyDisplayPoweredByUppy={false}
        onRequestClose={() => {
          setOpenUploadModal(false);
          message.destroy();
        }}
      />
      {/* <ModalFrameLayout /> */}
      <PreviewPlaylist ref={rerefPreviewf} data={dataItem[dataItem.length - 1]} />

      <ModalAssignGroup
        onSetOtherGroup={onSetOtherGroup}
        isEdit={!!DataPlaylist?.group}
        ref={refAssignGroup}
        onSubmit={onSubmitAssigned}
      />
    </div>
  );
}

export default React.memo(React.forwardRef(Index));
