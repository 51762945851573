import icon_create_folder from '@assets/images/icons/icon_create_folder.svg';
import ButtonCancel from '@commons/components/modals/Button/ButtonCancel';
import ButtonOk from '@commons/components/modals/Button/ButtonOk';
import useGetAllMedia from '@modules/Media/hooks/useGetAllMedia';
import { pageDefault } from '@modules/Media/types';
import { Button, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCreateFolder from '../../hooks/useCreateFolder';

export default function FolderModal() {
  const { t } = useTranslation(['media']);
  const { onCreateFolder } = useCreateFolder();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { data, onGetMedias } = useGetAllMedia();

  const [form] = Form.useForm();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const onSubmit = (values: any) => {
    onCreateFolder({
      // parentId: selectedFolder?._id.toString() || null,
      parentId: data?.currentFolder?._id.toString() || null,
      filename: values.filename.trim(),
    });
    onGetMedias({
      page: data?.currentPage,
      limit: pageDefault.pageSize,
      parentId: data?.currentFolder?._id,
    });
    setIsModalVisible(false);
    form.resetFields();
  };

  const onCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal} className="buttonHeader btn-submit">
        <img src={icon_create_folder} className="icon-btn" alt="icon_create" />
        {t('create_folder')}
      </Button>

      <Modal
        centered
        visible={isModalVisible}
        cancelText={t('cancel')}
        onCancel={() => onCancel()}
        title={t('create_folder')}
        className="modal_confirm_custom "
        footer={[
          <ButtonCancel key={'btn_cancel_create'} title={t('cancel')} classNameBtn={`mr-2 `} onClick={onCancel} />,
          <ButtonOk
            key={'btn_ok_create'}
            onClick={() => {
              form.submit();
            }}
            title={t('save')}
          />,
        ]}
      >
        <Form key="modal_create_folder" form={form} onFinish={onSubmit}>
          <Form.Item
            name="filename"
            className=" w-100"
            rules={[
              {
                required: true,
                message: t('validates.required_folder_name'),
              },
              { whitespace: true, message: t('validates.required_folder_name') },
            ]}
          >
            <Input allowClear type="text" autoComplete="off" placeholder={t('folder_name')} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
