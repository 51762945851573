import { User, User_Status } from '@/graphql/generated/graphql';
import useUpdateUserStatus from '@modules/User/hooks/useUpdateUserStatus';
import { Switch } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import '../../styled/index.scss';

interface UserRowActionProp {
  record: User;
  onEdit?(pck: User): void;
  onDelete?(pck: User): void;
  className?: string;
}

export default function UpdateStatus(props: UserRowActionProp) {
  const { mutate, isLoading } = useUpdateUserStatus();

  const { t } = useTranslation('button');

  const onchange = (values: boolean) => {
    if (props.record)
      mutate({
        input: {
          _id: props.record._id,
          status: !!values ? User_Status.Active : User_Status.Inactive,
        },
      });
  };

  return (
    <div className={`switch ${props?.className ? props?.className : ''}`}>
      <Switch
        size="default"
        className="btn-switch"
        defaultChecked={props.record.status === User_Status.Active ? true : false}
        onChange={onchange}
        loading={isLoading}
      />
      <span className={`container_icon status-text ${props.record.status === User_Status.Active ? 'checked' : ''}`}>
        {props.record.status === User_Status.Active ? t('on') : t('off')}
      </span>
    </div>
  );
}
