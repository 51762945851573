import { all, take } from 'redux-saga/effects';
import authSaga from '@modules/Auth/redux/sagas';
import playlistSaga from '@modules/PlayList/redux/sagas';
import mediaSaga from '@modules/Media/redux/sagas';
import deviceSaga from '@modules/Device/redux/sagas';
import groupDeviceSaga from '@modules/GroupDevice/redux/sagas';
import * as commonSaga from './common-saga';
import { APP_STARTED } from '@modules/App/redux/action-types';

export default function* rootSaga() {
  yield take(APP_STARTED);
  yield all([authSaga(), playlistSaga(), mediaSaga(), deviceSaga(), groupDeviceSaga(), commonSaga.checkErrorAsync()]);
}
