import { Media } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { updateFileApi } from '@modules/Media/services/apis';
import { put } from 'redux-saga/effects';
import { UpdateFileAction } from '../action-types';
import { updateFileActionError, updateFileActionSuccess } from '../actions';

export function* updateFileAsync(action: UpdateFileAction) {
  try {
    const media: Media = yield updateFileApi(action.payload);
    yield put(updateFileActionSuccess(media));
  } catch (error) {
    yield put(updateFileActionError(error));
  }
}

export function updateFileAsyncSuccess() {
  NotificationSuccess(i18n.t('notification'), i18n.t('update_success'));
}
