import { getSDK } from '@/graphql/client';
import { useQuery } from 'react-query';
import { GET_DETAIL_DEVICE_2 } from '../types';

export default function useDetailDevice2(_id?: string) {
  return useQuery(
    GET_DETAIL_DEVICE_2,
    () => {
      return getSDK().detailDevice({ input: { _id: _id || '' } });
    },
    {
      enabled: !!_id,
    },
  );
}
