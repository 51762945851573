import { APP_STARTED, AppActionTypes, SET_PAGE_TITLE, SET_APP_LAYOUT } from './action-types';

export interface AppState {
  loaded: boolean;
  title: string;
  layoutSize: 'small' | 'middle' | 'large';
}

const initialState: AppState = {
  loaded: false,
  title: '',
  layoutSize: 'small',
};

export default (state = initialState, action: AppActionTypes): AppState => {
  switch (action.type) {
    case APP_STARTED:
      return {
        ...state,
        loaded: true,
      };
    case SET_PAGE_TITLE:
      return {
        ...state,
        title: action.payload,
      };
    case SET_APP_LAYOUT:
      return {
        ...state,
        layoutSize: action.payload,
      };
    default:
      return state;
  }
};
