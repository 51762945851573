import { Roles, User } from '@/graphql/generated/graphql';
import { getAuthLocalData, updateLocalToken } from '@helpers/token';
import { UPDATE_PROFILE_SUCCESS } from '../action-types';
import {
  LOGIN,
  LoginActionTypes,
  LoginState,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  SUPPER_ADMIN_AUTO_LOGIN_SUCCESS,
} from '../action-types/login';

const initialState: LoginState = {
  loading: !!getAuthLocalData(),
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: LoginActionTypes): LoginState => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loading: true,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        data: {
          ...action.payload.payload,
          role: action.payload.role,
          companyId: action.payload.companyId,
          isSuperAdmin: action.payload.isSuperAdmin,
        },
        loading: false,
      };
    case UPDATE_PROFILE_SUCCESS:
      updateLocalToken(action.payload as User);
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loading: false,
      };

    case SUPPER_ADMIN_AUTO_LOGIN_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          companyId: action.payload,
          role: action.payload ? Roles.Admin : Roles.SuperAdmin,
        } as User,
      };
    default:
      return state;
  }
};
