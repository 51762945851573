import { FileType, Media } from '@/graphql/generated/graphql';
import { DeleteOutlined, DownloadOutlined, ExportOutlined, EyeOutlined, LinkOutlined } from '@ant-design/icons';
import { NotificationSuccess } from '@commons/components/Notification';
import { useCustomSelector } from '@hooks/useCustomSelector';
import { useRoles } from '@hooks/useRoles';
import useDeleteMediaItem from '@modules/Media/hooks/useDeleteMediaItem';
import useMoveFileItem from '@modules/Media/hooks/useMoveFileItem';
import usePreviewMedia from '@modules/Media/hooks/usePreviewMedia';
import { Button, Dropdown, Menu } from 'antd';
import axios from 'axios';
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import RenameModal from '../modal/RenameModal';

interface IProps {
  item: Media;
  isShowMenu?: boolean;
}

const styleMenuDropdown: CSSProperties = {
  flexDirection: 'row',
  alignItems: 'center',
  display: 'flex',
};

const styleTxtDropdown: CSSProperties = {
  margin: 0,
  marginLeft: 10,
  fontSize: 15,
  color: 'black',
};

export default function ItemDropdown({ item, isShowMenu }: IProps) {
  const { t } = useTranslation(['media']);
  const { onPreview } = usePreviewMedia();
  const { onDeleteItem } = useDeleteMediaItem();
  const { onMoveItem } = useMoveFileItem();
  const loginState = useCustomSelector((state) => state.auth.loginState.data);

  const { isEditor } = useRoles();

  const handlePreview = () => {
    onPreview(item);
  };
  const handleCopyLink = () => {
    if (item) {
      const tempInput = document.createElement('input');
      tempInput.value = item?.optimizeUrl || '';
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);
      NotificationSuccess(t('notification'), t('copy_link_success'));
    }
  };

  const handleDownload = () => {
    axios({
      url: `${item.optimizeUrl}`, //your url
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${item.filename}`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleDelete = () => {
    onDeleteItem(item);
  };
  const handleMoveFile = () => {
    onMoveItem(item);
  };
  const menu =
    item.type === FileType.File ? (
      <Menu>
        <Menu.Item key="preview" className="p-0 my-0">
          <div style={styleMenuDropdown} onClick={handlePreview}>
            <Button type="text" className="w-100 text-left pl-0 py-2" style={styleMenuDropdown}>
              <EyeOutlined className="ml-2" />
              <p style={styleTxtDropdown}>{t('preview')}</p>
            </Button>
          </div>
        </Menu.Item>
        <Menu.Item key="copy_link" className="p-0 m-0">
          <div style={styleMenuDropdown} onClick={handleCopyLink}>
            <Button type="text" className="w-100 text-left pl-0" style={styleMenuDropdown}>
              <LinkOutlined className="ml-2" />
              <p style={styleTxtDropdown}>{t('copy_link')}</p>
            </Button>
          </div>
        </Menu.Item>

        {(loginState?._id?.toString() === item?.createdById?.toString() || !isEditor()) && (
          <Menu.Item key="move_file" className="p-0 m-0">
            <div style={styleMenuDropdown} onClick={handleMoveFile}>
              <Button type="text" className="w-100 text-left pl-0" style={styleMenuDropdown}>
                <ExportOutlined className="ml-2" />
                <p style={styleTxtDropdown}>{t('move_file')}</p>
              </Button>
            </div>
          </Menu.Item>
        )}

        {item?.canEdit && (
          <Menu.Item key="rename" className="p-0 m-0">
            <div style={styleMenuDropdown}>
              <RenameModal item={item} />
            </div>
          </Menu.Item>
        )}

        <Menu.Item key="download" className="p-0 m-0">
          <div style={styleMenuDropdown} onClick={handleDownload}>
            <Button type="text" className="w-100 text-left pl-0" style={styleMenuDropdown}>
              <DownloadOutlined className="ml-2" />
              <p style={styleTxtDropdown}>{t('download')}</p>
            </Button>
          </div>
        </Menu.Item>
        {(loginState?._id?.toString() === item?.createdById?.toString() || !isEditor()) && (
          <Menu.Item key="delete" className="p-0 m-0">
            <div style={styleMenuDropdown}>
              <Button type="text" className="w-100 text-left pl-0" style={styleMenuDropdown} onClick={handleDelete}>
                <DeleteOutlined className="ml-2" />
                <p style={styleTxtDropdown}>{t('trash')}</p>
              </Button>
            </div>
          </Menu.Item>
        )}
      </Menu>
    ) : (
      <Menu>
        {item?.canEdit && (
          <Menu.Item key="rename" className="p-0 m-0">
            <div style={styleMenuDropdown}>
              <RenameModal item={item} />
            </div>
          </Menu.Item>
        )}
        {(loginState?._id?.toString() === item?.createdById?.toString() || !isEditor()) && (
          <Menu.Item key="delete" className="p-0 m-0">
            <div style={styleMenuDropdown}>
              <Button type="text" className="w-100 text-left pl-0" style={styleMenuDropdown} onClick={handleDelete}>
                <DeleteOutlined className="ml-2" />
                <p style={styleTxtDropdown}>{t('trash')}</p>
              </Button>
            </div>
          </Menu.Item>
        )}
      </Menu>
    );
  return (
    <Dropdown
      overlay={menu}
      placement="bottomRight"
      visible={isShowMenu}
      getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
    >
      <div className="dropdown-list" />
    </Dropdown>
  );
}
