import Loading from '@/commons/components/Loading';
import { Roles } from '@/graphql/generated/graphql';
import { LoginState } from '@modules/Auth/redux/action-types';
import { RootState } from '@redux/reducers';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AllUsersListPage from './all-users';
import CompanyUsersListPage from './company-users';

interface IState {
  loaded: boolean;
  companyId?: string | null;
}

export default function UserListV2Page() {
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);

  const [state, setState] = useState<IState>({
    loaded: false,
    companyId: undefined,
  });

  useEffect(() => {
    if (!loginState.loading) {
      setState({ ...state, companyId: loginState.data?.companyId, loaded: true });
    }
    // eslint-disable-next-line
  }, [loginState.loading]);

  return (
    <>
      {loginState.loading && <Loading />}
      {state.loaded ? (
        state.companyId && loginState.data?.role !== Roles.SuperAdmin ? (
          <CompanyUsersListPage />
        ) : (
          <AllUsersListPage />
        )
      ) : null}
    </>
  );
}
