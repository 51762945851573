import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addCurrentPlayListScheduleAction } from '../redux/actions/current_playlist_schedule';
import { PlaylistSchedule } from '../types';

const useAddCurrentPlaylistSchedule = () => {
  const dispatch = useDispatch();

  const onAddCurrentPlaylistSchedule = useCallback((data?: PlaylistSchedule) => {
    dispatch(addCurrentPlayListScheduleAction(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onAddCurrentPlaylistSchedule,
  };
};

export default useAddCurrentPlaylistSchedule;
