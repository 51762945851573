import { Media, CreateFolderInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { CREATE_FOLDER, CREATE_FOLDER_SUCCESS, CREATE_FOLDER_ERROR } from '../action-types';

export const createFolderAction = (payload: CreateFolderInput) => ({
  type: CREATE_FOLDER,
  payload,
});

export const createFolderActionSuccess = (payload?: Media) => ({
  type: CREATE_FOLDER_SUCCESS,
  payload,
});

export const createFolderActionError = (payload: AppError) => ({
  type: CREATE_FOLDER_ERROR,
  payload,
});
