import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
  title?: string;
  className?: string;
  onClick: () => void;
  loading?: boolean;
}

const BtnSave = (props: Props) => {
  const { t } = useTranslation(['button']);

  return (
    <Button className={`btn_save ${props.className}`} onClick={props.onClick} loading={props?.loading}>
      {props.title ? props.title.toLocaleUpperCase() : t('save')}
    </Button>
  );
};

export default BtnSave;
