/* eslint-disable react-hooks/exhaustive-deps */
import { getSDK } from '@/graphql/client';
import { PaginatedGroup, QueryGroupsInput } from '@/graphql/generated/graphql';

import { useCallback, useState } from 'react';

const useGetAllGroupPage = () => {
  const [groups, setGroups] = useState<PaginatedGroup>();

  const [loading, setLoading] = useState(false);

  const [maxData, setMaxData] = useState(false);

  const onGetGroups = useCallback(async (input: QueryGroupsInput) => {
    setLoading(true);
    try {
      const result = await getSDK().getListGroup({ input: input });
      if (result.groups) {
        if (result.groups.totalPage === 1 || (result.groups?.items?.length || 0) < input.limit) {
          setMaxData(true);
        }
        const dataGroup: any = {
          ...result?.groups,
          items:
            (input.page || 0) > 1
              ? [...(groups?.items || []), ...(result.groups?.items || [])]
              : result.groups?.items || [],
        };
        setGroups(dataGroup);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, []);

  return {
    loading: loading,
    data: groups,
    onGetGroups,
    maxData,
  };
};
export default useGetAllGroupPage;
