import { RootState } from '@redux/reducers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RemovePlaylistScheduleInput } from '../redux/action-types';
import { removePlaylistSchedulesGroup, removePlaylistSchedulesSecondGroup } from '../redux/actions';
import { CurrentGroupState } from '../redux/reducers/current_group';

const useRemovePlaylistScheduleGroup = () => {
  const dispatch = useDispatch();
  const currentGroupState = useSelector<RootState, CurrentGroupState>((state) => state.groupDevice.currentGroup);

  const onRemovePlaylistScheduleGroup = useCallback(
    (input: RemovePlaylistScheduleInput, type: number) => {
      if (type === 2) {
        dispatch(removePlaylistSchedulesSecondGroup(input));
      } else {
        dispatch(removePlaylistSchedulesGroup(input));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentGroupState.data, dispatch],
  );

  return {
    onRemovePlaylistScheduleGroup,
  };
};

export default useRemovePlaylistScheduleGroup;
