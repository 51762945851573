import { PaginatedMedia, QueryMediasInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { GET_MEDIAS, GET_MEDIAS_ERROR, GET_MEDIAS_SUCCESS } from '../action-types';

export const getMediasAction = (payload: QueryMediasInput) => ({
  type: GET_MEDIAS,
  payload,
});

export const getMediasActionSuccess = (payload: PaginatedMedia) => ({
  type: GET_MEDIAS_SUCCESS,
  payload,
});

export const getMediasActionError = (payload: AppError) => ({
  type: GET_MEDIAS_ERROR,
  payload,
});
