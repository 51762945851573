import { Setting, SettingData, SettingType } from '@/graphql/generated/graphql';

/**
 * Get ObjectSetting from array with key-value
 * @param array
 * @returns
 */
const mapObjectFromSettingDataArray = (array: SettingData[]) => {
  const object: any = array.reduce((ac: any, cu) => {
    if (!ac[`${cu.key}`]) {
      ac[`${cu.key}`] = cu.value;
    }
    return ac;
  }, {});
  return object;
};

const getSettingDataBySettingType = (array: Setting[], type: SettingType) => {
  const resultArray = array.find((st) => st.key === type);
  return resultArray?.value ? resultArray?.value : [];
};

export const getObjectSettingBySettingType = (array: [Setting] | undefined, type: SettingType) => {
  if (!array) {
    return {};
  }
  const settingsData = getSettingDataBySettingType(array, type);
  const result = settingsData.length ? mapObjectFromSettingDataArray(settingsData) : {};
  return result;
};

export function getQueryVariable(variable: string) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
}
