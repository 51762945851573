import { useState, useEffect } from 'react';
import env from '@/env';
import { getAuthLocalData } from '@helpers/token';
import axios from 'axios';
import { ErrorCode } from '@helpers/error';

export default function useRequestContinuousScreenshots() {
  const [source, setSource] = useState<any>(axios.CancelToken.source());

  useEffect(() => {
    if (!source?.token) {
      setSource(axios.CancelToken.source());
    }
  }, [source]);

  const request = async (deviceId: string) => {
    return await axios({
      url: `${env.apiHost}/devices/view-screen`,
      method: 'POST',
      data: { id: deviceId },
      headers: { Authorization: `Bearer ${getAuthLocalData()?.token}` },
      cancelToken: source.token,
    }).catch((err) => {
      if (err?.message !== ErrorCode.CANCEL_REQUEST) {
        request(deviceId);
      }
    });
  };

  const cancel = () => {
    setSource(source.cancel(ErrorCode.CANCEL_REQUEST));
  };

  return {
    requestContinuousScreenshots: request,
    cancelRequestContinuousScreenshots: cancel,
  };
}
