import { DeviceStatus, OsType, ScreenType } from '@/graphql/generated/graphql';

export const GET_ALL_DEVICE = 'GET_ALL_DEVICE';
export const GET_DETAIL_DEVICE = 'GET_DETAIL_DEVICE';
export const GET_DETAIL_DEVICE_2 = 'GET_DETAIL_DEVICE_2';
export interface DeviceFilter {
  query?: string;
  os?: OsType;
  screen?: ScreenType;
  status?: DeviceStatus;
  companyId?: string;
}

export const OSTypes = [
  {
    key: OsType.Android,
    name: OsType.Android,
  },
  {
    key: OsType.Linux,
    name: OsType.Linux,
  },
  {
    key: OsType.Tizen,
    name: OsType.Tizen,
  },
  {
    key: OsType.Webos,
    name: OsType.Webos,
  },
];

export const ScreenTypes = [
  {
    key: ScreenType.Led,
    name: ScreenType.Led,
  },
  {
    key: ScreenType.Oled,
    name: ScreenType.Oled,
  },
  {
    key: ScreenType.Qled,
    name: ScreenType.Qled,
  },
];

export const DeviceStatusTypes = [
  {
    key: DeviceStatus.On,
    name: 'online',
  },
  {
    key: DeviceStatus.Off,
    name: 'offline',
  },
  // {
  //   key: DeviceStatus.LostConnection,
  //   name: 'Lost Connection',
  // },
  // {
  //   key: DeviceStatus.NoConnection,
  //   name: 'No Connection',
  // },
  // {
  //   key: DeviceStatus.NoPlaylist,
  //   name: 'No Playlist',
  // },
];

export interface DayData {
  value: number;
  checked: boolean;
}

export interface MonthData {
  value: number;
  label: string;
  days: DayData[];
  checked: boolean;
  isShowDays: boolean;
}

export const getDeviceStatus = (status: any) => {
  let deviceStatus = 'No Connection';
  // eslint-disable-next-line
  DeviceStatusTypes.map((item: { key: DeviceStatus; name: string }) => {
    if (item.key === status) {
      deviceStatus = item.name;
    }
  });
  return deviceStatus;
};
