import { MonthInYear, ScheduleType } from '@/graphql/generated/graphql';
import { checkTimeInDay, formatMomentDate, getDaysInMonth, HOUR_MINUTE, renderFullDayInMonth } from '@helpers/date';
import useUpdateDevicePlaylistSchedules from '@modules/GroupDevice/hooks/useUpdateScheduleGroup';
import { CurrentPlaylistSchedulesState } from '@modules/GroupDevice/redux/reducers/current_playlist_schedule';
import { MonthI, TimeInDayI } from '@modules/GroupDevice/types';
import { RootState } from '@redux/reducers';
import { Button, Checkbox, Col, Form, Row } from 'antd';
import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DayOfMonth from '../Drawer/DayOfMonth';
import OpenCloseTime from '../Shedule/OpenCloseTime';
import { ErrorTimeInDayState } from '../Shedule/Right';

interface MonthlyProps {
  errorTimeInDay: ErrorTimeInDayState;
  onCancel: () => void;
}

interface MonthlyScheduleDataInput {
  months?: MonthI[];
  timeInDay?: TimeInDayI;
}

interface ModalDayOfMonthData {
  visible: boolean;
  month?: MonthI;
}

const Monthly1Component = (props: MonthlyProps, ref: any) => {
  const { t } = useTranslation(['group-device']);
  const { onUpdatePlaylistScheduleGroupDevice } = useUpdateDevicePlaylistSchedules();
  const currentPlaylistScheduleState = useSelector<RootState, CurrentPlaylistSchedulesState>(
    (state) => state?.groupDevice?.currentPlaylistSchedule,
  );
  const [form] = Form.useForm();

  const monthsDefaultCheckedAll = [
    { value: 1, label: t('month.1'), days: renderFullDayInMonth(1), checked: true },
    { value: 2, label: t('month.2'), days: renderFullDayInMonth(2), checked: true },
    { value: 3, label: t('month.3'), days: renderFullDayInMonth(3), checked: true },
    { value: 4, label: t('month.4'), days: renderFullDayInMonth(4), checked: true },
    { value: 5, label: t('month.5'), days: renderFullDayInMonth(5), checked: true },
    { value: 6, label: t('month.6'), days: renderFullDayInMonth(6), checked: true },
    { value: 7, label: t('month.7'), days: renderFullDayInMonth(7), checked: true },
    { value: 8, label: t('month.8'), days: renderFullDayInMonth(8), checked: true },
    { value: 9, label: t('month.9'), days: renderFullDayInMonth(9), checked: true },
    { value: 10, label: t('month.10'), days: renderFullDayInMonth(10), checked: true },
    { value: 11, label: t('month.11'), days: renderFullDayInMonth(11), checked: true },
    { value: 12, label: t('month.12'), days: renderFullDayInMonth(12), checked: true },
  ];

  const formatMonths = (months?: MonthInYear[]) => {
    if (!months) {
      return monthsDefaultCheckedAll;
    }
    return [
      { value: 1, label: t('month.1'), days: renderFullDayInMonth(1), checked: false },
      { value: 2, label: t('month.2'), days: renderFullDayInMonth(2), checked: false },
      { value: 3, label: t('month.3'), days: renderFullDayInMonth(3), checked: false },
      { value: 4, label: t('month.4'), days: renderFullDayInMonth(4), checked: false },
      { value: 5, label: t('month.5'), days: renderFullDayInMonth(5), checked: false },
      { value: 6, label: t('month.6'), days: renderFullDayInMonth(6), checked: false },
      { value: 7, label: t('month.7'), days: renderFullDayInMonth(7), checked: false },
      { value: 8, label: t('month.8'), days: renderFullDayInMonth(8), checked: false },
      { value: 9, label: t('month.9'), days: renderFullDayInMonth(9), checked: false },
      { value: 10, label: t('month.10'), days: renderFullDayInMonth(10), checked: false },
      { value: 11, label: t('month.11'), days: renderFullDayInMonth(11), checked: false },
      { value: 12, label: t('month.12'), days: renderFullDayInMonth(12), checked: false },
    ];
  };

  const [state, setState] = React.useState<MonthlyScheduleDataInput>({
    months: formatMonths(currentPlaylistScheduleState?.data?.monthlyData?.months || undefined),
  });

  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [unCheckedAll, setUnCheckedAll] = useState<boolean>(false);
  const [modalDayOfMonthData, setModalDayOfMonthData] = useState<ModalDayOfMonthData>({
    visible: false,
  });
  const [errorTimeInDay, setErrorTimeInDay] = useState<ErrorTimeInDayState>({
    message: '',
    status: false,
  });

  useEffect(() => {
    if (
      currentPlaylistScheduleState?.data?.type === ScheduleType.Monthly &&
      currentPlaylistScheduleState?.data?.monthlyData
    ) {
      const months: any = state.months?.map((month: MonthI) => {
        return {
          ...month,
          days:
            currentPlaylistScheduleState?.data?.monthlyData?.months?.find((m) => m.month === month.value)?.days ||
            month.days,
          checked: currentPlaylistScheduleState?.data?.monthlyData?.months?.find((m) => m.month === month.value)
            ? true
            : false,
        };
      });
      setState({
        ...state,
        months,
      });
    } else {
      setState({
        months: formatMonths(currentPlaylistScheduleState?.data?.monthlyData?.months || undefined),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlaylistScheduleState.data]);

  const handleCheckedAll = () => {
    const monthUnCheckAll = state.months ? [...state.months] : [];
    monthUnCheckAll.map((month) => (month.checked = true));
    setCheckedAll(true);
    setUnCheckedAll(false);
    setState({ ...state, months: monthUnCheckAll });
  };

  const handleUnCheckedAll = () => {
    const monthCheckAll = state.months ? [...state.months] : [];
    monthCheckAll.map((month) => (month.checked = false));
    setCheckedAll(false);
    setUnCheckedAll(true);
    setState({ ...state, months: monthCheckAll });
  };

  const onMonthChange = (isChecked: boolean, month: MonthI) => {
    const months = state.months?.map((m: MonthI) => {
      return {
        ...m,
        checked: month.value === m.value ? isChecked : m.checked,
      };
    });
    setState({ ...state, months });
  };

  useEffect(() => {
    if (state.months) {
      let countCheck = 0;
      state.months?.map((m) => (m.checked ? countCheck++ : null));
      if (countCheck <= 0) {
        setUnCheckedAll(true);
        setCheckedAll(false);
      } else {
        if (countCheck === 12) {
          setCheckedAll(true);
          setUnCheckedAll(false);
        } else {
          setCheckedAll(false);
          setUnCheckedAll(false);
        }
      }
    }
  }, [state.months]);

  useEffect(() => {
    if (currentPlaylistScheduleState.data) {
      form.setFieldsValue({
        startOfDay: currentPlaylistScheduleState.data.monthlyData?.timeInDay
          ? moment(currentPlaylistScheduleState.data.monthlyData?.timeInDay.startOfDay, HOUR_MINUTE)
          : undefined,
        endOfDay: currentPlaylistScheduleState.data.monthlyData?.timeInDay?.endOfDay
          ? moment(currentPlaylistScheduleState.data.monthlyData?.timeInDay?.endOfDay, HOUR_MINUTE)
          : undefined,
      });
    }
  }, [currentPlaylistScheduleState.data, form]);

  const onChooseDay = (values: any) => {
    const months = state.months?.map((m: MonthI) => {
      return {
        ...m,
        days: m.value === modalDayOfMonthData.month?.value ? [...values] : m.days,
      };
    });
    setState({ ...state, months });
  };

  useImperativeHandle(
    ref,
    () => ({
      onUpdatePlaylistScheduleGroup: () => {
        form.submit();
      },
    }),
    // eslint-disable-next-line
    [],
  );

  const setAllDayOfMonth = (month: MonthI) => {
    const months = state.months?.map((m: MonthI) => {
      return {
        ...m,
        days: m.value === month?.value ? renderFullDayInMonth(month.value) : m.days,
      };
    });
    setState({ ...state, months });
  };

  const setAllDayTime = () => {
    form.setFieldsValue({
      startOfDay: moment('00:00', HOUR_MINUTE),
      endOfDay: moment('00:00', HOUR_MINUTE),
    });
  };

  const onFinish = (values: any) => {
    if (state.months) {
      if (!state.months.some((month) => month.checked)) {
        return;
      }
    }
    let timeInDay;
    if (values?.startOfDay && !values?.endOfDay) {
      setErrorTimeInDay({
        status: true,
        message: t('group-device:schedule.validate.required_end_time'),
      });
      return;
    }
    if (!values?.startOfDay && values?.endOfDay) {
      setErrorTimeInDay({
        status: true,
        message: t('group-device:schedule.validate.required_start_time'),
      });
      return;
    }
    if (values?.endOfDay && values?.startOfDay) {
      if (!checkTimeInDay(values?.startOfDay, values?.endOfDay)) {
        setErrorTimeInDay({
          status: true,
          message: t('group-device:schedule.validate.time_in_day'),
        });
        return;
      }
      timeInDay = {
        startOfDay: formatMomentDate(values?.startOfDay, HOUR_MINUTE),
        endOfDay: formatMomentDate(values?.endOfDay, HOUR_MINUTE),
      };
    }
    if (state.months && state.months.length > 0) {
      const months: any = [];
      // eslint-disable-next-line array-callback-return
      state.months.map((month: any, index: number) => {
        if (month.checked) {
          months.push({
            days: month?.days,
            month: month.value,
          });
        }
      });
      const scheduleData: any = {
        playlistId: (currentPlaylistScheduleState?.data && currentPlaylistScheduleState.data.playlistId) || '',
        playlistName: currentPlaylistScheduleState?.data?.playlistName,
        type: ScheduleType.Monthly,
        index: currentPlaylistScheduleState?.data?.index,
        monthlyData: {
          months,
          timeInDay,
        },
      };
      setErrorTimeInDay({
        status: false,
        message: '',
      });
      onUpdatePlaylistScheduleGroupDevice(scheduleData);
      props.onCancel();
    }
  };

  return (
    <div className="schedule-type-content schedule-monthly">
      <Form form={form} onFinish={onFinish}>
        <Row className="mb-4">
          <Col span={24} md={5} className="d-flex align-items-center justify-content-start mb-2 mb-md-0">
            <label className="key">{t('schedule.select_time')}:</label>
          </Col>
          <Col span={24} md={19} className="d-flex align-items-center justify-content-between">
            <OpenCloseTime
              errorTimeInDay={props.errorTimeInDay}
              changeStartOfDay={(value?: string) => {
                form.setFieldsValue({
                  startOfDay: value ? moment(value, HOUR_MINUTE) : undefined,
                });
              }}
              changeEndOfDay={(value?: string) => {
                form.setFieldsValue({
                  endOfDay: value ? moment(value, HOUR_MINUTE) : undefined,
                });
              }}
            />
            <Button
              className="btn-all-day mr-2"
              onClick={() => {
                setAllDayTime();
              }}
            >
              {t('button:all_day')}
            </Button>
          </Col>
          <Col span={24} md={{ span: 19, offset: 5 }} className="d-flex align-items-center justify-content-between">
            {errorTimeInDay?.status && (
              <div className="ant-form-item-explain ant-form-item-explain-error">
                <div role="alert">{errorTimeInDay?.message}</div>
              </div>
            )}
          </Col>
        </Row>
      </Form>
      <Row className="mb-4">
        <Col span={7} className="d-flex align-items-center justify-content-start">
          <label className="key">{t('schedule.select_month')}:</label>
        </Col>
        <Col span={17} className="d-flex align-items-center justify-content-end">
          <div>
            <Checkbox onClick={handleUnCheckedAll} checked={unCheckedAll} className="mr-3">
              {t('schedule.un_check_all')}
            </Checkbox>
            <Checkbox onClick={handleCheckedAll} checked={checkedAll}>
              {t('schedule.check_all')}
            </Checkbox>
          </div>
        </Col>
      </Row>
      <div className="checkbox-group pb-4">
        {state.months && state.months.some((month) => month.checked) ? null : (
          <div className="ant-form-item-explain ant-form-item-explain-error">
            <div role="alert">{t('validate.required')}</div>
          </div>
        )}
        {state.months?.map((month, index: number) => {
          return (
            <div
              className={`checkbox-item w-100 d-flex align-items-center justify-content-between px-2 ${
                state.months && state.months.some((month) => month.checked) ? '' : 'required'
              }`}
              key={index}
            >
              <Checkbox key={index} checked={month.checked} onChange={(e) => onMonthChange(e.target.checked, month)}>
                {t(month.label)}
              </Checkbox>
              <div className="d-flex align-items-center justify-content-center">
                <span className="time font-medium">{`${month.days.length}/${getDaysInMonth(month.value)}`}</span>
                <Button
                  className="btn-set-time mr-4 text-capitalize"
                  onClick={() => {
                    setAllDayOfMonth(month);
                  }}
                  size="small"
                >
                  {t('schedule.all')}
                </Button>
                <Button
                  className="btn-set-time text-capitalize"
                  onClick={() => {
                    setModalDayOfMonthData({
                      month,
                      visible: true,
                    });
                  }}
                  size="small"
                >
                  {t('schedule.select')}
                </Button>
              </div>
            </div>
          );
        })}
      </div>
      <DayOfMonth
        visible={!!modalDayOfMonthData.visible}
        dayOfMonthData={modalDayOfMonthData}
        onChoose={onChooseDay}
        onCancel={() => {
          setModalDayOfMonthData({
            visible: false,
          });
        }}
      />
    </div>
  );
};

export default forwardRef(Monthly1Component);
