import UserAddNewUserPage from './pages/AddNewUser';
// import UserListPage from './pages/ListUser/index';
import UserListV2Page from './pages/ListUser/list';
import UpdateUserAdminPage from './pages/UpdateUserAdmin';

export const UserPageRoute = [
  {
    path: '/user/list',
    exact: true,
    component: UserListV2Page,
  },
  {
    path: '/user/new',
    exact: true,
    component: UserAddNewUserPage,
  },
  {
    path: '/user/:id',
    exact: true,
    component: UpdateUserAdminPage,
  },
];
