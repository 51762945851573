import {
  DailyScheduleData,
  DisplayMode,
  MonthlyScheduleData,
  Playlist,
  ScheduleType,
  SpecialDayScheduleData,
  WeeklyScheduleData,
} from '@/graphql/generated/graphql';

export enum ScheduleTypes {
  Daily = 'Daily',
  Monthly = 'Monthly',
  SpecialDay = 'Special Day',
  Weekly = 'Weekly',
}

export interface PlaylistSchedule {
  dailyData?: DailyScheduleData;
  monthlyData?: MonthlyScheduleData;
  playlist?: Playlist;
  playlistId: string;
  specialDayData?: [SpecialDayScheduleData];
  type?: ScheduleType;
  weeklyData?: WeeklyScheduleData;
  playlistName?: string;
  index?: number;
  createdById?: string;
}

export interface MonthI {
  value: number;
  label: string;
  days: number[];
  checked: boolean;
  // isShowDays: boolean;
  // month: number;
}
export interface WeeklyI {
  value: number;
  label: string;
}

export interface TimeInDayI {
  endOfDay?: string;
  startOfDay?: string;
}

export interface GroupSettingType {
  key: string;
  value: number | DisplayMode;
}

export const GroupSettingDisplayMode: GroupSettingType[] = [
  {
    key: 'fit',
    value: DisplayMode.Fit,
  },
  {
    key: 'fill',
    value: DisplayMode.Fill,
  },
  {
    key: 'stretch',
    value: DisplayMode.Stretch,
  },
];
