import { MediaType } from '@/graphql/generated/graphql';
import IcCalenda from '@assets/images/icons/ic_calendar.svg';
import { getTypeOfFile } from '@modules/Media/helper/file';
import { Col, Form, InputNumber, Row } from 'antd';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';

export default function InputDuration(props: any) {
  const [duration, setValueDuration] = useState<number | undefined>(props?.item?.duration || 5);
  const { t } = useTranslation(['playlist', 'company']);
  const refInputNumber = useRef<any>();

  const debouncedTextChangeHandler = debounce((value) => {
    return props.onUpdateDuration(value);
  }, 400);

  useEffect(() => {
    return () => {};
  }, []);

  const onChangeValue = (event: any) => {
    if (!event) {
      return;
    }
    setValueDuration(event ? event : 0);
    debouncedTextChangeHandler({ _id: props._id, duration: event });
  };

  const onClickAvailability = () => {
    props.onClickAvailability?.();
  };

  const onUpInput = () => {
    const NumberDu = Number((duration || 0).toFixed(4)) + 5;
    setValueDuration(Number(NumberDu.toFixed(4)));
  };

  const onDownInput = () => {
    if (duration && duration > 0 && duration >= 5) {
      const NumberDu = Number((duration || 0).toFixed(4)) - 5;
      setValueDuration(Number(NumberDu.toFixed(4)));
    }
  };

  return (
    <>
      {getTypeOfFile(props?.item?.mimetype || '') !== MediaType.Video ? (
        <Form.Item
          style={{ margin: 0 }}
          className="durationInput"
          name={`input_duration_${props._id}_${props.index}`}
          rules={[
            { required: true, message: t('validate.required') },
            ({ getFieldValue }) => ({
              validator() {
                if (Number(getFieldValue(`input_duration_${props._id}_${props.index}`)) < 0) {
                  return Promise.reject(new Error(t('company:validate.limit_min')));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Row className="row-focus">
            <InputNumber
              onChange={onChangeValue}
              step={5}
              min={0}
              style={{ flex: 1 }}
              onBlur={() => {
                props.onUpdateDuration({ _id: props._id, duration });
              }}
              ref={refInputNumber}
              readOnly={props?.readOnly}
              size="middle"
              placeholder={t('duration')}
              value={duration ?? duration}
              maxLength={5}
            />
            <Col>
              <div className="up-input" onClick={onUpInput}>
                <MdArrowDropUp size={20} />
              </div>
              <div className="down-input" onClick={onDownInput}>
                <MdArrowDropDown size={20} />
              </div>
            </Col>
          </Row>
        </Form.Item>
      ) : (
        <div>{props?.item?.duration}</div>
      )}
      <Row className="mt-2 cursor-pointer" onClick={onClickAvailability}>
        <img src={IcCalenda} alt="calennda" />
        <div className="available" style={{ textTransform: 'capitalize' }}>
          {t('availability')}
        </div>
      </Row>
    </>
  );
}
