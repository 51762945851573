import i18n from '@/i18n';
import { CommonPath } from '@commons/base-routes';
import { NotificationError, NotificationSuccess } from '@commons/components/Notification';
import getError from '@helpers/error';
import { getHistory } from '@helpers/history';
import { QueryClient } from 'react-query';
import env from './env';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        if (
          error?.response?.errors[0]?.extensions.code === '401' ||
          error?.response?.errors[0]?.extensions?.response?.statusCode === 401
        ) {
          localStorage.removeItem(env.tokenKey);
          getHistory().push(CommonPath.LOGIN_PATH);
        } else {
          NotificationError(i18n.t('warning'), getError(error?.response?.errors[0]?.extensions?.code));
        }
      },
    },
    mutations: {
      onError: (error: any) => {
        NotificationError(i18n.t('warning'), getError(error?.response?.errors[0]?.extensions?.code));
        if (
          error?.response?.errors[0]?.extensions.code === '401' ||
          error?.response?.errors[0]?.extensions?.response?.statusCode === 401
        ) {
          localStorage.removeItem(env.tokenKey);
          getHistory().push(CommonPath.LOGIN_PATH);
        }
      },
      onSuccess: (data: any) => {
        NotificationSuccess(i18n.t('notification'), data?.message || 'Update successfully');
      },
    },
  },
});

export default queryClient;
