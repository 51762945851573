import { Device, DeviceStatus, PlaylistScreenType, QueryCompaniesInput, Roles } from '@/graphql/generated/graphql';
import ModalConfirm from '@commons/components/modals/ModalConfirm';
import TableLayout from '@commons/components/TableLayout';
import TableWithNumber from '@commons/components/TableWithNumber';
import { FULL_DATE_TIME_REVERT } from '@helpers/date';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { LoginState } from '@modules/Auth/redux/action-types';
import useGetAllCompany from '@modules/Company/hooks/useGetAllCompany';
import { PageSizeTable } from '@modules/Company/types';
import DeviceRowAction from '@modules/Device/components/Action/DeviceRowAction';
import CellDeviceInfo from '@modules/Device/components/Form/CellTable/CellDeviceInfo';
import AddDeviceModal from '@modules/Device/components/Modal/AddDeviceModal';
import DeviceInfoModal from '@modules/Device/components/Modal/DeviceInfoModal';
import EditDeviceModal from '@modules/Device/components/Modal/EditDeviceModal';
import SettingDeviceModal from '@modules/Device/components/Modal/SettingDeviceModal';
import useClearDeviceStorage from '@modules/Device/hooks/useClearDeviceStorage';
import useDeleteDevice from '@modules/Device/hooks/useDeleteDevice';
import useGetAllDevice from '@modules/Device/hooks/useGetAllDeviceAutoRefetch';
import { DeviceFilter, DeviceStatusTypes } from '@modules/Device/types';
import { RootState } from '@redux/reducers';
import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import TextTruncate from 'react-text-truncate';
import { isDevicePlaylistsReady } from '@helpers/schedule';

interface IState {
  updateDeviceData?: Device;
  deleteDeviceData?: Device;
  deviceInfo?: Device;
  updateDeviceCode?: Device;
  settingDevice?: Device;
  clearStorageDevice?: Device;
}

export default function DeviceListPage() {
  const { t, i18n } = useTranslation(['device']);
  const { setPageTitle } = useSetPageTitle();
  const [visibleAddDevice, setVisibleAddDevice] = useState<boolean>(false);
  const [state, setState] = useState<IState>({
    updateDeviceData: undefined,
    deleteDeviceData: undefined,
    deviceInfo: undefined,
    updateDeviceCode: undefined,
    settingDevice: undefined,
    clearStorageDevice: undefined,
  });
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);
  const { mutate: deleteDevice, isSuccess: deleteDeviceSuccess, isLoading: loadingDelete } = useDeleteDevice();
  const {
    mutate: clearDeviceStorage,
    isSuccess: clearDeviceStorageSuccess,
    isLoading: clearDeviceStorageLoading,
  } = useClearDeviceStorage();

  // const getClickableLink = (link: string) => {
  //   return link.startsWith('http://') || link.startsWith('https://') ? link : `http://${link}`;
  // };

  const getClickablePlaylistLink = (id: string) => {
    const currentUrl = window.location.href;
    const currentDomain = window.location.hostname;
    const domainSplit = currentDomain.split('/')[0];
    return currentUrl.startsWith(`https://`)
      ? `https://${domainSplit}/playlist/detail/${id}`
      : `http://${domainSplit}/playlist/detail/${id}`;
  };

  useEffect(() => {
    moment.locale(i18n.language === 'no' ? 'nb' : i18n.language);
  }, [i18n.language]);

  // STT > Code > Model > Operating System > Name > Screen > Restaurant > Status
  const columns: ColumnsType<any> = [
    {
      title: t('stt'),
      key: '#',
      dataIndex: '#',
      width: 50,
      align: 'center',
    },
    {
      title: t('device_info'),
      key: 'deviceModel',
      dataIndex: 'deviceModel',
      width: 200,
      render: (value: any, record: Device) => <CellDeviceInfo record={record} />,
    },
    {
      title: t('company'),
      key: 'companyName',
      dataIndex: 'companyName',
      width: 120,
      render: (value: any, record: Device) => (
        <div>
          {record.company ? (
            <TextTruncate
              containerClassName="w-100 d-block word-break"
              line={3}
              element="span"
              truncateText="…"
              text={record.company?.name || 'Null'}
            />
          ) : null}
        </div>
      ),
    },
    {
      title: t('group'),
      key: 'group',
      width: 180,
      render: (value: any, record: Device) => {
        return (
          <div className="text-black font-medium word-break">
            {record.group?.name && record.group?.name?.length > 50 ? (
              <TextTruncate
                containerClassName="w-100 d-block"
                line={3}
                element="span"
                truncateText="…"
                text={record.group?.name}
              />
            ) : (
              record.group?.name
            )}
          </div>
        );
      },
    },
    {
      title: t('playlist'),
      key: 'currentPlaylists',
      width: 150,
      render: (value: any, record: Device) => {
        const generateDeviceTime =
          record.lastConnect && record.group
            ? moment
                .utc(record.lastConnect.split('-')[1], FULL_DATE_TIME_REVERT)
                .tz(record.group.timezone)
                .format(FULL_DATE_TIME_REVERT)
            : null;
        const diffDuration = record.lastConnect
          ? moment.utc(record.lastConnect.split('-')[1], FULL_DATE_TIME_REVERT)
          : null;
        const isDeviceOn = record.lastGetInfoAt && moment().valueOf() - 5 * 60 * 1000 - record.lastGetInfoAt <= 0;

        // if ('WS-2344Z9OB' === record?.deviceCode){
        //   console.log('record>>>>>>', isDevicePlaylistsReady(record));
        // }
        return (
          record.status !== DeviceStatus.NoPlaylist && (
            <div className="text-black font-medium word-break cell-device-info">
              {record?.currentPlaylists?.length || !isDevicePlaylistsReady(record) ? (
                <>
                  <ul className="pl-3 mb-0">
                    {loginState.data?.companyId
                      ? record?.currentPlaylists?.map((pl, index: number) => (
                          <li key={index}>
                            <a
                              className="text-2-line"
                              href={getClickablePlaylistLink(pl?._id || '')}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <TextTruncate
                                containerClassName="w-100 d-block"
                                line={2}
                                element="span"
                                truncateText="…"
                                text={pl?.name}
                              />
                            </a>
                          </li>
                        ))
                      : record?.currentPlaylists?.map((pl, index: number) => (
                          <li key={index}>
                            <TextTruncate
                              containerClassName="w-100 d-block"
                              line={2}
                              element="span"
                              truncateText="…"
                              text={pl?.name}
                            />
                          </li>
                        ))}
                  </ul>
                  {record?.lastConnect && record?.group?.timezone ? (
                    <div className="device-time">
                      <li>
                        <Row gutter={6}>
                          <Col>{t('last_connect')}:</Col>
                          <Col className="value">{diffDuration?.fromNow() || generateDeviceTime}</Col>
                          <Col>
                            {t('timezone')}: {record.group.timezone}
                          </Col>
                        </Row>
                      </li>
                    </div>
                  ) : null}
                </>
              ) : record.group?.layoutSettings?.screenType === PlaylistScreenType.FulScreen &&
                record.group.schedules?.length ? (
                isDeviceOn && <Spin />
              ) : (
                record.group?.layoutSettings?.screenType === PlaylistScreenType.SplitScreen &&
                (record.group.schedules?.length || record.group.schedulesSecond?.length) &&
                isDeviceOn && <Spin />
              )}
            </div>
          )
        );
      },
    },
    {
      title: '',
      key: 'action',
      width: 100,
      render: (record: any) => {
        if (loginState.data?.role === Roles.Editor) return null;
        return (
          <DeviceRowAction
            record={record}
            onDeviceInfo={(record) => {
              setState((states) => {
                return { ...states, deviceInfo: record };
              });
            }}
            onDelete={(record) => {
              setState((states) => {
                return { ...states, deleteDeviceData: record };
              });
            }}
            onEdit={(record) => {
              setState((states) => {
                return { ...states, updateDeviceData: record };
              });
            }}
            onSettingDevice={(record) => {
              setState((states) => {
                return { ...states, settingDevice: record };
              });
            }}
            onClearDeviceStorage={(record) => {
              setState((states) => {
                return { ...states, clearStorageDevice: record };
              });
            }}
          />
        );
      },
    },
  ];

  const [paginate, setPaginate] = useState({
    page: 1,
    pageSize: PageSizeTable,
  });

  const [filter, setFilter] = useState<DeviceFilter>({});

  const { isLoading, data } = useGetAllDevice({
    page: paginate.page,
    limit: paginate.pageSize,
    query: filter?.query || undefined,
    os: filter?.os || undefined,
    companyId: filter?.companyId || undefined,
    status: filter?.status || undefined,
  });
  const dataSource = data?.devices?.items?.map((device: any, index) => {
    return {
      ...device,
      status: device?.status ? device?.status : 'NO CONNECTION',
      companyName: device?.company?.name || `${t('repository')}`,
    };
  });

  const [form] = Form.useForm();

  const onSearch = (values: any) => {
    const companyId = values.companyId?.split('/$/')[0];
    const input = {
      query: values.query ? values.query : undefined,
      companyId: companyId || undefined,
      status: values.status ? values.status : undefined,
    };

    if (paginate.page !== 1) {
      setPaginate({
        ...paginate,
        page: 1,
      });
    }
    setFilter(input);
  };

  const { Option } = Select;

  const [filterCompany, setFilterCompany] = useState<QueryCompaniesInput>({
    limit: 8,
    page: 1,
  });

  const { data: listCompany, refetch: getListCompany } = useGetAllCompany(filterCompany);

  const onCancel = () => {
    setVisibleAddDevice(false);
    setState((states) => {
      return {
        ...states,
        updateDeviceData: undefined,
        deleteDeviceData: undefined,
        deviceInfo: undefined,
        updateDeviceCode: undefined,
        settingDevice: undefined,
        clearStorageDevice: undefined,
      };
    });
  };

  const onFilterDeviceByRes = (value?: string) => {
    setFilter({ ...filter, companyId: value ? value.toString().split('/$/')[0] : undefined });
  };

  const renderAdminColumn = useCallback(() => {
    if ((loginState.data?.role === Roles.SuperAdmin || loginState.data?.isSuperAdmin) && !loginState.data?.companyId) {
      return columns;
    }
    return columns.filter((column) => column.key !== 'companyName');
  }, [columns, loginState.data]);

  const onFilterDeviceByStatus = (status?: DeviceStatus) => {
    setFilter({ ...filter, status });
  };

  const onDeleteDevice = () => {
    if (state.deleteDeviceData) {
      deleteDevice({ input: { _id: state.deleteDeviceData._id } });
    }
  };

  const onClearDeviceStorage = () => {
    if (state.clearStorageDevice) {
      clearDeviceStorage({ input: { _id: state.clearStorageDevice._id } });
    }
  };

  useEffect(() => {
    if (clearDeviceStorageSuccess) {
      setState({ ...state, clearStorageDevice: undefined });
    }
    // eslint-disable-next-line
  }, [clearDeviceStorageSuccess]);

  useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t('device'));
    // eslint-disable-next-line
  }, [t]);

  // delete device success, reload list device
  useEffect(() => {
    if (deleteDeviceSuccess) {
      getListCompany();
      setState((state) => {
        return { ...state, deleteDeviceData: undefined };
      });
    }
    // eslint-disable-next-line
  }, [deleteDeviceSuccess]);

  return (
    <div>
      <TableLayout
        tableHeader={
          <Form layout="inline" form={form} onFinish={onSearch} className="d-flex justify-content-start gap-3">
            {!loginState.data?.companyId ? (
              <Form.Item name="companyId">
                <Form.Item shouldUpdate={true} labelCol={{ span: 6 }} name="companyId" style={{ width: 300 }}>
                  <Select
                    style={{ width: '100%', maxWidth: 485 }}
                    showSearch
                    dropdownClassName="mr-0"
                    allowClear
                    onChange={(values) => {
                      if (values) {
                        onFilterDeviceByRes(values.toString());
                      }
                    }}
                    onClear={() => {
                      onFilterDeviceByRes(undefined);
                    }}
                    // searchValue={filterCompany.query?.trim() || ''}
                    filterOption={false}
                    placeholder={t('company')}
                    onSearch={(e) => {
                      return setFilterCompany({
                        ...filterCompany,
                        page: 1,
                        query: e,
                      });
                    }}
                    dropdownRender={(menu) => {
                      return (
                        <React.Fragment>
                          {menu}
                          <Row className="ml-2 mr-2 mt-2 mb-2 justify-content-center">
                            <Col className="mr-2">
                              <Button
                                onMouseDown={() => {
                                  if (filterCompany?.page && filterCompany.page <= 1) {
                                    return;
                                  }
                                  setFilterCompany({
                                    ...filterCompany,
                                    page: filterCompany?.page && filterCompany.page ? filterCompany.page - 1 : 1,
                                  });
                                }}
                              >
                                {t('button:pre')}
                              </Button>
                            </Col>
                            <Col className="mr-2">
                              <Button
                                onMouseDown={() => {
                                  if (
                                    filterCompany.page === listCompany?.companies?.totalPage ||
                                    (listCompany?.companies && listCompany?.companies?.totalPage <= 0)
                                  ) {
                                    return;
                                  }
                                  setFilterCompany({
                                    ...filterCompany,
                                    page: filterCompany?.page && filterCompany.page ? filterCompany.page + 1 : 1,
                                  });
                                }}
                              >
                                {t('button:next')}
                              </Button>
                            </Col>
                            <Col className="mt-1">{`${listCompany?.companies?.currentPage} / ${listCompany?.companies?.totalPage}`}</Col>
                          </Row>
                        </React.Fragment>
                      );
                    }}
                  >
                    {listCompany &&
                      listCompany?.companies &&
                      listCompany?.companies.items?.map((company: any) => (
                        <Option key={company?._id} value={`${company._id}/$/${company.name}`} title={`${company.name}`}>
                          {company.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Form.Item>
            ) : null}
            <Form.Item name="status">
              <Select
                style={{ width: 180 }}
                allowClear
                placeholder={t('status')}
                onChange={(value) => onFilterDeviceByStatus(value as DeviceStatus)}
                onClear={() => onFilterDeviceByStatus(undefined)}
              >
                {DeviceStatusTypes.map((status) => (
                  <Option key={status.key} value={status.key}>
                    {t(`${status.name}`)}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              className={`${
                !loginState.data?.companyId ||
                loginState.data?.role === Roles.SuperAdmin ||
                loginState.data?.isSuperAdmin
                  ? ''
                  : 'mr-0'
              }`}
            >
              <Input.Group compact>
                <Form.Item name="query" className="w-full mr-0">
                  <Input className="bd-form-search" placeholder={`${t('search_device')}...`} />
                </Form.Item>
                <Button icon={<FiSearch color="#F80000" />} className="btn-search" htmlType="submit" />
              </Input.Group>
            </Form.Item>
            {/* {!loginState.data?.companyId ||
            loginState.data?.role === Roles.SuperAdmin ||
            loginState.data?.isSuperAdmin ? (
              <BtnAddNew onClick={() => setVisibleAddDevice(true)} />
            ) : null} */}
          </Form>
        }
      >
        <TableWithNumber
          rowKey={(data) => data._id}
          columns={renderAdminColumn()}
          rowClassName={'table-striped-rows'}
          dataSource={dataSource}
          loading={isLoading}
          pagination={false}
          scroll={{
            x: true,
          }}
          totalTitle={t('device')}
          tableLayout="fixed"
          paginate={{
            pageSize: paginate.pageSize,
            total: data?.devices?.total || 0,
            current: data?.devices?.currentPage || 1,
            onChange: (page) => {
              setPaginate({
                ...paginate,
                page,
              });
            },
          }}
        />
      </TableLayout>
      <AddDeviceModal visible={visibleAddDevice} onCancel={onCancel} />
      <SettingDeviceModal settingDevice={state.settingDevice} onCancel={onCancel} />
      <EditDeviceModal onCancel={onCancel} deviceData={state.updateDeviceData} />
      <ModalConfirm
        isLoading={loadingDelete}
        data={state.deleteDeviceData}
        handleOk={onDeleteDevice}
        handleCancel={onCancel}
        title={t('delete_title')}
        okTitle={t('delete')}
        content={
          <Trans
            i18nKey="delete_content"
            t={t}
            values={{ name: state.deleteDeviceData?.name }}
            components={[<span className="primary_color fw-500" key={''} />]}
          />
        }
      />
      <ModalConfirm
        isLoading={clearDeviceStorageLoading}
        data={state.clearStorageDevice}
        handleOk={onClearDeviceStorage}
        handleCancel={onCancel}
        title={t('clear_device_storage')}
        okTitle={t('confirm')}
        content={
          <Trans
            i18nKey="clear_device_storage_content"
            t={t}
            values={{ name: state.clearStorageDevice?.name }}
            components={[<span className="primary_color fw-500" key={''} />]}
          />
        }
      />
      {!!state.deviceInfo && <DeviceInfoModal onCancel={onCancel} deviceData={state.deviceInfo} />}
    </div>
  );
}
