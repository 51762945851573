/* eslint-disable react-hooks/exhaustive-deps */
import { FileType, Media, MediaStatus } from '@/graphql/generated/graphql';
import { CopyOutlined } from '@ant-design/icons';
import Folder from '@assets/images/Folder.png';
import IconDownload from '@assets/images/icons/ic_download_media.svg';
import IconTrash from '@assets/images/icons/ic_trash_media.svg';
import { NotificationSuccess } from '@commons/components/Notification';
import { formatMomentDate } from '@helpers/date';
import { LoginState } from '@modules/Auth/redux/action-types';
import { allowedMimeImage } from '@modules/Media/helper/file';
import useDeleteMediaItem from '@modules/Media/hooks/useDeleteMediaItem';
import { MediaFileType } from '@modules/Media/pages/components/media-item-grid.component';
import { RootState } from '@redux/reducers';
import { Col, Row } from 'antd';
import axios from 'axios';
import { truncate } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';

interface IFProps {
  media?: Media;
}

function MediaInfoComponent(props: IFProps) {
  const { t } = useTranslation(['media']);

  const { onDeleteItem } = useDeleteMediaItem();

  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);

  const { media } = props;

  function onCopy(): void {
    if (media) {
      const tempInput = document.createElement('input');
      tempInput.value = media?.optimizeUrl || '';
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);
      NotificationSuccess(t('notification'), t('copy_success'));
    }
  }
  function renderContent() {
    if (!media || media.status !== MediaStatus.Done) return <p className="not_media">{t('not_info')}</p>;

    return (
      <div style={{ height: '100%' }}>
        {media?.type === FileType.File ? (
          <>
            <div className="media-info-image d-flex justify-content-center">
              <MediaFileType media={props.media} src={props.media?.optimizeUrl || ''} />
            </div>
            <div className="media-info-content">
              <p className="name-title">{t('info_name')}</p>
              <p className="name-value">{media.filename || ''}</p>
              <div className="view-link-title">
                <p className="name-title view-link-txt">{t('info_link')}</p>
                <CopyOutlined className="view-link-icon" onClick={onCopy} />
              </div>

              <p className="name-value">
                {truncate(props.media?.optimizeUrl || '', {
                  length: 40,
                })}
              </p>

              <p className="name-title">{t('info_size')}</p>
              <p className="name-value">{`${media.size} MB`}</p>

              <p className="name-title">{t('info_type')}</p>
              <p className="name-value">{`${media.mimetype}`}</p>

              <p className="name-title">{t('createdAt')}</p>
              <p className="name-value">{formatMomentDate(media.createdAt)}</p>

              <p className="name-title">{t('updatedAt')}</p>
              <p className="name-value">{media.updatedAt ? formatMomentDate(media.updatedAt) : t('not_edited')}</p>
            </div>
          </>
        ) : (
          <>
            <div className="media-info-image">
              <img src={Folder} className="icon-folder" alt="folder" width={100} />
            </div>
            <div className="media-info-content">
              <p className="name-title">{t('info_name')}</p>
              <p className="name-value">{media.filename || ''}</p>

              <p className="name-title">{t('createdAt')}</p>
              <p className="name-value">{formatMomentDate(media.createdAt)}</p>

              <p className="name-title">{t('updatedAt')}</p>
              <p className="name-value">{media.updatedAt ? formatMomentDate(media.updatedAt) : t('not_edited')}</p>
            </div>
          </>
        )}
      </div>
    );
  }

  const handleDelete = useCallback(() => {
    onDeleteItem(media);
  }, [media]);

  const handleDownload = useCallback(() => {
    if (allowedMimeImage[media?.mimetype || '']) {
      window.open(media?.optimizeUrl || '', '_blank');
      return;
    }
    axios({
      url: `${media?.optimizeUrl}`, //your url
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${media?.filename}`); //or any other extension
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
    });
  }, [media]);

  const renderBottomDownload = useMemo(() => {
    return (
      <Row justify="space-around" className="bottomDownload">
        {media?.type === FileType.File && (
          <Col className="cursor-pointer" style={{ textAlign: 'center' }} onClick={handleDownload}>
            <img src={IconDownload} alt="download" />
            <div className="text-downnload">{t('download')}</div>
          </Col>
        )}
        {(loginState.data?.isSuperAdmin || media?.createdById === loginState.data?._id) && (
          <Col className="cursor-pointer" style={{ textAlign: 'center' }} onClick={handleDelete}>
            <img src={IconTrash} alt="Trash" />
            <div className="text-delete">{t('trash')}</div>
          </Col>
        )}
      </Row>
    );
  }, [media]);

  return (
    <div className="media-info d-none d-md-block">
      <PerfectScrollbar>{renderContent()}</PerfectScrollbar>
      {!!media && renderBottomDownload}
    </div>
  );
}

export default MediaInfoComponent;
