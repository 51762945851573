export interface Media {
  totalUsers: number;
}

export const GET_ALL_MEDIA = 'GET_ALL_MEDIA';
export const GET_ALL_FOLDER = 'GET_ALL_FOLDER';

export const pageDefault = {
  page: 1,
  pageSize: 60,
};
