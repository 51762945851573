import { Button } from 'antd';
import React from 'react';
import { IoIosLogIn } from 'react-icons/io';
import './styles.scss';

interface Props {
  onClick: (e: any) => void;
  classNameContainer?: string;
  isLoading?: boolean;
  title?: string;
  isCollapsed: boolean;
}

const ButtonLoginAsAdmin = (props: Props) => {
  const Login = (e: any) => {
    props.onClick(e);
  };

  return (
    <Button
      className={`px-0 h-100 ${props.classNameContainer}`}
      onClick={Login}
      type="link"
      loading={props.isLoading || false}
    >
      <div className="container_icon d-flex">
        <span className={`title_btn ${props.isCollapsed ? 'd-none' : 'd-flex'}`}>
          {props.title && !props.isCollapsed ? props.title : ''}
        </span>
        <IoIosLogIn color="#fff" size={24} />
      </div>
    </Button>
  );
};

export default ButtonLoginAsAdmin;
