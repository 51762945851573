import React from 'react';
import icon_view from '@assets/images/Preview.svg';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { Button } from 'antd';

interface Props {
  onClick: (e: any) => void;
  classNameContainer?: string;
  isLoading?: boolean;
  title?: string;
}

const ButtonViewBlue = (props: Props) => {
  const { t } = useTranslation(['media']);
  const onDelete = (e: any) => {
    props.onClick(e);
  };

  return (
    <Button className={`btn_view_no_bg px-0 h-100 `} onClick={onDelete} type="link" loading={props.isLoading || false}>
      <div className="container_icon">
        <img className={`icon_btn`} src={icon_view} alt="ic_btn" />
      </div>
      <p className="title_btn">{props.title ? props.title : t('preview')}</p>
    </Button>
  );
};

export default ButtonViewBlue;
