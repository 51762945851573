import { addPlaylistSchedulesGroup, addPlaylistSchedulesSecondGroup } from '@modules/GroupDevice/redux/actions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AddPlaylistScheduleInput } from '../redux/action-types';

const useAddPlaylistScheduleGroup = () => {
  const dispatch = useDispatch();

  const onAddPlaylistScheduleGroupDevice = useCallback((data: AddPlaylistScheduleInput) => {
    dispatch(addPlaylistSchedulesGroup(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddPlaylistScheduleSecondGroupDevice = useCallback((data: AddPlaylistScheduleInput) => {
    dispatch(addPlaylistSchedulesSecondGroup(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onAddPlaylistScheduleGroupDevice,
    onAddPlaylistScheduleSecondGroupDevice,
  };
};

export default useAddPlaylistScheduleGroup;
