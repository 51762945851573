import { Media } from '@/graphql/generated/graphql';

/**
 * Reorder
 */
// const reorder = (list: any, startIndex: any, endIndex: any) => {
//   const result = Array.from(list);
//   const [removed] = result.splice(startIndex, 1);
//   result.splice(endIndex, 0, removed);

//   return result;
// };

/**
 * With new task ids
 */
const withNewTaskIds = (column: any, taskIds: any) => taskIds;

/**
 * Reorder single drag
 */
const reorderSingleDrag = ({ entities, selectedTaskIds, source, destination }: any) => {
  // moving in the same list

  // moving to a new list
  const home = entities[Number(source.droppableId - 1)];
  const foreign = entities[Number(destination.droppableId - 1)];

  // the id of the task to be moved
  const taskId = home[source.index];

  // remove from home column
  const newHomeTaskIds = [...home];
  newHomeTaskIds.splice(source.index, 1);

  // add to foreign column
  const newForeignTaskIds = [...foreign];
  newForeignTaskIds.splice(destination.index, 0, taskId);

  return {
    entities: [withNewTaskIds(home, newHomeTaskIds), withNewTaskIds(foreign, newForeignTaskIds)],
  };
};

/**
 * Get home column
 */
export const getHomeColumn = (entities: [Media[], Media[]], taskId: any, index?: number) => {
  return entities[index || 0];
};

/**
 * Reorder multi drag
 */
const reorderMultiDrag = ({ entities, selectedTaskIds, source, destination }: any) => {
  const start = entities[Number(source.droppableId - 1)];
  const dragged = start[source.index];

  const insertAtIndex = (() => {
    const destinationIndexOffset = selectedTaskIds.reduce((previous: any, current: any) => {
      if (current === dragged._id) {
        return previous;
      }

      const final = entities[Number(destination.droppableId) - 1];
      const column = entities[0];

      if (column !== final) {
        return previous;
      }

      const index = column.indexOf(current);

      if (index >= destination.index) {
        return previous;
      }

      // the selected item is before the destination index
      // we need to account for this when inserting into the new location
      return previous + 1;
    }, 0);

    const result = destination.index - destinationIndexOffset;
    return result;
  })();

  // doing the ordering now as we are required to look up columns
  // and know original ordering
  const orderedSelectedTaskIds = entities[0].filter((e: any) => selectedTaskIds.includes(e._id));

  const final = entities[Number(destination.droppableId - 1)];

  const withInserted = (() => {
    const base = [...final];
    base.splice(insertAtIndex, 0, ...orderedSelectedTaskIds);
    return base;
  })();

  return {
    entities: [entities[0], [...withInserted]],
    selectedTaskIds: orderedSelectedTaskIds,
  };
};

/**
 * Mutli drag aware reorder
 */
export const mutliDragAwareReorder = (args: any) => {
  if (args.selectedTaskIds.length > 1) {
    return reorderMultiDrag(args);
  }

  return reorderSingleDrag(args);
};

/**
 * Multi select to
 */
export const multiSelectTo = (entities: any, selectedTaskIds: any, newTaskId: any) => {
  // Nothing already selected
  if (!selectedTaskIds.length) {
    return [newTaskId];
  }

  const columnOfNew = getHomeColumn(entities, newTaskId);
  const indexOfNew = columnOfNew.indexOf(newTaskId);

  const lastSelected = selectedTaskIds[selectedTaskIds.length - 1];
  const columnOfLast = getHomeColumn(entities, lastSelected);
  const indexOfLast = columnOfLast.indexOf(lastSelected);

  // multi selecting to another column
  // select everything up to the index of the current item
  if (columnOfNew !== columnOfLast) {
    return columnOfNew.slice(0, indexOfNew + 1);
  }

  // multi selecting in the same column
  // need to select everything between the last index and the current index inclusive

  // nothing to do here
  if (indexOfNew === indexOfLast) {
    return null;
  }

  const isSelectingForwards = indexOfNew > indexOfLast;
  const start = isSelectingForwards ? indexOfLast : indexOfNew;
  const end = isSelectingForwards ? indexOfNew : indexOfLast;

  const inBetween = columnOfNew.slice(start, end + 1);

  // everything inbetween needs to have it's selection toggled.
  // with the exception of the start and end values which will always be selected

  const toAdd = inBetween.filter((taskId: any) => {
    // if already selected: then no need to select it again
    if (selectedTaskIds.includes(taskId)) {
      return false;
    }
    return true;
  });

  const sorted = isSelectingForwards ? toAdd : [...toAdd].reverse();
  const combined = [...selectedTaskIds, ...sorted];

  return combined;
};
