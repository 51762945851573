/* eslint-disable react-hooks/exhaustive-deps */
import { GroupStatus, Playlist, PlaylistChangeGroupAction, QueryGroupsInput } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import icon_close from '@assets/images/icons/icon_close_gray.svg';
import ButtonCancel from '@commons/components/modals/Button/ButtonCancel';
import ButtonOk from '@commons/components/modals/Button/ButtonOk';
import { NotificationError, NotificationSuccess } from '@commons/components/Notification';
import { LoginState } from '@modules/Auth/redux/action-types';
import { LocationInput } from '@modules/Company/types';
import useGetAllGroupPage from '@modules/Media/hooks/useGetAllGroupPage';
import { updatePlaylistGroupApi } from '@modules/PlayList/services/apis';
import { RootState } from '@redux/reducers';
import { Button, Empty, Form, Input, Modal, Row, Spin } from 'antd';
import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useSelector } from 'react-redux';

export enum ViewAssign {
  PLAYLIST = 'PLAYLIST',
  GROUP = 'GROUP',
}

export interface SetOtherGroupI {
  groupId: string;
  action: PlaylistChangeGroupAction;
}

interface Props {
  data?: LocationInput;
  visible?: boolean;
  isEdit?: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
  view?: ViewAssign;
  onSetOtherGroup?: (input: SetOtherGroupI, playlistId?: string) => void;
}

const ModalAssignGroup = (props: Props, ref: any) => {
  // const { isEdit } = props;
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);

  const [form] = Form.useForm();

  const { t } = useTranslation(['playlist', 'button', 'dashboard']);

  const [visible, setVisible] = useState(false);

  const [loadingGroup, setLoading] = useState(false);

  const [ItemSelect, setItemSelect] = useState<Playlist | undefined>();

  const [value, setValue] = useState<string>();

  const { data, loading, onGetGroups, maxData } = useGetAllGroupPage();

  const onSelectItem = (value: string) => () => {
    setValue(value);
  };

  const [filterGroups, setFilterGroups] = useState<QueryGroupsInput>({
    companyId: loginState.data?.companyId || undefined,
    limit: 20,
    page: 1,
    status: [GroupStatus.Approved, GroupStatus.Pending, GroupStatus.Rejected, GroupStatus.WaitToApprove],
  });

  const onShow = (item: Playlist) => {
    setVisible(true);
    setItemSelect(item);
    setValue(item.group?._id);
  };

  useImperativeHandle(ref, () => ({ onShow, onCancel }), []);

  useEffect(() => {
    if (filterGroups) {
      onGetGroups(filterGroups);
    }
  }, [filterGroups]);

  const handleOk = () => {
    AssignGroup();
  };

  const handleChangeGroup = (action: PlaylistChangeGroupAction) => {
    if (value) {
      return props?.onSetOtherGroup && props?.onSetOtherGroup({ groupId: value, action }, ItemSelect?._id);
    }
  };

  const AssignGroup = async () => {
    if (!value || !ItemSelect) {
      return;
    }
    setLoading(true);
    try {
      const result = await updatePlaylistGroupApi({ _id: ItemSelect!._id, groupId: value });
      if (result.updatePlaylistGroup) {
        props.onSubmit?.();
        setVisible(false);
        setLoading(false);
        setItemSelect(undefined);
        NotificationSuccess(i18n.t('notification'), 'assign group success');
      } else {
        setLoading(false);
        NotificationError(t('warring'), 'has an error');
      }
    } catch (error) {
      setLoading(false);
      NotificationError(t('warring'), 'has an error');
    }
  };

  const onCancel = () => {
    setVisible(false);
    setItemSelect(undefined);
  };

  const onSearch = (values: any) => {
    setFilterGroups({
      ...filterGroups,
      query: values.query,
    });
  };

  const fetchData = useCallback(
    (e: any) => {
      if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight - 0.5) {
        if (data?.items) {
          if (maxData) return;

          setFilterGroups({
            ...filterGroups,
            page: (filterGroups.page || 0) + 1,
          });
        }
      }
    },
    [filterGroups, data, maxData],
  );

  if (!visible) return null;

  return (
    <Modal
      centered
      visible={visible}
      footer={
        <div className="row-btn">
          <ButtonCancel key={'btn_cancel'} title={t('cancel')} classNameBtn={``} onClick={onCancel} />
          {!ItemSelect?.group && (
            <ButtonOk isLoading={loadingGroup} key={'btn_ok'} onClick={() => handleOk()} title={t('submit')} />
          )}

          {!!ItemSelect?.group && (
            <ButtonOk
              isLoading={loadingGroup}
              key={'btn_move'}
              onClick={() => handleChangeGroup(PlaylistChangeGroupAction.Move)}
              title={t('button:move')}
            />
          )}
          {!!ItemSelect?.group && (
            <ButtonOk
              isLoading={loadingGroup}
              key={'btn_coppy'}
              onClick={() => handleChangeGroup(PlaylistChangeGroupAction.Copy)}
              title={t('button:copy')}
            />
          )}
        </div>
      }
      closeIcon={null}
      closable={false}
      focusTriggerAfterClose
      onCancel={() => {
        onCancel();
        props.onCancel?.();
      }}
      className="modal-assign-group"
    >
      <div className="container_view">
        <Row justify="space-between" className="header-container">
          <div className="title">{props.view === ViewAssign.GROUP ? t('set_to_other_group') : t('set_to_group')}</div>
          <img
            src={icon_close}
            className="ic_close cursor-pointer"
            alt="ic_close"
            width={14}
            height={14}
            onClick={onCancel}
          />
        </Row>
        <Form form={form} onFinish={onSearch} className="form-input">
          <Row justify="space-between" className="header-container-input">
            <Form.Item name={'query'} className="w-full mr-0" style={{ flex: 1, margin: 0 }}>
              <Input
                style={{ width: '100%', paddingRight: 50, background: 'transparent' }}
                placeholder={`${t('search')}`}
                className=""
              />
            </Form.Item>
            <Button
              icon={<FiSearch color="#F80000" />}
              className="btn-search d-flex align-items-center justify-content-center"
              style={{ marginLeft: -40, background: '#FFE5E5' }}
              htmlType="submit"
            />
          </Row>
          <div className="total-group">
            <strong className="total-group-num">{data?.total || 0}</strong>
            {(data?.total || 0) > 1 ? t('dashboard:groups') : t('dashboard:group')}
          </div>
        </Form>
        <div className="contaier-body">
          <div className="height-body" onScroll={fetchData}>
            {loading ? (
              <Row justify="center" className="cursor-pointer row-item">
                <Spin style={{ textAlign: 'center' }} />
              </Row>
            ) : data?.items?.length === 0 ? (
              <Empty />
            ) : (
              <InfiniteScroll
                className="uk-animation-fade"
                dataLength={(data?.items?.length || 1) + 1}
                next={() => {}}
                hasMore={!!data?.hasMore}
                loader={<h4>Loading...</h4>}
                style={{ paddingBottom: 20 }}
              >
                {data?.items?.map((e) => {
                  return (
                    <Row
                      key={e._id}
                      justify="space-between"
                      align="middle"
                      className="cursor-pointer row-item"
                      onClick={onSelectItem(e._id)}
                    >
                      <div style={{ flex: 1, marginRight: 5 }}>{e?.name}</div>
                      <div className={`radio-parent${value === e._id ? '-active' : ''}`}>
                        <div className={`active-rounded${value === e._id ? '-active' : ''}`} />
                      </div>
                    </Row>
                  );
                })}
              </InfiniteScroll>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(React.forwardRef(ModalAssignGroup));
