import { Group } from '@/graphql/generated/graphql';
import ButtonApproveGreen from '@commons/components/ButtonRowActions/ButtonApproveGreen';
// import ButtonGroupSetting from '@commons/components/ButtonRowActions/ButtonGroupSetting';
import ButtonRejectRed from '@commons/components/ButtonRowActions/ButtonRejectRed';
import ButtonViewBlue from '@commons/components/ButtonRowActions/ButtonViewBlue';
import * as React from 'react';

interface GroupPendingRowActionProp {
  record: Group;
  onView?(pck: Group): void;
  onReject?(pck: Group): void;
  onApprove?(pck: Group): void;
}

export default function GroupPendingRowAction(props: GroupPendingRowActionProp) {
  // const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);

  // const menu = (
  //   <Menu className="dropdown_more_row_actions">
  //     {loginState.data?.companyId && (
  //       <>
  //         <Menu.Item key={'group_message'}>
  //           <ButtonGroupMessageSetting group={props.record} />
  //         </Menu.Item>
  //         {/* <Menu.Item key={'group_setting'}>
  //           <ButtonGroupSetting group={props.record} />
  //         </Menu.Item> */}
  //       </>
  //     )}
  //   </Menu>
  // );

  return (
    <div onClick={(e) => e.stopPropagation()} className={`d-flex justify-content-end`}>
      <ButtonViewBlue
        onClick={() => {
          return props.onView && props.onView(props.record);
        }}
      />

      <ButtonApproveGreen
        onClick={() => {
          return props.onApprove && props.onApprove(props.record);
        }}
      />

      {/* <Dropdown overlay={menu} trigger={['click']} placement="bottomRight"> */}
      <ButtonRejectRed
        onClick={() => {
          return props.onReject && props.onReject(props.record);
        }}
      />
      {/* </Dropdown> */}
    </div>
  );
}
