import React, { useState } from 'react';
import { Form, Input, Button, Modal } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined, ApartmentOutlined } from '@ant-design/icons';
import { FormProps } from 'antd/es/form';
import { RegisterInput } from '@modules/Auth/redux/action-types/register';
import { Link } from 'react-router-dom';
import { CommonPath } from '@commons/base-routes';
import './style.scss';
import { getAppName } from '@helpers/info';
import TermDetail from '@commons/components/TermsDetail';
import { BigLogo } from '@commons/components/logos/BigLogo';
import { __ } from '@helpers/string';
import ModalTitle from '@commons/components/modals/Title';
import '../../../Login/styled/LoginForm/index.scss';

interface Props extends FormProps {
  onRegister(data: RegisterInput): void;
  loading: boolean;
}

const RegisterForm = (props: Props) => {
  const onFinish = (values: any) => {
    props.onRegister(values);
  };

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className="login-container">
      <div className="login-content register-content">
        <BigLogo canDirect={false} collapsed={false} />
        <Form name="login-form" className="login-form" onFinish={onFinish}>
          <Input.Group compact>
            <Form.Item
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
              name="firstName"
              rules={[
                { required: true, message: __('Trường này là bắt buộc') },
                { whitespace: true, message: __('Trường này là bắt buộc') },
              ]}
            >
              <Input
                autoFocus={true}
                autoComplete="new-password"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder={__('Tên')}
              />
            </Form.Item>
            <Form.Item
              style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginLeft: '16px' }}
              name="lastName"
              rules={[
                { required: true, message: __('Trường này là bắt buộc') },
                { whitespace: true, message: __('Trường này là bắt buộc') },
              ]}
            >
              <Input autoComplete="new-password" placeholder={__('Họ')} />
            </Form.Item>
          </Input.Group>

          <Form.Item
            name="email"
            rules={[
              { required: true, message: __('Trường này là bắt buộc') },
              { type: 'email', message: __('Email không hợp lệ') },
            ]}
          >
            <Input
              autoComplete="new-password"
              prefix={<MailOutlined className="site-form-item-icon" />}
              type="email"
              placeholder="Email"
              autoCorrect="off"
              autoCapitalize="none"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: __('Trường này là bắt buộc'),
              },
              {
                min: 6,
                message: __('Mật khẩu của bạn phải có 6 ký tự trở lên'),
              },
              { whitespace: true, message: __('Trường này là bắt buộc') },
            ]}
          >
            <Input
              type="password"
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder={__('Mật khẩu')}
            />
          </Form.Item>

          <Form.Item
            name="passwordConfirm"
            dependencies={['password']}
            rules={[
              { required: true, message: __('Vui lòng nhập xác nhận mật khẩu của bạn!') },
              ({ getFieldValue }) => ({
                validator(rule: any, value: any) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(__('Mật khẩu của bạn và mật khẩu xác nhận phải khớp nhau!'));
                },
              }),
            ]}
          >
            <Input
              type="password"
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder={__('Xác nhận mật khẩu')}
            />
          </Form.Item>

          <Form.Item name="organizationName">
            <Input
              autoComplete="new-password"
              prefix={<ApartmentOutlined className="site-form-item-icon" />}
              type="text"
              placeholder={__('Tên tổ chức')}
            />
          </Form.Item>

          <Form.Item className="login-form-submit" style={{ marginTop: '2rem' }}>
            <Button loading={props.loading} htmlType="submit" className="btn-dangerous login-form-button">
              {__('Tạo tài khoản')}
            </Button>
          </Form.Item>

          <div className="text-center">
            {__('Bằng cách đăng ký, bạn đồng ý với')}{' '}
            <span className="term-link" onClick={toggleModal}>
              {__('Điều khoản và chính sách bảo mật')} của {getAppName()}
            </span>
          </div>
        </Form>
      </div>
      <Link to={CommonPath.LOGIN_PATH}>
        <span>{__('Đăng nhập')}</span>
      </Link>
      <Modal
        title={<ModalTitle title="Điều khoản và chính sách bảo mật" />}
        visible={showModal}
        onOk={toggleModal}
        onCancel={toggleModal}
        footer={[
          <Button key="submit" className="btn-dangerous" onClick={toggleModal}>
            {__('Đồng ý')}
          </Button>,
        ]}
      >
        <TermDetail />
      </Modal>
    </div>
  );
};

export default RegisterForm;
