import { PlaylistScreenType, QueryGroupPlaylistsInput, Roles, ScheduleType } from '@/graphql/generated/graphql';
import { PlusOutlined } from '@ant-design/icons';
import icon_calendar from '@assets/images/icons/icon_calendar.svg';
import icon_calendar_check from '@assets/images/icons/icon_calendar_check.svg';
import ButtonDeleteRed from '@commons/components/ButtonRowActions/ButtonDeleteRed';
import ButtonEditBlue from '@commons/components/ButtonRowActions/ButtonEditBlue';
import ButtonOk from '@commons/components/modals/Button/ButtonOk';
import { FULL_DATE } from '@helpers/date';
import { LoginState } from '@modules/Auth/redux/action-types';
import useAddCurrentPlaylistSchedule from '@modules/GroupDevice/hooks/useAddCurrentPlaylistSchedule';
import useAddPlaylistScheduleGroup from '@modules/GroupDevice/hooks/useAddPlaylistScheduleGroup';
import useGetGroupPlaylist from '@modules/GroupDevice/hooks/useGetGroupPlaylist';
import useRemovePlaylistScheduleGroup from '@modules/GroupDevice/hooks/useRemovePlaylistScheduleGroup';
import useUpdateCurrentGroup from '@modules/GroupDevice/hooks/useUpdateCurrentGroup';
import useUpdatePlaylistToOtherGroup from '@modules/GroupDevice/hooks/useUpdatePlaylistToOtherGroup';
import { GroupDeviceModuleState } from '@modules/GroupDevice/redux/reducers';
import { CurrentGroupState } from '@modules/GroupDevice/redux/reducers/current_group';
import { PlaylistSchedule } from '@modules/GroupDevice/types';
import { RootState } from '@redux/reducers';
import { Button, Card, Col, Empty, Form, Popconfirm, Row, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import EditPlaylist from '../Drawer/EditPlaylist';
import AssignPlayListModal, { SetOtherGroupI } from '../Modal/AssignPlayListModal';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { reorder } from '@modules/PlayList/components/PlaylistContent/ListFrame';
import useUpdateScheduleGroupDevice from '@modules/GroupDevice/hooks/useUpdateScheduleGroup';

interface ScheduleLeftProps {
  onSelectedPlaylistSchedule(playlist: PlaylistSchedule | undefined): void;
  currentPlaylistSchedule?: PlaylistSchedule;
}

const { Option } = Select;

const SchedulePlaylist = (props: ScheduleLeftProps) => {
  const { t } = useTranslation(['group-device', 'notification']);
  const { onUpdateCurrentGroupActiveScreen } = useUpdateCurrentGroup();
  const [openAssignModal, setOpenAssignModal] = useState(false);

  const currentGroupState = useSelector<RootState, CurrentGroupState>((state) => state?.groupDevice.currentGroup);
  const loginState = useSelector<RootState, LoginState>((state) => state?.auth.loginState);

  const renderSchedulePlaylistTime = (record: PlaylistSchedule) => {
    let html: string = '';
    let length: any = 0;
    let timeInDay: string = '';
    let dateRange: string = '';
    const listTimeInDay: string[] = [];

    switch (record.type) {
      case ScheduleType.Monthly:
        length = record.monthlyData?.months?.length;
        record.monthlyData?.months?.map((month, index) => {
          html += index < Number(length) - 1 ? `${t(`month.${month.month}`)}, ` : `${t(`month.${month.month}`)} `;
        });
        if (record?.monthlyData?.timeInDay) {
          timeInDay = `${record.monthlyData?.timeInDay?.startOfDay} - ${record.monthlyData?.timeInDay?.endOfDay}`;
        }
        break;
      case ScheduleType.Weekly:
        length = record.weeklyData?.daysOfWeek?.length;
        record.weeklyData?.daysOfWeek?.map((w, index) => {
          html += index < Number(length) - 1 ? `${t(`week.${w}`)}, ` : `${t(`week.${w}`)} `;
        });
        if (record.weeklyData?.dateRange) {
          dateRange = `${moment(record.weeklyData?.dateRange?.dateFrom || '', FULL_DATE).format(FULL_DATE)} - ${moment(
            record.weeklyData?.dateRange?.dateTo || '',
            FULL_DATE,
          ).format(FULL_DATE)} ${timeInDay}`;
        }
        if (record?.weeklyData?.timeInDay) {
          timeInDay = `${record.weeklyData?.timeInDay?.startOfDay} - ${record.weeklyData?.timeInDay?.endOfDay}`;
        }
        break;
      case ScheduleType.Daily:
        if (record.dailyData?.dateRange) {
          dateRange = `${moment(record.dailyData?.dateRange?.dateFrom || '', FULL_DATE).format(FULL_DATE)}- ${moment(
            record.dailyData?.dateRange?.dateTo || '',
            FULL_DATE,
          ).format(FULL_DATE)} ${timeInDay}`;
        }
        if (record.dailyData?.timeInDay) {
          timeInDay = `${record.dailyData?.timeInDay?.startOfDay} - ${record.dailyData?.timeInDay?.endOfDay}`;
        }
        break;
      case ScheduleType.SpecialDay:
        if (record?.specialDayData) {
          record?.specialDayData?.slice(0, 2).map((item) => {
            listTimeInDay.push(
              `${item.name ? `<span style="font-weight: 500">${item.name}:</span>` : ''}${
                item.date ? moment(item.date, FULL_DATE).format(FULL_DATE) : ''
                // tslint:disable-next-line:prefer-template
              }${item.toDate ? '- ' + moment(item.toDate, FULL_DATE).format(FULL_DATE) : ''}${`<span class="pl-3">${
                item?.timeInDay?.startOfDay ? `${item?.timeInDay?.startOfDay} - ` : ''
              } ${item?.timeInDay?.endOfDay ? item?.timeInDay?.endOfDay : ''}</span>`}`,
            );
          });
          if (record?.specialDayData.length > 2) {
            listTimeInDay.push('......');
          }
        }
        break;

      default:
        break;
    }
    return (
      <>
        {html && <p className="mb-0">{html}</p>}
        {record.type === ScheduleType.SpecialDay
          ? listTimeInDay.map((item, index: number) => {
              return (
                <div
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: item,
                  }}
                />
              );
            })
          : (dateRange || timeInDay) && (
              <div>
                {dateRange && (
                  <>
                    {/* <span style={{ color: 'rgb(176 174 174)' }}>{t('schedule.date')}: </span> */}
                    <span style={{ marginRight: 15 }}>{dateRange}</span>
                  </>
                )}
                {timeInDay && (
                  <>
                    {/* <span style={{ color: 'rgb(176 174 174)' }}>{t('schedule.time')}: </span> */}
                    <span>{timeInDay}</span>
                  </>
                )}
              </div>
            )}
      </>
    );
  };

  const { onAddCurrentPlaylistSchedule } = useAddCurrentPlaylistSchedule();

  const renderTextScheduleType = (type: ScheduleType | undefined) => {
    let text: string = '';
    switch (type) {
      case ScheduleType.Daily:
        text = t('schedule.type.daily');
        break;
      case ScheduleType.Monthly:
        text = t('schedule.type.monthly');
        break;
      case ScheduleType.SpecialDay:
        text = t('schedule.type.special_day');
        break;
      case ScheduleType.Weekly:
        text = t('schedule.type.weekly');
        break;
      default:
        break;
    }
    return text;
  };

  const [selectForm] = Form.useForm();
  const [selectFormScreen2] = Form.useForm();
  const scheduleDeviceGroupState = useSelector<RootState, GroupDeviceModuleState>((state) => state?.groupDevice);

  const [filterGroupPlaylist, setFilterGroupPlaylist] = useState<QueryGroupPlaylistsInput>({
    page: 1,
    limit: 10,
    groupId: currentGroupState.data?._id || '',
  });
  const [currentPlayList, setCurrentPlayList] = useState<PlaylistSchedule[]>([]);
  const [editPlaylist, seEditPlaylist] = useState<string>('');
  const [currentScheduleSecondPlayList, setCurrentScheduleSecondPlayList] = useState<PlaylistSchedule[]>([]);
  const [currentSchedulePlaylist, setCurrentSchedulePlaylist] = useState<PlaylistSchedule | undefined>();
  const { onRemovePlaylistScheduleGroup } = useRemovePlaylistScheduleGroup();
  const { onAddPlaylistScheduleGroupDevice, onAddPlaylistScheduleSecondGroupDevice } = useAddPlaylistScheduleGroup();
  const {
    onUpdateScheduleGroupDeviceSuccess,
    onUpdateScheduleSecondGroupDeviceSuccess,
  } = useUpdateScheduleGroupDevice();
  const { mutate } = useUpdatePlaylistToOtherGroup();

  useEffect(() => {
    if (scheduleDeviceGroupState.scheduleGroupDevice) {
      setCurrentPlayList(scheduleDeviceGroupState.scheduleGroupDevice.data);
      setCurrentScheduleSecondPlayList(scheduleDeviceGroupState.scheduleGroupDevice.schedulesSecond);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleDeviceGroupState.scheduleGroupDevice]);

  useEffect(() => {
    setCurrentSchedulePlaylist(props.currentPlaylistSchedule);
  }, [props.currentPlaylistSchedule]);

  useEffect(() => {
    if (currentGroupState.data?._id) {
      setFilterGroupPlaylist({
        ...filterGroupPlaylist,
        groupId: currentGroupState.data?._id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGroupState?.data?._id]);

  // eslint-disable-next-line
  const { data: groupPlaylists } = useGetGroupPlaylist(filterGroupPlaylist);

  const removeCurrentPlaylistItem = (playlist: any, type: number) => {
    // nếu playlist đang xóa trùng với playlist đang active thì set lại item active rỗng
    if (currentSchedulePlaylist?.playlistId === playlist.playlistId) {
      props.onSelectedPlaylistSchedule(undefined);
    }
    // // xóa playlist khỏi table
    // setCurrentPlayList(
    //   currentPlayList.filter((item) => {
    //     return item.playlistId !== playlist.playlistId;
    //   }),
    // );
    onRemovePlaylistScheduleGroup({ playlistId: playlist?.playlistId, index: playlist.index }, type);
  };

  const onFinishSelect = (value: any) => {
    const createdById = groupPlaylists?.getGroupPlaylists?.items?.find(
      (item) => item._id === value?.playlists?.split('$')[0],
    )?.createdById;
    if (value.playlists)
      onAddPlaylistScheduleGroupDevice({
        playlistId: value.playlists.split('$')[0],
        playlistName: value.playlists.split('$')[1],
        type: ScheduleType.Daily,
        createdById,
      });

    selectForm.resetFields(['playlists']);
  };

  const onFinishSelect2 = (value: any) => {
    const createdById = groupPlaylists?.getGroupPlaylists?.items?.find(
      (item) => item._id === value?.playlists?.split('$')[0],
    )?.createdById;

    if (value.playlists)
      onAddPlaylistScheduleSecondGroupDevice({
        playlistId: value.playlists.split('$')[0],
        playlistName: value.playlists.split('$')[1],
        type: ScheduleType.Daily,
        createdById,
      });
    selectFormScreen2.resetFields(['playlists']);
  };

  // eslint-disable-next-line
  const columns1: ColumnsType<any> = [
    {
      title: t('stt'),
      width: 100,
      key: 'stt',
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: t('schedule.playlist').toLocaleUpperCase(),
      width: 600,
      render: (record) => {
        return (
          <div>
            <p className="mb-1 font-18 playlist-name">{record?.playlist?.name || record.playlistName}</p>
            <div className="d-flex flex-column align-items-start justify-content-start">
              <div className="d-flex align-items-center justify-content-start mb-1">
                <span className="text-red d-flex align-items-center justify-content-start mr-1">
                  <img src={icon_calendar_check} alt="icon_calendar_check" className="mr-2" /> {t('schedule.schedule')}{' '}
                  <span className="ml-1">({renderTextScheduleType(record.type)})</span>
                </span>
              </div>
              <div className="time">{renderSchedulePlaylistTime(record)}</div>
            </div>
          </div>
        );
      },
    },
    {
      title: t('schedule.schedule'),
      width: 150,
      key: 'schedule',
      render: (text, record, index) => {
        return (
          <Button
            className="d-flex align-items-center justify-content-center btn-schedule"
            icon={<img src={icon_calendar} alt="icon_calendar" className="mr-2" />}
            onClick={() => {
              setCurrentSchedulePlaylist(record);
              props.onSelectedPlaylistSchedule(record);
              onAddCurrentPlaylistSchedule(record);
            }}
          >
            {t('schedule.schedule')}
          </Button>
        );
      },
    },
    {
      title: t('action').toLocaleUpperCase(),
      key: 'action',
      width: 150,
      render: (record) => {
        return (
          <div className="d-flex align-items-center justify-content-end pr-5">
            {(loginState &&
              loginState.data &&
              loginState.data._id === record?.createdById &&
              loginState.data?.role === Roles.Editor) ||
            loginState.data?.role === Roles.SuperAdmin ||
            loginState.data?.role === Roles.Admin ? (
              <ButtonEditBlue
                onClick={() => {
                  seEditPlaylist(record?.playlistId);
                }}
                showTitle={false}
                classNameContainer={'ml-0'}
              />
            ) : null}
            <Popconfirm
              title={t('delete_title')}
              okText={t('ok')}
              cancelText={t('cancel')}
              style={{ padding: '10px 20px' }}
              overlayInnerStyle={{ padding: '10px 10px', background: 'white' }}
              onConfirm={() => {
                removeCurrentPlaylistItem(record, 1);
              }}
            >
              {/* <img style={{ width: 15, marginLeft: 15 }} src={icon_close} alt="icon close" className="cursor-pointer" /> */}
              <ButtonDeleteRed onClick={() => {}} showTitle={false} />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  //fix tam thời
  // eslint-disable-next-line
  const columns2: ColumnsType<any> = [
    {
      title: t('stt'),
      width: 100,
      key: 'stt',
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: t('schedule.playlist').toLocaleUpperCase(),
      width: 600,
      render: (record) => {
        return (
          <div>
            <p className="mb-1 font-18 playlist-name">{record?.playlist?.name || record.playlistName}</p>
            <div className="d-flex flex-column align-items-start justify-content-start">
              <div className="d-flex align-items-center justify-content-start mb-1">
                <span className="text-red d-flex align-items-center justify-content-start mr-1">
                  <img src={icon_calendar_check} alt="icon_calendar_check" className="mr-2" /> {t('schedule.schedule')}{' '}
                  <span className="ml-1">({renderTextScheduleType(record.type)})</span>
                </span>
              </div>
              <div className="time">{renderSchedulePlaylistTime(record)}</div>
            </div>
          </div>
        );
      },
    },
    {
      title: t('schedule.schedule'),
      width: 150,
      key: 'schedule',
      render: (text, record, index) => {
        return (
          <Button
            className="d-flex align-items-center justify-content-center btn-schedule"
            icon={<img src={icon_calendar} alt="icon_calendar" className="mr-2" />}
            onClick={() => {
              setCurrentSchedulePlaylist(record);
              props.onSelectedPlaylistSchedule(record);
              onAddCurrentPlaylistSchedule(record);
            }}
          >
            {t('schedule.schedule')}
          </Button>
        );
      },
    },
    {
      title: t('action').toLocaleUpperCase(),
      key: 'action',
      width: 150,
      render: (record) => {
        return (
          <div className="d-flex align-items-center justify-content-start">
            <ButtonEditBlue
              onClick={() => {
                seEditPlaylist(record?.playlistId);
              }}
              showTitle={false}
              classNameContainer={'ml-0'}
            />
            <Popconfirm
              title={t('delete_title')}
              okText={t('ok')}
              cancelText={t('cancel')}
              style={{ padding: '10px 20px' }}
              overlayInnerStyle={{ padding: '10px 10px', background: 'white' }}
              onConfirm={() => {
                removeCurrentPlaylistItem(record, 2);
              }}
            >
              {/* <img style={{ width: 15, marginLeft: 15 }} src={icon_close} alt="icon close" className="cursor-pointer" /> */}
              <ButtonDeleteRed onClick={() => {}} showTitle={false} />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onCancel = () => {
    setOpenAssignModal(false);
  };

  const onSetOtherGroup = (input: SetOtherGroupI, playlistId?: string) => {
    if (playlistId)
      mutate({
        input: {
          _id: playlistId,
          ...input,
        },
      });
  };

  const playListItem = (item: PlaylistSchedule, index: number, type: number) => {
    return (
      <Draggable draggableId={`${item.playlistId}_${index}`} index={index} key={`${item.playlistId}_${index}`}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="p-3 border-bottom"
            onClick={() => onUpdateCurrentGroupActiveScreen(type)}
          >
            <Row gutter={8} wrap={false}>
              <Col span={2}>{index + 1}</Col>
              <Col span={12}>
                <p className="mb-1 font-18 playlist-name">{item?.playlist?.name || item.playlistName}</p>
                <div className="d-flex flex-column align-items-start justify-content-start">
                  <div className="d-flex align-items-center justify-content-start mb-1">
                    <span className="text-red d-flex align-items-center justify-content-start mr-1">
                      <img src={icon_calendar_check} alt="icon_calendar_check" className="mr-2" />
                      {t('schedule.schedule')}
                      <span className="ml-1">({renderTextScheduleType(item.type)})</span>
                    </span>
                  </div>
                  <div className="time">{renderSchedulePlaylistTime(item)}</div>
                </div>
              </Col>
              <Col span={6}>
                <Button
                  className="btn-schedule"
                  icon={<img src={icon_calendar} alt="icon_calendar" className="mr-2 mb-1" />}
                  onClick={() => {
                    setCurrentSchedulePlaylist(item);
                    props.onSelectedPlaylistSchedule(item);
                    onAddCurrentPlaylistSchedule(item);
                  }}
                >
                  {t('schedule.schedule')}
                </Button>
              </Col>
              <Col span={4}>
                <div className="d-flex align-items-center">
                  {(loginState &&
                    loginState.data &&
                    loginState.data._id === item?.createdById &&
                    loginState.data?.role === Roles.Editor) ||
                  loginState.data?.role === Roles.SuperAdmin ||
                  loginState.data?.role === Roles.Admin ? (
                    <ButtonEditBlue
                      onClick={() => {
                        seEditPlaylist(item?.playlistId);
                      }}
                      showTitle={false}
                      classNameContainer="ml-0"
                    />
                  ) : null}
                  <Popconfirm
                    title={t('delete_title')}
                    okText={t('ok')}
                    cancelText={t('cancel')}
                    style={{ padding: '10px 20px' }}
                    overlayInnerStyle={{ padding: '10px', background: 'white' }}
                    onConfirm={() => {
                      removeCurrentPlaylistItem(item, type);
                    }}
                  >
                    <ButtonDeleteRed onClick={() => {}} showTitle={false} />
                  </Popconfirm>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Draggable>
    );
  };

  const playList1 = useMemo(() => {
    return currentPlayList?.map((item, index) => playListItem(item, index, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlayList]);

  const playList2 = useMemo(() => {
    return currentScheduleSecondPlayList?.map((item, index) => playListItem(item, index, 2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentScheduleSecondPlayList]);

  const onDragEnd = (result: DropResult, list: PlaylistSchedule[], callback: (data: PlaylistSchedule[]) => void) => {
    if (result.destination && list.length) {
      const playlist = reorder(list, result.source.index, result.destination.index) as PlaylistSchedule[];
      playlist.forEach((item, index) => (item.index = index));
      callback(playlist);
    }
  };

  return (
    <div className="playlist">
      <div>
        <Card
          title={t('schedule.select_a_playlist')}
          extra={<ButtonOk key={'btn_coppy'} onClick={() => setOpenAssignModal(true)} title={t('button:copy')} />}
          bordered={false}
        >
          <Form onFinish={onFinishSelect} form={selectForm}>
            <div className="w-full select-playlist">
              <div style={{ width: `calc(100% - 110px)` }} className="d-flex align-items-center">
                {currentGroupState.data?.layoutSettings?.screenType === PlaylistScreenType.SplitScreen && (
                  <label className="lb-screen">{t('screen')} 1</label>
                )}
                <Form.Item
                  rules={[{ required: true, message: t('validate.required') }]}
                  className="bg_input_form mb-0"
                  name="playlists"
                  style={{ width: '100%', overflow: 'hidden' }}
                >
                  <Select
                    style={{ width: '100%', maxWidth: 680 }}
                    showSearch
                    dropdownClassName="mr-0"
                    allowClear
                    placeholder={t('schedule.select_a_playlist')}
                    notFoundContent={<div className="text-center py-3">{t('notification:no_data')}</div>}
                    onSearch={(e) => {
                      setFilterGroupPlaylist({
                        ...filterGroupPlaylist,
                        query: e,
                      });
                    }}
                    filterOption={false}
                    dropdownRender={(menu) => {
                      return (
                        <React.Fragment>
                          {menu}
                          <Row className="ml-2 mr-2 mt-2 mb-2 justify-content-center">
                            <Col className="mr-2">
                              <Button
                                onMouseDown={() => {
                                  if (
                                    filterGroupPlaylist &&
                                    filterGroupPlaylist.page &&
                                    filterGroupPlaylist.page <= 1
                                  ) {
                                    return;
                                  }
                                  setFilterGroupPlaylist({
                                    ...filterGroupPlaylist,
                                    page: (filterGroupPlaylist.page || 0) - 1,
                                  });
                                }}
                              >
                                {t('button:pre')}
                              </Button>
                            </Col>
                            <Col className="mr-2">
                              <Button
                                onMouseDown={() => {
                                  if (
                                    (filterGroupPlaylist &&
                                      filterGroupPlaylist?.page &&
                                      filterGroupPlaylist?.page === groupPlaylists?.getGroupPlaylists?.totalPage) ||
                                    (groupPlaylists?.getGroupPlaylists &&
                                      groupPlaylists?.getGroupPlaylists?.totalPage <= 0)
                                  ) {
                                    return;
                                  }
                                  setFilterGroupPlaylist({
                                    ...filterGroupPlaylist,
                                    page: (filterGroupPlaylist.page || 0) + 1,
                                  });
                                }}
                              >
                                {t('button:next')}
                              </Button>
                            </Col>
                            <Col className="mt-1">{`${groupPlaylists?.getGroupPlaylists?.currentPage || 1} / ${
                              groupPlaylists?.getGroupPlaylists?.totalPage || 1
                            }`}</Col>
                          </Row>
                        </React.Fragment>
                      );
                    }}
                  >
                    {groupPlaylists &&
                      groupPlaylists.getGroupPlaylists &&
                      groupPlaylists.getGroupPlaylists.items?.map((playlist: any) => (
                        <Option key={playlist._id} value={`${playlist._id}$${playlist.name}`}>
                          {playlist.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
              <Button icon={<PlusOutlined />} size="middle" htmlType="submit" className="btn-add-playlist">
                {t('add')}
              </Button>
            </div>
          </Form>
        </Card>
        <div className="table_schedule">
          <Row gutter={8} wrap={false} className="text-uppercase font-14 font-weight-bold px-3 py-2 bg-header">
            <Col span={2}>{t('stt')}</Col>
            <Col span={12}>{t('schedule.playlist')}</Col>
            <Col span={6}>{t('schedule.schedule')}</Col>
            <Col span={4}>{t('action')}</Col>
          </Row>
          {!currentPlayList.length ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('notification:no_data')} />
          ) : (
            <DragDropContext
              onDragEnd={(result) => onDragEnd(result, currentPlayList, onUpdateScheduleGroupDeviceSuccess)}
            >
              <Droppable droppableId="currentPlayListDroppableId">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {playList1}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
        {/* <Table
          className="table_schedule"
          dataSource={currentPlayList}
          rowKey={(item) => item?.index}
          onRow={(r) => ({
            onClick: () => onUpdateCurrentGroupActiveScreen(1),
          })}
          pagination={false}
          columns={columns1}
          scroll={{ x: 600 }}
          locale={{
            emptyText: t('notification:no_data'),
          }}
          rowClassName={(record, index) => {
            return `table-row-height table-row-white ${
              record?.playlistId === currentSchedulePlaylist?.playlistId &&
              record?.index === currentSchedulePlaylist?.index
                ? 'active'
                : ''
            }`;
          }}
        /> */}
        <AssignPlayListModal
          visible={openAssignModal}
          onCancel={onCancel}
          onSetOtherGroup={onSetOtherGroup}
          groupId={currentGroupState.data?._id}
        />
      </div>
      <EditPlaylist
        visible={!!editPlaylist}
        playlistId={editPlaylist}
        onCancel={() => {
          seEditPlaylist('');
        }}
      />
      {currentGroupState.data?.layoutSettings?.screenType === PlaylistScreenType.SplitScreen && (
        <div>
          <Card bordered={false}>
            <Form onFinish={onFinishSelect2} form={selectFormScreen2}>
              <div className="w-full select-playlist">
                <div style={{ width: `calc(100% - 110px)` }} className="d-flex align-items-center">
                  <label className="lb-screen">{t('screen')} 2</label>
                  <Form.Item
                    name="playlists"
                    className="mb-0"
                    rules={[{ required: true, message: t('validate.required') }]}
                    labelCol={{ span: 4 }}
                    style={{ width: '100%', overflow: 'hidden' }}
                  >
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      dropdownClassName="mr-0"
                      allowClear
                      placeholder={t('schedule.select_a_playlist')}
                      notFoundContent={<div className="text-center py-3">{t('notification:no_data')}</div>}
                      onSearch={(e) => {
                        setFilterGroupPlaylist({
                          ...filterGroupPlaylist,
                          query: e,
                        });
                      }}
                      filterOption={false}
                      dropdownRender={(menu) => {
                        return (
                          <React.Fragment>
                            {menu}
                            <Row className="ml-2 mr-2 mt-2 mb-2 justify-content-center">
                              <Col className="mr-2">
                                <Button
                                  onMouseDown={() => {
                                    if (
                                      filterGroupPlaylist &&
                                      filterGroupPlaylist.page &&
                                      filterGroupPlaylist.page <= 1
                                    ) {
                                      return;
                                    }
                                    setFilterGroupPlaylist({
                                      ...filterGroupPlaylist,
                                      page: (filterGroupPlaylist.page || 0) - 1,
                                    });
                                  }}
                                >
                                  {t('button:pre')}
                                </Button>
                              </Col>
                              <Col className="mr-2">
                                <Button
                                  onMouseDown={() => {
                                    if (
                                      (filterGroupPlaylist &&
                                        filterGroupPlaylist?.page &&
                                        filterGroupPlaylist?.page === groupPlaylists?.getGroupPlaylists?.totalPage) ||
                                      (groupPlaylists?.getGroupPlaylists &&
                                        groupPlaylists?.getGroupPlaylists?.totalPage <= 0)
                                    ) {
                                      return;
                                    }
                                    setFilterGroupPlaylist({
                                      ...filterGroupPlaylist,
                                      page: (filterGroupPlaylist.page || 0) + 1,
                                    });
                                  }}
                                >
                                  {t('button:next')}
                                </Button>
                              </Col>
                              <Col className="mt-1">{`${groupPlaylists?.getGroupPlaylists?.currentPage || 1} / ${
                                groupPlaylists?.getGroupPlaylists?.totalPage || 1
                              }`}</Col>
                            </Row>
                          </React.Fragment>
                        );
                      }}
                    >
                      {groupPlaylists &&
                        groupPlaylists.getGroupPlaylists &&
                        groupPlaylists.getGroupPlaylists.items?.map((playlist: any) => (
                          <Option key={playlist._id} value={`${playlist._id}$${playlist.name}`}>
                            {playlist.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
                <Button icon={<PlusOutlined />} size="middle" htmlType="submit" className="btn-add-playlist">
                  {t('add')}
                </Button>
              </div>
            </Form>
          </Card>
          <div className="table_schedule">
            <Row gutter={8} wrap={false} className="text-uppercase font-14 font-weight-bold px-3 py-2 bg-header">
              <Col span={2}>{t('stt')}</Col>
              <Col span={12}>{t('schedule.playlist')}</Col>
              <Col span={6}>{t('schedule.schedule')}</Col>
              <Col span={4}>{t('action')}</Col>
            </Row>
            {!currentScheduleSecondPlayList.length ? (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('notification:no_data')} />
            ) : (
              <DragDropContext
                onDragEnd={(result) =>
                  onDragEnd(result, currentScheduleSecondPlayList, onUpdateScheduleSecondGroupDeviceSuccess)
                }
              >
                <Droppable droppableId="currentScheduleSecondPlayListDroppableId">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {playList2}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </div>
          {/* <Table
            className="table_schedule"
            dataSource={currentScheduleSecondPlayList}
            rowKey={(item) => item?.index}
            pagination={false}
            columns={columns2}
            scroll={{ x: 600 }}
            onRow={(r) => ({
              onClick: () => onUpdateCurrentGroupActiveScreen(2),
            })}
            locale={{
              emptyText: t('notification:no_data'),
            }}
            rowClassName={(record, index) => {
              return `table-row-height table-row-white ${
                record?.playlistId === currentSchedulePlaylist?.playlistId &&
                record?.index === currentSchedulePlaylist?.index
                  ? 'active'
                  : ''
              }`;
            }}
          /> */}
        </div>
      )}
    </div>
  );
};

export default SchedulePlaylist;
