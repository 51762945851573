import { FileType } from '@/graphql/generated/graphql';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import useDeleteMedia from '@modules/Media/hooks/useDeleteMedia';
import useDeleteMediaItem from '@modules/Media/hooks/useDeleteMediaItem';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';
// import useGetAllMedia from '@modules/Media/hooks/useGetAllMedia';
// import { pageDefault } from '@modules/Media/types';

export default function DeleteModal() {
  const { item, onDeleteItem } = useDeleteMediaItem();
  const { t } = useTranslation(['media']);
  const { onDeleteMedia } = useDeleteMedia();
  // const { data, onGetMedias } = useGetAllMedia();

  const handleCancel = () => {
    onDeleteItem(undefined);
  };
  const handleOk = () => {
    if (item) {
      onDeleteMedia({ _id: item?._id });
      onDeleteItem(undefined);
    }
  };

  return (
    <Modal
      onOk={handleOk}
      okText={t('ok_text')}
      cancelText={t('cancel_text')}
      onCancel={handleCancel}
      className="delete-item-modal"
      title={t('notice')}
      visible={false}
      okButtonProps={{
        style: {
          color: '#fff',
          backgroundColor: '#c8171e',
          borderColor: '#c8171e',
        },
      }}
    >
      {/* */}
      {item?.type === FileType.File ? (
        <p style={{ fontSize: 15 }}>
          {' '}
          <ExclamationCircleOutlined style={{ color: '#c8171e', marginRight: 15, fontSize: 30 }} />
          {t('question_action_delete_file')}
        </p>
      ) : (
        <p style={{ fontSize: 15 }}>
          {' '}
          <ExclamationCircleOutlined style={{ color: '#c8171e', marginRight: 15, fontSize: 30 }} />
          {t('question_action_delete_folder')}
        </p>
      )}
    </Modal>
  );
}
