/* eslint-disable react-hooks/exhaustive-deps */
import { getHistory } from '@helpers/history';
import useUpdatePlaylistToOtherGroup from '@modules/GroupDevice/hooks/useUpdatePlaylistToOtherGroup';
import ModalAssignGroup, {
  SetOtherGroupI,
  ViewAssign,
} from '@modules/PlayList/components/assigngroup/AssignGroupModal';
import EditPlaylistForm from '@modules/PlayList/pages/AddNew';
import { Drawer, DrawerProps } from 'antd';
import React, { useEffect, useRef } from 'react';

interface ScheduleTypesI {
  onCancel: () => void;
  visible: boolean;
  isLoading?: boolean;
  placement?: DrawerProps['placement'];
  playlistId?: string;
}

export default function EditPlaylist(props: ScheduleTypesI) {
  const { mutate, isSuccess } = useUpdatePlaylistToOtherGroup();

  const refAssignGroup = useRef<any>();
  const refEditPlaylist = useRef<any>();
  const history = getHistory();

  const onSetOtherGroup = (input: SetOtherGroupI) => {
    if (props.playlistId)
      mutate({
        input: {
          _id: props.playlistId,
          ...input,
        },
      });
  };

  useEffect(() => {
    if (isSuccess) {
      history.push('/group-device/list');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
  useEffect(() => {
    if (!props.visible) {
    }
  }, [props.visible]);

  return (
    <Drawer
      placement={props?.placement || 'right'}
      maskClosable={true}
      closable={false}
      onClose={() => {
        refEditPlaylist.current?.onResetDataItem?.();
        props.onCancel();
      }}
      visible={props.visible}
      width={'82%'}
    >
      <EditPlaylistForm
        playlistId={props?.playlistId}
        onSetToOtherGroup={() => {
          refAssignGroup?.current.onShow?.();
        }}
        ref={refEditPlaylist}
        view={ViewAssign.GROUP}
        onClose={() => {
          refEditPlaylist.current?.onResetDataItem?.();
          props.onCancel();
        }}
      />
      <ModalAssignGroup view={ViewAssign.GROUP} ref={refAssignGroup} onSetOtherGroup={onSetOtherGroup} />
    </Drawer>
  );
}
