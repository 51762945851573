import { QueryCompaniesInput } from '@/graphql/generated/graphql';
import ButtonCancel from '@commons/components/modals/Button/ButtonCancel';
import ButtonOk from '@commons/components/modals/Button/ButtonOk';
import useGetAllCompany from '@modules/Company/hooks/useGetAllCompany';
import { Button, Col, Modal, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  isShow: boolean;
  onCancel: () => void;
  onOk: (value: string) => void;
  currentCompanyId?: string | null | undefined;
}

const { Option } = Select;

export default function SelectCompanyModal(props: IProps) {
  const { t } = useTranslation(['leftNavigation', 'company']);
  const [filterCompany, setFilterCompany] = useState<QueryCompaniesInput>({
    page: 1,
    limit: 8,
    query: undefined,
  });
  const { data: listCompany, refetch } = useGetAllCompany(filterCompany);
  const [selectedCompanyId, setSelectedCompanyId] = useState('');

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      centered
      visible={!!props.isShow}
      cancelText={t('cancel')}
      onCancel={() => props.onCancel()}
      className="modal_confirm_custom width_modal_add_device"
      title={t('switch_admin')}
      footer={[
        <ButtonCancel key={'btn_cancel'} title={t('cancel')} classNameBtn={`mr-2 `} onClick={props.onCancel} />,
        <ButtonOk key={'btn_ok'} onClick={() => props.onOk(selectedCompanyId)} title={t('switch')} />,
      ]}
    >
      <label>{t('company:company_search')}:</label>
      <Select
        className="w-100 my-2 rounded-0"
        showSearch
        dropdownClassName="mr-0"
        allowClear
        placeholder={t('company:company_enter')}
        searchValue={filterCompany.query || ''}
        onSearch={(e) => {
          setFilterCompany({
            ...filterCompany,
            page: 1,
            query: e,
          });
        }}
        filterOption={false}
        onChange={(value: string) => {
          const companyId = value?.split('/$/')[0];
          setSelectedCompanyId(companyId);
        }}
        dropdownRender={(menu) => {
          return (
            <React.Fragment>
              {menu}
              <Row className="mt-2 mb-2 justify-content-center align-items-center">
                <Col className="mr-2">
                  <Button
                    size="middle"
                    onMouseDown={() => {
                      if (filterCompany && filterCompany.page && filterCompany.page <= 1) {
                        return;
                      }
                      setFilterCompany({
                        ...filterCompany,
                        page: (filterCompany.page || 0) - 1,
                      });
                    }}
                  >
                    {t('prev')}
                  </Button>
                </Col>
                <Col className="mr-2">
                  <Button
                    size="middle"
                    onMouseDown={() => {
                      if (
                        (filterCompany &&
                          filterCompany.page &&
                          filterCompany.page === listCompany?.companies?.totalPage) ||
                        (listCompany?.companies && listCompany?.companies.totalPage <= 0)
                      ) {
                        return;
                      }
                      setFilterCompany({
                        ...filterCompany,
                        page: (filterCompany.page || 0) + 1,
                      });
                    }}
                  >
                    {t('next')}
                  </Button>
                </Col>
                <Col className="mt-1">{`${listCompany?.companies?.currentPage} / ${listCompany?.companies?.totalPage}`}</Col>
              </Row>
            </React.Fragment>
          );
        }}
      >
        {props.currentCompanyId ? (
          <Option key={'switch_to_super_admin'} value="" title={t('switch_to_super_admin')}>
            <strong>
              <i>{t('switch_to_super_admin')}</i>
            </strong>
          </Option>
        ) : null}

        {listCompany &&
          listCompany?.companies &&
          listCompany?.companies.items?.map((company: any) => (
            <Option key={company?._id} value={`${company._id}/$/${company.name}`} title={`${company.name}`}>
              {company.name}
            </Option>
          ))}
      </Select>
    </Modal>
  );
}
