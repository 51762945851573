/* eslint-disable react-hooks/exhaustive-deps */
import env from '@/env';
import { Company } from '@/graphql/generated/graphql';
import addIcon from '@assets/images/icons/ic_add_person.svg';
import exportIcon from '@assets/images/icons/ic_export.svg';
import ModalConfirm from '@commons/components/modals/ModalConfirm';
import { NotificationError, NotificationSuccess } from '@commons/components/Notification';
import TableLayout from '@commons/components/TableLayout';
import TableWithNumber from '@commons/components/TableWithNumber';
import { FULL_DATE } from '@helpers/date';
import { getHistory } from '@helpers/history';
import { _formatNum } from '@helpers/regex';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import useAutoLogin from '@modules/Auth/hooks/useAutoLogin';
import { LoginState } from '@modules/Auth/redux/action-types';
import CompanyRowAction from '@modules/Company/components/Action/CompanyRowAction';
import useDeleteCompany from '@modules/Company/hooks/useDeleteCompany';
import useGetAllCompany from '@modules/Company/hooks/useGetAllCompany';
import { CompanyFilter, PageSizeTable } from '@modules/Company/types';
import { ExportExcelApi } from '@modules/PlayList/services/apis';
import { getQueryVariable } from '@modules/Setting/helpers/utils';
import { RootState } from '@redux/reducers';
import { Button, Form, Input, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

interface IState {
  updateData?: Company;
  deleteData?: Company;
  addNewVisible: boolean;
}

export default function CompanyListPage() {
  const { t } = useTranslation(['company', 'group-device']);

  const history = useHistory();

  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);
  const [loadingExport, setLoadingExport] = useState(false);

  const [state, setState] = useState<IState>({
    updateData: undefined,
    deleteData: undefined,
    addNewVisible: false,
  });
  const {
    isLoading: deleteCompanyLoading,
    mutate: onDeleteCompanyMutate,
    isSuccess: isDeleteSuccess,
  } = useDeleteCompany();

  const { setPageTitle } = useSetPageTitle();

  const { onAutoLogin } = useAutoLogin();

  useEffect(() => {
    t && setPageTitle(t('customer_list'));
    // eslint-disable-next-line
  }, [t]);

  const onCancel = () => {
    setState({ ...state, updateData: undefined, deleteData: undefined, addNewVisible: false });
  };

  const onDelete = () => {
    if (state.deleteData) {
      onDeleteCompanyMutate({ input: { _id: state.deleteData._id } });
    }
  };

  const handleAutoLogin = (company: Company) => {
    onAutoLogin(company._id.toString());
  };

  React.useEffect(() => {
    if (isDeleteSuccess) onCancel();
    // eslint-disable-next-line
  }, [isDeleteSuccess]);

  useEffect(() => {
    const query = getQueryVariable('page');
    setPaginate({
      ...paginate,
      page: query ? Number(query) : 1,
    });

    return () => {};
  }, [history.location.search]);

  const columns: ColumnsType<any> = [
    {
      title: t('group-device:stt'),
      key: '#',
      dataIndex: 'STT',
      width: 60,
      align: 'center',
    },
    {
      title: t('name'),
      key: 'name',
      dataIndex: 'name',
      width: 200,
      render: (value) => {
        return (
          <Tooltip title={value || 'name'}>
            <span className="fw-500 text-2-line">{value}</span>
          </Tooltip>
        );
      },
    },
    {
      responsive: ['lg'],
      title: t('address'),
      key: 'address',
      dataIndex: 'address',
      width: 200,
      render: (_, record) => {
        return (
          <Tooltip title={_ || 'name'}>
            <span className="text-2-line">{_}</span>
          </Tooltip>
        );
      },
    },
    {
      title: t('phone'),
      key: 'phone',
      dataIndex: 'phone',
      width: 100,
    },
    {
      title: t('start_date'),
      key: 'startDate',
      dataIndex: 'startDate',
      width: 100,
      render: (startDate: any) => <div>{startDate ? moment(startDate).format(FULL_DATE) : null}</div>,
    },
    {
      title: t('end_date'),
      key: 'endDate',
      dataIndex: 'endDate',
      width: 100,
      render: (endDate: any) => <div>{endDate ? moment(endDate).format(FULL_DATE) : null}</div>,
    },
    {
      title: t('limit_upload'),
      key: 'limitUpload',
      dataIndex: 'limitUpload',
      width: 100,
      render: (limitUpload: any) => <div className="d-flex align-items-center h-full">{_formatNum(limitUpload)}</div>,
    },
    {
      title: '',
      key: 'action',
      width: 120,
      render: (record: any) => (
        <CompanyRowAction
          record={record}
          onEdit={(record) => {
            getHistory().push(`/company/${record._id}?page=${paginate.page}`);
          }}
          onDelete={(record) => {
            setState({ ...state, deleteData: record });
          }}
          onLogin={(record) => {
            handleAutoLogin(record);
          }}
        />
      ),
    },
  ];

  // eslint-disable-next-line
  const [paginate, setPaginate] = useState({
    page: 1,
    pageSize: PageSizeTable,
  });

  // eslint-disable-next-line
  const [filter, setFilter] = useState<CompanyFilter>({});

  // eslint-disable-next-line
  const { isLoading, data } = useGetAllCompany({
    page: paginate.page,
    limit: paginate.pageSize,
    capacity: filter?.capacity,
    query: filter?.query || undefined,
  });

  const [form] = Form.useForm();

  const onSearch = (values: any) => {
    const input = {
      query: values.query ? values.query : undefined,
      capacity: values.capacity || undefined,
    };
    if (paginate.page !== 1) {
      setPaginate({
        ...paginate,
        page: 1,
      });
    }
    setFilter(input);
  };

  const onExportClick = async () => {
    setLoadingExport(true);
    try {
      const result = await ExportExcelApi();
      if (result?.exportCompany) {
        setLoadingExport(false);
        NotificationSuccess(t('notification'), t('export_success'));
        window.open(env.apiHost + '/' + result?.exportCompany, '_blank');
      } else {
        setLoadingExport(false);
        NotificationError(t('warring'), t('export_fail'));
      }
    } catch (error) {
      NotificationError(t('warring'), t('export_fail'));
      setLoadingExport(false);
    }
  };

  return (
    <div>
      <TableLayout
        tableHeader={
          !loginState.data?.companyId ? (
            <Form
              layout="inline"
              form={form}
              onFinish={onSearch}
              className="table-form-search justify-content-end justify-content-md-end  "
            >
              <Form.Item>
                <Input.Group compact>
                  <Form.Item name="query" className="w-full">
                    <Input className="bd-form-search" placeholder={`${t('search')}`} />
                  </Form.Item>
                  <Button icon={<FiSearch color="#F80000" />} className="btn-search" htmlType="submit" />
                </Input.Group>
              </Form.Item>
              <Button
                icon={<img src={exportIcon} style={{ marginRight: -20 }} alt="add company" />}
                size="middle"
                onClick={onExportClick}
                className="btn-export"
                loading={loadingExport}
                type="default"
                disabled={loadingExport}
              >
                {t('export')}
              </Button>
              <Button
                icon={<img src={addIcon} style={{ marginRight: 10 }} alt="add company" />}
                size="middle"
                onClick={() => history.push(`/company/new?page=${paginate.page}`)}
                className="btn-add-new"
                type="primary"
              >
                {t('add_new')}
              </Button>
            </Form>
          ) : null
        }
      >
        <TableWithNumber
          className="table-striped-rows"
          tableLayout="auto"
          rowKey={(data) => data._id}
          columns={columns}
          dataSource={data?.companies?.items || []}
          loading={isLoading}
          pagination={false}
          paginate={{
            pageSize: paginate.pageSize,
            total: data?.companies?.total || 0,
            current: data?.companies?.currentPage || 1,
            onChange: (page) => {
              history.push(`/company/list?page=${page}`);
            },
          }}
        />
      </TableLayout>
      <ModalConfirm
        isLoading={deleteCompanyLoading}
        data={state.deleteData}
        handleOk={onDelete}
        handleCancel={onCancel}
        title={t('delete_title')}
        okTitle={t('delete')}
        content={
          <Trans
            i18nKey="delete_content"
            t={t}
            values={{ name: state.deleteData?.name }}
            components={[<span className="primary_color fw-500"></span>]}
          />
        }
      />
    </div>
  );
}
